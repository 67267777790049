// React
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { matchPath } from 'react-router';

// Redux
import connect from "react-redux/es/connect/connect";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Error as ErrorIcon, Check as CheckIcon} from "@material-ui/icons";

// Creative Tim
import stylesAdmin from "creativetim/material-dashboard-pro/assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import Snackbars from "creativetim/material-dashboard-pro/components/Snackbar/Snackbar.js";

// Vendor
import cx from "classnames";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// App
import AdminNavbar from "components/AdminNavbar.js";
import Footer from "components/Footer.js";
import Sidebar from "components/Sidebar.js";
import routes from "routes.js";
import {setError, setMessage} from "../actions";

const styles = theme => ({
    ...stylesAdmin(theme),
    content: {
        ...stylesAdmin(theme).content,
        minHeight: "calc(100vh - 190px)"
    },
})
var ps;

const useStyles = makeStyles(styles);

function Dashboard(props) {
    const {setMessage, setError, message, error, form, fetching, ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [image] = React.useState(require("creativetim/material-dashboard-pro/assets/img/sidebar-2.jpg"));
  const [color] = React.useState("blue");
  const [bgColor] = React.useState("black");
  const [logo] = React.useState(require("creativetim/material-dashboard-pro/assets/img/logo-white.svg"));
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
    });
  // ref for main panel div
  const mainPanel = React.createRef();

  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";

    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);

    };

  });

    React.useEffect(() => {
        let timerError = null;
        if (formError(error)) {
            timerError = setTimeout(cancelError, 3000)
        }
        let timerMessage = null
        if (formMessage(message, error)) {
            timerMessage = setTimeout(cancelMessage, 3000)
        }

        return function cleanup() {
            if (timerError) {
                clearTimeout(timerError)
            }
            if (timerMessage) {
                clearTimeout(timerMessage)
            }
        }
    })
    const [updateNow, setUpdateNow] = React.useState(true)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = routes => {
    let activeRoute = "Accueil";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
          if (
              matchPath( window.location.pathname, { path:routes[i].path } )
        ) {

            return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
    const getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (prop.layout === "/admin") {
                return (
                    <Route
                        path={prop.path}
                        component={prop.component}
                        key={key}
                    />
            );
            } else {
                return null;
            }
        });
    };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };


  const renderPages = () => {
      // On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller
      return  getRoute() ? (

          <div className={classes.content}>
            <div className={classes.container}>
                <Switch>
                    {getRoutes(routes)}
                    <Redirect from="/admin" to="/admin/dashboard" />
                </Switch>
            </div>
          </div>
  ) : (
      <div className={classes.map}>
        <Switch>
            {getRoutes(routes)}
            <Redirect from="/admin" to="/admin/dashboard" />
        </Switch>
      </div>
  )

  }


  const renderFooter = () => {
      return getRoute() ? <Footer fluid /> : null
  }

    const forms = [
        'configurationBijouterieForm',
        'configurationEscapeGameForm',
        'configurationCyberForm',
        'configurationImmeubleForm',
        'configurationGarageForm',
        'configurationDecennaleForm',
        'configurationInformatiqueForm',
        'clientForm',
        'devisEscapeGameForm',
        'devisBijouterieForm',
        'devisCyberForm',
        'devisImmeubleForm',
        'devisGarageForm',
        'devisDecennaleForm',
        'devisInformatiqueForm',
    ]

    var cancelError = function() {
        setError('')
        forms.forEach(
            formName => {
                if (form[formName]) {
                    form[formName].error = ''
                }
            }
        )
        setUpdateNow(!updateNow)
    };

    var cancelMessage = function () {
        setMessage('')
    }
    const formError = (error) => {
        if (fetching) {
            return false;
        }

        let formError = null

        if (error) {
            formError = error
        }

        forms.forEach(
            formName => {
                if (form[formName] && form[formName].error) {
                    formError = form[formName].error;
                }
            }
        )

        if (formError) {

            return formError
        }

        return false
    }

    const formMessage = (message, error) => {
        if (fetching) {
            return false;
        }

        if (message && !error) {
            return message
        }
        return false
    }

  return (

    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={"BA Assurances"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
      <div id="main-panel" className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />

        {!fetching &&
            <Snackbars
                place="tc"
                color="danger"
                icon={ErrorIcon}
                message={formError(error)}
                open={formError(error) ? true : false}
                closeNotification={() => false}
            />
        }
        {!fetching &&
            <Snackbars  xs={12} sm={6} md={4}
                place="tc"
                color="info"
                message={formMessage(message, error)}
                icon={CheckIcon}
                open={formMessage(message, error) ? true : false}
                closeNotification={() => false}
            />
        }
        {renderPages()}
        {renderFooter()}
      </div>
    </div>
  );
}

function  mapStateToProps(store) {
    return {
        ...store.messageReducer,
        ...store.requestReducer,
        form: store.form
    }
}

const mapDispatchToProps = {
    setMessage,
    setError,
}

//export default Pages
export default connect(mapStateToProps, mapDispatchToProps)(
    (Dashboard)
)

