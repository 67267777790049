//React
import React, {Component} from "react";

// @material-ui/core components
import { Box, Slide, Dialog, DialogTitle, DialogContent, DialogActions} from "@material-ui/core";
import { Assignment as AssignmentIcon, StarBorder as StarBorderIcon, Delete as DeleteIcon, Close as CloseIcon } from '@material-ui/icons';
import withStyles from "@material-ui/core/styles/withStyles";

// Redux
import connect from "react-redux/es/connect/connect";
import {Field, formValueSelector, reduxForm} from "redux-form";

// Creative Tim
import GridContainer from "creativetim/material-dashboard-pro/components/Grid/GridContainer.js";
import GridItem from "creativetim/material-dashboard-pro/components/Grid/GridItem.js";
import Table from "creativetim/material-dashboard-pro/components/Table/Table.js";
import CustomLinearProgress from "creativetim/material-dashboard-pro/components/CustomLinearProgress/CustomLinearProgress.js";
import Card from "creativetim/material-dashboard-pro/components/Card/Card.js";
import CardBody from "creativetim/material-dashboard-pro/components/Card/CardBody.js";
import CardHeader from "creativetim/material-dashboard-pro/components/Card/CardHeader.js";
import CardIcon from "creativetim/material-dashboard-pro/components/Card/CardIcon.js";
import Button from "creativetim/material-dashboard-pro/components/CustomButtons/Button.js";

// Vendor

// App
import Informations from "./DevisEscapeGame/Informations.js";
import InformationsPublic from "./DevisEscapeGame/InformationsPublic.js";
import CoordonneesSouscripteur from "./DevisEscapeGame/CoordonneesSouscripteur.js";
import Description from "./DevisEscapeGame/Description.js";
import MoyensIncendie from "./DevisEscapeGame/MoyensIncendie.js";
import MoyensVol from "./DevisEscapeGame/MoyensVol.js";
import AntecedentsAssurance from "./DevisEscapeGame/AntecedentsAssurance.js";
import GarantiesSouhaitees from "./DevisEscapeGame/GarantiesSouhaitees.js";
import GarantiesOptionnellesSouhaitees from "./DevisEscapeGame/GarantiesOptionnellesSouhaitees.js";
import ProtectionDonnees from "./DevisEscapeGame/ProtectionDonnees.js";
import ProtectionDonneesPublic from "./DevisEscapeGame/ProtectionDonneesPublic.js";

import {createRowState, renderDropzoneField} from 'formUtils'
import {deleteDocument, getConfigEscapeGame, devisEscapeGameReset, getDevisEscapeGame, devisEscapeGameSave, getClients, getCourtiers, getLastConfigEscapeGame, requestFetching, setMessage, download} from "../actions";

import styles from "assets/jss/styles.js"
import columns from "./DevisEscapeGame/Columns.js";
import {validateColumns} from 'columnsUtils'
import Wizard from "components/Wizard.js";

var moment = require('moment');
require('moment/locale/fr');

const selector = formValueSelector('devisEscapeGameForm')

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class DevisEscapeGame extends Component {

    constructor(props) {
        super(props);
        this.columns = [];
        this.state = {
            init : false,
            public : window.location.pathname.indexOf('/iframe') === 0,
            files:new FormData(),
            modalDelete:false,
            documentToDelete:null,
            initialId:props.match.params.id
        };
        this.abortController = new AbortController();
        this.mounted = false;
        this.dateTimeResilieRef = React.createRef()
        this.dateTimeSansEffetRef = React.createRef()
    }

    componentWillUnmount()
    {
        this.abortController.abort();
        this.mounted = false;
    }
    changeDateResilie (date) {
        this.props.change("dateContratResilie", date)
        setTimeout(function() {this.dateTimeResilieRef.current.closeCalendar()}.bind(this),100);
    }
    changeDateSanseffet (date) {
        this.props.change("dateContratSanseffet", date)
        setTimeout(function() {this.dateTimeSansEffetRef.current.closeCalendar()}.bind(this),100);
    }
    handleDownload(url, nom) {
        this.props.download(url + '/download', nom)
    }
    handleDelete(url, nom) {
        this.setState({modalDelete: true, documentToDelete : {url:url, nom:nom}});
    }
    async handleDeleteConfirm()
    {
        if (!this.state.documentToDelete) {
            return;
        }

        await this.props.deleteDocument(this.state.documentToDelete['url'])
        this.props.getDevisEscapeGame(this.props.match.params.id, this.abortController, this.props.history)
        this.setState({modalDelete:false});
    }
    getDocuments()
    {
        if (this.props.initialValues.documents && this.props.initialValues.documents.length) {
            return this.props.initialValues.documents.slice(0).reverse().map(
                document =>  {
                    let src = null
                    let mimetype = document.mimetype ? document.mimetype.split('/')[1] : null;
                    try{
                        src = require(`assets/img/mimetype/32px/${mimetype}.png`);
                    }
                    catch(err){
                        src = require(`assets/img/mimetype/32px/_blank.png`);
                    }
                    return [
                        document['nom'],
                        new moment(document.date).format('L LT'),
                        <img alt={document['nom']} className={this.props.classes.cursorPointer} onClick={this.handleDownload.bind(this, document['@id'], document['nom'])}  src={src} />,
                        <DeleteIcon onClick={this.handleDelete.bind(this, document['@id'], document['nom'])} />
                    ]
                }
            )
        }

        return [];
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!this.mounted) {
            return false;
        }

        if (!this.state.public) {

            this.columns = columns(
                {
                    config: nextProps.configurationEscapeGame.uri,
                    changeDateResilie: this.changeDateResilie,
                    dateContratResilieValue: nextProps.dateContratResilieValue,
                    changeDateSanseffet: this.changeDateSanseffet,
                    dateContratSanseffetValue: nextProps.dateContratSanseffetValue,
                    statutContratValue: nextProps.statutContratValue,
                    obj: this
                }
            )['state'];

            if (!this.props.match.params.id) {
                let columnDevis = this.columns.find(column => column.name === 'statutDevis')
                delete this.columns[this.columns.indexOf(columnDevis)];
                this.props.change("config", this.props.configurationEscapeGame.uri)
            }
        }

        return true;
    }

    async  componentDidMount() {
        this.mounted = true;
        this.props.devisEscapeGameReset()
        if (!this.state.public) {
            await this.props.getCourtiers(this.abortController)
            await this.props.getClients(this.abortController)
            if (this.props.match.params.id) {
                await this.props.getDevisEscapeGame(this.props.match.params.id, this.abortController, this.props.history)
                await this.props.getConfigEscapeGame(this.props.initialValues.config, this.abortController)
            } else {
                await this.props.getLastConfigEscapeGame(this.abortController, true)
            }
        } else {
            await this.props.getLastConfigEscapeGame(this.abortController, false)
            if (this.mounted) {
                this.props.change("ispublic", true)
            }
        }
        if (this.mounted) {
            this.setState({ init: true});
        }
    }

    async componentDidUpdate()
    {
        if (!this.state.init || !this.mounted) {
            return;
        }

        if (!this.state.public && this.props.match.params.id && this.props.match.params.id !== this.state.initialId) {
            this.setState({ init: false});
            await this.props.getDevisEscapeGame(this.props.match.params.id, this.abortController, this.props.history)
            await this.props.getConfigEscapeGame(this.props.initialValues.config, this.abortController)
            this.setState({ init: true, initialId: this.props.match.params.id});
        }
    }

    submitButton({classes, handleSubmit, pristine, submitting}) {
        if (this.props.match.params.id) {
            return (
                <div className={classes.textRight}>
                    <Button onClick={handleSubmit(this.submit.bind(this))} disabled={pristine || submitting} color="rose" >
                Sauvegarder
                </Button>
                </div>
            )
        }
    }

    render() {
        const { handleSubmit, classes, fetching} = this.props;

        const wait = !this.state.init || fetching;
        if (this.state.init && this.state.public && !this.props.configurationEscapeGame.activePublic) {
            return (
                <GridContainer>
                      <GridItem xs={12}>
                            <Card>
                              <CardHeader >
                                <div className={classes.wizardHeader}>
                                    <h3 className={classes.title}>Nouveau devis</h3>
                                    <h5 className={classes.subtitle}>{this.props.configurationEscapeGame['nom']}</h5>
                                </div>
                              </CardHeader>
                              <CardBody className={classes.textCenter}>
                                Ce produit n'est actuellement pas disponible<br/>
                                <br/>
                                <br/>
                             </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            )
        }
        if (wait) {
            return (
                <div className={classes.linearProgressWrapper} >
                    <CustomLinearProgress color="primary"/ >
                </div>
            )
        }
        return (

            <div>
                <Box display={this.state.public && this.props.initialValues.id !== null ? "block" : "none"}>
                    <GridContainer>
                          <GridItem xs={12}>
                                <Card>
                                  <CardHeader >
                                    <div className={classes.wizardHeader}>
                                        <h3 className={classes.title}>Nouveau devis</h3>
                                        <h5 className={classes.subtitle}>{this.props.configurationEscapeGame['nom']}</h5>
                                    </div>
                                  </CardHeader>
                                  <CardBody className={classes.textCenter}>
                                    Votre devis est en cours de cotation, une réponse vous sera apportée dans les 48h<br/>
                                    <br/>
                                    <br/>
                                 </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </Box>
                <Box display={this.state.public && this.props.initialValues.id !== null ? "none" : "block"}>
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                        <Box className={classes.linearProgressWrapper} display={wait ? "block" : "none"}>
                            <CustomLinearProgress color="primary"/ >
                        </Box>

                        <Box>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12}>
                                    <Wizard
                                        validate
                                        steps={[
                                                { stepName: "1/9", stepComponent: this.state.public ? InformationsPublic : Informations, stepId: "informations"},
                                                { stepName: "2/9", stepComponent: CoordonneesSouscripteur, stepId: "CoordonneesSouscripteur"},
                                                { stepName: "3/9", stepComponent: Description, stepId: "Description"},
                                                { stepName: "4/9", stepComponent: MoyensIncendie, stepId: "MoyensIncendie"},
                                                { stepName: "5/9", stepComponent: MoyensVol, stepId: "MoyensVol"},
                                                { stepName: "6/9", stepComponent: AntecedentsAssurance, stepId: "AntecedentsAssurance"},
                                                { stepName: "7/9", stepComponent: GarantiesSouhaitees, stepId: "GarantiesSouhaitees"},
                                                { stepName: "8/9", stepComponent: GarantiesOptionnellesSouhaitees, stepId: "GarantiesOptionnellesSouhaitees"},
                                                { stepName: "9/9", stepComponent: this.state.public && this.props.initialValues.id == null ? ProtectionDonneesPublic : ProtectionDonnees, stepId: "ProtectionDonnees"},
                                        ]}
                                        title="Nouveau devis"
                                        subtitle={this.props.configurationEscapeGame['nom']}
                                        finishButtonClick={handleSubmit(this.submit.bind(this))}
                                        nextButtonText="Suivant"
                                        previousButtonText="Précédent"
                                        finishButtonText="Enregistrer"
                                        finishButtonClasses="boutonEnregistrer"
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                  <GridItem xs={12}>
                                        <Card>
                                          <CardHeader color="rose" icon>
                                            <CardIcon color="rose">
                                              <AssignmentIcon />
                                            </CardIcon>
                                            <h4 className={classes.cardIconTitle}>Documents</h4>
                                          </CardHeader>
                                          <CardBody>
                                            {this.getDocuments().length > 0 &&
                                                <Table
                                                  tableHeaderColor="primary"
                                                  tableHead={["Nom", "Date", "Téléchargement", "Suppression"]}
                                                  tableData={this.getDocuments()}
                                                  coloredColls={[3]}
                                                  colorsColls={["primary"]}
                                                />
                                            }
                                            <Field name="picture" classes={classes} component={renderDropzoneField} key={new Date(this.props.initialValues.dateEdition).getTime()} type="file" change={this.handleChange.bind(this)}  />
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                            {!this.state.public &&
                                <GridContainer justify="center">
                                    <GridItem xs={12} sm={12}>
                                        <Card>
                                            <CardHeader color="rose" icon>
                                                <CardIcon color="rose">
                                                    <StarBorderIcon />
                                                </CardIcon>
                                                <h4 className={classes.cardIconTitle}>Administration</h4>
                                            </CardHeader>
                                            <CardBody>
                                                <GridContainer>
                                                    {
                                                        this.columns.map( (column, i) =>
                                                            {
                                                                if (column.condition === undefined || column.condition(this.props)) {
                                                                    return (createRowState(column, classes, fetching, i, false))
                                                                }
                                                                return null
                                                            }
                                                        )
                                                    }
                                                </GridContainer>
                                                {this.submitButton(this.props)}
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                            }
                        </Box>

                        <Dialog
                            classes={{
                                root: classes.center,
                                    paper: classes.modal
                            }}
                            open={this.state.modalDelete}
                            transition={Transition}
                            keepMounted
                            onClose={() => this.setState({modal: false})}
                            aria-labelledby="modal-slide-title"
                            aria-describedby="modal-slide-description"
                                >
                                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                        <Button
                            justIcon
                            className={classes.modalCloseButton}
                            key="close"
                            aria-label="Close"
                            color="transparent"
                            onClick={() => this.setState({modalDelete: false})}
                        >
                        <CloseIcon className={classes.modalClose} />
                            </Button>
                            <h4 className={classes.modalTitle}>Suppression d'un document</h4>
                            </DialogTitle>
                            <DialogContent id="modal-slide-description" className={classes.modalBody} >
                                Êtes-vous sûr de vouloir supprimer le document {this.state.documentToDelete ? this.state.documentToDelete['nom'] : null} ?
                            </DialogContent>
                            <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter} >
                                <Button onClick={() => this.setState({modalDelete: false})}>Annuler</Button>
                                <Button disabled={!this.state.documentToDelete} onClick={() => this.handleDeleteConfirm()} color="success">Supprimer</Button>
                            </DialogActions>
                        </Dialog>
                    </form>
                </Box>
            </div>
        );
    }

    handleChange(files){
        let formData = new FormData()
        formData.append('fileLength', files.length)
        files.forEach(
            (file, i) => formData.append('file' + i, file, file.name)
        )

        this.setState({
            files: formData
        });
    }
    submit(post) {
        this.setState({count:1})
        return this.props.devisEscapeGameSave(post, this.props.history, this.state.files)

    }

}

function validate (values) {
    values = values ? values : {}
    let errors = {}
    if (window.location.pathname.indexOf('/iframe') === 0) {
        errors = {...validateColumns(values, columns({})['informationsPublic']), ...errors};
    } else {
        errors = {...validateColumns(values, columns({})['informations']), ...errors};
    }
    errors = {...validateColumns(values, columns({})['coordonneesSouscripteur']), ...errors};
    errors = {...validateColumns(values, columns({})['description']), ...errors};
    errors = {...validateColumns(values, columns({})['moyensIncendie']), ...errors};
    errors = {...validateColumns(values, columns({})['moyensVol']), ...errors};
    errors = {...validateColumns(values, columns({})['antecedentsAssurance']), ...errors};
    // Cas spécifique : on redonne les props car le required est varialble
    errors = {...validateColumns(values, columns({
            descriptionValeurContenuValue :values.descriptionValeurContenu,
            garantieDegatsEauxValue:values.garantieDegatsEaux,
            garantieDegatsVolValue:values.garantieDegatsVol,
            garantieDegatsBrisGlaceValue:values.garantieDegatsBrisGlace,
            garantieDegatsDommagesElectriquesValue:values.garantieDegatsDommagesElectriques,
            garantieDegatsBrisMachineValue:values.garantieDegatsBrisMachine,
        })['garantiesSouhaitees']), ...errors};
    errors = {...validateColumns(values, columns({})['garantiesOptionnellesSouhaitees']), ...errors};
    if (window.location.pathname.indexOf('/iframe') === 0) {
        errors = {...validateColumns(values, columns({})['protectionDonneesPublic']), ...errors};
    }
    errors = {...validateColumns(values, columns({})['state']), ...errors};
    return errors;
}

const formConfig ={
    form: 'devisEscapeGameForm',
    validate,
    enableReinitialize : true ,
}


const mapStateToProps = store => ({
    ...store.authReducer,
    ...store.requestReducer,
    ...store.courtiersReducer,
    ...store.clientsReducer,
    configurationEscapeGame: store.configurationEscapeGameReducer,
    message: store.messageReducer.message,
    initialValues:{...store.devisEscapeGameReducer},
    dateContratResilieValue: selector(store, 'dateContratResilie'),
    dateContratSanseffetValue: selector(store, 'dateContratSanseffet'),
    statutContratValue: selector(store, 'statutContrat'),
    descriptionValeurContenuValue: selector(store, 'descriptionValeurContenu'),
    garantieDegatsEauxValue: selector(store, 'garantieDegatsEaux'),
    garantieDegatsVolValue: selector(store, 'garantieDegatsVol'),
    garantieDegatsBrisGlaceValue: selector(store, 'garantieDegatsBrisGlace'),
    garantieDegatsDommagesElectriquesValue: selector(store, 'garantieDegatsDommagesElectriques'),
    garantieDegatsBrisMachineValue: selector(store, 'garantieDegatsBrisMachine'),

});

const mapDispatchToProps = {
    getCourtiers,
    requestFetching,
    setMessage,
    getClients,
    getLastConfigEscapeGame,
    devisEscapeGameSave,
    devisEscapeGameReset,
    getDevisEscapeGame,
    getConfigEscapeGame,
    download,
    deleteDocument

};

export default
connect(mapStateToProps, mapDispatchToProps) (
    reduxForm(formConfig) (
        withStyles(styles)
        (
            DevisEscapeGame
        )
    )
);
