import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Redux
import {formValueSelector, reduxForm} from 'redux-form'
import connect from "react-redux/es/connect/connect";

// App
import columns from "./Columns.js";
import {validateColumns, renderColumns} from 'columnsUtils'

import styles from "assets/jss/styles.js"

const selector = formValueSelector('devisBijouterieForm')

class Antecedents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          touched : false
        };

        this.columns1 = [];
        this.columns2 = [];
        this.columns3 = [];
        this.columns4 = [];
    }

    shouldComponentUpdate(nextProps, nextState) {
        this.columns1 = columns(
            {
                sinistresDeclaresValue:nextProps.sinistresDeclaresValue
            }
        )['antecedentsSinistres'];

        this.columns2 = columns(
            {
                assuranceCumulativeValue:nextProps.assuranceCumulativeValue
            }
        )['antecedentsAssurancesCumulatives'];

        this.columns3 = columns(
            {
                assuranceAnterieurAssureValue:nextProps.assuranceAnterieurAssureValue
            }
        )['antecedentsAssurancesAnterieures'];

        this.columns4 = columns(
            {
            }
        )['antecedentsAutres'];

        return true;
    }

    isValidated() {
        let errors = validateColumns(this.props.myform.devisBijouterieForm.values, this.columns1);
        errors = {...validateColumns(this.props.myform.devisBijouterieForm.values, this.columns2), ...errors};
        errors = {...validateColumns(this.props.myform.devisBijouterieForm.values, this.columns3), ...errors};
        errors = {...validateColumns(this.props.myform.devisBijouterieForm.values, this.columns4), ...errors};
        this.setState({ touched: true});
        return Object.keys(errors).length === 0;
    }


    sendState() {
        return this.state;
    }

    render() {

        const { classes, fetching } = this.props;

        // Cas spécifique pour l'autocomplete
        if (fetching) {
            return (<div></div>)
        }

        return (
            <>
            {
                renderColumns(
                    {
                        columns: this.columns1,
                        titre: 'Antécédents',
                        soustitre: "Sinistres déclarés sur les 5 dernières années",
                        classes: classes,
                        fetching: fetching,
                        touched: this.state.touched
                    }
                )
            }
            {
                renderColumns(
                    {
                        columns: this.columns2,
                        titre : <div>&nbsp;</div>,
                        soustitre: "Assurances cumulatives",
                        classes: classes,
                        fetching: fetching,
                        touched: this.state.touched
                    }
                )
            }
            {
                renderColumns(
                    {
                        columns: this.columns3,
                        titre : <div>&nbsp;</div>,
                        soustitre: "Assurances antérieures",
                        classes: classes,
                        fetching: fetching,
                        touched: this.state.touched
                    }
                )
            }
            {
                renderColumns(
                    {
                        columns: this.columns4,
                        titre : <div>&nbsp;</div>,
                        soustitre: "Autre",
                        classes: classes,
                        fetching: fetching,
                        touched: this.state.touched
                    }
                )
            }
            </>
        );
    }
}

const mapStateToProps = store => ({
    myform:store.form,
    sinistresDeclaresValue: selector(store, 'sinistresDeclares'),
    assuranceCumulativeValue: selector(store, 'assuranceCumulative'),
    assuranceAnterieurAssureValue: selector(store, 'assuranceAnterieurAssure'),

});

const mapDispatchToProps = {
};

export default
connect(mapStateToProps, mapDispatchToProps) (
    // reduxForm nécéssaire pour le this.props.change
    reduxForm({form: 'devisBijouterieForm'}) (
        withStyles(styles)
       (
           Antecedents
       )
    )
);

