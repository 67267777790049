import {DEVIS_ESCAPEGAME_RESET, DEVIS_ESCAPEGAME_RECEIVED, DEVIS_ESCAPEGAME_SAVE} from "../actions";

const defaultState = {
    id: null,
    config: null,
    coordonneesSiret: null,
    coordonneesCaHt: null,
    coordonneesEffectif: null,
    coordonneesMj: null,
    descriptionAdresseLocal: null,
    descriptionCodepostalLocal: null,
    descriptionVilleLocal: null,
    descriptionSuperficie: null,
    descriptionQualiteJuridique: null,
    descriptionNbSallesJeux: null,
    descriptionValeurContenu: null,
    descriptionNbParticipantMax: null,
    protectionIncendiePresenceExtincteur: null,
    protectionIncendieInstallationElectriqueVerifiee: null,
    protectionVolDevantureVitree: null,
    protectionVolDevantureVitreeDetail: null,
    protectionVolOuverturesProtegees: null,
    protectionVolNbPointsSecurites: null,
    protectionVolAlarmeAntiIntrusion: null,
    protectionVolAlarmeAntiIntrusionCentraleTelesurveillance: null,
    antecedentAssuranceDejaAssuree: null,
    antecedentAssuranceDejaAssureeDetail: null,
    antecedentAssuranceSinistres3Ans: null,
    antecedentAssuranceSinistres3AnsDetail: null,
    antecedentAssurancePasResilie: null,
    antecedentAssurancePasResilieDetail: null,
    garantieDegatsEaux: null,
    garantieDegatsEauxAutre: null,
    garantieDegatsVol: null,
    garantieDegatsVolAutre: null,
    garantieDegatsBrisGlace: null,
    garantieDegatsBrisGlaceAutre: null,
    garantieDegatsDommagesElectriques: null,
    garantieDegatsDommagesElectriquesAutre: null,
    garantieDegatsBrisMachine: null,
    garantieDegatsBrisMachineAutre: null,
    garantieOptionnelleTousRisquesDecors: null,
    garantieOptionnelleVolCasiers: null,
    garantieOptionnelleVolCasiersAutre: null,
    garantieOptionnelleIndividuelleAccident: null,
    proposantNom: null,
    proposantAdresse: null,
    proposantVille: null,
    proposantCodepostal: null,
    proposantTelephone: null,
    proposantEmail: null,
    rgpd: null,
    captcha: null,
    fraisIntermediation: null,
    signatureContrat: false,
    statutPriseGarantie: 3,
    statutContrat: 0,
    statutQuittance: 0,
    statutDevis: 3,
    dateContratResilie: null,
    dateContratSanseffet: null,
    client: null,
    utilisateur: null,
    referenceNumero: null,
    ispublic: null,
    notificationUtilisateur: null,

}

export default (state = defaultState, action) => {
    switch (action.type) {
        case DEVIS_ESCAPEGAME_RESET:
            return defaultState;

        case DEVIS_ESCAPEGAME_SAVE:
            return {
                ...state,
                ...action.data
            };
        case DEVIS_ESCAPEGAME_RECEIVED:
            delete action.data['referenceMasque'];
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }
}
