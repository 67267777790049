import {CLIENTS_RECEIVED} from "../actions";

export default (state = {
    clients:[],
    page:0,
    totalCount:0

}, action) => {
  switch (action.type) {

      case CLIENTS_RECEIVED:

          var clients = action.data['hydra:member'].map(
              client => ({
                  "id" : client.id,
                  "uri": '/clients/' + client.id,
                  "name": client.nom
              })
          );

          return {
              ...state,
              clients: clients
          };
    default:
      return state;
  }
}
