// React
import React from "react";

// Creative Tim
import Button from "creativetim/material-dashboard-pro/components/CustomButtons/Button.js";
import GridContainer from "creativetim/material-dashboard-pro/components/Grid/GridContainer.js";
import GridItem from "creativetim/material-dashboard-pro/components/Grid/GridItem.js";

// Vendor
import TagsInput from "react-tagsinput";

// App
import {renderInput, renderSelect} from 'formUtils'
import {FormHelperText} from "@material-ui/core";

class GarageActivite extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            items: JSON.parse(props.initItems),
            selectedValue: ""
        };
        this.partRef = React.createRef();

        this.options = [
            {value:0, label:"Mécanique véhicule -3,5T"},
            {value:1, label:"Mécanique véhicule +3,5T"},
            {value:2, label:"Carrossier- tôlier véhicule -3,5T"},
            {value:3, label:"Carrossier- tôlier véhicule +3,5T"},
            {value:4, label:"Négociant automobile -3,5T"},
            {value:5, label:"Négociant automobile +3,5T"},
            {value:6, label:"Centre d'entretien automobile"},
            {value:7, label:"Centre de lavage automobile"},
            {value:8, label:"Contrôle technique automobile"},
            {value:9, label:"Vente et réparation de motocycles, mini-voitures ou quads"},
            {value:10, label:"Vente véhicule poids lourds"},
            {value:11, label:"Mandataire ou courtier automobile"},
            {value:12, label:"Location courte durée"},
            {value:13, label:"Station-service"},
            {value:14, label:"Dépanneur- remorqueur automobile"},
            {value:15, label:"Vente et/ou réparation de matériel agricole"},
            {value:16, label:"Vente et/ou réparation d'engins de chantier"},
            {value:17, label:"Vente et/ou réparation de matériel de motoculture de plaisance"},
            {value:18, label:"Vente et / ou réparation de caravanes et véhicules de loisir"},
            {value:19, label:"Vente et montage de pneumatiques"},
            {value:20, label:"Vente et pose de vitrages automobiles"},
            {value:21, label:"Convoyeur de véhicules"},
            {value:22, label:"Nettoyage, déparaffinage et préparation de véhicules"},
            {value:23, label:"Autres"},
        ];
    }

    onSubmit(event) {
        event.preventDefault();
        let activiteIdx = this.state.selectedValue;
        let part = this.partRef.current.value;
        if (activiteIdx !== '' && part) {
            let items = [...this.state.items];
            items.unshift({
                activite: this.options[activiteIdx].label,
                part: part
            });
            this.setState({items: items});
            this.props.onChange(items)

            this.partRef.current.value = ''
            this.setState({selectedValue:""})
        }

    }
    handleChange(event) {
        this.setState({selectedValue:event.target.value})
    }
    onDelete(tags, change, index) {
        let items = [...this.state.items];
        items.splice(index, 1);
        this.setState({items: items});
        this.props.onChange(items)
    }
    render() {
        const {fetching, classes, error, isTouched} = this.props

        let items = this.state.items.map((item, index) => {
            return item.activite + " : " + item.part + "%"
        });

        return (
            <>
                <TagsInput
                    value={items}
                    onChange={this.onDelete.bind(this)}
                    tagProps={{ className: "react-tagsinput-tag info" }}
                    renderInput={() => {}}
                />
                <GridContainer>
                    <GridItem xs={12} sm={4} xl={2}>
                        {renderSelect({options:this.options, placeholder:"Activité", classes:classes, fetching: fetching, meta : {touched:isTouched, invalid:error ? true : false}, onChange:this.handleChange.bind(this), value:this.state.selectedValue})}
                    </GridItem>
                    <GridItem xs={12} sm={4} xl={2}>
                        {renderInput({input:{inputRef:this.partRef}, label:"Part du CA (%)", classes:classes, fetching: fetching, meta : {touched:isTouched, invalid:error ? true : false}})}
                    </GridItem>
                    <GridItem xs={12} sm={4} xl={2} className={classes.addActiviteSuperficie}>
                        <Button type="submit" disabled={fetching} color="rose" onClick={this.onSubmit.bind(this)}>
                            Ajouter
                        </Button>
                    </GridItem>
                </GridContainer>
                <FormHelperText className={classes.labelError}>{isTouched && error}</FormHelperText>
            </>
        );
    }
}

export default GarageActivite
