import { combineReducers } from 'redux'
import { reducer as ReducerForm } from 'redux-form'
import ReducerAuth from './auth'
import ReducerRequest from './request'
import ReducerProfile from './profile'
import ReducerCourtiers from './courtiers'
import ReducerClient from './client'
import ReducerClients from './clients'
import ReducerProduits from './produits'
import ReducerMessage from './message'
import ReducerConfigurationBijouterie from './configuration-bijouterie'
import ReducerConfigurationEscapeGame from './configuration-escapegame'
import ReducerConfigurationCyber from './configuration-cyber'
import ReducerConfigurationImmeuble from './configuration-immeuble'
import ReducerConfigurationGarage from './configuration-garage'
import ReducerConfigurationDecennale from './configuration-decennale'
import ReducerConfigurationInformatique from './configuration-informatique'
import ReducerDevisEscapeGame from './devis-escapegame'
import ReducerDevisBijouterie from './devis-bijouterie'
import ReducerDevisCyber from './devis-cyber'
import ReducerDevisImmeuble from './devis-immeuble'
import ReducerDevisGarage from './devis-garage'
import ReducerDevisDecennale from './devis-decennale'
import ReducerDevisInformatique from './devis-informatique'

const rootReducer = combineReducers({
    authReducer: ReducerAuth,
    requestReducer: ReducerRequest,
    profileReducer: ReducerProfile,
    configurationBijouterieReducer: ReducerConfigurationBijouterie,
    configurationEscapeGameReducer: ReducerConfigurationEscapeGame,
    configurationCyberReducer: ReducerConfigurationCyber,
    configurationImmeubleReducer: ReducerConfigurationImmeuble,
    configurationGarageReducer: ReducerConfigurationGarage,
    configurationDecennaleReducer: ReducerConfigurationDecennale,
    configurationInformatiqueReducer: ReducerConfigurationInformatique,
    devisEscapeGameReducer: ReducerDevisEscapeGame,
    devisBijouterieReducer: ReducerDevisBijouterie,
    devisCyberReducer: ReducerDevisCyber,
    devisImmeubleReducer: ReducerDevisImmeuble,
    devisGarageReducer: ReducerDevisGarage,
    devisDecennaleReducer: ReducerDevisDecennale,
    devisInformatiqueReducer: ReducerDevisInformatique,
    courtiersReducer : ReducerCourtiers,
    clientReducer : ReducerClient,
    clientsReducer : ReducerClients,
    messageReducer : ReducerMessage,
    produitsReducer : ReducerProduits,
    form: ReducerForm
})

export default rootReducer
