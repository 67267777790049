import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Redux
import {formValueSelector, reduxForm} from 'redux-form'
import connect from "react-redux/es/connect/connect";

// App
import columns from "./Columns.js";
import {validateColumns, renderColumns} from 'columnsUtils'
import styles from "assets/jss/styles.js"

const selector = formValueSelector('devisBijouterieForm')

class AssuranceStock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          touched : false
        };

        this.columns1 = [];
        this.columns2 = [];
        this.columns3 = [];
        this.columns4 = [];
    }

    shouldComponentUpdate(nextProps, nextState) {
        this.columns1 = columns(
            {
                obj:this,
                proposantGroupementValue:nextProps.proposantGroupementValue,
            }
        )['assuranceStockValeur'];

        this.columns2 = columns(
            {
                obj:this,
                garantiesAugmentation25Debut1Value:nextProps.garantiesAugmentation25Debut1Value,
                garantiesAugmentation25Fin1Value:nextProps.garantiesAugmentation25Fin1Value,
                changeGarantiesAugmentation25Debut1:this.changeGarantiesAugmentation25Debut1,
                changeGarantiesAugmentation25Fin1:this.changeGarantiesAugmentation25Fin1,
                garantiesAugmentation25Debut2Value:nextProps.garantiesAugmentation25Debut2Value,
                garantiesAugmentation25Fin2Value:nextProps.garantiesAugmentation25Fin2Value,
                changeGarantiesAugmentation25Debut2:this.changeGarantiesAugmentation25Debut2,
                changeGarantiesAugmentation25Fin2:this.changeGarantiesAugmentation25Fin2,
            }
        )['assuranceStockGaranties'];

        this.columns3 = columns(
            {
            }
        )['assuranceStockGaranties2'];

        this.columns4 = columns(
            {
                garantiesStockValeurTotaleValue:nextProps.garantiesStockValeurTotaleValue,
            }
        )['assuranceStockValeur2'];

        return true;
    }

    changeGarantiesAugmentation25Debut1(date) {
        this.props.change("garantiesAugmentation25Debut1", date)
    }

    changeGarantiesAugmentation25Fin1(date) {
        this.props.change("garantiesAugmentation25Fin1", date)
    }
    changeGarantiesAugmentation25Debut2(date) {
        this.props.change("garantiesAugmentation25Debut2", date)
    }

    changeGarantiesAugmentation25Fin2(date) {
        this.props.change("garantiesAugmentation25Fin2", date)
    }
    isValidated() {
        let errors = validateColumns(this.props.myform.devisBijouterieForm.values, this.columns1);
        errors = {...validateColumns(this.props.myform.devisBijouterieForm.values, this.columns2), ...errors};
        errors = {...validateColumns(this.props.myform.devisBijouterieForm.values, this.columns3), ...errors};
        errors = {...validateColumns(this.props.myform.devisBijouterieForm.values, this.columns4), ...errors};
        this.setState({ touched: true});
        return Object.keys(errors).length === 0;
    }

    sendState() {
        return this.state;
    }

    render() {

        const { classes, fetching } = this.props;

        // Cas spécifique pour l'autocomplete
        if (fetching) {
            return (<div></div>)
        }

        return (
            <>
                <h4 className={classes.infoText}>
                    Assurance du stock
                </h4>

                <div>
                    <strong>Précieux :</strong> matières premières (déchets d’or, lingots, pierres précieuses non montées, perles), bijoux en or, empierrés ou non, d’une valeur unitaire supérieure à 77 euros en prix d’achat hors taxes, montres en toutes matières d’une valeur unitaire supérieure à 458 euros en prix d’achat hors taxes
                </div>
                <div>
                    <strong>Non précieux :</strong> bijoux en or, empierrés ou non, d’une valeur unitaire inférieure à 77 euros en prix d’achat hors taxes, bijoux en argent, en plaqué or, en vermeil et en alliage 9 carats, montres de toutes matières d’une valeur unitaire n’excédant pas 458 euros en prix d’achat hors taxes, objets de grosse horlogerie, articles cadeaux (porcelaine, vaisselle, maroquinerie, etc)
                </div>
                <br/>
                <br/>
                <>
                    {
                        renderColumns(
                            {
                                columns: this.columns1,
                                soustitre: 'Valeur totale du stock (y compris confiés clients, fournisseurs)',
                                classes: classes,
                                fetching: fetching,
                                touched: this.state.touched
                            }
                        )
                    }
                </>
                <>
                    {
                        renderColumns(
                            {
                                columns: this.columns2,
                                soustitre: "Garanties / Montant assurés / Garanties accordées par sinistre et par année d’assurance",
                                classes: classes,
                                fetching: fetching,
                                touched: this.state.touched
                            }
                        )
                    }
                </>
                <>
            <div className={classes.textCenter}>
                <br/>
                <br/>
                <strong>Sont comprises dans la limite des capitaux ci-dessus, les garanties suivantes :</strong>
                <br/>
                <br/>
            </div>
            <table className={classes.marginAuto}>
                <tbody>
                    <tr>
                        <td className={classes.width50}>
                            <strong>Marchandises expédiées</strong> par colis et par destinataire.<br/>
                            Franchise : 10% du montant du sinistre, minimum 150 €<br/>
                            <br/>
                        </td>
                        <td className={classes.width50 + " " + classes.textCenter}><strong>GARANTI</strong><br/><br/><br/></td>
                    </tr>
                    <tr>
                        <td className={classes.width50}>
                                                • Valeur déclarée
                        </td>
                        <td className={classes.width50 + " " + classes.textCenter}>
                            30.000 €
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.width50}>
                                                • Recommandé
                        </td>
                        <td className={classes.width50 + " " + classes.textCenter}>
                            1.500 €
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.width50}>
                                                • Colissimo / Colissimo Suivi
                        </td>
                        <td className={classes.width50 + " " + classes.textCenter}>
                            3.000 €
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.width50}>
                                                • Chronopost, Transporteur non désigné, Fret aérien sans déclaration de valeur
                        </td>
                        <td className={classes.width50 + " " + classes.textCenter}>
                            3.000 €
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.width50}>
                                                • Export et DOM/TOM par FEDEX ou UPS
                        </td>
                        <td className={classes.width50 + " " + classes.textCenter}>
                            6.000 €
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.width50}>
                                                • Transitaire spécialisé BRINKS, FERRARI TECHNOTRANS, VDH, PAGNOT
                        </td>
                        <td className={classes.width50 + " " + classes.textCenter}>
                            25.000 €
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.width50}>
                                                • Fret aérien avec déclaration de valeur (mini 20% de la valeur de l’envoi)
                        </td>
                        <td className={classes.width50 + " " + classes.textCenter}>
                            6.000 €
                        </td>
                    </tr>
                </tbody>
            </table>

                    {
                        renderColumns(
                            {
                                columns: this.columns3,
                                classes: classes,
                                fetching: fetching,
                                touched: this.state.touched
                            }
                        )
                    }
                </>
                <>
                    {
                        renderColumns(
                            {
                                columns: this.columns4,
                                soustitre: "Franchise obligatoire",
                                classes: classes,
                                fetching: fetching,
                                touched: this.state.touched
                            }
                        )
                    }
                </>
            </>

        );
    }
}

const mapStateToProps = store => ({
    myform:store.form,
    proposantGroupementValue: selector(store, 'proposantGroupement'),
    garantiesAugmentation25Debut1Value: selector(store, 'garantiesAugmentation25Debut1'),
    garantiesAugmentation25Fin1Value: selector(store, 'garantiesAugmentation25Fin1'),
    garantiesAugmentation25Debut2Value: selector(store, 'garantiesAugmentation25Debut2'),
    garantiesAugmentation25Fin2Value: selector(store, 'garantiesAugmentation25Fin2'),
    garantiesStockValeurTotaleValue: selector(store, 'garantiesStockValeurTotale'),
});

const mapDispatchToProps = {
};

export default
connect(mapStateToProps, mapDispatchToProps) (
    // reduxForm nécéssaire pour le this.props.change
    reduxForm({form: 'devisBijouterieForm'}) (
        withStyles(styles)
       (
           AssuranceStock
       )
    )
);

