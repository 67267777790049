export const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
};
export const smoothScroll = target => {
    var targetScroll = document.getElementById(target);
    if (targetScroll) {
        scrollTo(document.getElementById("main-panel") ? document.getElementById("main-panel") : document.documentElement, targetScroll.offsetTop, 300);
    }
};
export const scrollTo = (element, to, duration) => {
    var start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20;

    var animateScroll = function() {
        currentTime += increment;
        var val = easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;
        if (currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };
    animateScroll();
};