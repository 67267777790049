// App
import {renderCaptcha, renderDate, renderText, renderTextarea, renderInput, renderSelect, renderHidden, renderAutocomplete, renderCheckbox, renderRadio} from 'formUtils'

const columns = function (props) {
    return {
        'informationsPublic': [
            {name:"proposantNom", required:true, label:"Nom et prénom du proposant ou Raison Sociale", component:renderInput},
            {name:"proposantAdresse", required:true, label:"Adresse", component:renderInput},
            {name:"proposantVille", required:true, label:"Ville", component:renderInput},
            {name:"proposantCodepostal", required:true, label:"Code postal", component:renderInput},
            {name:"proposantTelephone", required:true, label:"Téléphone", component:renderInput},
            {name:"proposantEmail", required:true, label:"Email", component:renderInput},
            {
                name: "ispublic",
                component: renderHidden,
            },
        ],
        'informations': [
            {
                name: "utilisateur",
                component: renderHidden,
                required: true,
                reference: 'utilisateurList'
            },
            {
                name: "utilisateurList",
                label: "Utilisateur",
                component: renderAutocomplete,
                componentOptions: {
                    list: props.courtiers,
                    change: props.changeCourtier ? props.changeCourtier.bind(props.obj) : null,

                },
                fieldProps: {
                    selectedValue: props.courtiers != null ? props.getSelectedValueCourtier(props.obj) : null
                }
            },
            {
                name:"client",
                component:renderHidden,
                required:true,
                reference:'clientList'

            },
            {
                name:"clientList",
                label:"Client",
                component:renderAutocomplete,
                componentOptions:{
                    list:props.clients,
                    change:props.changeClient ? props.changeClient.bind(props.obj) : null
                },
                fieldProps: {
                    selectedValue:props.clients != null ? props.getSelectedValueClient(props.obj) : null
                }
            },
            {
                name: "utilisateur",
                component: renderHidden,
                componentOptions: {value:'/utilisateurs/0'}
            },
            {
                name:"client",
                component:renderHidden,
                componentOptions: {value:'/clients/0'}
            },
            {
                name:"config",
                component:renderHidden,
                componentOptions:{value:'/produit_cyber_configs/0'}
            },
            {
                name:"fraisIntermediation",
                label:"Frais d'intermédiation",
                component:renderSelect,
                required:true,
                componentOptions:{
                    options:props.getFraisIntermediation ? props.getFraisIntermediation(props.obj) : null
                }
            }
        ],
        'entreprise' : [
            {
                name:"entrepriseNombreSalarie",
                label:"Nombre de salarié(s) ",
                component:renderInput,
            },
            {
                name:"entrepriseCaHtAnnuel",
                label:"Chiffre d’affaire annuel HT",
                component:renderInput,
            },
            {
                name:"entrepriseText1",
                label:"Exercez-vous dans les domaines suivants ",
                component:renderText,
            },
            {
                name:"entrepriseExerciceLogicielsMedicaux",
                label:"Logiciels et/ou dispositifs médicaux",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entrepriseExerciceLogicielsMedicauxDetail",
                label:"Si oui, précisez",
                component:renderInput,
                condition:() => {return true === props.entrepriseExerciceLogicielsMedicauxValue},
            },
            {
                name:"entrepriseExerciceFabricationHorsUe",
                label:"Fabrication de matériel hors UE",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entrepriseExerciceFabricationHorsUeDetail",
                label:"Si oui, précisez",
                component:renderInput,
                condition:() => {return true === props.entrepriseExerciceFabricationHorsUeValue},
            },
            {
                name:"entrepriseExerciceLogicielsBancaires",
                label:"Logiciels bancaires",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entrepriseExerciceLogicielsBancairesDetail",
                label:"Si oui, précisez",
                component:renderInput,
                condition:() => {return true === props.entrepriseExerciceLogicielsBancairesValue},
            },
            {
                name:"entrepriseExerciceScada",
                label:"SCADA et systèmes de contrôle de process industriel",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entrepriseExerciceScadaDetail",
                label:"Si oui, précisez",
                component:renderInput,
                condition:() => {return true === props.entrepriseExerciceScadaValue},
            },
            {
                name:"entrepriseExerciceLogicielsBiometrie",
                label:"Logiciels et matériel de biométrie",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entrepriseExerciceLogicielsBiometrieDetail",
                label:"Si oui, précisez",
                component:renderInput,
                condition:() => {return true === props.entrepriseExerciceLogicielsBiometrieValue},
            },
            {
                name:"entrepriseExerciceProductionAssistee",
                label:"Production assistée par ordinateur",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entrepriseExerciceProductionAssisteeDetail",
                label:"Si oui, précisez",
                component:renderInput,
                condition:() => {return true === props.entrepriseExerciceProductionAssisteeValue},
            },
            {
                name:"entrepriseExerciceProcesseurPaiement",
                label:"Processeur et/ou portail de paiement",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entrepriseExerciceProcesseurPaiementDetail",
                label:"Si oui, précisez",
                component:renderInput,
                condition:() => {return true === props.entrepriseExerciceProcesseurPaiementValue},
            },
            {
                name:"entrepriseExerciceAssistanceNavigation",
                label:"Assistance à la navigation, au pilotage ou à la conduite de moyens de transports",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entrepriseExerciceAssistanceNavigationDetail",
                label:"Si oui, précisez",
                component:renderInput,
                condition:() => {return true === props.entrepriseExerciceAssistanceNavigationValue},
            },
            {
                name:"entrepriseExerciceReseauMobileVirtuel",
                label:"Réseaux mobiles virtuels",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entrepriseExerciceReseauMobileVirtuelDetail",
                label:"Si oui, précisez",
                component:renderInput,
                condition:() => {return true === props.entrepriseExerciceReseauMobileVirtuelValue},
            },
            {
                name:"entrepriseMoyenneContratsRealises",
                label:"Moyenne des contrats réalisés :",
                child: [
                    {
                        name: "entrepriseMoyenneContratsRealisesEuro",
                        fieldProps:{label: "En Montant"},
                        component: renderInput,
                    },
                    {
                        name: "entrepriseMoyenneContratsRealisesDuree",
                        fieldProps:{label: "En durée"},
                        component: renderInput,
                    }
                ],
            },
            {
                name:"entrepriseAppelSousTraitant",
                label:"Faites-vous appel à des sous-traitants ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entrepriseAppelSousTraitantDetail",
                label:"Quel pourcentage de votre activité est sous-traité ?",
                component:renderInput,
            },
            {
                name:"entreprisePrestationsHebergementSousTraites",
                label:"Les prestations d’hébergement sont-elles sous traités ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
        ],
        'activites1' : [
            {
                name:"activiteInfrastructureReseaux",
                label:"Infrastructures / Réseaux / Hébergement / Cloud",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
                validate:(total) => {return total <= 100},
                doubleValidate:true,
                error: "Le total de toutes les activités ne peut pas dépasser 100%",
                validateOptions: {
                    options: [
                        {name: "activiteArchitecteReseau"},
                        {name: "activiteAdministrationSysteme"},
                        {name: "activiteAdministrateurArchitecte"},
                        {name: "activiteDevOps"},
                        {name: "activiteIntegrationSystemes"},
                        {name: "activiteVenteLocationMaterielInformatique"},
                        {name: "activiteInstallationConfigurationMaterielInformatique"},
                        {name: "activiteMaintenanceMaterielInformatique"},
                        {name: "activiteHebergementDonnees"},
                        {name: "activiteHebergementSite"},
                        {name: "activiteInfrastructurePaas"},
                        {name: "activiteDatacenter"},
                        {name: "activiteFabricationMateriel"},
                    ]
                }
            },
            {
                name:"activiteArchitecteReseau",
                label:"Architecte système / réseaux / cloud",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteInfrastructureReseauxValue},
            },
            {
                name:"activiteAdministrationSysteme",
                label:"Administration systèmes, réseaux",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteInfrastructureReseauxValue},
            },
            {
                name:"activiteAdministrateurArchitecte",
                label:"Administrateur / architecte base de données (BDD)",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteInfrastructureReseauxValue},
            },
            {
                name:"activiteDevOps",
                label:"DévOps",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteInfrastructureReseauxValue},
            },
            {
                name:"activiteIntegrationSystemes",
                label:"Intégration systèmes / réseaux",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteInfrastructureReseauxValue},
            },
            {
                name:"activiteVenteLocationMaterielInformatique",
                label:"Vente, location matériel informatique",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteInfrastructureReseauxValue},
            },
            {
                name:"activiteInstallationConfigurationMaterielInformatique",
                label:"Installation, configuration, paramétrage de matériel informatique",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteInfrastructureReseauxValue},
            },
            {
                name:"activiteMaintenanceMaterielInformatique",
                label:"Maintenance de matériel informatique",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteInfrastructureReseauxValue},
            },
            {
                name:"activiteHebergementDonnees",
                label:"Hébergement de données (stockage, sauvegarde, etc..)",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteInfrastructureReseauxValue},
            },
            {
                name:"activiteHebergementSite",
                label:"Hébergements de site/application Web",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteInfrastructureReseauxValue},
            },
            {
                name:"activiteInfrastructurePaas",
                label:"Infrastructure & platform as a Service (IaaS/ PaaS)",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteInfrastructureReseauxValue},
            },
            {
                name:"activiteDatacenter",
                label:"Datacenter",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteInfrastructureReseauxValue},
            },
            {
                name:"activiteFabricationMateriel",
                label:"Fabrication de matériel informatique",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteInfrastructureReseauxValue},
            },
        ],
        'activites2' : [
            {
                name:"activiteTelecoms",
                label:"Télécoms",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
                validate:(total) => {return total <= 100},
                doubleValidate:true,
                error: "Le total de toutes les activités ne peut pas dépasser 100%",
                validateOptions: {
                    options: [
                        {name: "activiteArchitecteReseau"},
                        {name: "activiteAdministrationSysteme"},
                        {name: "activiteAdministrateurArchitecte"},
                        {name: "activiteDevOps"},
                        {name: "activiteIntegrationSystemes"},
                        {name: "activiteVenteLocationMaterielInformatique"},
                        {name: "activiteInstallationConfigurationMaterielInformatique"},
                        {name: "activiteMaintenanceMaterielInformatique"},
                        {name: "activiteHebergementDonnees"},
                        {name: "activiteHebergementSite"},
                        {name: "activiteInfrastructurePaas"},
                        {name: "activiteDatacenter"},
                        {name: "activiteFabricationMateriel"},
                        {name: "activiteOperateurTelecoms"},
                        {name: "activiteSecurisationSystemeTelephonie"},
                        {name: "activiteServiceTelecom"},
                        {name: "activiteVenteLocationMaterielTelecom"},
                        {name: "activiteInstallationConfigurationMaterielTelecom"},
                        {name: "activiteConseilTelecom"},
                        {name: "activiteMaintenanceMaterielTelecom"},
                        {name: "activiteSystemeRfid"},
                    ]
                }
            },
            {
                name:"activiteOperateurTelecoms",
                label:"opérateur télécoms",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteTelecomsValue},
            },
            {
                name:"activiteSecurisationSystemeTelephonie",
                label:"Sécurisation du système de téléphonie",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteTelecomsValue},
            },
            {
                name:"activiteServiceTelecom",
                label:"Services télécoms (VPN, Ethernet, IP/VOIP, email, visioconférence...)",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteTelecomsValue},
            },
            {
                name:"activiteVenteLocationMaterielTelecom",
                label:"Vente, location matériel télécoms",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteTelecomsValue},
            },
            {
                name:"activiteInstallationConfigurationMaterielTelecom",
                label:"Installation, configuration, paramétrage de matériel télécoms",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteTelecomsValue},
            },
            {
                name:"activiteConseilTelecom",
                label:"Conseil en télécoms",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteTelecomsValue},
            },
            {
                name:"activiteMaintenanceMaterielTelecom",
                label:"Maintenance de matériel de télécoms",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteTelecomsValue},
            },
            {
                name:"activiteSystemeRfid",
                label:"Systèmes RFID / QR code",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteTelecomsValue},
            },
        ],
        'activites3' : [
            {
                name:"activiteLogicielsProgiciels",
                label:"Logiciels / Progiciels / Applications spécifiques",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
                validate:(total) => {return total <= 100},
                doubleValidate:true,
                error: "Le total de toutes les activités ne peut pas dépasser 100%",
                validateOptions: {
                    options: [
                        {name: "activiteArchitecteReseau"},
                        {name: "activiteAdministrationSysteme"},
                        {name: "activiteAdministrateurArchitecte"},
                        {name: "activiteDevOps"},
                        {name: "activiteIntegrationSystemes"},
                        {name: "activiteVenteLocationMaterielInformatique"},
                        {name: "activiteInstallationConfigurationMaterielInformatique"},
                        {name: "activiteMaintenanceMaterielInformatique"},
                        {name: "activiteHebergementDonnees"},
                        {name: "activiteHebergementSite"},
                        {name: "activiteInfrastructurePaas"},
                        {name: "activiteDatacenter"},
                        {name: "activiteFabricationMateriel"},
                        {name: "activiteOperateurTelecoms"},
                        {name: "activiteSecurisationSystemeTelephonie"},
                        {name: "activiteServiceTelecom"},
                        {name: "activiteVenteLocationMaterielTelecom"},
                        {name: "activiteInstallationConfigurationMaterielTelecom"},
                        {name: "activiteConseilTelecom"},
                        {name: "activiteMaintenanceMaterielTelecom"},
                        {name: "activiteSystemeRfid"},
                        {name: "activiteEditionLogiciels"},
                        {name: "activiteEditionLogicielsSaas"},
                        {name: "activiteIntegrationLogiciels"},
                        {name: "activiteDeveloppementLogiciels"},
                        {name: "activiteDeveloppementApplicationSpecifique"},
                        {name: "activiteTestRecette"},
                        {name: "activiteVenteInstallationLogiciels"},
                        {name: "activiteConsultantErp"},
                        {name: "activiteArchitecteLogiciels"},
                    ]
                }
            },
            {
                name:"activiteEditionLogiciels",
                label:"Edition de logiciels / progiciels",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteLogicielsProgicielsValue},
            },
            {
                name:"activiteEditionLogicielsSaas",
                label:"Edition de logiciels / progiciels en mode SaaS",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteLogicielsProgicielsValue},
            },
            {
                name:"activiteIntegrationLogiciels",
                label:"Intégration de logiciels / progiciels",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteLogicielsProgicielsValue},
            },
            {
                name:"activiteDeveloppementLogiciels",
                label:"Développement, programmation de logiciels / progiciels",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteLogicielsProgicielsValue},
            },
            {
                name:"activiteDeveloppementApplicationSpecifique",
                label:"Développement, programmation d'applications spécifiques / à façon",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteLogicielsProgicielsValue},
            },
            {
                name:"activiteTestRecette",
                label:"Test / recettes de logiciels / progiciels",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteLogicielsProgicielsValue},
            },
            {
                name:"activiteVenteInstallationLogiciels",
                label:"Vente, installation, paramétrage de logiciels / progiciels",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteLogicielsProgicielsValue},
            },
            {
                name:"activiteConsultantErp",
                label:"Consultant ERP, CRM, BI, Datamining (ex.: Oracle, SAP, Microsoft)",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteLogicielsProgicielsValue},
            },
            {
                name:"activiteArchitecteLogiciels",
                label:"Architecte logiciel",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteLogicielsProgicielsValue},
            },
        ],
        'activites4' : [
            {
                name:"activiteJeuxVideos",
                label:"Jeux vidéo",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
                validate:(total) => {return total <= 100},
                doubleValidate:true,
                error: "Le total de toutes les activités ne peut pas dépasser 100%",
                validateOptions: {
                    options: [
                        {name: "activiteArchitecteReseau"},
                        {name: "activiteAdministrationSysteme"},
                        {name: "activiteAdministrateurArchitecte"},
                        {name: "activiteDevOps"},
                        {name: "activiteIntegrationSystemes"},
                        {name: "activiteVenteLocationMaterielInformatique"},
                        {name: "activiteInstallationConfigurationMaterielInformatique"},
                        {name: "activiteMaintenanceMaterielInformatique"},
                        {name: "activiteHebergementDonnees"},
                        {name: "activiteHebergementSite"},
                        {name: "activiteInfrastructurePaas"},
                        {name: "activiteDatacenter"},
                        {name: "activiteFabricationMateriel"},
                        {name: "activiteOperateurTelecoms"},
                        {name: "activiteSecurisationSystemeTelephonie"},
                        {name: "activiteServiceTelecom"},
                        {name: "activiteVenteLocationMaterielTelecom"},
                        {name: "activiteInstallationConfigurationMaterielTelecom"},
                        {name: "activiteConseilTelecom"},
                        {name: "activiteMaintenanceMaterielTelecom"},
                        {name: "activiteSystemeRfid"},
                        {name: "activiteEditionLogiciels"},
                        {name: "activiteEditionLogicielsSaas"},
                        {name: "activiteIntegrationLogiciels"},
                        {name: "activiteDeveloppementLogiciels"},
                        {name: "activiteDeveloppementApplicationSpecifique"},
                        {name: "activiteTestRecette"},
                        {name: "activiteVenteInstallationLogiciels"},
                        {name: "activiteConsultantErp"},
                        {name: "activiteArchitecteLogiciels"},
                        {name: "activiteGameDesign"},
                        {name: "activiteAnimation"},
                        {name: "activiteRealiteAugmentee"},
                        {name: "activiteEditionJeuxVideos"},
                        {name: "activiteDeveloppementTestJeuxVideos"},
                    ]
                }
            },
            {
                name:"activiteGameDesign",
                label:"Game design, UX, gameplay",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteJeuxVideosValue},
            },
            {
                name:"activiteAnimation",
                label:"Animation 2D/3D",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteJeuxVideosValue},
            },
            {
                name:"activiteRealiteAugmentee",
                label:"Réalité augmentée / virtuelle",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteJeuxVideosValue},
            },
            {
                name:"activiteEditionJeuxVideos",
                label:"Edition de jeux vidéo",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteJeuxVideosValue},
            },
            {
                name:"activiteDeveloppementTestJeuxVideos",
                label:"Développement / tests de jeux vidéo ",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteJeuxVideosValue},
            },
        ],
        'activites5' : [
            {
                name:"activiteDigital",
                label:"Digital (web / mobile)",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
                validate:(total) => {return total <= 100},
                doubleValidate:true,
                error: "Le total de toutes les activités ne peut pas dépasser 100%",
                validateOptions: {
                    options: [
                        {name: "activiteArchitecteReseau"},
                        {name: "activiteAdministrationSysteme"},
                        {name: "activiteAdministrateurArchitecte"},
                        {name: "activiteDevOps"},
                        {name: "activiteIntegrationSystemes"},
                        {name: "activiteVenteLocationMaterielInformatique"},
                        {name: "activiteInstallationConfigurationMaterielInformatique"},
                        {name: "activiteMaintenanceMaterielInformatique"},
                        {name: "activiteHebergementDonnees"},
                        {name: "activiteHebergementSite"},
                        {name: "activiteInfrastructurePaas"},
                        {name: "activiteDatacenter"},
                        {name: "activiteFabricationMateriel"},
                        {name: "activiteOperateurTelecoms"},
                        {name: "activiteSecurisationSystemeTelephonie"},
                        {name: "activiteServiceTelecom"},
                        {name: "activiteVenteLocationMaterielTelecom"},
                        {name: "activiteInstallationConfigurationMaterielTelecom"},
                        {name: "activiteConseilTelecom"},
                        {name: "activiteMaintenanceMaterielTelecom"},
                        {name: "activiteSystemeRfid"},
                        {name: "activiteEditionLogiciels"},
                        {name: "activiteEditionLogicielsSaas"},
                        {name: "activiteIntegrationLogiciels"},
                        {name: "activiteDeveloppementLogiciels"},
                        {name: "activiteDeveloppementApplicationSpecifique"},
                        {name: "activiteTestRecette"},
                        {name: "activiteVenteInstallationLogiciels"},
                        {name: "activiteConsultantErp"},
                        {name: "activiteArchitecteLogiciels"},
                        {name: "activiteGameDesign"},
                        {name: "activiteAnimation"},
                        {name: "activiteRealiteAugmentee"},
                        {name: "activiteEditionJeuxVideos"},
                        {name: "activiteDeveloppementTestJeuxVideos"},
                        {name: "activiteWebDesign"},
                        {name: "activiteDeveloppementEcommerce"},
                        {name: "activiteIntegrationCms"},
                        {name: "activiteDeveloppementWebMobile"},
                        {name: "activiteReferencement"},
                        {name: "activiteCreationNdd"},
                        {name: "activiteHebergementWeb"},
                    ]
                }
            },
            {
                name:"activiteWebDesign",
                label:"Webdesign, intégration (dont UX/UI)",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteDigitalValue},
            },
            {
                name:"activiteDeveloppementEcommerce",
                label:"Développement, intégration de solutions e-Commerce",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteDigitalValue},
            },
            {
                name:"activiteIntegrationCms",
                label:"Intégration CMS   % Edition d'applications web / mobile",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteDigitalValue},
            },
            {
                name:"activiteDeveloppementWebMobile",
                label:"Développement web / mobile",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteDigitalValue},
            },
            {
                name:"activiteReferencement",
                label:"Référencement (dont SEO, traffic management, etc.)",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteDigitalValue},
            },
            {
                name:"activiteCreationNdd",
                label:"Création, achat, gestion de noms de domaine",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteDigitalValue},
            },
            {
                name:"activiteHebergementWeb",
                label:"Hébergements de site/application Web",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteDigitalValue},
            },
        ],
        'activites6' : [
            {
                name:"activiteServiceConseil",
                label:"Services et conseils en informatique",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
                validate:(total) => {return total === 100},
                doubleValidate:true,
                error: "Le total de toutes les activités doit être égal à 100%",
                validateOptions: {
                    options: [
                        {name: "activiteArchitecteReseau"},
                        {name: "activiteAdministrationSysteme"},
                        {name: "activiteAdministrateurArchitecte"},
                        {name: "activiteDevOps"},
                        {name: "activiteIntegrationSystemes"},
                        {name: "activiteVenteLocationMaterielInformatique"},
                        {name: "activiteInstallationConfigurationMaterielInformatique"},
                        {name: "activiteMaintenanceMaterielInformatique"},
                        {name: "activiteHebergementDonnees"},
                        {name: "activiteHebergementSite"},
                        {name: "activiteInfrastructurePaas"},
                        {name: "activiteDatacenter"},
                        {name: "activiteFabricationMateriel"},
                        {name: "activiteOperateurTelecoms"},
                        {name: "activiteSecurisationSystemeTelephonie"},
                        {name: "activiteServiceTelecom"},
                        {name: "activiteVenteLocationMaterielTelecom"},
                        {name: "activiteInstallationConfigurationMaterielTelecom"},
                        {name: "activiteConseilTelecom"},
                        {name: "activiteMaintenanceMaterielTelecom"},
                        {name: "activiteSystemeRfid"},
                        {name: "activiteEditionLogiciels"},
                        {name: "activiteEditionLogicielsSaas"},
                        {name: "activiteIntegrationLogiciels"},
                        {name: "activiteDeveloppementLogiciels"},
                        {name: "activiteDeveloppementApplicationSpecifique"},
                        {name: "activiteTestRecette"},
                        {name: "activiteVenteInstallationLogiciels"},
                        {name: "activiteConsultantErp"},
                        {name: "activiteArchitecteLogiciels"},
                        {name: "activiteGameDesign"},
                        {name: "activiteAnimation"},
                        {name: "activiteRealiteAugmentee"},
                        {name: "activiteEditionJeuxVideos"},
                        {name: "activiteDeveloppementTestJeuxVideos"},
                        {name: "activiteWebDesign"},
                        {name: "activiteDeveloppementEcommerce"},
                        {name: "activiteIntegrationCms"},
                        {name: "activiteDeveloppementWebMobile"},
                        {name: "activiteReferencement"},
                        {name: "activiteCreationNdd"},
                        {name: "activiteHebergementWeb"},
                        {name: "activiteConseilEtude"},
                        {name: "activiteProductOwner"},
                        {name: "activiteFormation"},
                        {name: "activiteAssistance"},
                        {name: "activiteInfogerance"},
                        {name: "activiteSecurite"},
                        {name: "activiteMoe"},
                        {name: "activiteBigData"},
                        {name: "activiteGestionElectronique"},
                    ]
                }
            },
            {
                name:"activiteConseilEtude",
                label:"Conseil, études, audit, AMOA (dont gestion de projet) ",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteServiceConseilValue},
            },
            {
                name:"activiteProductOwner",
                label:"Product owner, Business analyst",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteServiceConseilValue},
            },
            {
                name:"activiteFormation",
                label:"Formation",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteServiceConseilValue},
            },
            {
                name:"activiteAssistance",
                label:"Assistance, helpdesk, maintenance, tierce maintenance applicative, mises à jour",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteServiceConseilValue},
            },
            {
                name:"activiteInfogerance",
                label:"Infogérance",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteServiceConseilValue},
            },
            {
                name:"activiteSecurite",
                label:"Sécurité des systèmes d'information, dont tests d'intrusion",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteServiceConseilValue},
            },
            {
                name:"activiteMoe",
                label:"Maîtrise d’œuvre / gestion de projet, Tech lead, Lead dev",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteServiceConseilValue},
            },
            {
                name:"activiteBigData",
                label:"Big data, Data science",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteServiceConseilValue},
            },
            {
                name:"activiteGestionElectronique",
                label:"Gestion électronique des documents / Archivage numérique",
                fieldProps:{label: "CA en %", type:"number"},
                component:renderInput,
                condition:() => {return true === props.activiteServiceConseilValue},
            },
        ],
        'assurance' : [
            {
                name:"assuranceResponsabiliteContrat",
                label:"Insérez-vous une limitation de responsabilité dans vos contrats ?",
                required:true,
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Toujours",value:"0"},
                        {label:"Parfois",value:"1"},
                        {label:"Jamais",value:"2"},
                    ]
                }

            },
            {
                name:"assuranceResponsabiliteDommages",
                label:"Acceptez-vous une responsabilité pour les dommages indirects ?",
                required:true,
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Toujours",value:"0"},
                        {label:"Parfois",value:"1"},
                        {label:"Jamais",value:"2"},
                    ]
                }

            },
            {
                name:"assuranceContenuDiffusion",
                label:"Lorsque vous créez des sites internet pour le compte de clients, approuvent-ils le contenu avant diffusion ?",
                required:true,
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"},
                        {label:"Non concerné",value:"2"},
                    ]
                }

            },
            {
                name:"assuranceProprieteIntellectuelle",
                label:"Vous assurez-vous de la propriété intellectuelle (copyrights, droits de marques, etc.) ou de l’obtention des licences d’utilisation de toutes les images, sons ou musiques qui sont utilisés par vos soins ?",
                required:true,
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"},
                        {label:"Non concerné",value:"2"},
                    ]
                }

            },
            {
                name:"assuranceSinistre3Ans",
                label:"Sinistre(s) dans les 3 dernières années ?",
                required:true,
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"},
                    ]
                }

            },
            {
                name:"assuranceSinistre3AnsDetail",
                label:"Si oui, indiquez la date du ou des sinistre(s), la nature du sinistre et le montant indemnisé",
                component:renderTextarea,
                condition:() => {return true === props.assuranceSinistre3AnsValue},
            },
            {
                name:"assuranceRcPro",
                label:"Etes-vous assuré en RC Professionnelle ?",
                required:true,
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"},
                    ]
                }

            },
            {
                name:"assuranceRcProDetail",
                label:"Si oui, auprès de quel assureur ? pour quel montant de garantie ?",
                component:renderInput,
                condition:() => {return true === props.assuranceRcProValue},
            },
            {
                name:"assuranceMontantGarantie",
                label:"Montant de garantie souhaité ",
                required:true,
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"100 000 €",value:"100000"},
                        {label:"200 000 €",value:"200000"},
                        {label:"300 000 €",value:"300000"},
                        {label:"500 000 €",value:"500000"},
                        {label:"Autres",value:"0"},
                    ]
                }
            },
            {
                name:"assuranceMontantGarantieDetail",
                label:"Précisez",
                component:renderInput,
                condition:() => {return 0 === props.assuranceMontantGarantieValue},
            },
            {
                name:"assuranceDejaResilie",
                label:"Avez-vous déjà été résilié par un assureur ?",
                required:true,
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"},
                    ]
                }

            },
            {
                name:"assuranceDejaResilieDetail",
                label:"Si oui pour quel motif ?",
                component:renderTextarea,
                condition:() => {return true === props.assuranceDejaResilieValue},
            },
        ],
        'state' : [
            {
                name:"config",
                required:true,
                component:renderHidden,
                componentOptions:{value:props.config}
            },
            {
                name:"statutPriseGarantie",
                required:true,
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Prise de garantie refusée",value:"0"},
                        {label:"Prise de garantie validée",value:"1"},
                        {label:"Prise de garantie en cours",value:"2"},
                        {label:"Prise de garantie en attente",value:"3"},
                    ]
                }

            },
            {
                name:"signatureContrat",
                required:true,
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Contrat signé",value:"1"},
                        {label:"Contrat non signé",value:"0"},
                    ]
                }

            },
            {
                name:"statutQuittance",
                required:true,
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Quittance à jour",value:"1"},
                        {label:"Quittance à régulariser",value:"0"},
                    ]
                }

            },
            {
                name:"statutContrat",
                required:true,
                component:renderRadio,
                parse: function (value) {
                    // Workaround pour les calendriers
                    if (value === "0" || value === "1" || value === "2" || value === "3" || value === "4") {
                        return Number(value);
                    }
                    return props.statutContratValue;
                },
                componentOptions:{
                    options:[
                        {label:"Contrat à échéance",value:"2"},
                        {
                            label:"Contrat résilié",
                            value:"1",
                            child: [
                                {
                                    name:"dateContratResilie",
                                    component: renderHidden,
                                    reference: 'contratResilieDatePicker'
                                },
                                {
                                    name:"contratResilieDatePicker",
                                    component:renderDate,
                                    fieldProps: {
                                        selectedValue: props.dateContratResilieValue,
                                        placeholder:'Date résiliation',
                                        dateTimeRef:props.obj ? props.obj.dateTimeResilieRef : null
                                    },
                                    componentOptions: {
                                        change: props.changeDateResilie ? props.changeDateResilie.bind(props.obj) : null,

                                    },
                                    condition:() => {return 1 === props.statutContratValue},

                                },
                            ]

                        },
                        {
                            label:"Contrat non résilié",
                            value:"0"
                        },
                        {
                            label:"Contrat sans effet",
                            value:"4",
                            child: [
                                {
                                    name:"dateContratSanseffet",
                                    component: renderHidden,
                                    reference: 'contratSanseffetDatePicker'
                                },
                                {
                                    name:"contratSanseffetDatePicker",
                                    component:renderDate,
                                    placeholder:'test',
                                    fieldProps: {
                                        selectedValue: props.dateContratSanseffetValue,
                                        placeholder:'Date sans effet',
                                        dateTimeRef:props.obj ? props.obj.dateTimeSansEffetRef : null
                                    },
                                    componentOptions: {
                                        change: props.changeDateSanseffet ? props.changeDateSanseffet.bind(props.obj) : null,

                                    },
                                    condition:() => {return 4 === props.statutContratValue},

                                },
                            ]
                        },
                    ]
                }

            },
            {
                name:"statutDevis",
                required:true,
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Devis en cours de cotation",value:"3"},
                        {label:"Devis valide",value:"1"},
                        {label:"Devis non valide",value:"0"},
                    ]
                }
            },
        ],
        'protectionDonneesPublic' : [
            {
                name:"rgpd",
                required:true,
                component:renderCheckbox,
                componentOptions: {
                    checkboxLabel:"En cochant cette case, vous acceptez que vos données soient utilisées dans le cadre d’une demande d’assurance"
                }
            },
            {name:"captcharesponse", required:true, component:renderCaptcha}

        ]
    }
}
export default columns;


