import {DEVIS_IMMEUBLE_RESET, DEVIS_IMMEUBLE_RECEIVED, DEVIS_IMMEUBLE_SAVE} from "../actions";

const defaultState = {
    id: null,
    config: null,

    qualiteSouscripteur: null,
    informationsAdresse: null,
    informationsCopropriete: null,
    informationsSurfaceTotale: null,
    informationsTypeMesureSurface: null,
    informationsNombreNiveaux: null,
    informationsSousSolParking: null,
    informationsAnneeConstruction: null,
    informationsStanding: null,
    informationsBatimentClasse: null,
    informationsBatimentClasseDetail: null,
    informationsBatimentExpropriation: null,
    informationsBatimentZoneInondable: null,
    informationsConstructionMateriaux: null,
    informationsCouvertureMateriaux: null,
    informationsPresenceAmiante: null,
    informationsTypeOccupationHabitation: false,
    informationsTypeOccupationProfessionnelle: false,
    informationsSurfaceProSup25: null,
    informationsSurfaceProDetail: '[]',
    informationsActiviteAggravanteRestaurantHotel: false,
    informationsActiviteAggravanteTravailBois: false,
    informationsActiviteAggravanteBoulangerie: false,
    informationsActiviteAggravanteDiscothequeCabaret: false,
    informationsActiviteAggravanteAmbassadeConsulat: false,
    informationsLocauxVides: null,
    informationsLocauxVidesDetail: null,

    entretienFacadeRue: null,
    entretienFacadeCours: null,
    entretienTags: null,
    entretienToiture: null,
    entretienPartiesVitrees: null,
    entretienEscaliers: null,
    entretienPartiesCommunes: null,
    entretienPresenceExtincteurPartieCommune: null,
    entretienCavesLocalPouvelle: null,
    entretienVandalisme: null,
    entretienSquats: null,
    entretienInstallationElectrique: null,
    entretienGardien: null,
    entretienSecurisationAccesImmeuble: null,
    entretienParkingSousSolAccesSecurise: null,

    entretienProgrammeRavalement: null,
    entretienProgrammeToiture: null,
    entretienProgrammeInstallationElectrique: null,
    entretienProgrammeCanalisationEauxChauffage: null,

    antecedentAssuranceDejaAssuree: null,
    antecedentAssuranceDejaAssureeDetail: null,
    antecedentAssuranceDateEcheance: null,
    antecedentAssuranceFranchiseActuelle: null,
    antecedentAssurancePrimeAssuranceActuelle: null,
    antecedentAssuranceResilie: null,
    antecedentAssuranceResilieDetail: null,
    antecedentAssuranceSinistres3Ans: null,
    antecedentAssuranceSinistres3AnsDetail: null,

    garantieEffondrement: null,
    garantieBrisMachines: null,
    garantieRcSyndicBenevole: null,
    garantiePjImmeuble: null,

    proposantAdresse: null,
    proposantVille: null,
    proposantCodepostal: null,
    proposantTelephone: null,
    proposantEmail: null,
    rgpd: null,
    captcha: null,
    fraisIntermediation: null,
    signatureContrat: false,
    statutPriseGarantie: 3,
    statutContrat: 0,
    statutQuittance: 0,
    statutDevis: 3,
    dateContratResilie: null,
    dateContratSanseffet: null,
    client: null,
    utilisateur: null,
    referenceNumero: null,
    ispublic: null,
    notificationUtilisateur: null,

}

export default (state = defaultState, action) => {
    switch (action.type) {
        case DEVIS_IMMEUBLE_RESET:
            return defaultState;

        case DEVIS_IMMEUBLE_SAVE:
            return {
                ...state,
                ...action.data
            };
        case DEVIS_IMMEUBLE_RECEIVED:
            delete action.data['referenceMasque'];
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }
}
