import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Redux
import {formValueSelector, reduxForm} from 'redux-form'
import connect from "react-redux/es/connect/connect";

// App
import columns from "./Columns.js";
import {validateColumns, renderColumns} from 'columnsUtils'
import styles from "assets/jss/styles.js"

const selector = formValueSelector('devisBijouterieForm')

class InformationsRisque extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          touched : false
        };

        this.columns1 = [];
        this.columns2 = [];
        this.columns3 = [];
    }

    shouldComponentUpdate(nextProps, nextState) {
        this.columns1 = columns(
            {
                obj:this,
                proposantGroupementValue:nextProps.proposantGroupementValue,
            }
        )['informationsRisqueProposant'];

        this.columns2 = columns(
            {
            }
        )['informationsRisqueActivite'];

        this.columns3 = columns(
            {
            }
        )['informationsRisqueLocalCommercial'];

        return true;
    }

    isValidated() {
        let errors = validateColumns(this.props.myform.devisBijouterieForm.values, this.columns1);
        errors = {...validateColumns(this.props.myform.devisBijouterieForm.values, this.columns2), ...errors};
        errors = {...validateColumns(this.props.myform.devisBijouterieForm.values, this.columns3), ...errors};
        this.setState({ touched: true});
        return Object.keys(errors).length === 0;
    }

    sendState() {
        return this.state;
    }

    render() {

        const { classes, fetching } = this.props;

        // Cas spécifique pour l'autocomplete
        if (fetching) {
            return (<div></div>)
        }

        return (
            <>
                <>
                    {
                        renderColumns(
                            {
                                columns: this.columns1,
                                titre: 'Informations du risque',
                                soustitre:'Le proposant',
                                classes: classes,
                                fetching: fetching,
                                touched: this.state.touched
                            }
                        )
                    }
                </>
                <>
                    {
                        renderColumns(
                            {
                                columns: this.columns2,
                                soustitre: "L'activité",
                                classes: classes,
                                fetching: fetching,
                                touched: this.state.touched
                            }
                        )
                    }
                </>
                <>
                    {
                        renderColumns(
                            {
                                columns: this.columns3,
                                soustitre: "Le local commercial",
                                classes: classes,
                                fetching: fetching,
                                touched: this.state.touched
                            }
                        )
                    }
                </>
            </>
        );
    }
}

const mapStateToProps = store => ({
    myform:store.form,
    proposantGroupementValue: selector(store, 'proposantGroupement'),
});

const mapDispatchToProps = {
};

export default
connect(mapStateToProps, mapDispatchToProps) (
    // reduxForm nécéssaire pour le this.props.change
    reduxForm({form: 'devisBijouterieForm'}) (
        withStyles(styles)
       (
           InformationsRisque
       )
    )
);

