// App
import {renderImmeubleActivite, renderCaptcha, renderDate, renderText, renderTextarea, renderInput, renderSelect, renderHidden, renderAutocomplete, renderCheckbox, renderRadio, renderMultiCheckbox} from 'formUtils'

const columns = function (props) {
    return {
        'informationsPublic': [
            {name:"proposantNom", required:true, label:"Nom et prénom du proposant ou Raison Sociale", component:renderInput},
            {name:"proposantAdresse", required:true, label:"Adresse", component:renderInput},
            {name:"proposantVille", required:true, label:"Ville", component:renderInput},
            {name:"proposantCodepostal", required:true, label:"Code postal", component:renderInput},
            {name:"proposantTelephone", required:true, label:"Téléphone", component:renderInput},
            {name:"proposantEmail", required:true, label:"Email", component:renderInput},
            {
                name: "ispublic",
                component: renderHidden,
            },
        ],
        'informations': [
            {
                name: "utilisateur",
                component: renderHidden,
                required: true,
                reference: 'utilisateurList'
            },
            {
                name: "utilisateurList",
                label: "Utilisateur",
                component: renderAutocomplete,
                componentOptions: {
                    list: props.courtiers,
                    change: props.changeCourtier ? props.changeCourtier.bind(props.obj) : null,

                },
                fieldProps: {
                    selectedValue: props.courtiers != null ? props.getSelectedValueCourtier(props.obj) : null
                }
            },
            {
                name:"client",
                component:renderHidden,
                required:true,
                reference:'clientList'

            },
            {
                name:"clientList",
                label:"Client",
                component:renderAutocomplete,
                componentOptions:{
                    list:props.clients,
                    change:props.changeClient ? props.changeClient.bind(props.obj) : null
                },
                fieldProps: {
                    selectedValue:props.clients != null ? props.getSelectedValueClient(props.obj) : null
                }
            },
            {
                name: "utilisateur",
                component: renderHidden,
                componentOptions: {value:'/utilisateurs/0'}
            },
            {
                name:"client",
                component:renderHidden,
                componentOptions: {value:'/clients/0'}
            },
            {
                name:"config",
                component:renderHidden,
                componentOptions:{value:'/produit_immeuble_configs/0'}
            },
            {
                name:"fraisIntermediation",
                label:"Frais d'intermédiation",
                component:renderSelect,
                required:true,
                componentOptions:{
                    options:props.getFraisIntermediation ? props.getFraisIntermediation(props.obj) : null
                }
            }
        ],
        'informationsRisque' : [
            {
                name:"qualiteSouscripteur",
                label:"Qualité du souscripteur",
                component:renderRadio,
                required:true,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Association syndicale libre",value:"0"},
                        {label:"Propriétaire non occupant",value:"1"},
                        {label:"Propriétaire occupant partiel",value:"2"},
                        {label:"Société civile immobilière",value:"3"},
                        {label:"Syndic de copropriété professionnel",value:"4"},
                        {label:"Syndic de copropriété bénévole",value:"5"},
                    ]
                },
            },
            {
                name:"informationsAdresse",
                label:"Adresse du risque",
                component:renderInput,

            },
            {
                name:"informationsCopropriete",
                label:"Copropriété",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"informationsSurfaceTotale",
                label:"Surface TOTALE (m²)",
                component:renderInput,

            },
            {
                name:"informationsTypeMesureSurface",
                label:"Type de mesure de la surface",
                component:renderRadio,
                required:true,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Développée",value:"0"},
                        {label:"Plancher",value:"1"},
                        {label:"SHOB",value:"2"},
                        {label:"SHON",value:"3"},
                        {label:"Habitable",value:"4"},
                    ]
                },
            },
            {
                name:"informationsNombreNiveaux",
                label:"Nombre de niveaux (RDC+ étages)",
                component:renderInput,

            },
            {
                name:"informationsSousSolParking",
                label:"Sous-sols et parkings",
                component:renderInput,

            },
            {
                name:"informationsAnneeConstruction",
                label:"Année de construction",
                component:renderInput,

            },
            {
                name:"informationsStanding",
                label:"Standing ",
                component:renderRadio,
                required:true,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Standard",value:"0"},
                        {label:"Luxueux",value:"1"}
                    ]
                },
            },
            {
                name:"informationsBatimentClasse",
                label:"Le bâtiment est-il classé totalement ou partiellement Monuments historiques ou inscrit à l’inventaire ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"informationsBatimentClasseDetail",
                label:"Quelle(s) partie(s) de l’immeuble est/sont classée(s)",
                component:renderTextarea,
                condition:() => {return true === props.informationsBatimentClasseValue}
            },
            {
                name:"informationsBatimentExpropriation",
                label:"Le bâtiment est frappé d'expropriation ou d'alignement ; et fait l'objet d'un décret d'insalubrité ou d'un arrêté de péril ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"informationsBatimentZoneInondable",
                label:"Le bâtiment est situé dans une zone inondable ou ayant fait l'objet d'un PPR (Loi BARNIER)",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"informationsConstructionMateriaux",
                label:"Matériaux de construction",
                component:renderRadio,
                required:true,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Dur",value:"0"},
                        {label:"Léger",value:"1"}
                    ]
                },
            },
            {
                name:"informationsCouvertureMateriaux",
                label:"Matériaux de couverture",
                component:renderRadio,
                required:true,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Dur",value:"0"},
                        {label:"Léger",value:"1"}
                    ]
                },
            },
            {
                name:"informationsPresenceAmiante",
                label:"Présence d'amiante",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"informationsTypeOccupation",
                label:"Type d'occupation",
                component:renderMultiCheckbox,
                componentOptions:{
                    options:[
                        {
                            componentOptions: {
                                checkboxLabel:"Habitation"
                            },
                            value:"1",
                            name:"informationsTypeOccupationHabitation",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel:"Professionnelle"
                            },
                            value:"1",
                            name:"informationsTypeOccupationProfessionnelle",
                            parse: value => Boolean(Number(value)),
                        },
                    ]
                },
            },
            {
                name:"informationsSurfaceProSup25",
                label:"Surface professionnelle > 25%",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                condition:() => {return true === props.informationsTypeOccupationProfessionnelleValue},
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"informationsSurfaceProDetail",
                component:renderHidden,
            },
            {
                name:"informationsSurfaceProDetailImmeubleActivite",
                label:"Si surface professionnelle : quelles activités sur quelle superficie ?",
                component:renderImmeubleActivite,
                condition:() => {return true === props.informationsTypeOccupationProfessionnelleValue},
                componentOptions:{
                    list:props.informationsSurfaceProDetailValue,
                    change: (items) => {props.changeInformationsSurfaceProDetail(props.obj, items)},

                },
            },
            {
                name:"informationsActiviteAggravante",
                label:"Activités très aggravantes",
                component:renderMultiCheckbox,
                componentOptions:{
                    options:[
                        {
                            componentOptions: {
                                checkboxLabel:"Restaurant/Hôtel-restaurant"
                            },
                            value:"1",
                            name:"informationsActiviteAggravanteRestaurantHotel",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel: "Travail du bois",
                            },
                            value:"1",
                            name:"informationsActiviteAggravanteTravailBois",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel: "Boulangerie",
                            },
                            value:"1",
                            name:"informationsActiviteAggravanteBoulangerie",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel: "Discothèque, Cabaret",
                            },
                            value:"1",
                            name:"informationsActiviteAggravanteDiscothequeCabaret",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel: "Ambassade, Consulat",
                            },
                            value:"1",
                            name:"informationsActiviteAggravanteAmbassadeConsulat",
                            parse: value => Boolean(Number(value)),
                        },
                    ]
                },
            },
            {
                name:"informationsLocauxVides",
                label:"Y a-t-il des locaux vides",
                tooltip:"Surface vide d'occupants déclarée : c'est la surface maximum des locaux vides d'occupant (hors sous-sols, caves, parkings et greniers non habitables)",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"informationsLocauxVidesDetail",
                label:"Si oui quelle surface",
                component:renderInput,
                condition:() => {return true === props.informationsLocauxVidesValue}
            },
        ],
        'entretien1' : [
            {
                name:"entretienFacadeRue",
                label:"Bon état apparent de la façade sur rue",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entretienFacadeCours",
                label:"Bon état apparent de la façade sur cours",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entretienTags",
                label:"Présence de tags",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entretienToiture",
                label:"Bon état apparent de la toiture",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entretienPartiesVitrees",
                label:"Bon état apparent des parties vitrées",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entretienEscaliers",
                label:"Bon état apparent des escaliers (marches, rampes)",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entretienPartiesCommunes",
                label:"Propreté des parties communes",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entretienPresenceExtincteurPartieCommune",
                label:"Présence d'extincteur(s) dans les parties communes",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entretienCavesLocalPouvelle",
                label:"Bon état apparent des caves et du local poubelle",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entretienVandalisme",
                label:"Traces de vandalisme",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entretienSquats",
                label:"Squats",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entretienInstallationElectrique",
                label:"Bon état apparent de l'installation électrique",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entretienGardien",
                label:"Présence d'un gardien sur place",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entretienSecurisationAccesImmeuble",
                label:"Sécurisation des accès à l'immeuble",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entretienParkingSousSolAccesSecurise",
                label:"Si parking en sous-sol, accès sécurisé",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
        ],
        'entretien2' : [
            {
                name:"entretienProgrammeRavalement",
                label:"Ravalement",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entretienProgrammeToiture",
                label:"Toiture",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entretienProgrammeInstallationElectrique",
                label:"Installation électrique",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entretienProgrammeCanalisationEauxChauffage",
                label:"Canalisations d'eaux ou de chauffage",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
        ],
        'assurance1' : [
            {
                name:"antecedentAssuranceDejaAssuree",
                label:"Déjà assuré ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"antecedentAssuranceDejaAssureeDetail",
                label:"Si oui, nom de l'assureur",
                component:renderInput,
                condition:() => {return true === props.antecedentAssuranceDejaAssureeValue},

            },
            {
                name:"antecedentAssuranceDateEcheance",
                label:"Date d'échéance principale",
                component:renderInput,

            },
            {
                name:"antecedentAssuranceFranchiseActuelle",
                label:"Franchise actuelle",
                component:renderInput,

            },
            {
                name:"antecedentAssurancePrimeAssuranceActuelle",
                label:"Prime d’assurance actuelle",
                component:renderInput,

            },
            {
                name:"antecedentAssuranceResilie",
                label:"Résiliation d'un précèdent assureur",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"antecedentAssuranceResilieDetail",
                label:"Si oui pour quel motif ?",
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Sinistre",value:"0"},
                        {label:"Non-paiement",value:"1"},
                        {label:"Fausse déclaration",value:"2"},
                    ]
                },
                condition:() => {return true === props.antecedentAssuranceResilieValue},

            },
            {
                name:"antecedentAssuranceSinistres3Ans",
                label:"Sinistres sur les 36 derniers mois",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"antecedentAssuranceSinistres3AnsDetail",
                label:"Si oui, merci de préciser et de joindre la statistique sinistre via la partie document",
                component:renderTextarea,
                condition:() => {return true === props.antecedentAssuranceSinistres3AnsValue},
            },
        ],
        'assurance2' : [
            {
                name:"assurance2Text1",
                label:"Incendie et risques annexes",
                component:renderText,
                componentOptions:{text:"Oui"},
            },
            {
                name:"assurance2Text2",
                label:"Dégâts des eaux",
                component:renderText,
                componentOptions:{text:"Oui"},
            },
            {
                name:"assurance2Text3",
                label:"Tempête, Grêle, Neige",
                component:renderText,
                componentOptions:{text:"Oui"},
            },
            {
                name:"assurance2Text4",
                label:"Catastrophes naturelles",
                component:renderText,
                componentOptions:{text:"Oui"},
            },
            {
                name:"assurance2Text5",
                label:"Catastrophes technologiques",
                component:renderText,
                componentOptions:{text:"Oui"},
            },
            {
                name:"assurance2Text6",
                label:"Vol",
                component:renderText,
                componentOptions:{text:"Oui"},
            },
            {
                name:"assurance2Text7",
                label:"Emeute, Mouvements populaires, Actes de sabotages ou de vandalisme",
                component:renderText,
                componentOptions:{text:"Oui"},
            },
            {
                name:"assurance2Text8",
                label:"Attentats et actes de terrorisme",
                component:renderText,
                componentOptions:{text:"Oui"},
            },
            {
                name:"garantieEffondrement",
                label:"Effondrement",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"assurance2Text9",
                label:"Dommages électriques",
                component:renderText,
                componentOptions:{text:"Oui"},
            },
            {
                name:"garantieBrisMachines",
                label:"Bris de machines",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"assurance2Text10",
                label:"Bris de glaces",
                component:renderText,
                componentOptions:{text:"Oui"},
            },
            {
                name:"assurance2Text11",
                label:"Responsabilité civile Propriétaire d'immeuble",
                component:renderText,
                componentOptions:{text:"Oui"},
            },
            {
                name:"garantieRcSyndicBenevole",
                label:"Responsabilité civile du syndic bénévole",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"garantiePjImmeuble",
                label:"Protection juridique Immeuble",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
        ],
        'state' : [
            {
                name:"config",
                required:true,
                component:renderHidden,
                componentOptions:{value:props.config}
            },
            {
                name:"statutPriseGarantie",
                required:true,
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Prise de garantie refusée",value:"0"},
                        {label:"Prise de garantie validée",value:"1"},
                        {label:"Prise de garantie en cours",value:"2"},
                        {label:"Prise de garantie en attente",value:"3"},
                    ]
                }

            },
            {
                name:"signatureContrat",
                required:true,
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Contrat signé",value:"1"},
                        {label:"Contrat non signé",value:"0"},
                    ]
                }

            },
            {
                name:"statutQuittance",
                required:true,
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Quittance à jour",value:"1"},
                        {label:"Quittance à régulariser",value:"0"},
                    ]
                }

            },
            {
                name:"statutContrat",
                required:true,
                component:renderRadio,
                parse: function (value) {
                    // Workaround pour les calendriers
                    if (value === "0" || value === "1" || value === "2" || value === "3" || value === "4") {
                        return Number(value);
                    }
                    return props.statutContratValue;
                },
                componentOptions:{
                    options:[
                        {label:"Contrat à échéance",value:"2"},
                        {
                            label:"Contrat résilié",
                            value:"1",
                            child: [
                                {
                                    name:"dateContratResilie",
                                    component: renderHidden,
                                    reference: 'contratResilieDatePicker'
                                },
                                {
                                    name:"contratResilieDatePicker",
                                    component:renderDate,
                                    fieldProps: {
                                        selectedValue: props.dateContratResilieValue,
                                        placeholder:'Date résiliation',
                                        dateTimeRef:props.obj ? props.obj.dateTimeResilieRef : null
                                    },
                                    componentOptions: {
                                        change: props.changeDateResilie ? props.changeDateResilie.bind(props.obj) : null,

                                    },
                                    condition:() => {return 1 === props.statutContratValue},

                                },
                            ]

                        },
                        {
                            label:"Contrat non résilié",
                            value:"0"
                        },
                        {
                            label:"Contrat sans effet",
                            value:"4",
                            child: [
                                {
                                    name:"dateContratSanseffet",
                                    component: renderHidden,
                                    reference: 'contratSanseffetDatePicker'
                                },
                                {
                                    name:"contratSanseffetDatePicker",
                                    component:renderDate,
                                    placeholder:'test',
                                    fieldProps: {
                                        selectedValue: props.dateContratSanseffetValue,
                                        placeholder:'Date sans effet',
                                        dateTimeRef:props.obj ? props.obj.dateTimeSansEffetRef : null
                                    },
                                    componentOptions: {
                                        change: props.changeDateSanseffet ? props.changeDateSanseffet.bind(props.obj) : null,

                                    },
                                    condition:() => {return 4 === props.statutContratValue},

                                },
                            ]
                        },
                    ]
                }

            },
            {
                name:"statutDevis",
                required:true,
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Devis en cours de cotation",value:"3"},
                        {label:"Devis valide",value:"1"},
                        {label:"Devis non valide",value:"0"},
                    ]
                }
            },
        ],
        'protectionDonneesPublic' : [
            {
                name:"rgpd",
                required:true,
                component:renderCheckbox,
                componentOptions: {
                    checkboxLabel:"En cochant cette case, vous acceptez que vos données soient utilisées dans le cadre d’une demande d’assurance"
                }
            },
            {name:"captcharesponse", required:true, component:renderCaptcha}

        ]
    }
}
export default columns;


