// Material UI
import {Security as SecurityIcon, Business as BusinessIcon, Build as BuildIcon, Forward10 as Forward10Icon, ImportantDevices as ImportantDevicesIcon} from "@material-ui/icons";
import { ExitRun as ExitRunIcon, DiamondStone as DiamondStoneIcon } from 'mdi-material-ui'

// App
import LoginPage from "./pages/LoginPage.js";
import ConfigurationBijouterie from "./components/ConfigurationBijouterie.js";
import ConfigurationEscapeGame from "./components/ConfigurationEscapeGame.js";
import ConfigurationCyber from "./components/ConfigurationCyber.js";
import ConfigurationImmeuble from "./components/ConfigurationImmeuble.js";
import ConfigurationGarage from "./components/ConfigurationGarage.js";
import ConfigurationDecennale from "./components/ConfigurationDecennale.js";
import ConfigurationInformatique from "./components/ConfigurationInformatique.js";
import DevisBijouterie from "./components/DevisBijouterie.js";
import DevisEscapeGame from "./components/DevisEscapeGame.js";
import DevisCyber from "./components/DevisCyber.js";
import DevisImmeuble from "./components/DevisImmeuble.js";
import DevisGarage from "./components/DevisGarage.js";
import DevisDecennale from "./components/DevisDecennale.js";
import DevisInformatique from "./components/DevisInformatique.js";
import Clients from "./components/Clients.js";
import Dashboard from "./components/Dashboard.js";
import Devis from "./components/Devis.js";
import Utilisateur from "./components/Utilisateur.js";
import Client from "./components/Client.js";
import ErrorPage from "pages/ErrorPage.js";

var dashRoutes = [
    {
        path: "/404",
        name: "Erreur",
        rtlName: "صفحة الخطأ",
        mini: "E",
        rtlMini: "البريد",
        component: ErrorPage,
        layout: "/admin",
        sidebar:false,
    },
    {
        path: "/login-page",
        name: "BA Assurance",
        mini: "L",
        component: LoginPage,
        form:"LoginForm",
        layout: "/auth",
    },
    {
        path: "/devis-escape-game/:id",
        name: "Modification devis Escape Game",
        icon: ExitRunIcon,
        component: DevisEscapeGame,
        role:'ROLE_ADMIN',
        layout: "/admin",
        sidebar:false
    },
    {
        path: "/devis-bijouterie/:id",
        name: "Modification devis Bijouterie",
        icon: ExitRunIcon,
        component: DevisBijouterie,
        role:'ROLE_ADMIN',
        layout: "/admin",
        sidebar:false
    },
    {
        path: "/devis-cyber/:id",
        name: "Modification devis Cyber",
        icon: SecurityIcon,
        component: DevisCyber,
        role:'ROLE_ADMIN',
        layout: "/admin",
        sidebar:false
    },
    {
        path: "/devis-immeuble/:id",
        name: "Modification devis Immeuble",
        icon: BusinessIcon,
        component: DevisImmeuble,
        role:'ROLE_ADMIN',
        layout: "/admin",
        sidebar:false
    },
    {
        path: "/devis-garage/:id",
        name: "Modification devis Garage",
        icon: BuildIcon,
        component: DevisGarage,
        role:'ROLE_ADMIN',
        layout: "/admin",
        sidebar:false
    },
    {
        path: "/devis-decennale/:id",
        name: "Modification devis Décennale",
        icon: Forward10Icon,
        component: DevisDecennale,
        role:'ROLE_ADMIN',
        layout: "/admin",
        sidebar:false
    },
    {
        path: "/devis-informatique/:id",
        name: "Modification devis Informatique",
        icon: ImportantDevicesIcon,
        component: DevisInformatique,
        role:'ROLE_ADMIN',
        layout: "/admin",
        sidebar:false
    },
    {
        path: "/devis-bijouterie",
        name: "Ajout devis Bijouterie",
        icon: ExitRunIcon,
        component: DevisBijouterie,
        role:'ROLE_ADMIN',
        layout: "/iframe",
    },
    {
        path: "/devis-escape-game",
        name: "Ajout devis Escape Game",
        icon: ExitRunIcon,
        component: DevisEscapeGame,
        role:'ROLE_ADMIN',
        layout: "/iframe",
    },
    {
        path: "/devis-cyber",
        name: "Ajout devis Cyber",
        icon: SecurityIcon,
        component: DevisCyber,
        role:'ROLE_ADMIN',
        layout: "/iframe"
    },
    {
        path: "/devis-immeuble",
        name: "Ajout devis Immeuble",
        icon: BusinessIcon,
        component: DevisImmeuble,
        role:'ROLE_ADMIN',
        layout: "/iframe"
    },
    {
        path: "/devis-garage",
        name: "Ajout devis Garage",
        icon: BuildIcon,
        component: DevisGarage,
        role:'ROLE_ADMIN',
        layout: "/iframe",
    },
    {
        path: "/devis-decennale",
        name: "Ajout devis Décennale",
        icon: Forward10Icon,
        component: DevisDecennale,
        role:'ROLE_ADMIN',
        layout: "/iframe",
    },
    {
        path: "/devis-informatique",
        name: "Ajout devis Informatique",
        icon: ImportantDevicesIcon,
        component: DevisInformatique,
        role:'ROLE_ADMIN',
        layout: "/iframe",
    },
    {
        path: "/devis-bijouterie",
        name: "Ajout devis Bijouterie",
        icon: ExitRunIcon,
        component: DevisBijouterie,
        role:'ROLE_ADMIN',
        layout: "/admin",
        sidebar: false,
    },
    {
        path: "/devis-escape-game",
        name: "Ajout devis Escape Game",
        icon: ExitRunIcon,
        component: DevisEscapeGame,
        role:'ROLE_ADMIN',
        layout: "/admin",
        sidebar: false,
    },
    {
        path: "/devis-cyber",
        name: "Ajout devis Cyber",
        icon: SecurityIcon,
        component: DevisCyber,
        role:'ROLE_ADMIN',
        layout: "/admin",
        sidebar: false,
    },
    {
        path: "/devis-immeuble",
        name: "Ajout devis Immeuble",
        icon: BusinessIcon,
        component: DevisImmeuble,
        role:'ROLE_ADMIN',
        layout: "/admin",
        sidebar: false,
    },
    {
        path: "/devis-garage",
        name: "Ajout devis Garage",
        icon: BuildIcon,
        component: DevisGarage,
        role:'ROLE_ADMIN',
        layout: "/admin",
        sidebar: false,
    },
    {
        path: "/devis-decennale",
        name: "Ajout devis Décennale",
        icon: Forward10Icon,
        component: DevisDecennale,
        role:'ROLE_ADMIN',
        layout: "/admin",
        sidebar: false,
    },
    {
        path: "/devis-informatique",
        name: "Ajout devis Informatique",
        icon: ImportantDevicesIcon,
        component: DevisInformatique,
        role:'ROLE_ADMIN',
        layout: "/admin",
        sidebar: false,
    },
    {
        path: "/utilisateurs/:id",
        name: "Compte",
        component: Utilisateur,
        layout: "/admin",
        sidebar:false
    },
    {
        path: "/liste-devis",
        name: "Devis et contrats",
        icon: "list",
        component: Devis,
        layout: "/admin",
    },
    {
        collapse: true,
        role:'ROLE_ADMIN',
        name: "Configuration",
        icon: "settings",
        state: "configCollapse",
        views: [
            {
                path: "/config-bijouterie",
                name: "Configuration Bijouterie",
                icon: DiamondStoneIcon,
                component: ConfigurationBijouterie,
                role:'ROLE_ADMIN',
                layout: "/admin"
            },
            {
                path: "/config-escape-game",
                name: "Configuration Escape Game",
                icon: ExitRunIcon,
                component: ConfigurationEscapeGame,
                role:'ROLE_ADMIN',
                layout: "/admin"
            },
            {
                path: "/config-cyber",
                name: "Configuration Cyber",
                icon: SecurityIcon,
                component: ConfigurationCyber,
                role:'ROLE_ADMIN',
                layout: "/admin"
            },
            {
                path: "/config-immeuble",
                name: "Configuration Immeuble",
                icon: BusinessIcon,
                component: ConfigurationImmeuble,
                role:'ROLE_ADMIN',
                layout: "/admin"
            },
            {
                path: "/config-garage",
                name: "Configuration Garage",
                icon: BuildIcon,
                component: ConfigurationGarage,
                role:'ROLE_ADMIN',
                layout: "/admin"
            },
            {
                path: "/config-decennale",
                name: "Configuration Décennale",
                icon: Forward10Icon,
                component: ConfigurationDecennale,
                role:'ROLE_ADMIN',
                layout: "/admin"
            },
            {
                path: "/config-informatique",
                name: "Configuration Informatique",
                icon: ImportantDevicesIcon,
                component: ConfigurationInformatique,
                role:'ROLE_ADMIN',
                layout: "/admin"
            },
        ]
    },
    {
        collapse: true,
        role:'ROLE_ADMIN',
        name: "Nouveau devis",
        icon: "add_box",
        state: "nouveauDevisCollapse",
        views: [
            {
                path: "/devis-bijouterie",
                name: "Bijouterie",
                icon: DiamondStoneIcon,
                component: DevisBijouterie,
                role:'ROLE_ADMIN',
                layout: "/admin"
            },
            {
                path: "/devis-escape-game",
                name: "Escape Game",
                icon: ExitRunIcon,
                component: DevisEscapeGame,
                role:'ROLE_ADMIN',
                layout: "/admin"
            },
            {
                path: "/devis-cyber",
                name: "Cyber",
                icon: SecurityIcon,
                component: DevisCyber,
                role:'ROLE_ADMIN',
                layout: "/admin"
            },
            {
                path: "/devis-immeuble",
                name: "Immeuble",
                icon: BusinessIcon,
                component: DevisImmeuble,
                role:'ROLE_ADMIN',
                layout: "/admin"
            },
            {
                path: "/devis-garage",
                name: "Garage",
                icon: BuildIcon,
                component: DevisGarage,
                role:'ROLE_ADMIN',
                layout: "/admin",
            },
            {
                path: "/devis-decennale",
                name: "Décennale",
                icon: Forward10Icon,
                component: DevisDecennale,
                role:'ROLE_ADMIN',
                layout: "/admin",
            },
            {
                path: "/devis-informatique",
                name: "Informatique",
                icon: ImportantDevicesIcon,
                component: DevisInformatique,
                role:'ROLE_ADMIN',
                layout: "/admin",
            },
        ]
    },
    {
        path: "/client/:id",
        name: "Modification client",
        icon: "group",
        component: Client,
        layout: "/admin",
        sidebar:false
    },
    {
        path: "/client",
        name: "Ajout client",
        icon: "group",
        component: Client,
        layout: "/admin",
        sidebar:false
    },
    {
        path: "/clients",
        name: "Clients",
        icon: "group",
        component: Clients,
        layout: "/admin",
    },
    /*{
        path: "/documents",
        name: "Documents",
        icon: "content_paste",
        component: Document,
        layout: "/admin",
    },*/
    {
        path: "/",
        name: "Accueil",
        mini: "A",
        component: Dashboard,
        layout: "/admin",
        sidebar:false
    },
];
export default dashRoutes;
