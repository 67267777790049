// App
import {renderMultiCheckbox, renderCaptcha, renderGarageActivite, renderGarageVehicule, renderDate, renderText, renderTextarea, renderInput, renderSelect, renderHidden, renderAutocomplete, renderCheckbox, renderRadio} from 'formUtils'

const columns = function (props) {
    return {
        'informationsPublic': [
            {name:"proposantNom", required:true, label:"Nom et prénom du proposant ou Raison Sociale", component:renderInput},
            {name:"proposantAdresse", required:true, label:"Adresse", component:renderInput},
            {name:"proposantVille", required:true, label:"Ville", component:renderInput},
            {name:"proposantCodepostal", required:true, label:"Code postal", component:renderInput},
            {name:"proposantTelephone", required:true, label:"Téléphone", component:renderInput},
            {name:"proposantEmail", required:true, label:"Email", component:renderInput},
            {
                name: "ispublic",
                component: renderHidden,
            },
        ],
        'informations': [
            {
                name: "utilisateur",
                component: renderHidden,
                required: true,
                reference: 'utilisateurList'
            },
            {
                name: "utilisateurList",
                label: "Utilisateur",
                component: renderAutocomplete,
                componentOptions: {
                    list: props.courtiers,
                    change: props.changeCourtier ? props.changeCourtier.bind(props.obj) : null,

                },
                fieldProps: {
                    selectedValue: props.courtiers != null ? props.getSelectedValueCourtier(props.obj) : null
                }
            },
            {
                name:"client",
                component:renderHidden,
                required:true,
                reference:'clientList'

            },
            {
                name:"clientList",
                label:"Client",
                component:renderAutocomplete,
                componentOptions:{
                    list:props.clients,
                    change:props.changeClient ? props.changeClient.bind(props.obj) : null
                },
                fieldProps: {
                    selectedValue:props.clients != null ? props.getSelectedValueClient(props.obj) : null
                }
            },
            {
                name: "utilisateur",
                component: renderHidden,
                componentOptions: {value:'/utilisateurs/0'}
            },
            {
                name:"client",
                component:renderHidden,
                componentOptions: {value:'/clients/0'}
            },
            {
                name:"config",
                component:renderHidden,
                componentOptions:{value:'/produit_cyber_configs/0'}
            },
            {
                name:"fraisIntermediation",
                label:"Frais d'intermédiation",
                component:renderSelect,
                required:true,
                componentOptions:{
                    options:props.getFraisIntermediation ? props.getFraisIntermediation(props.obj) : null
                }
            }
        ],
        'entreprise' : [
            {
                name:"entrepriseSiret",
                label:"SIRET",
                component:renderInput,
                required:true,

            },

            {
                name:"entrepriseCaHt",
                label:"Chiffre d'affaire annuel HT (€)",
                component:renderInput,
                required:true,

            },
            {
                name:"entrepriseEffectif",
                label:"Effectif",
                component:renderInput,
                required:true,

            },
            {
                name:"entrepriseActivitesCa",
                label:"Activités",
                component:renderGarageActivite,
                componentOptions:{
                    list:props.entrepriseActivitesValue,
                    change: (items) => {props.changeEntrepriseActivites(props.obj, items)},

                },
                required:true,
                validateActivites:true,
                validate:(total) => {
                    return total === 100
                },
                error:"Le total des activités doit être égal à 100%"
            },
            {
                name:"entrepriseActivites",
                label:"Activités",
                component:renderHidden,

            },
            {
                name:"entrepriseAdresse",
                label:"Adresse des locaux (si différente du siège social)",
                component:renderInput,
                required:true,

            },
            {
                name:"entrepriseVille",
                label:"Ville",
                component:renderInput,
                required:true,

            },
            {
                name:"entrepriseCodepostal",
                label:"Code postal",
                component:renderInput,
                required:true,

            },
            {
                name:"entrepriseSurface",
                label:"Surface développée des bâtiments (m²)",
                component:renderInput,
                required:true,

            },
            {
                name:"entrepriseQualiteJuridique",
                label:"Qualité juridique",
                component:renderRadio,
                required:true,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Propriétaire",value:"0"},
                        {label:"Locataire",value:"1"},
                    ]
                },
            },
        ],
        'risque' : [
            {
                name:"risqueAutoPersonnesEntreprise",
                label:"Personnes de l’entreprise conduisant un véhicule tous les jours",
                component:renderInput,
                required:true,

            },
            {
                name:"risqueAutoPersonnesEntrepriseNombreConducteurs",
                label:"Nombre de conducteurs (chef d’entreprise, salarié, apprentis)",
                component:renderInput,
                required:true,

            },
            {
                name:"risqueAutoPersonnesPrivees",
                label:"Personnes privées (membres de la famille) conduisant un véhicule (propriété de l’entreprise ou des représentants légaux ou associés) tous les jours",
                component:renderInput,
                required:true,

            },
            {
                name:"risqueAutoPersonnesPriveesNombreConduteurTousUsages",
                label:"Nombre de conducteurs (conjoints, enfants) conduisant en tous usages",
                component:renderInput,
                required:true,

            },
            {
                name:"risqueAutoPersonnesPriveesNombreConduteurUsageAffaire",
                label:"Nombre de conducteurs (conjoints, enfants) conduisant en usage Affaires (tournés hors activité du garage)",
                component:renderInput,
                required:true,

            },
            {
                name:"risqueAutoPersonnesPriveesNombreConduteurNovice",
                label:"Nombre de conducteurs (conjoint, enfant) novice (permis < 3 ans)",
                component:renderInput,
                required:true,

            },
            {
                name:"risqueAutoValeurParc",
                label:"Valeur du parc automobile (propriété + confiés)",
                component:renderInput,
                required:true,

            },
            {
                name:"risqueAutoValeurParcInterieur",
                label:"Dans les bâtiments (€)",
                component:renderInput,
                required:true,

            },
            {
                name:"risqueAutoValeurParcExterieur",
                label:"A l’extérieur des bâtiments (dans l’enceinte de l’entreprise) (€)",
                component:renderInput,
                required:true,

            },
        ],
        'moyensPreventionIncendie' : [
            {
                name:"moyensPreventionIncendiePresenceExtincteurs",
                label:"Présence d’extincteurs conformes à la règle R4 (APSAD) annuellement vérifiés",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"moyensPreventionIncendieVerificationAnnuelle",
                label:"Vérification annuelle des installations électriques par un professionnel qualifié",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"moyensPreventionIncendieAutresMoyens",
                label:"Autres moyens de protection contre l’incendie",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"moyensPreventionIncendieAutresMoyensDetail",
                label:"Si oui, champ à compléter",
                component:renderInput,
                condition:() => {return true === props.moyensPreventionIncendieAutresMoyensValue}

            },
        ],
        'moyensPreventionVol' : [
            {
                name:"moyensPreventionVolEnvironnement",
                label:"Environnement géographique",
                component:renderMultiCheckbox,
                required:true,
                componentOptions:{
                    options:[
                        {
                            componentOptions: {
                                checkboxLabel:"Habitation du proposant sur place ou à proximité immédiate, ou gardiennage"
                            },
                            value:"1",
                            name:"moyensPreventionVolEnvironnementHabitationProposant",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel: "Zone habitée (ensemble d'habitations occupées de façon permanente)",
                            },
                            value:"1",
                            name:"moyensPreventionVolEnvironnementZoneHabitee",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel: "Zone isolée (non habitée, le souscripteur n'habitant pas sur place ou à proximité immédiate)",
                            },
                            value:"1",
                            name:"moyensPreventionVolEnvironnementZoneIsolee",
                            parse: value => Boolean(Number(value)),
                        },
                    ]
                },
            },
            {
                name:"moyensPreventionVolStationnement",
                label:"Stationnement des véhicules la nuit et hors horaires d’ouverture",
                component:renderMultiCheckbox,
                required:true,
                componentOptions:{
                    options:[
                        {
                            componentOptions: {
                                checkboxLabel:"dans les bâtiments"
                            },
                            value:"1",
                            name:"moyensPreventionVolStationnementInterieur",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel: "dans l'enceinte (hors des bâtiments mais à l'intérieur des limites de propriété de l'entreprise)",
                            },
                            value:"1",
                            name:"moyensPreventionVolStationnementEnceinte",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel: "en dehors de l'enceinte (trottoir, parking de supermarché, ...)",
                            },
                            value:"1",
                            name:"moyensPreventionVolStationnementExterieur",
                            parse: value => Boolean(Number(value)),
                        },
                    ]
                },
            },
            {
                name:"moyensPreventionVolProtectionEnceinte",
                label:"Protection de l’enceinte de l'entreprise",
                component:renderMultiCheckbox,
                required:true,
                componentOptions:{
                    options:[
                        {
                            componentOptions: {
                                checkboxLabel:"clôtures lourdes ou clôtures défensives avec sommet aménagé pour retarder le franchissement"
                            },
                            value:"1",
                            name:"moyensPreventionVolProtectionEnceinteCloturesLourdes",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel: "est éclairée la nuit ou éclairage avec détection de présence",
                            },
                            value:"1",
                            name:"moyensPreventionVolProtectionEnceinteEclairage",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel: "est entourée d'un grillage (barrière) avec portail fermant à clé ou fait l'objet d'un gardiennage",
                            },
                            value:"1",
                            name:"moyensPreventionVolProtectionEnceinteGrillage",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel: "pas de protection",
                            },
                            value:"1",
                            name:"moyensPreventionVolProtectionEnceinteAucune",
                            parse: value => Boolean(Number(value)),
                        },
                    ]
                },
            },
            {
                name:"moyensPreventionVolProtection",
                label:"Protection du parc de véhicules de l'entreprise : la nuit et hors horaires d’ouverture",
                component:renderMultiCheckbox,
                required:true,
                componentOptions:{
                    options:[
                        {
                            componentOptions: {
                                checkboxLabel:"clôtures lourdes ou clôtures défensives avec sommet aménagé pour retarder ou dissuader le franchissement"
                            },
                            value:"1",
                            name:"moyensPreventionVolProtectionParcCloturesLourdes",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel: "clôture grillagée avec accès par portail fermant à clé ou plots ou glissières de sécurité ou gardiennage",
                            },
                            value:"1",
                            name:"moyensPreventionVolProtectionParcGrillage",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel: "véhicules équipés de leur système anti-vol activé ou rendus «indémarrables» (retrait bougie, démarreur, etc…)",
                            },
                            value:"1",
                            name:"moyensPreventionVolProtectionParcAntivol",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel: "pas de protection",
                            },
                            value:"1",
                            name:"moyensPreventionVolProtectionParcAucune",
                            parse: value => Boolean(Number(value)),
                        },
                    ]
                },
            },
            {
                name:"moyensPreventionVolProtectionElectronique",
                label:"Protection électronique",
                component:renderMultiCheckbox,
                required:true,
                componentOptions:{
                    options:[
                        {
                            componentOptions: {
                                checkboxLabel:"Le parc extérieur est-il placé sous surveillance électronique (alarme)"
                            },
                            value:"1",
                            name:"moyensPreventionVolProtectionElectroniqueAlarme",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel: "Existe-t-il une liaison téléphonique directe à un poste de gardiennage ou station de télésurveillance",
                            },
                            value:"1",
                            name:"moyensPreventionVolProtectionElectroniqueLiaison",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel: "La liaison téléphonique est secourue (GSM ?)",
                            },
                            value:"1",
                            name:"moyensPreventionVolProtectionElectroniqueSecourue",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel: "Pas de protection électronique",
                            },
                            value:"1",
                            name:"moyensPreventionVolProtectionElectroniqueAucune",
                            parse: value => Boolean(Number(value)),
                        },
                    ]
                },
            },
        ],
        'garantiesRc' : [
            {
                name:"ganratieRc",
                label:"Responsabilité civile professionnelle",
                component:renderText,
                componentOptions:{text:"Oui"}
            },
        ],
        'garantiesLocaux' : [
            {
                name:"garantieLocaux",
                label:"Les locaux",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"ganratieLocauxIncendiesRisquesAnnexes",
                label:"Incendies et Risques Annexes – Événements naturels",
                component:renderText,
                componentOptions:{text:"Oui"},
                condition:() => {return true === props.garantieLocauxValue},
            },
            {
                name:"garantieLocauxIncendieValeurMarchandises",
                label:"Valeur totale des marchandises (hors véhicules)",
                component:renderInput,
                condition:() => {return true === props.garantieLocauxValue},
            },
            {
                name:"garantieLocauxIncendieValeurMateriel",
                label:"Valeur du matériel/mobilier",
                component:renderInput,
                condition:() => {return true === props.garantieLocauxValue},
            },
            {
                name:"garantieLocauxDegatsEaux",
                label:"Dégâts des eaux",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
                condition:() => {return true === props.garantieLocauxValue},
            },
            {
                name:"garantieLocauxDegatsEauxSouhaitee",
                label:"Montant de garantie souhaité",
                component:renderInput,
                condition:() => {return true === props.garantieLocauxValue && true === props.garantieLocauxDegatsEauxValue},
            },
            {
                name:"garantieLocauxVol",
                label:"Vol",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
                condition:() => {return true === props.garantieLocauxValue},
            },
            {
                name:"garantieLocauxVolPremierRisque",
                label:"Valeur premier risque",
                component:renderInput,
                condition:() => {return true === props.garantieLocauxValue && true === props.garantieLocauxVolValue},
            },
            {
                name:"garantieLocauxVolCoffreFort",
                label:"Valeur en coffre-fort",
                component:renderInput,
                condition:() => {return true === props.garantieLocauxValue && true === props.garantieLocauxVolValue},
            },
            {
                name:"moyensPreventionVolEnvironnement",
                label:"Environnement géographique",
                component:renderMultiCheckbox,
                componentOptions:{
                    options:[
                        {
                            componentOptions: {
                                checkboxLabel:"Inoccupation  > 2 jours"
                            },
                            value:"1",
                            name:"garantieLocauxVolInnocupationSup2",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel: "Inoccupation > 45 jours",
                            },
                            value:"1",
                            name:"garantieLocauxVolInnocupationSup45",
                            parse: value => Boolean(Number(value)),
                        },
                    ]
                },
                condition:() => {return true === props.garantieLocauxValue && true === props.garantieLocauxVolValue},
            },
            {
                name:"garantieBrisGlaces",
                label:"Bris de glaces",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
                condition:() => {return true === props.garantieLocauxValue},
            },
            {
                name:"garantieBrisGlacesValeurAssuree",
                label:"Valeur assurée ",
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"6 000 €",value:"6000"},
                        {label:"12 000 €",value:"12000"},
                        {label:"Autres",value:"0"}
                    ]
                },
                condition:() => {return true === props.garantieLocauxValue && true === props.garantieBrisGlacesValue},
            },
            {
                name:"garantieBrisGlacesValeurAssureeDetail",
                label:"Si autres, précisez",
                component:renderInput,
                condition:() => {return true === props.garantieLocauxValue && true === props.garantieBrisGlacesValue && 0 === props.garantieBrisGlacesValeurAssureeValue},
            },
            {
                name:"garantieBrisGlacesEnseigne",
                label:"Bris des enseignes",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
                condition:() => {return true === props.garantieLocauxValue && true === props.garantieBrisGlacesValue},
            },
            {
                name:"garantieBrisGlacesAutres",
                label:"Bris des produits autres que verriers",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
                condition:() => {return true === props.garantieLocauxValue && true === props.garantieBrisGlacesValue},
            },
            {
                name:"garantieDommagesElectriques",
                label:"Dommages électriques",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
                condition:() => {return true === props.garantieLocauxValue},
            },
            {
                name:"garantieDommagesElectriquesCapitalAssure",
                label:"Capital assuré",
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"15 000 €",value:"15000"},
                        {label:"Autres",value:"0"}
                    ]
                },
                condition:() => {return true === props.garantieLocauxValue && true === props.garantieDommagesElectriquesValue},
            },
            {
                name:"garantieDommagesElectriquesCapitalAssureDetail",
                label:"Si autres, précisez",
                component:renderInput,
                condition:() => {return true === props.garantieLocauxValue && true === props.garantieDommagesElectriquesValue && 0 === props.garantieDommagesElectriquesCapitalAssureValue},
            },
            {
                name:"garantieBrisMachines",
                label:"Bris de machines et matériel informatique",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
                condition:() => {return true === props.garantieLocauxValue},
            },
            {
                name:"garantieBrisMachinesCapitalAssure",
                label:"Capital assuré",
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"6 000 €",value:"6000"},
                        {label:"12 000 €",value:"12000"},
                        {label:"Autres",value:"0"}
                    ]
                },
                condition:() => {return true === props.garantieLocauxValue && true === props.garantieBrisMachinesValue},
            },
            {
                name:"garantieBrisMachinesCapitalAssureDetail",
                label:"Si autres, précisez",
                component:renderInput,
                condition:() => {return true === props.garantieLocauxValue && true === props.garantieBrisMachinesValue && 0 === props.garantieBrisMachinesCapitalAssureValue},
            },
            {
                name:"garantiePerteExploitation",
                label:"Perte d’exploitation",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
                condition:() => {return true === props.garantieLocauxValue},
            },
            {
                name:"garantiePerteExploitationMargeBrute",
                label:"Marge brute",
                component:renderInput,
                condition:() => {return true === props.garantieLocauxValue && true === props.garantiePerteExploitationValue},
            },
            {
                name:"garantiePerteExploitationDetail",
                label:"",
                component:renderMultiCheckbox,
                componentOptions:{
                    options:[
                        {
                            name:"garantiePerteExploitationApresIncendie",
                            componentOptions: {
                                checkboxLabel:"Après incendie"
                            },
                            value:"1",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            name:"garantiePerteExploitationDegatsEaux",
                            componentOptions: {
                                checkboxLabel: "Dégâts des eaux",
                            },
                            value:"1",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            name:"garantiePerteExploitationBrisMachine",
                            componentOptions: {
                                checkboxLabel: "Bris de machines",
                            },
                            value:"1",
                            parse: value => Boolean(Number(value)),
                        },
                    ]
                },
                condition:() => {return true === props.garantieLocauxValue && true === props.garantiePerteExploitationValue},

            },
            {
                name:"garantiePerteExploitationPeriodeIndemnisation",
                label:"Période d'indemnisation en mois",
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"8",value:"8"},
                        {label:"12",value:"12"},
                        {label:"24",value:"24"}
                    ]
                },
                condition:() => {return true === props.garantieLocauxValue && true === props.garantiePerteExploitationValue},
            },
            {
                name:"garantiePerteValeurVenale",
                label:"Perte de la valeur vénale du fonds de commerce",
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"100 % du Chiffre d’affaires ",value:"100"},
                        {label:"150 % du Chiffre d’affaires",value:"150"},
                        {label:"Autre montant",value:"0"}
                    ]
                },
                condition:() => {return true === props.garantieLocauxValue},
            },
            {
                name:"garantiePerteValeurVenaleDetail",
                label:"Si autres, précisez",
                component:renderInput,
                condition:() => {return true === props.garantieLocauxValue && 0 === props.garantiePerteValeurVenaleValue},
            },
        ],
        'garantiesVehiculesConfies' : [
            {
                name:"garantiesVehiculesConfies",
                label:"Véhicules confiés",
                component:renderText,
                componentOptions:{text:"Oui"},
            },
            {
                name:"garantieVehiculeConfiesSup76000",
                label:"Présence de véhicule d’une valeur supérieure à 76.000 €",
                required:true,
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"garantieVehiculeConfiesSup76000Detail",
                label:"Si oui, quelle valeur maximum ",
                component:renderInput,
                condition:() => {return true === props.garantieVehiculeConfiesSup76000Value},
            },
        ],
        'garantiesVehiculesPropres' : [
            {
                name:"garantieVehiculePropres",
                label:"Véhicules propres (au nom de la société ou du gérant)",
                required:true,
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"garantieVehiculePropresDetail",
                component:renderHidden,
            },
            {
                name:"garantieVehiculePropresGarageVehicules",
                label:"Si oui, merci de nous indiquer les véhicules à assurer (marque, modèle, année, usage, préciser si valeur supérieure à 76.000 €)",
                component:renderGarageVehicule,
                componentOptions:{
                    list:props.garantieVehiculePropresDetailValue,
                    change: (items) => {props.changeGarantieVehiculePropresDetail(props.obj, items)},
                },
                condition:() => {return true === props.garantieVehiculePropresValue},
            },
        ],
        'assurance' : [
            {
                name:"antecedentAssuranceDejaAssuree",
                label:"Déjà assuré ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"antecedentAssuranceDejaAssureeDetail",
                label:"Si oui, auprès de quel assureur ?",
                component:renderInput,
                condition:() => {return true === props.antecedentAssuranceDejaAssureeValue},
            },
            {
                name:"antecedentAssuranceDateEcheance",
                label:"Date d'échéance principale",
                component:renderInput,

            },
            {
                name:"antecedentAssuranceResilie",
                label:"Avez-vous déjà été résilié par un assureur ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"antecedentAssuranceResilieDetail",
                label:"Si oui pour quel motif ?",
                component:renderTextarea,
                condition:() => {return true === props.antecedentAssuranceResilieValue},

            },
            {
                name:"antecedentAssurancePrimeAssuranceActuelle",
                label:"Prime d’assurance actuelle",
                component:renderInput,

            },
            {
                name:"antecedentAssuranceSinistres3Ans",
                label:"Sinistres sur les 36 derniers mois",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"antecedentAssuranceSinistres3AnsDetail",
                label:"Si oui, merci de préciser et de joindre la statistique sinistre via la partie document",
                component:renderTextarea,
                condition:() => {return true === props.antecedentAssuranceSinistres3AnsValue},
            },
        ],
        'state' : [
            {
                name:"config",
                required:true,
                component:renderHidden,
                componentOptions:{value:props.config}
            },
            {
                name:"statutPriseGarantie",
                required:true,
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Prise de garantie refusée",value:"0"},
                        {label:"Prise de garantie validée",value:"1"},
                        {label:"Prise de garantie en cours",value:"2"},
                        {label:"Prise de garantie en attente",value:"3"},
                    ]
                }

            },
            {
                name:"signatureContrat",
                required:true,
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Contrat signé",value:"1"},
                        {label:"Contrat non signé",value:"0"},
                    ]
                }

            },
            {
                name:"statutQuittance",
                required:true,
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Quittance à jour",value:"1"},
                        {label:"Quittance à régulariser",value:"0"},
                    ]
                }

            },
            {
                name:"statutContrat",
                required:true,
                component:renderRadio,
                parse: function (value) {
                    // Workaround pour les calendriers
                    if (value === "0" || value === "1" || value === "2" || value === "3" || value === "4") {
                        return Number(value);
                    }
                    return props.statutContratValue;
                },
                componentOptions:{
                    options:[
                        {label:"Contrat à échéance",value:"2"},
                        {
                            label:"Contrat résilié",
                            value:"1",
                            child: [
                                {
                                    name:"dateContratResilie",
                                    component: renderHidden,
                                    reference: 'contratResilieDatePicker'
                                },
                                {
                                    name:"contratResilieDatePicker",
                                    component:renderDate,
                                    fieldProps: {
                                        selectedValue: props.dateContratResilieValue,
                                        placeholder:'Date résiliation',
                                        dateTimeRef:props.obj ? props.obj.dateTimeResilieRef : null
                                    },
                                    componentOptions: {
                                        change: props.changeDateResilie ? props.changeDateResilie.bind(props.obj) : null,

                                    },
                                    condition:() => {return 1 === props.statutContratValue},

                                },
                            ]

                        },
                        {
                            label:"Contrat non résilié",
                            value:"0"
                        },
                        {
                            label:"Contrat sans effet",
                            value:"4",
                            child: [
                                {
                                    name:"dateContratSanseffet",
                                    component: renderHidden,
                                    reference: 'contratSanseffetDatePicker'
                                },
                                {
                                    name:"contratSanseffetDatePicker",
                                    component:renderDate,
                                    placeholder:'test',
                                    fieldProps: {
                                        selectedValue: props.dateContratSanseffetValue,
                                        placeholder:'Date sans effet',
                                        dateTimeRef:props.obj ? props.obj.dateTimeSansEffetRef : null
                                    },
                                    componentOptions: {
                                        change: props.changeDateSanseffet ? props.changeDateSanseffet.bind(props.obj) : null,

                                    },
                                    condition:() => {return 4 === props.statutContratValue},

                                },
                            ]
                        },
                    ]
                }

            },
            {
                name:"statutDevis",
                required:true,
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Devis en cours de cotation",value:"3"},
                        {label:"Devis valide",value:"1"},
                        {label:"Devis non valide",value:"0"},
                    ]
                }
            },
        ],
        'protectionDonneesPublic' : [
            {
                name:"rgpd",
                required:true,
                component:renderCheckbox,
                componentOptions: {
                    checkboxLabel:"En cochant cette case, vous acceptez que vos données soient utilisées dans le cadre d’une demande d’assurance"
                }
            },
            {name:"captcharesponse", required:true, component:renderCaptcha}

        ]
    }
}
export default columns;


