// React
import React, { Component } from 'react'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";

// @material-ui/icons
import Settings from "@material-ui/icons/Settings";

// Redux
import {reduxForm,Field} from 'redux-form'
import connect from "react-redux/es/connect/connect";

// Creative Tim
import GridContainer from "creativetim/material-dashboard-pro/components/Grid/GridContainer.js";
import GridItem from "creativetim/material-dashboard-pro/components/Grid/GridItem.js";
import Button from "creativetim/material-dashboard-pro/components/CustomButtons/Button.js";
import Card from "creativetim/material-dashboard-pro/components/Card/Card.js";
import CardBody from "creativetim/material-dashboard-pro/components/Card/CardBody.js";
import CardHeader from "creativetim/material-dashboard-pro/components/Card/CardHeader.js";
import CardIcon from "creativetim/material-dashboard-pro/components/Card/CardIcon.js";
import CardFooter from "creativetim/material-dashboard-pro/components/Card/CardFooter.js";
import CustomLinearProgress from "creativetim/material-dashboard-pro/components/CustomLinearProgress/CustomLinearProgress.js";

//App
import {getLastConfigImmeuble, configImmeubleSave, getCourtiers,requestFetching, setMessage} from "../actions";
import {renderInput, renderHidden, renderAutocomplete, RenderSwitch} from 'formUtils'
import styles from "assets/jss/styles.js"

class ConfigurationImmeuble extends Component {

    constructor(props) {
        super(props);
        this.abortController = new AbortController();
    }

    componentWillUnmount()
    {
        this.abortController.abort();
    }

    componentDidMount() {
        this.props.getLastConfigImmeuble(this.abortController)
        this.props.getCourtiers(this.abortController)
    }
    submit(post) {
        return this.props.configImmeubleSave(post)
    }
    handleChange(uri) {
        this.props.change("courtierDefaut", uri)
    }

    getSelectedValue()
    {
        if (this.props.courtiers == null || this.props.initialValues.courtierDefaut == null) {
            return null;
        }

        let c =  this.props.courtiers.find(
            (courtier) =>
                courtier.uri === this.props.initialValues.courtierDefaut
        )

        return this.props.courtiers[this.props.courtiers.indexOf(c)]
    }

    render() {
        const {courtiers, handleSubmit, classes, fetching, pristine, submitting} = this.props;
        if (fetching || this.props.courtiers == null || this.props.initialValues.courtierDefaut == null) {
            return (
                <div className={classes.linearProgressWrapper}>
                    <CustomLinearProgress color="primary"/ >
                </div>
            )
        }

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <form onSubmit={handleSubmit(this.submit.bind(this))} >
                            <Card>
                                <CardHeader color="rose" icon>
                                    <CardIcon color="rose">
                                        <Settings />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>
                                        Configuration - <small>Immeuble</small>
                                    </h4>
                                </CardHeader>
                                <CardBody>

                                    <GridContainer>
                                        <GridItem xs={12} sm={4} md={4} xl={2}>
                                            <FormLabel className={classes.labelHorizontal + " " + classes.labelSwitch}>
                                                Activer le produit
                                            </FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={4} md={4} lg={3} xl={2}>
                                            <Field name="activePublic" component={RenderSwitch} label="sur le site public"  classes={classes} fetching={fetching} />
                                        </GridItem>
                                        <GridItem xs={12} sm={4} md={4} lg={3} xl={2}>
                                            <Field name="activePro" component={RenderSwitch} label="sur le site pro"  classes={classes} fetching={fetching} />
                                        </GridItem>
                                    </GridContainer>

                                    <GridContainer>
                                        <GridItem xs={12} sm={4} xl={2}>
                                            <FormLabel className={classes.labelHorizontal}>
                                                Nom
                                            </FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={8} xl={10}>
                                            <Field
                                                name="nom"
                                                id="nom"
                                                maxLength="128"
                                                component={renderInput}
                                                classes={classes}
                                                fetching={fetching}
                                            />
                                        </GridItem>
                                    </GridContainer>

                                    <GridContainer>
                                        <GridItem xs={12} sm={4} xl={2}>
                                            <FormLabel className={classes.labelHorizontal}>
                                                Frais d'intermédiation admin
                                            </FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={4} xl={5}>
                                            <Field
                                                type="number"
                                                parse={value => Number(value)}
                                                name="fraisIntermediationAdminMin"
                                                id="fraisIntermediationAdminMin"
                                                component={renderInput}
                                                placeholder="minimum"
                                                classes={classes}
                                                fetching={fetching}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={4} xl={5}>
                                            <Field
                                                type="number"
                                                parse={value => Number(value)}
                                                name="fraisIntermediationAdminMax"
                                                id="fraisIntermediationAdminMax"
                                                component={renderInput}
                                                placeholder="maximum"
                                                classes={classes}
                                                fetching={fetching}
                                            />
                                        </GridItem>
                                    </GridContainer>

                                    <GridContainer>
                                        <GridItem xs={12} sm={4} xl={2}>
                                            <FormLabel className={classes.labelHorizontal}>
                                                Frais d'intermédiation courtier
                                            </FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={4} xl={5}>
                                            <Field
                                                type="number"
                                                parse={value => Number(value)}
                                                name="fraisIntermediationCourtierMin"
                                                id="fraisIntermediationCourtierMin"
                                                component={renderInput}
                                                placeholder="minimum"
                                                classes={classes}
                                                fetching={fetching}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={4} xl={5}>
                                            <Field
                                                type="number"
                                                parse={value => Number(value)}
                                                name="fraisIntermediationCourtierMax"
                                                id="fraisIntermediationCourtierMax"
                                                component={renderInput}
                                                placeholder="maximum"
                                                classes={classes}
                                                fetching={fetching}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={4} xl={2}>
                                            <FormLabel className={classes.labelHorizontal}>
                                                Frais d'intermédiation par défaut
                                            </FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={4} xl={5}>
                                            <Field
                                                type="number"
                                                parse={value => Number(value)}
                                                name="fraisIntermediationDefaut"
                                                id="fraisIntermediationDefaut"
                                                component={renderInput}
                                                classes={classes}
                                                fetching={fetching}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={4} xl={2}>
                                            <FormLabel className={classes.labelHorizontal}>
                                                Courtier par défaut
                                            </FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={8} xl={5}>
                                            <Field
                                                type="hidden"
                                                name="courtierDefaut"
                                                id="courtierDefaut"
                                                component={renderHidden}
                                                classes={classes}
                                                fetching={fetching}
                                            />
                                            <Field
                                                name="courtierDefautList"
                                                id="courtierDefautList"
                                                selectedValue={courtiers != null ? this.getSelectedValue() : null}
                                                disableClearable={true}
                                                component={renderAutocomplete}
                                                change={this.handleChange.bind(this)}
                                                list={courtiers}
                                                classes={classes}
                                                fetching={fetching}
                                                placeholder="Sélectionner un courtier ..."
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                                <CardFooter className={classes.justifyContentCenter}>
                                    <Button type="submit" disabled={pristine || submitting} color="rose" >
                                        Sauvegarder
                                    </Button>
                                </CardFooter>
                            </Card>
                        </form>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    ...store.authReducer,
    ...store.requestReducer,
    ...store.configurationImmeubleReducer,
    ...store.courtiersReducer,
    message: store.messageReducer.message,
    initialValues:{...store.configurationImmeubleReducer},
});

const mapDispatchToProps = {
    getLastConfigImmeuble,
    configImmeubleSave,
    getCourtiers,
    requestFetching,
    setMessage
};

function handleChangeForm(values, dispatch, props)
{
    if (props.dirty && props.message !== '') {
        props.setMessage('');
    }
}
function validate (values) {
    const errors = {}
    if(!values['nom'] ){
        errors['nom']= "Le nom est requis"
    }
    if(values['fraisIntermediationAdminMin']  === ''){
        errors['fraisIntermediationAdminMin']= "Les frais d'intermédiation admin sont requis"
    } else  if(values['fraisIntermediationAdminMin']  < 0){
        errors['fraisIntermediationAdminMin']= "Cette valeur doit être supérieure ou égale à 0"
    } else  if(values['fraisIntermediationAdminMin']  > 100){
        errors['fraisIntermediationAdminMin']= "Cette valeur doit être inférieure ou égale à 100"
    }

    if(values['fraisIntermediationAdminMax'] === ''){
        errors['fraisIntermediationAdminMax']= "Les frais d'intermédiation admin sont requis"
    } else  if(values['fraisIntermediationAdminMax']  < 0){
        errors['fraisIntermediationAdminMax']= "Cette valeur doit être supérieure ou égale à 0"
    } else  if(values['fraisIntermediationAdminMax']  > 100){
        errors['fraisIntermediationAdminMax']= "Cette valeur doit être inférieure ou égale à 100"
    } else if (values['fraisIntermediationAdminMax']  < values['fraisIntermediationAdminMin']){
        errors['fraisIntermediationAdminMax']= "Cette valeur doit être supérieure ou égale à la valeur minimum"
    }

    if(values['fraisIntermediationCourtierMin']  === ''){
        errors['fraisIntermediationCourtierMin']= "Les frais d'intermédiation courtier sont requis"
    } else  if(values['fraisIntermediationCourtierMin']  < 0){
        errors['fraisIntermediationCourtierMin']= "Cette valeur doit être supérieure ou égale à 0"
    } else  if(values['fraisIntermediationCourtierMin']  > 100){
        errors['fraisIntermediationCourtierMin']= "Cette valeur doit être inférieure ou égale à 100"
    }

    if(values['fraisIntermediationCourtierMax']  === ''){
        errors['fraisIntermediationCourtierMax']= "Les frais d'intermédiation courtier sont requis"
    } else  if(values['fraisIntermediationCourtierMax']  < 0){
        errors['fraisIntermediationCourtierMax']= "Cette valeur doit être supérieure ou égale à 0"
    } else  if(values['fraisIntermediationCourtierMax']  > 100){
        errors['fraisIntermediationCourtierMax']= "Cette valeur doit être inférieure ou égale à 100"
    } else if (values['fraisIntermediationCourtierMax']  < values['fraisIntermediationCourtierMin']){
        errors['fraisIntermediationCourtierMax']= "Cette valeur doit être supérieure ou égale à la valeur minimum"
    }

    if(values['fraisIntermediationCourtierMax']  === ''){
        errors['fraisIntermediationDefaut']= "Les frais d'intermédiation par défaut sont requis"
    } else  if(values['fraisIntermediationDefaut']  < 0){
        errors['fraisIntermediationDefaut']= "Cette valeur doit être supérieure ou égale à 0"
    } else if(values['fraisIntermediationDefaut']  > 100){
        errors['fraisIntermediationDefaut']= "Cette valeur doit être inférieure ou égale à 100"
    } else if(values['fraisIntermediationDefaut']  > values['fraisIntermediationCourtierMax'] || values['fraisIntermediationDefaut']  > values['fraisIntermediationAdminMax'] || values['fraisIntermediationDefaut']  < values['fraisIntermediationCourtierMin'] || values['fraisIntermediationDefaut']  < values['fraisIntermediationAdminMin']){
        errors['fraisIntermediationDefaut']= "Cette valeur doit être comprise dans les frais d'intermédiation courtier et admin"
    }

    if(!values['courtierDefaut'] ){
        errors['courtierDefaut']= "Lecourtier par défaut est requis"
    }
    return errors;
}

const formConfig ={
    form: 'ConfigurationImmeubleForm',
    fields : ['activePro', 'activePublic', 'nom', 'fraisIntermediationAdminMin', 'fraisIntermediationAdminMax', 'fraisIntermediationCourtierMin', 'fraisIntermediationCourtierMax', 'fraisIntermediationDefaut', 'courtierDefaut', 'courtierDefautList'],
    validate,
    enableReinitialize : true ,
    onChange:handleChangeForm
}

export default
connect(mapStateToProps, mapDispatchToProps) (
    reduxForm(formConfig) (
        withStyles(styles)
        (
            ConfigurationImmeuble
        )
    )
);



