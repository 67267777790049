import {CONFIGURATION_BIJOUTERIE_RECEIVED, CONFIGURATION_BIJOUTERIE_SAVE} from "../actions";

export default (state = {
    uri:null,
    activePro: false,
    activePublic: false,
    nom: '',
    fraisIntermediationAdminMin: 0,
    fraisIntermediationAdminMax: 100,
    fraisIntermediationCourtierMin: 0,
    fraisIntermediationCourtierMax: 20,
    fraisIntermediationDefaut: 20,
    courtierDefaut: null,
    courtierDefautList:null,

}, action) => {
  switch (action.type) {
      case CONFIGURATION_BIJOUTERIE_SAVE:
          return {
              ...state,
              ...action.data
          };
      case CONFIGURATION_BIJOUTERIE_RECEIVED:
          return {
              ...state,
              uri:action.data['@id'],
              activePro: action.data.activePro,
              activePublic: action.data.activePublic,
              nom: action.data.nom,
              fraisIntermediationAdminMin: action.data.fraisIntermediationAdminMin,
              fraisIntermediationAdminMax: action.data.fraisIntermediationAdminMax,
              fraisIntermediationCourtierMin: action.data.fraisIntermediationCourtierMin,
              fraisIntermediationCourtierMax: action.data.fraisIntermediationCourtierMax,
              fraisIntermediationDefaut: action.data.fraisIntermediationDefaut,
              courtierDefaut: action.data.courtierDefaut,
          };
    default:
      return state;
  }
}
