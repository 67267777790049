import {USER_LOGIN_SUCCESS, USER_LOGOUT} from "./../actions";
import {requests} from "../requests";

export const tokenMiddleware = store => next => action => {
  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      window.localStorage.setItem('lastLogin', new Date());
      window.localStorage.setItem('jwtToken', action.data.token);
      window.localStorage.setItem('userId', action.data.utilisateur_id);
      window.localStorage.setItem('userName', action.data.utilisateur_nom);
      window.localStorage.setItem('userRoles', action.data.role);
      requests.setToken(action.data.token);
      break;
    case USER_LOGOUT:
      window.localStorage.removeItem('jwtToken');
      window.localStorage.removeItem('userId');
      window.localStorage.removeItem('userName');
      window.localStorage.removeItem('userRoles');
      requests.setToken(null);
      break;
    default:
  }
  next(action);
};
