import {CLIENT_RECEIVED, CLIENT_SAVE, CLIENT_RESET} from "../actions";

const defaultState = {
    id:null,
    nom: null,
    representant: null,
    adresse: null,
    codepostal: null,
    ville: null,
    telephone: null,
    email: null,
    siteInternet:null,
    utilisateur:null,

}

export default (state = defaultState, action) => {
  switch (action.type) {
      case CLIENT_RESET:
          return defaultState;

      case CLIENT_SAVE:
          return {
              ...state,
              ...action.data
          };
      case CLIENT_RECEIVED:
          return {
              ...state,
              id: action.data.id,
              nom: action.data.nom,
              representant: action.data.representant,
              adresse: action.data.adresse,
              codepostal: action.data.codepostal,
              ville: action.data.ville,
              telephone: action.data.telephone,
              email: action.data.email,
              siteInternet:action.data.siteInternet,
              utilisateur:action.data.utilisateur
          };
    default:
      return state;
  }
}
