// React
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link, AppBar, Toolbar, Hidden, Drawer, List, ListItem, ListItemText } from '@material-ui/core';

// @material-ui/icons
import { Mail as MailIcon, Phone as PhoneIcon, Menu as MenuIcon } from "@material-ui/icons";

// Vendor
import cx from "classnames";
import PropTypes from "prop-types";

// Creativ Tim
import Button from "creativetim/material-dashboard-pro/components/CustomButtons/Button";
import styles from "creativetim/material-dashboard-pro/assets/jss/material-dashboard-pro-react/components/authNavbarStyle.js";

const useStyles = makeStyles(styles);

export default function AuthNavbar(props) {
  const [open, setOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  // verifies if routeName is the one active (in browser input)
  const classes = useStyles();
  const { color } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });
  var list = (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Link href={"mailto:contact@eba-solutions.com"} className={classes.navLink}>
          <MailIcon className={classes.listItemIcon} />
          <ListItemText
            primary={"contact@eba-solutions.com"}
            disableTypography={true}
            className={classes.listItemText}
          />
        </Link>
      </ListItem>

      <ListItem className={classes.listItem}>
        <Link href={"tel:0148838331"} className={classes.navLink}>
          <PhoneIcon className={classes.listItemIcon} />
          <ListItemText
            primary={"01 48 83 83 31"}
            disableTypography={true}
            className={classes.listItemText}
          />
        </Link>
      </ListItem>
    </List>
  );
  return (
    <AppBar position="static" className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown>
          <div className={classes.flex}>
            <Button href="#" className={classes.title} color="transparent">
              <img src={require('assets/img/logo.png')} alt="BA Assurances"/>
            </Button>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.flex}>
            <Button href="#" className={classes.title} color="transparent">
                <img src={require('assets/img/logo.png')} alt="BA Assurances"/>
            </Button>
          </div>
        </Hidden>
        <Hidden smDown>{list}</Hidden>
        <Hidden mdUp>
          <Button
            className={classes.sidebarButton}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </Button>
        </Hidden>
        <Hidden mdUp>
          <Hidden mdUp>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={open}
              classes={{
                paper: classes.drawerPaper
              }}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              {list}
            </Drawer>
          </Hidden>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

AuthNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string
};
