import {SET_MESSAGE} from "../actions";

export default (state = {
    message: null,
    error: false,
}, action) => {
  switch (action.type) {

      case SET_MESSAGE:
          return {
              ...state,
              message: action.message,
              error: action.error,
          };
    default:
      return state;
  }
}
