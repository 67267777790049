// React
import React from "react";

// App
import {renderMultiCheckbox, renderCaptcha, renderDate, renderText, renderTextarea, renderInput, renderSelect, renderHidden, renderAutocomplete, renderCheckbox, renderRadio} from 'formUtils'

const columns = function (props) {
    return {
        'informationsPublic': [
            {name:"proposantNom", required:true, label:"Nom et prénom du proposant ou Raison Sociale", component:renderInput},
            {name:"proposantAdresse", required:true, label:"Adresse", component:renderInput},
            {name:"proposantVille", required:true, label:"Ville", component:renderInput},
            {name:"proposantCodepostal", required:true, label:"Code postal", component:renderInput},
            {name:"proposantTelephone", required:true, label:"Téléphone", component:renderInput},
            {name:"proposantEmail", required:true, label:"Email", component:renderInput},
            {
                name: "ispublic",
                component: renderHidden,
            },
        ],
        'informations': [
            {
                name: "utilisateur",
                component: renderHidden,
                required: true,
                reference: 'utilisateurList'
            },
            {
                name: "utilisateurList",
                label: "Utilisateur",
                component: renderAutocomplete,
                componentOptions: {
                    list: props.courtiers,
                    change: props.changeCourtier ? props.changeCourtier.bind(props.obj) : null,

                },
                fieldProps: {
                    selectedValue: props.courtiers != null ? props.getSelectedValueCourtier(props.obj) : null
                }
            },
            {
                name:"client",
                component:renderHidden,
                required:true,
                reference:'clientList'

            },
            {
                name:"clientList",
                label:"Client",
                component:renderAutocomplete,
                componentOptions:{
                    list:props.clients,
                    change:props.changeClient ? props.changeClient.bind(props.obj) : null
                },
                fieldProps: {
                    selectedValue:props.clients != null ? props.getSelectedValueClient(props.obj) : null
                }
            },
            {
                name: "utilisateur",
                component: renderHidden,
                componentOptions: {value:'/utilisateurs/0'}
            },
            {
                name:"client",
                component:renderHidden,
                componentOptions: {value:'/clients/0'}
            },
            {
                name:"config",
                component:renderHidden,
                componentOptions:{value:'/produit_cyber_configs/0'}
            },
            {
                name:"fraisIntermediation",
                label:"Frais d'intermédiation",
                component:renderSelect,
                required:true,
                componentOptions:{
                    options:props.getFraisIntermediation ? props.getFraisIntermediation(props.obj) : null
                }
            }
        ],
        'entreprise' : [
            {
                name:"entrepriseSiret",
                label:"SIRET",
                component:renderInput,
                required:true,

            },
            {
                name:"entrepriseNombreSalarie",
                label:"Nombre de salarié(s)",
                component:renderInput,
                required:true,

            },
            {
                name:"entrepriseCaAnnuel",
                label:"Chiffre d'affaire annuel HT (€)",
                component:renderInput,
                required:true,

            },
            {
                name:"entreprisePourcentageTransaction",
                label:"Part de Chiffre d’affaires réalisé via des transactions en ligne (%)",
                component:renderInput,
                required:true,

            },
            {
                name:"entreprisePersonnesDonneesSensibles",
                label:"Nombre de personnes à propos desquelles vous êtes susceptibles de collecter et/ou détenir des données sensibles",
                tooltip:"Données sensibles  : 1. Numéro de sécurité sociale, permis de conduire ou passeport. 2. Données bancaire (carte de crédit, etc.) 3. Données relatives à la race, l’ethnie, l’orientation sexuelle, la santé, les convictions religieuses ou philosophiques, les opinions politiques, les engagements syndicaux.)",
                component:renderRadio,
                required:true,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"< 20 000",value:"19999"},
                        {label:"20 000 - 100 000",value:"100000"},
                        {label:"100 001 - 250 000",value:"250000"},
                        {label:"Autres",value:"0"}
                    ]
                },
            },
            {
                name:"entreprisePersonnesDonneesSensiblesDetail",
                label:"Précisez",
                component:renderInput,
                required:0 === props.entreprisePersonnesDonneesSensiblesValue,
                condition:() => {return 0 === props.entreprisePersonnesDonneesSensiblesValue},


            },
            {
                name:"entrepriseFilialeHorsUe",
                label:"Disposez-vous d'une filiale hors de l'UE, en Polynésie Française ou en Nouvelle Calédonie ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entrepriseFilialeHorsUeDetail",
                label:"Si oui, merci de préciser les adresses des filiales",
                component:renderTextarea,
                required: true === props.entrepriseFilialeHorsUeValue,
                condition:() => {return true === props.entrepriseFilialeHorsUeValue}
            },
        ],
        'securite' : [
            {
                name:"securiteFirewall",
                label:"Utilisez-vous un antivirus et un firewall mis à jour au moins tous les 30 jours ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"securiteMaj",
                label:"Mettez-vous à jour les logiciels et les systèmes que vous utilisez au moins tous les 30 jours ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"securiteTestVulnerabilite",
                label:"Conduisez-vous des tests d'analyse de vulnérabilité régulièrement ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"securiteNonRecoursCompteAdmin",
                label:"Vous n'avez pas recours à des comptes administrateur pour des usages basiques (navigation sur internet, utilisation d'une boîte mail) , pour lesquels il existe des comptes sans privilèges administrateurs.",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"securiteRestrictionAccess",
                label:"Restreignez-vous les accès de vos employés à votre système d'information et aux informations sur la base de ce dont ils ont besoin pour travailler ? De plus, leurs accès sont-ils systématiquement coupés lorsque vos employés quittent votre entreprise ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"securiteVerificationDeuxDetapes",
                label:"Si pertinent, avez-vous recours à la vérification en deux étapes pour gérer les accès à distance (par exemple, Gsuite ou Office 365) ?",
                component:renderRadio,
                required:true,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"},
                        {label:"Non pertinent",value:"2"}
                    ]
                },
            },
            {
                name:"securiteCharteBonMdp",
                label:"Avez-vous une charte qui explique les caractéristiques d'un bon mot de passe (comme ne pas utiliser les mots de passe similaires ou évidents) pour tous vos systèmes ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"securiteDonnnesCryptees",
                label:"Les données que vous stockez sont-elles cryptées lorsqu'elles sont",
                component:renderMultiCheckbox,
                required:false,
                componentOptions:{
                    options:[
                        {
                            componentOptions: {
                                checkboxLabel:"Sur votre réseau ?"
                            },
                            value:"1",
                            name:"securiteDonnnesCrypteesReseau",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel: "Sur les périphériques de stockages mobiles ou terminaux mobiles ?",
                            },
                            value:"1",
                            name:"securiteDonnnesCrypteesPeripherique",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel: "Sur les serveurs gérés par d'autres en votre nom ?",
                            },
                            value:"1",
                            name:"securiteDonnnesCrypteesServeur",
                            parse: value => Boolean(Number(value)),
                        },
                    ]
                },
            },
            {
                name:"securitePaimentCb",
                label:"Si vous acceptez les paiements par cartes bancaires, êtes-vous conforme au standard PCI DSS 3.2 ou avez-vous recours à un fournisseur qui y est conforme ?",
                component:renderRadio,
                required:true,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"},
                        {label:"Je ne sais pas",value:"2"}
                    ]
                },
            },
            {
                name:"securitePaimentCbDetail",
                label:"Si vous ne savez pas, merci de donner le nom de votre fournisseur",
                component:renderInput,
                condition:() => {return 2 === props.securitePaimentCbValue}
            },
            {
                name:"securiteVerificationPrestataires",
                label:"Vérifiez-vous les niveaux de cyber-sécurité de vos nouveaux prestataires ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"securiteAuditPrestataire",
                label:"Faites-vous annuellement des audits de cyber-sécurité chez vos prestataires ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"securitePolitiqueDonnees",
                label:"Avez-vous mis en place une politique de gestion des données personnelles et de sécurité informatique applicable à l'ensemble des services et filiales de l'entreprise ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"},
                    ]
                },
            },
            {
                name:"securitePolitiqueDonneesDetail",
                label:"Si vous avez obtenu une certification (par exemple : ISO 27001) laquelle avez-vous ? ",
                component:renderInput,
            },
            {
                name:"securiteCorrespondantInformatique",
                label:"Si vous êtes dans l'obligation de le faire, avez-vous désigné un correspondant informatique et libertés ou un Data protection officer, en interne ou externe ?",
                component:renderRadio,
                required:true,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"},
                        {label:"Pas d’obligation de désigner un DPO",value:"2"}
                    ]
                },
            },
            {
                name:"securiteSauvegardeHebdo",
                label:"Vos données et systèmes font-elles l'objet d'une sauvegarde hebdomadaire testée et débranchée de vos systèmes ou placée dans un site externe ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"securiteSauvegardeHebdoDetail",
                label:"Si oui, à quelle fréquence ?",
                component:renderInput,
                required: true === props.securiteSauvegardeHebdoValue,
                condition:() => {return true === props.securiteSauvegardeHebdoValue},
            },
            {
                name:"securitePlanReprise",
                label:"Avez-vous mis en place un plan régulièrement testé de reprise / de continuation d'activité en cas d'incident sur vos systèmes d'information ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"securiteDelaiReprise12h",
                label:"Le délai nécessaire à la reprise d'activité en cas d'incident sur vos systèmes d'information est-il estimé à plus de 12h ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"securiteDelaiReprise12hDetail",
                label:"Si oui, combien de temps",
                component:renderInput,
                required: true === props.securiteDelaiReprise12hValue,
                condition:() => {return true === props.securiteDelaiReprise12hValue},
            },
            {
                name:"securiteProgrammeSensibilisation",
                label:"Avez-vous mis en place un programme de formation / sensibilisation à la cyber-sécurité sur vos employés ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
        ],
        'assurance' : [
            {
                name:"antecedentAssuranceSinistres3Ans",
                label:"Sinistre(s) dans les 3 dernières années ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"antecedentAssuranceSinistres3AnsDetail",
                label:"Si oui, indiquez la date du ou des sinistre(s), la nature du sinistre et le montant indemnisé",
                component:renderTextarea,
                condition:() => {return true === props.antecedentAssuranceSinistres3AnsValue},
            },
            {
                name:"antecedentAssuranceDejaAssuree",
                label:"Etes-vous assuré en assurance Cyber ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"antecedentAssuranceDejaAssureeDetail",
                label:"Si oui, auprès de quel assureur ?",
                component:renderInput,
                condition:() => {return true === props.antecedentAssuranceDejaAssureeValue},

            },
            {
                name:"antecedentAssuranceResilie",
                label:"Avez-vous déjà été résilié par un assureur ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"antecedentAssuranceResilieDetail",
                label:"Si oui pour quel motif ?",
                component:renderTextarea,
                condition:() => {return true === props.antecedentAssuranceResilieValue},

            },
            {
                name:"garantieSouhaitee",
                label:"Montant de garantie souhaitée",
                component:renderRadio,
                required:true,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"100 000 €",value:"100000"},
                        {label:"200 000 €",value:"200000"},
                        {label:"500 000 €",value:"500000"},
                        {label:"Autres",value:"0"},
                    ]
                },
            },
            {
                name:"garantieSouhaiteeDetail",
                label:"Précisez",
                component:renderInput,
                condition:() => {return 0 === props.garantieSouhaiteeValue},
            },
            {
                name:"garantieText1",
                label:"Atteinte aux informations et reconstitutions des données",
                componentOptions:{text:"Oui"},
                component:renderText,
            },
            {
                name:"garantieText2",
                label:"Vol des données personnelles et notification",
                componentOptions:{text:"Oui"},
                component:renderText,
            },
            {
                name:"garantiePerteExploitation",
                label:"Perte d'exploitation",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"garantieRc",
                label:"Responsabilité civile",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"garantieTentativeCyberRanconnage",
                label:"Tentative de cyber-rançonnage",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"garantieCyberDetournement",
                label:"Cyber-détournement de fonds",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"garantieCyberEspionnage",
                label:"Cyber espionnage économique ou industriel",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"garantiePj",
                label:"Protection juridique ",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },


        ],
        'state' : [
            {
                name:"config",
                required:true,
                component:renderHidden,
                componentOptions:{value:props.config}
            },
            {
                name:"statutPriseGarantie",
                required:true,
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Prise de garantie refusée",value:"0"},
                        {label:"Prise de garantie validée",value:"1"},
                        {label:"Prise de garantie en cours",value:"2"},
                        {label:"Prise de garantie en attente",value:"3"},
                    ]
                }

            },
            {
                name:"signatureContrat",
                required:true,
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Contrat signé",value:"1"},
                        {label:"Contrat non signé",value:"0"},
                    ]
                }

            },
            {
                name:"statutQuittance",
                required:true,
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Quittance à jour",value:"1"},
                        {label:"Quittance à régulariser",value:"0"},
                    ]
                }

            },
            {
                name:"statutContrat",
                required:true,
                component:renderRadio,
                parse: function (value) {
                    // Workaround pour les calendriers
                    if (value === "0" || value === "1" || value === "2" || value === "3" || value === "4") {
                        return Number(value);
                    }
                    return props.statutContratValue;
                },
                componentOptions:{
                    options:[
                        {label:"Contrat à échéance",value:"2"},
                        {
                            label:"Contrat résilié",
                            value:"1",
                            child: [
                                {
                                    name:"dateContratResilie",
                                    component: renderHidden,
                                    reference: 'contratResilieDatePicker'
                                },
                                {
                                    name:"contratResilieDatePicker",
                                    component:renderDate,
                                    fieldProps: {
                                        selectedValue: props.dateContratResilieValue,
                                        placeholder:'Date résiliation',
                                        dateTimeRef:props.obj ? props.obj.dateTimeResilieRef : null
                                    },
                                    componentOptions: {
                                        change: props.changeDateResilie ? props.changeDateResilie.bind(props.obj) : null,

                                    },
                                    condition:() => {return 1 === props.statutContratValue},

                                },
                            ]

                        },
                        {
                            label:"Contrat non résilié",
                            value:"0"
                        },
                        {
                            label:"Contrat sans effet",
                            value:"4",
                            child: [
                                {
                                    name:"dateContratSanseffet",
                                    component: renderHidden,
                                    reference: 'contratSanseffetDatePicker'
                                },
                                {
                                    name:"contratSanseffetDatePicker",
                                    component:renderDate,
                                    placeholder:'test',
                                    fieldProps: {
                                        selectedValue: props.dateContratSanseffetValue,
                                        placeholder:'Date sans effet',
                                        dateTimeRef:props.obj ? props.obj.dateTimeSansEffetRef : null
                                    },
                                    componentOptions: {
                                        change: props.changeDateSanseffet ? props.changeDateSanseffet.bind(props.obj) : null,

                                    },
                                    condition:() => {return 4 === props.statutContratValue},

                                },
                            ]
                        },
                    ]
                }

            },
            {
                name:"statutDevis",
                required:true,
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Devis en cours de cotation",value:"3"},
                        {label:"Devis valide",value:"1"},
                        {label:"Devis non valide",value:"0"},
                    ]
                }
            },
        ],
        'protectionDonneesPublic' : [
            {
                name:"rgpd",
                required:true,
                component:renderCheckbox,
                componentOptions: {
                    checkboxLabel:"En cochant cette case, vous acceptez que vos données soient utilisées dans le cadre d’une demande d’assurance"
                }
            },
            {name:"captcharesponse", required:true, component:renderCaptcha}

        ]
    }
}
export default columns;


