import React from 'react';
import {Route, Switch, Redirect} from "react-router";

import {connect} from "react-redux";

import AuthLayout from "../layouts/Auth";
import AdminLayout from "../layouts/Admin";
import IframeLayout from "../layouts/Iframe";

import {userLogout, userSave, initDone} from "../actions";

function PrivateRoute({ children, isAuthenticated, ...rest }) {
    return (
        <Route
            path="/"
            render={({ location }) =>
                isAuthenticated ? (
                    children
                ) : (
                    <Redirect to={{ pathname: "/auth", state: { from: location } }} />
                )
            }
        />
    );
}

function NotConnectedRoute({ children, isAuthenticated, ...rest }) {

    return (
        <Route
            path="/auth"
            render={({ location }) =>
                !isAuthenticated ? (
                    children
                ) : (
                    <Redirect to={{ pathname: "/", state: { from: location } }} />
                )
            }
        />
    );
}

class App extends React.Component {
  constructor(props) {
    super(props);
      const token = window.localStorage.getItem('jwtToken');
      if (token) {
          props.userSave(
              {
                  token:token,
                  role:window.localStorage.getItem('userRoles'),
                  utilisateur_id:window.localStorage.getItem('userId'),
                  utilisateur_nom:window.localStorage.getItem('userName'),

              }
          );
      } else {
          this.props.initDone()
      }
  }


  render() {
    const {init, isAuthenticated} = this.props;
      if (!init) {
          return(
              <div></div>
          );
      }

      return (
      <div>
        <Switch>
          <NotConnectedRoute path="/auth" isAuthenticated={isAuthenticated} >
            <AuthLayout/>
          </NotConnectedRoute>
          <Route path="/iframe" >
            <IframeLayout/>
          </Route>
          <PrivateRoute path="/" isAuthenticated={isAuthenticated} >
            <AdminLayout/>
          </PrivateRoute>

        </Switch>
      </div>
    )
  }
}

const mapStateToProps = store => ({
    ...store.authReducer,
    ...store.requestReducer
});

const mapDispatchToProps = {
    userLogout, userSave,initDone
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
