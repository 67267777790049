import {DEVIS_INFORMATIQUE_RESET, DEVIS_INFORMATIQUE_RECEIVED, DEVIS_INFORMATIQUE_SAVE} from "../actions";

const defaultState = {
    id: null,
    config: null,

    entrepriseNombreSalarie: null,
    entrepriseCaHtAnnuel: null,
    entrepriseExerciceLogicielsMedicaux: null,
    entrepriseExerciceLogicielsMedicauxDetail: null,
    entrepriseExerciceFabricationHorsUe: null,
    entrepriseExerciceFabricationHorsUeDetail: null,
    entrepriseExerciceLogicielsBancaires: null,
    entrepriseExerciceLogicielsBancairesDetail: null,
    entrepriseExerciceScada: null,
    entrepriseExerciceScadaDetail: null,
    entrepriseExerciceLogicielsBiometrie: null,
    entrepriseExerciceLogicielsBiometrieDetail: null,
    entrepriseExerciceProductionAssistee: null,
    entrepriseExerciceProductionAssisteeDetail: null,
    entrepriseExerciceProcesseurPaiement: null,
    entrepriseExerciceProcesseurPaiementDetail: null,
    entrepriseExerciceAssistanceNavigation: null,
    entrepriseExerciceAssistanceNavigationDetail: null,
    entrepriseExerciceReseauMobileVirtuel: null,
    entrepriseExerciceReseauMobileVirtuelDetail: null,
    entrepriseMoyenneContratsRealisesEuro: null,
    entrepriseMoyenneContratsRealisesDuree: null,
    entrepriseAppelSousTraitant: null,
    entrepriseAppelSousTraitantDetail: null,
    entreprisePrestationsHebergementSousTraites: null,

    activiteInfrastructureReseaux: null,
    activiteArchitecteReseau: null,
    activiteAdministrationSysteme: null,
    activiteAdministrateurArchitecte: null,
    activiteDevOps: null,
    activiteIntegrationSystemes: null,
    activiteVenteLocationMaterielInformatique: null,
    activiteInstallationConfigurationMaterielInformatique: null,
    activiteMaintenanceMaterielInformatique: null,
    activiteHebergementDonnees: null,
    activiteHebergementSite: null,
    activiteInfrastructurePaas: null,
    activiteDatacenter: null,

    activiteFabricationMateriel: null,
    activiteTelecoms: null,
    activiteOperateurTelecoms: null,
    activiteSecurisationSystemeTelephonie: null,
    activiteServiceTelecom: null,
    activiteVenteLocationMaterielTelecom: null,
    activiteInstallationConfigurationMaterielTelecom: null,
    activiteConseilTelecom: null,
    activiteMaintenanceMaterielTelecom: null,
    activiteSystemeRfid: null,

    activiteJeuxVideos: null,
    activiteGameDesign: null,
    activiteAnimation: null,
    activiteRealiteAugmentee: null,
    activiteEditionJeuxVideos: null,
    activiteDeveloppementTestJeuxVideos: null,

    activiteLogicielsProgiciels: null,
    activiteEditionLogiciels: null,
    activiteEditionLogicielsSaas: null,
    activiteIntegrationLogiciels: null,
    activiteDeveloppementLogiciels: null,
    activiteDeveloppementApplicationSpecifique: null,
    activiteTestRecette: null,
    activiteVenteInstallationLogiciels: null,
    activiteConsultantErp: null,
    activiteArchitecteLogiciels: null,

    activiteDigital: null,
    activiteWebDesign: null,
    activiteDeveloppementEcommerce: null,
    activiteIntegrationCms: null,
    activiteDeveloppementWebMobile: null,
    activiteReferencement: null,
    activiteCreationNdd: null,
    activiteHebergementWeb: null,

    activiteServiceConseil: null,
    activiteConseilEtude: null,
    activiteProductOwner: null,
    activiteFormation: null,
    activiteAssistance: null,
    activiteInfogerance: null,
    activiteSecurite: null,
    activiteMoe: null,
    activiteBigData: null,
    activiteGestionElectronique: null,

    assuranceResponsabiliteContrat: null,
    assuranceResponsabiliteDommages: null,
    assuranceContenuDiffusion: null,
    assuranceProprieteIntellectuelle: null,
    assuranceSinistre3Ans: null,
    assuranceSinistre3AnsDetail: null,
    assuranceRcPro: null,
    assuranceRcProDetail: null,
    assuranceMontantGarantie: null,
    assuranceMontantGarantieDetail: null,
    assuranceDejaResilie: null,
    assuranceDejaResilieDetail: null,

    proposantAdresse: null,
    proposantVille: null,
    proposantCodepostal: null,
    proposantTelephone: null,
    proposantEmail: null,
    rgpd: null,
    captcha: null,
    fraisIntermediation: null,
    signatureContrat: false,
    statutPriseGarantie: 3,
    statutContrat: 0,
    statutQuittance: 0,
    statutDevis: 3,
    dateContratResilie: null,
    dateContratSanseffet: null,
    client: null,
    utilisateur: null,
    referenceNumero: null,
    ispublic: null,
    notificationUtilisateur: null,

}

export default (state = defaultState, action) => {
    switch (action.type) {
        case DEVIS_INFORMATIQUE_RESET:
            return defaultState;

        case DEVIS_INFORMATIQUE_SAVE:
            return {
                ...state,
                ...action.data
            };
        case DEVIS_INFORMATIQUE_RECEIVED:
            delete action.data['referenceMasque'];
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }
}
