import {INIT_DONE, REQUEST_DONE, REQUEST_FETCHING} from "../actions";

export default (state = {
  fetching: false,
  init: false,
}, action) => {
  switch (action.type) {
    case REQUEST_FETCHING :
      return {
        ...state,
        fetching: true
      };
    case REQUEST_DONE :
        return {
            ...state,
            fetching: false
        };
  case INIT_DONE :
      return {
          ...state,
          init: true
      };
    default:
      return state;
  }
}
