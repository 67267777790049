import {PROFILE_RECEIVED} from "../actions";

export default (state = {
  name: null,
}, action) => {
  switch (action.type) {

      case PROFILE_RECEIVED:
          return {
              ...state,
              email: action.data.email,
          };
    default:
      return state;
  }
}
