// React
import React, {Component, forwardRef} from "react";

// Redux
import connect from "react-redux/es/connect/connect";

// @material-ui/core components
import { Slide, Dialog, DialogTitle, DialogContent, DialogActions} from "@material-ui/core";
import { List as ListIcon, Close as CloseIcon } from '@material-ui/icons';
import withStyles from "@material-ui/core/styles/withStyles";

// Creative Tim
import Button from "creativetim/material-dashboard-pro/components/CustomButtons/Button.js";
import GridContainer from "creativetim/material-dashboard-pro/components/Grid/GridContainer.js";
import GridItem from "creativetim/material-dashboard-pro/components/Grid/GridItem.js";
import Card from "creativetim/material-dashboard-pro/components/Card/Card.js";
import CardBody from "creativetim/material-dashboard-pro/components/Card/CardBody.js";
import CardHeader from "creativetim/material-dashboard-pro/components/Card/CardHeader.js";
import CardIcon from "creativetim/material-dashboard-pro/components/Card/CardIcon.js";
import CardFooter from "creativetim/material-dashboard-pro/components/Card/CardFooter.js";
import Success from "creativetim/material-dashboard-pro/components/Typography/Success.js";
import Warning from "creativetim/material-dashboard-pro/components/Typography/Warning.js";
import Danger from "creativetim/material-dashboard-pro/components/Typography/Danger.js";
import stylesUserProfile from "creativetim/material-dashboard-pro/assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import stylesCustomInput from "creativetim/material-dashboard-pro/assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import stylesModal from "creativetim/material-dashboard-pro/assets/jss/material-dashboard-pro-react/modalStyle.js";
import CustomLinearProgress from "creativetim/material-dashboard-pro/components/CustomLinearProgress/CustomLinearProgress.js";

// Vendor
import MaterialTable from "material-table";

// App
import {deleteDevis, getProduits, getCourtiers, requestFetching, setMessage, download, userLogout} from "../actions";
import {requests} from "../requests";

var moment = require('moment');
require('moment/locale/fr');


const styles = theme =>
    ({
        ...stylesUserProfile,
        ...stylesModal(theme),
        ...stylesCustomInput,

        linearProgressWrapper:{
            position:'absolute',
            width:'100%',
            top:'49%'
        },
        labelSwitch:{
            paddingTop:'10px'
        },
        justifyContentCenter: {
            justifyContent: "center"
        },
        cursorPointer:{
            cursor:'pointer'
        }
    })

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class Devis extends Component {
    constructor(props) {

        super(props);
        this.state = {
            modalDelete : false,
            devisToDelete:null,
            tableRef:null
        };
        this.tableRef = React.createRef();
        this.abortController = new AbortController();
    }
    componentWillUnmount()
    {
        this.abortController.abort();
    }

    handleDownload(url, nom) {
        this.props.download(url + '/download', nom)
    }
    handleChangeColumnHidden(column, hidden) {
        let columnsHidden = JSON.parse(window.localStorage.getItem("produits-columns"))
        if (columnsHidden === null) {
            columnsHidden = []
        }
        var find = false;
        if (columnsHidden.length) {
            columnsHidden.forEach(
                function(columnHidden) {
                    if (columnHidden.field === column.field) {
                        columnHidden.hidden = hidden;
                        find = true;
                    }
                }
            )
        }
        if (!find) {
            columnsHidden.push ({field:column.field, hidden:hidden});
        }
        window.localStorage.setItem("produits-columns", JSON.stringify(columnsHidden));
    }
    getColumns(savedQuery, columnsHidden, classes, handleDownload){

        let src = require(`assets/img/mimetype/32px/pdf.png`);
        let devis = this;
        let columns = [
            { title: "Id", field: "id", customSort : () => {} },
            { title: "Date création", field: "dateCreation", customSort : () => {},  render:(rowData) => new moment(rowData.dateCreation).format('L LT')},
            { title: "Produit", field: "@type", customSort : () => {}, lookup: { 'ProduitBijouterie': 'Bijouterie', 'ProduitEscapeGame': 'Escape Game', 'ProduitCyber': 'Cyber', 'ProduitImmeuble': 'Immeuble', 'ProduitGarage': 'Garage', 'ProduitDecennale': 'Décennale', 'ProduitInformatique': 'Informatique' }, },
            { title: "Client", field: "client.nom", customSort : () => {} },
            { title: "Apporteur", field: "utilisateur.cabinet", customSort : () => {} },
            { title: "Adresse", field: "adresse", customSort : () => {}},
            { title: "Superficie", field: "superficie", customSort : () => {} },
            { title: "Franchise", field: "franchise", customSort : () => {} },
            { title: "Frais de gestion", field: "fraisIntermediation", customSort : () => {} },
            { title: "Date effet", field: "dateEffet", customSort : () => {}, render:(rowData) => rowData.dateEffet ? new moment(rowData.dateEffet).format('L') : ''},
            {
                title: "Devis",
                field: "devis",
                customSort : () => {},
                render:
                    function(rowData) {
                        let document = rowData.documents.filter(document => document.nom && document.nom.toLowerCase() === 'devis.pdf');
                        if (document[0]) {
                            return <img alt={document[0]['nom']} className={classes.cursorPointer} src={src} onClick={devis.handleDownload.bind(devis,document[0]['@id'], document[0]['nom']) } />
                        }
                        return null;
                    },
                lookup: { '1': 'OUI', '0': 'NON' },

            },
            { title: "Contrat", field: "contrat", customSort : () => {},  render:(rowData) => rowData.contrat ? 'OUI' : 'NON', lookup: { '1': 'OUI', '0': 'NON' } },
            { title: "Quittance", field: "", customSort : () => {} },
            { title: "PJ", field: "", customSort : () => {} },
            { title: "N° adhésion", field: "reference", customSort : () => {}},
            {
                title: "Statut",
                field: "status",
                customSort : () => {},
                render: (rowData) =>  rowData.status.type === 'success' ? <Success>{rowData.status.text}</Success> : rowData.status.type === 'warning' ? <Warning>{rowData.status.text}</Warning> : <Danger>{rowData.status.text}</Danger>,
                lookup: {
                    'statutContrat:1': 'Contrat résilié',
                    'statutContrat:2': 'Contrat à échéance',
                    'statutContrat:4': 'Contrat sans effet',
                    'statutQuittance:1&signatureContrat:1': 'Contrat et quittance à jour',
                    'statutQuittance:0&signatureContrat:1': 'Contrat signé quittance à régulariser',
                    'statutQuittance:1&signatureContrat:0': 'Contrat non signé quittance régularisée',
                    'statutPriseGarantie:2': 'Prise de garantie en cours',
                    'statutPriseGarantie:1': 'Prise de garantie validée',
                    'statutPriseGarantie:0': 'Prise de garantie refusée',
                    'statutDevis:3': 'Demande de cotation en cours',
                    'statutDevis:1': 'Devis validé',
                    'statutDevis:0': 'Devis refusé' ,
                },
            },
    ];

        columns.forEach(
            function(column) {
                if (savedQuery.orderBy && savedQuery.orderBy.field === column.field) {
                    column.defaultSort = savedQuery.orderDirection
                }

                if (savedQuery.filters) {
                    savedQuery.filters.forEach(
                        function (filter) {

                            if (filter.column.field === column.field) {
                                column.defaultFilter = filter.value
                            }
                        }
                    );
                }
                if (columnsHidden.length) {
                    columnsHidden.forEach(
                        function (columnHidden) {
                            if (columnHidden.field === column.field) {
                                column.hidden = columnHidden.hidden
                            }
                        }
                    );
                }


            }
        )

        return columns
    }

    async  handleDelete()
    {
        if (!this.state.devisToDelete) {
            return;
        }

        await this.props.deleteDevis(this.state.devisToDelete)
        this.setState({modalDelete:false});
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }

    getUrl(uri) {
        uri = uri.replace('produit_escape_games', 'devis-escape-game');
        uri = uri.replace('produit_bijouteries', 'devis-bijouterie');
        uri = uri.replace('produit_cybers', 'devis-cyber');
        uri = uri.replace('produit_immeubles', 'devis-immeuble');
        uri = uri.replace('produit_garages', 'devis-garage');
        uri = uri.replace('produit_decennales', 'devis-decennale');
        uri = uri.replace('produit_informatiques', 'devis-informatique');
        return uri
    }

    render () {

        const { history, produits, classes, fetching } = this.props;

        if (fetching || produits == null) {
            return (
                <div className={classes.linearProgressWrapper}>
                    <CustomLinearProgress color="primary"/ >
                </div>
            )
        }

        let savedQuery = JSON.parse(window.localStorage.getItem("produits-query"))
        let columnsHidden = JSON.parse(window.localStorage.getItem("produits-columns"))

        if (savedQuery === null) {
            savedQuery = []
        }
        if (columnsHidden === null) {
            columnsHidden = []
        }
        const devisRef = this;
        return (
            <>

            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
            <form>
            <Card>
            <CardHeader color="rose" icon>
        <CardIcon color="rose">
            <ListIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
        Devis et contrats
        </h4>
        </CardHeader>
        <CardBody>


        <MaterialTable
        icons={{
            Filter: forwardRef((props, ref) => <div {...props} ref={ref} />),
        }}
        onChangeColumnHidden={this.handleChangeColumnHidden.bind(this)}
        columns={this.getColumns(savedQuery, columnsHidden, classes, this.handleDownload)}
        tableRef={this.tableRef}
        data= {
            function(query) {
                devisRef.abortController.abort();
                return new Promise(
                    (resolve, reject) => {
                        devisRef.abortController = new AbortController();
                        let url = '/produits';
                        url += '?page=' + (query.page + 1)
                        url += '&itemsPerPage=' + query.pageSize
                        if (query.orderBy && query.orderBy.field) {
                            url += '&order[' + query.orderBy.field + ']=' + query.orderDirection
                        } else {
                            url += '&order[id]=desc'
                        }
                        query.filters.forEach(
                            filter => url+= "&" + filter.column.field + "=" + filter.value
                        );
                        url += '&time=' + new Date().getTime();
                        window.localStorage.setItem("produits-query", JSON.stringify(query));

                        return requests.get(url, true, devisRef.abortController)
                            .then(result => {
                                resolve({
                                    data: result['hydra:member'],
                                    page:  query.page,
                                    totalCount: result['hydra:totalItems'],
                                })
                            }).catch(error => {
                                if (error.response && 401 === error.response.status) {
                                    return devisRef.props.userLogout('Merci de vous reconnecter');
                                }
                            })
                    }
                )
            }
        }
        actions={[
            {
                icon: 'create',
                tooltip: 'Modification procédure',
                onClick: (event, rowData) => history.push(this.getUrl(rowData['@id']))
            },
            {
                icon: 'delete',
                tooltip: 'Suppression procédure',
                onClick: (event, rowData) => this.setState({tableRef:this.tableRef.current, modalDelete: true, devisToDelete: rowData.id})

            }
    ]}
        options={{
            filtering: true,
                actionsColumnIndex:99,
                columnsButton:true,
                pageSize:savedQuery.pageSize ? savedQuery.pageSize : 10,
                pageSizeOptions:[10,30,100],
                thirdSortClick:false,
                search:false,
                rowStyle: rowData => ({
                backgroundColor: (rowData.tableData.id % 2) ? '#FFF' : '#fafafa'
            })
        }}
        title=""
            />
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>

    </CardFooter>
        </Card>
        </form>
        </GridItem>
        </GridContainer>

        <Dialog
        classes={{
            root: classes.center,
                paper: classes.modal
        }}
        open={this.state.modalDelete}
        transition={Transition}
        keepMounted
        onClose={() => this.setState({modal: false})}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
            >
            <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
    <Button
        justIcon
        className={classes.modalCloseButton}
        key="close"
        aria-label="Close"
        color="transparent"
        onClick={() => this.setState({modalDelete: false})}
    >
    <CloseIcon className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>Suppression d'une procédure</h4>
        </DialogTitle>
        <DialogContent id="modal-slide-description" className={classes.modalBody} >
    <h5>Etes-vous sur de vouloir supprimer cette procédure ? </h5>

        </DialogContent>
        <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter} >
    <Button onClick={() => this.setState({modalDelete: false})}>Annuler</Button>
        <Button disabled={!this.state.devisToDelete} onClick={() => this.handleDelete()} color="success">Supprimer</Button>
            </DialogActions>
            </Dialog>
            </>
    );

    }
}

const mapStateToProps = store => ({
    ...store.requestReducer,
    ...store.courtiersReducer,
    ...store.clientsReducer,
    ...store.produitsReducer,
    message: store.messageReducer.message,
});

const mapDispatchToProps = {
    getCourtiers,
    requestFetching,
    setMessage,
    getProduits,
    download,
    deleteDevis,
    userLogout

};
export default
connect(mapStateToProps, mapDispatchToProps) (
    withStyles(styles)
    (
        Devis
    )
);