import {DEVIS_DECENNALE_RESET, DEVIS_DECENNALE_RECEIVED, DEVIS_DECENNALE_SAVE} from "../actions";

const defaultState = {
    id: null,
    config: null,

    entrepriseSiret: null,
    entrepriseFormeJuridique: null,
    entrepriseProcedureJudiciaireMandat: false,
    entrepriseProcedureJudiciaireConciliation: false,
    entrepriseProcedureJudiciaireSauvegarde: false,
    entrepriseProcedureJudiciaireRedressement: false,
    entrepriseExperience: null,
    entrepriseExperienceDetail: null,
    entrepriseCompagnon: null,
    entrepriseCompagnonDetail: null,
    entrepriseEffectifTotal: null,
    entrepriseEffectifTravauxChantier: null,
    entrepriseEffectifHorsTravauxChantier: null,
    entrepriseExperienceMoyenne: null,
    entrepriseCaHt: null,
    entrepriseCaHtSousTraite: null,
    entrepriseCaTravauxPriveEuro: null,
    entrepriseCaTravauxPrivePourcentage: null,
    entrepriseCaTravauxPublicEuro: null,
    entrepriseCaTravauxPublicPourcentage: null,

    typeTravauxParticuliers: null,
    typeTravauxCollectivitesPublic: null,
    typeTravauxEntreprises: null,
    typeTravauxConstructeurs: null,
    typeTravauxAutres: null,
    typeTravauxCaRenovation: null,
    typeTravauxCaNeuf: null,

    lieuTravauxFrance: false,
    lieuTravauxDrom: false,
    lieuTravauxUnionEuropeene: false,
    lieuTravauxUnionEuropeeneDetail: null,
    lieuTravauxCom: false,
    lieuTravauxAutres: false,
    lieuTravauxAutresDetail: null,

    antecedentAssureSansInterruption: null,
    antecedentAssureSansInterruptionDetail: null,
    antecedentAssureDateEcheance: null,
    antecedentAssuranceResilie: null,
    antecedentAssuranceResilieDetail: null,
    antecedentMiseEnCause: null,
    antecedentMiseEnCauseDetail: null,
    interventionCmi: null,
    interventionContractant: null,
    interventionObligationAssurance: null,
    interventionMontantSup36: null,

    activiteDemolitionExplosifCa: null,
    activiteDemolitionExplosifSousTraitance: null,
    activiteDemolitionSansExplosifCa: null,
    activiteDemolitionSansExplosifSousTraitance: null,
    activiteTerrassementCa: null,
    activiteTerrassementSousTraitance: null,
    activiteForageVerticalCa: null,
    activiteForageVerticalSousTraitance: null,
    activiteAmeliorationSolCa: null,
    activiteAmeliorationSolSousTraitance: null,
    activiteVrdCa: null,
    activiteVrdSousTraitance: null,
    activiteAmenagementPaysagerCa: null,
    activiteAmenagementPaysagerSousTraitance: null,
    activiteMontageEchafaudageCa: null,
    activiteMontageEchafaudageSousTraitance: null,
    activiteTraitementAmianteCa: null,
    activiteTraitementAmianteSousTraitance: null,
    activiteXylophagesCa: null,
    activiteXylophagesSousTraitance: null,
    activiteAssechementMursCa: null,
    activiteAssechementMursSousTraitance: null,

    activiteFondationsSpecialesCa: null,
    activiteFondationsSpecialesSousTraitance: null,
    activiteMaconnerieBetonArmeCa: null,
    activiteMaconnerieBetonArmeSousTraitance: null,
    activiteBetonPreContraintCa: null,
    activiteBetonPreContraintSousTraitance: null,
    activiteCharpenteStructureBoisCa: null,
    activiteCharpenteStructureBoisSousTraitance: null,
    activiteOssatureBoisCa: null,
    activiteOssatureBoisSousTraitance: null,
    activiteCharpenteStructureMetalliqueCa: null,
    activiteCharpenteStructureMetalliqueSousTraitance: null,

    activiteCouvertureCa: null,
    activiteCouvertureSousTraitance: null,
    activiteEtancheiteToitureCa: null,
    activiteEtancheiteToitureSousTraitance: null,
    activiteEtancheiteCuvelageCa: null,
    activiteEtancheiteCuvelageSousTraitance: null,
    activiteRevetementEnduitCa: null,
    activiteRevetementEnduitSousTraitance: null,
    activiteIteCa: null,
    activiteIteSousTraitance: null,
    activiteBardageCa: null,
    activiteBardageSousTraitance: null,
    activiteFacadeRideauxCa: null,
    activiteFacadeRideauxSousTraitance: null,
    activiteStructureTextileCa: null,
    activiteStructureTextileSousTraitance: null,
    activiteMenuiserieExterieurCa: null,
    activiteMenuiserieExterieurSousTraitance: null,
    activiteVerandaCa: null,
    activiteVerandaSousTraitance: null,

    activiteMenuiserieInterieurCa: null,
    activiteMenuiserieInterieurSousTraitance: null,
    activiteSdbCa: null,
    activiteSdbSousTraitance: null,
    activiteCuisineCa: null,
    activiteCuisineSousTraitance: null,
    activitePlatretieCa: null,
    activitePlatretieSousTraitance: null,
    activiteSerrurerieCa: null,
    activiteSerrurerieSousTraitance: null,
    activiteVitrerieCa: null,
    activiteVitrerieSousTraitance: null,
    activitePeintureCa: null,
    activitePeintureSousTraitance: null,
    activiteRevetementSoupleCa: null,
    activiteRevetementSoupleSousTraitance: null,
    activiteRevetementDurCa: null,
    activiteRevetementDurSousTraitance: null,
    activiteRevetementVerticalDurCa: null,
    activiteRevetementVerticalDurSousTraitance: null,
    activiteItiCa: null,
    activiteItiSousTraitance: null,
    activiteIsolationFrigorifiqueCa: null,
    activiteIsolationFrigorifiqueSousTraitance: null,

    activitePlomberieCa: null,
    activitePlomberieSousTraitance: null,
    activiteChauffageCa: null,
    activiteChauffageSousTraitance: null,
    activiteFumisterieCa: null,
    activiteFumisterieSousTraitance: null,
    activiteAeroliqueCa: null,
    activiteAeroliqueSousTraitance: null,
    activiteElectriciteCa: null,
    activiteElectriciteSousTraitance: null,
    activiteAscenceurCa: null,
    activiteAscenceurSousTraitance: null,
    activitePiscineCa: null,
    activitePiscineSousTraitance: null,
    activiteGeothermieCa: null,
    activiteGeothermieSousTraitance: null,
    activitePhotovoltaiqueCa: null,
    activitePhotovoltaiqueSousTraitance: null,
    activiteEolienCa: null,
    activiteEolienSousTraitance: null,
    activiteFourIndustrielCa: null,
    activiteFourIndustrielSousTraitance: null,

    proposantAdresse: null,
    proposantVille: null,
    proposantCodepostal: null,
    proposantTelephone: null,
    proposantEmail: null,
    rgpd: null,
    captcha: null,
    fraisIntermediation: null,
    signatureContrat: false,
    statutPriseGarantie: 3,
    statutContrat: 0,
    statutQuittance: 0,
    statutDevis: 3,
    dateContratResilie: null,
    dateContratSanseffet: null,
    client: null,
    utilisateur: null,
    referenceNumero: null,
    ispublic: null,
    notificationUtilisateur: null,

}

export default (state = defaultState, action) => {
    switch (action.type) {
        case DEVIS_DECENNALE_RESET:
            return defaultState;

        case DEVIS_DECENNALE_SAVE:
            return {
                ...state,
                ...action.data
            };
        case DEVIS_DECENNALE_RECEIVED:
            delete action.data['referenceMasque'];
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }
}
