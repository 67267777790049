import {DEVIS_BIJOUTERIE_RESET, DEVIS_BIJOUTERIE_SAVE, DEVIS_BIJOUTERIE_RECEIVED} from "../actions";

const defaultState = {
    id: null,
    config: null,
    infoDevisAffaireNouvelle: null,
    infoDevisRemplacementContratNum: null,
    infoDevisDateEffet: null,
    infoDevisDateEcheance: null,
    proposantSiret: null,
    proposantGroupement: null,
    proposantGroupementDetail: null,
    proposantExerceDepuis: null,
    activitesNatureBijouterie: false,
    activitesNatureHorlogerie: false,
    activitesRepartitionDetail: null,
    activitesRepartitionFabrication: null,
    activitesRepartitionGros: null,
    activitesRepartitionDiamantaire: null,
    activitesRepartitionAutre: null,
    activitesCaHt: null,
    activitesLiquidationOuRedressement: null,
    activitesProprietaireCommerce: null,
    activitesNombrePersonne: null,
    activitesNombreMiniSalaries: null,
    activitesNombreMiniSalariesDejeuner: null,
    activitesDureeInoccupationAnnuelle: null,
    localDenominationEnseigne: null,
    localAdresse: null,
    localCodePostal: null,
    localVille: null,
    localLocalisationCentreCommercial: false,
    localLocalisationCentreVille: false,
    localLocalisationRuePietonne: false,
    localLocalisationZac: false,
    localNatureMagasin: false,
    localNatureAtelier: false,
    localNatureAppartement: false,
    localNatureStand: false,
    localLocalNatureKiosque:false,
    localNiveauxOccupes: null,
    localEtages: null,
    localSurfaceOccupee: null,
    garantiesStockValeurTotale: null,
    garantiesStockValeurPrecieux: null,
    garantiesStockValeurNonPrecieux: null,
    garantiesAugmentation25Debut1: null,
    garantiesAugmentation25Fin1: null,
    garantiesAugmentation25Debut2: null,
    garantiesAugmentation25Fin2: null,
    garantiesDeteriorationMobiliere: null,
    garantiesDeteriorationImmobiliere: null,
    garantiesTerritorialite: null,
    garantiesExpositions: null,
    garantiesBrisGlace: null,
    garantiesFranchiseInf150: null,
    garantiesFranchise150300: null,
    garantiesFranchise300500: null,
    garantiesFranchise500750: null,
    garantiesFranchise750Sup: null,
    conditionsRespectees: null,
    conditionsRespecteesDetail: null,
    conditionsAutresProtections: null,
    alarmeMarque: null,
    alarmeInstallateur: null,
    alarmeDateMiseService: null,
    alarmeSocieteEntretien: null,
    centraleTelesurveillance: null,
    centraleTelesurveillanceNomSociete: null,
    coffreFortMarque: null,
    coffreFortClasse: null,
    coffreFortPoids: null,
    coffreFortSousProtection: null,
    coffreFortContacteurs: null,
    coffreFortSerrureHoraire: null,
    coffreFortScelle: null,
    devantures: null,
    devanturesNbVitres: null,
    devanturesNatureProduitVerrier: null,
    devanturesEpaisseurProduitVerrier: null,
    devanturesVitrinesRelieesAlarme: null,
    rideaux: null,
    rideauxDescriptif: null,
    rideauxPlacement: null,
    rideauxRelieAlarme: null,
    rideauxProtection: null,
    rideauxProtectionDetail: null,
    autresAcces: null,
    autresAccesModeFermeturePorte: null,
    autresAccesModeFermetureFenetre: null,
    autresAccesReliesAlarme: null,
    assuranceLocal: null,
    assuranceLocalDateConstruction: null,
    assuranceLocalNatureConstruction: null,
    assuranceLocalNatureCouverture: null,
    assuranceLocalEtatEntretien: null,
    assuranceLocalNatureProfessionExercees: null,
    assuranceLocalRisqueAggravant: null,
    assuranceLocalRisqueAggravantDetail: null,
    precisionProposantQualite: null,
    precisionProposantQualiteType: null,
    renonciationRecoursProprietaireAssureur: null,
    renonciationRecoursReciproque: null,
    renonciationRecoursInteretCommun: null,
    assuranceBatimentProprietaire: null,
    incendieRisqueBatiment: null,
    incendieRisqueMobilier: null,
    degatsEauxBatiment: null,
    degatsEauxMobilier: null,
    brisAccidentel: null,
    dommageMateriels: null,
    rc: null,
    rcPrestationsTiers: null,
    rcPrestationsTiersDetail: null,
    rcActiviteFabrication: null,
    rcActiviteFabricationDetail: null,
    rcRecoursSousTraitance: null,
    rcBiensConfies: null,
    perteExploitation: null,
    assurancePersonne: null,
    sinistresDeclares: null,
    sinistresDeclaresDetail: null,
    sinistresDeclaresMesures: null,
    assuranceCumulative: null,
    assuranceCumulativeDetail: null,
    assuranceAnterieurRefus: null,
    assuranceAnterieurAssure: null,
    assuranceAnterieurAssureDetail: null,
    autre: null,
    proposantNom: null,
    proposantAdresse: null,
    proposantVille: null,
    proposantCodepostal: null,
    rgpd: null,
    captcha: null,
    fraisIntermediation: null,
    signatureContrat: false,
    statutPriseGarantie: 3,
    statutContrat: 0,
    statutQuittance: 0,
    statutDevis: 3,
    dateContratResilie: null,
    dateContratSanseffet: null,
    client: null,
    utilisateur: null,
    referenceNumero: null,
    ispublic: null,
    notificationUtilisateur: null,

}

export default (state = defaultState, action) => {
    switch (action.type) {
        case DEVIS_BIJOUTERIE_RESET:
            return defaultState;

        case DEVIS_BIJOUTERIE_SAVE:
            return {
                ...state,
                ...action.data
            };
        case DEVIS_BIJOUTERIE_RECEIVED:
            delete action.data['referenceMasque'];
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }
}
