import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Redux
import {formValueSelector, reduxForm} from 'redux-form'
import connect from "react-redux/es/connect/connect";

// App
import columns from "./Columns.js";
import {validateColumns, renderColumns} from 'columnsUtils'
import styles from "assets/jss/styles.js"

const selector = formValueSelector('devisBijouterieForm')

class ProtectionRisque extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          touched : false
        };

        this.columns1 = [];
        this.columns2 = [];
        this.columns3 = [];
        this.columns4 = [];
        this.columns5 = [];
        this.columns6 = [];
        this.columns7 = [];
    }

    shouldComponentUpdate(nextProps, nextState) {

        this.columns1 = columns(
            {
                obj:this,
                proposantGroupementValue:nextProps.proposantGroupementValue,
                conditionsRespecteesValue:nextProps.conditionsRespecteesValue,
            }
        )['protectionRisqueProtections'];

        this.columns2 = columns(
            {
                obj:this,

            }
        )['protectionRisqueAlarme'];

        this.columns3 = columns(
            {
                obj:this,
                centraleTelesurveillanceValue:nextProps.centraleTelesurveillanceValue,
            }
        )['protectionRisqueTelesurveillance'];

        this.columns4 = columns(
            {
                classes:nextProps.classes
            }
        )['protectionRisqueCoffre'];

        this.columns5 = columns(
            {
                devanturesValue:nextProps.devanturesValue
            }
        )['protectionRisqueDevanture'];

        this.columns6 = columns(
            {
                rideauxValue:nextProps.rideauxValue,
                rideauxProtectionValue:nextProps.rideauxProtectionValue
            }
        )['protectionRisqueRideaux'];

        this.columns7 = columns(
            {
                autresAccesValue:nextProps.autresAccesValue,
            }
        )['protectionRisqueAutreAcces'];

        return true;
    }


    isValidated() {
        let errors = validateColumns(this.props.myform.devisBijouterieForm.values, this.columns1);
        errors = {...validateColumns(this.props.myform.devisBijouterieForm.values, this.columns2), ...errors};
        errors = {...validateColumns(this.props.myform.devisBijouterieForm.values, this.columns3), ...errors};
        errors = {...validateColumns(this.props.myform.devisBijouterieForm.values, this.columns4), ...errors};
        errors = {...validateColumns(this.props.myform.devisBijouterieForm.values, this.columns5), ...errors};
        errors = {...validateColumns(this.props.myform.devisBijouterieForm.values, this.columns6), ...errors};
        errors = {...validateColumns(this.props.myform.devisBijouterieForm.values, this.columns7), ...errors};

        this.setState({ touched: true});
        return Object.keys(errors).length === 0;
    }

    sendState() {
        return this.state;
    }

    render() {

        const { classes, fetching } = this.props;

        // Cas spécifique pour l'autocomplete
        if (fetching) {
            return (<div></div>)
        }

        return (
            <>
                <>
                    <h4 className={classes.infoText}>
                        Protections du risque
                    </h4>

                    <h6 className={classes.infoText}>
                        Protections minimum requises SOUS PEINE DE NON GARANTIE
                    </h6>


                    <table className={classes.marginAuto}>
                        <tbody>
                            <tr>
                                <td className={classes.width50 + " " + classes.textCenter}>
                                    <strong>Protections mécaniques requises </strong>
                                </td>
                                <td className={classes.width50 + " " + classes.textCenter}>
                                    <strong>Protections électroniques requises </strong>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top" className={classes.width50 + " " + classes.textCenter}>
                                    <div>
                                        Porte secondaire sécurisée/blindée<br/>
                                        Si devanture, épaisseur du produit verrier 10 mm<br/>
                                        Coffre-fort d’au moins 500 kg ou coffre-fort scellé<br/>
                                    </div>
                                    {this.props.garantiesStockValeurTotaleValue >= 150000 && this.props.garantiesStockValeurTotaleValue < 300000 &&
                                        <div>
                                            Rideau métallique<br/>
                                            Gâche électrique<br/>
                                        </div>
                                    }
                                    {this.props.garantiesStockValeurTotaleValue >= 300000 && this.props.garantiesStockValeurTotaleValue < 500000 &&
                                        <div>
                                            Code sous contrainte<br/>
                                            Bouton/bip/pédale Hold up<br/>
                                        </div>
                                    }
                                    {this.props.garantiesStockValeurTotaleValue >= 500000 && this.props.garantiesStockValeurTotaleValue < 750000 &&
                                        <div>
                                            Ventouse électromagnétique (résistance minimum 300 Kg) sur accès principal<br/>
                                            Si devanture – épaisseur produit verrier 15 mm ou 10 mm + film retardateur d’effraction sur devanture<br/>
                                            Plot anti-bélier ou inaccessibilité aux véhicules des devantures<br/>
                                        </div>
                                    }
                                    {this.props.garantiesStockValeurTotaleValue >= 750000 &&
                                        <div>
                                            Sas d’entrée ou générateur de fumées<br/>
                                            Coffre-fort ouverture différée ou serrure horaire<br/>
                                        </div>
                                    }
                                </td>
                                <td valign="top" className={classes.width50 + " " + classes.textCenter}>
                                    <div>
                                        Système d’alarme périmétrique et volumétrique<br/>
                                        Entretien semestriel au minimum<br/>
                                        Mémorisation des événements ou contrôleur enregistreur<br/>
                                        Détecteurs d’ouverture sur ouvrants<br/>
                                        Contacteurs de choc sur ouvrants<br/>
                                    </div>
                                    {this.props.garantiesStockValeurTotaleValue >= 150000 && this.props.garantiesStockValeurTotaleValue < 300000 &&
                                        <div>
                                            Transmetteur téléphonique<br/>
                                            Test en ligne toutes les 3h<br/>
                                            Protection volumétrique sur coffre-fort<br/>
                                        </div>
                                    }
                                    {this.props.garantiesStockValeurTotaleValue >= 300000 && this.props.garantiesStockValeurTotaleValue < 500000 &&
                                        <div>
                                            Alarme reliée à une télésurveillance<br/>
                                            Autoprotection de type GSM<br/>
                                            Contacteur/détecteur alarme sur coffre-fort<br/>
                                            Caméra(s) avec moniteur de contrôle<br/>
                                        </div>
                                    }
                                    {this.props.garantiesStockValeurTotaleValue >= 500000 && this.props.garantiesStockValeurTotaleValue < 750000 &&
                                        <div>
                                            Caméra(s) avec enregistrement<br/>
                                        </div>
                                    }
                                    {this.props.garantiesStockValeurTotaleValue >= 750000 &&
                                        <div>
                                            Vidéosurveillance (liaison à la société de télésurveillance avec levée de doutes)<br/>
                                        </div>
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {
                        renderColumns(
                            {
                                columns: this.columns1,
                                classes: classes,
                                fetching: fetching,
                                touched: this.state.touched
                            }
                        )
                    }
                </>
                <>
                    {
                        renderColumns(
                            {
                                columns: this.columns2,
                                titre : <div>&nbsp;</div>,
                                soustitre: "Système d’alarme (avec dispositif sonore) - OBLIGATOIRE",
                                classes: classes,
                                fetching: fetching,
                                touched: this.state.touched
                            }
                        )
                    }
                </>
                <>
                    {
                        renderColumns(
                            {
                                columns: this.columns3,
                                titre : <div>&nbsp;</div>,
                                soustitre: "Centrale de Télésurveillance",
                                classes: classes,
                                fetching: fetching,
                                touched: this.state.touched
                            }
                        )
                    }
                </>
                <>
                    {
                        renderColumns(
                            {
                                columns: this.columns4,
                                titre : <div>&nbsp;</div>,
                                soustitre: "Coffre-fort - OBLIGATOIRE",
                                classes: classes,
                                fetching: fetching,
                                touched: this.state.touched
                            }
                        )
                    }
                </>
                <>
                    {
                        renderColumns(
                            {
                                columns: this.columns5,
                                titre : <div>&nbsp;</div>,
                                soustitre: "Devantures",
                                classes: classes,
                                fetching: fetching,
                                touched: this.state.touched
                            }
                        )
                    }
                </>
                <>
                    {
                        renderColumns(
                            {
                                columns: this.columns6,
                                titre : <div>&nbsp;</div>,
                                soustitre: "Rideaux",
                                classes: classes,
                                fetching: fetching,
                                touched: this.state.touched
                            }
                        )
                    }
                </>
                <>
                    {
                        renderColumns(
                            {
                                columns: this.columns7,
                                titre : <div>&nbsp;</div>,
                                soustitre: "Autres accès (portes/fenêtres/baies vitrées)",
                                classes: classes,
                                fetching: fetching,
                                touched: this.state.touched
                            }
                        )
                    }
                </>
            </>

        );
    }
}

const mapStateToProps = store => ({
    myform:store.form,
    garantiesStockValeurTotaleValue: selector(store, 'garantiesStockValeurTotale'),
    conditionsRespecteesValue: selector(store, 'conditionsRespectees'),
    centraleTelesurveillanceValue: selector(store, 'centraleTelesurveillance'),
    devanturesValue: selector(store, 'devantures'),
    rideauxValue: selector(store, 'rideaux'),
    rideauxProtectionValue: selector(store, 'rideauxProtection'),
    autresAccesValue: selector(store, 'autresAcces'),
});

const mapDispatchToProps = {
};

export default
connect(mapStateToProps, mapDispatchToProps) (
    // reduxForm nécéssaire pour le this.props.change
    reduxForm({form: 'devisBijouterieForm'}) (
        withStyles(styles)
       (
           ProtectionRisque
       )
    )
);

