// React
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Redux
import connect from "react-redux/es/connect/connect";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Error as ErrorIcon, Check as CheckIcon} from "@material-ui/icons";

// Creative Tim
import register from "creativetim/material-dashboard-pro/assets/img/register.jpeg";
import login from "creativetim/material-dashboard-pro/assets/img/login.jpeg";
import lock from "creativetim/material-dashboard-pro/assets/img/lock.jpeg";
import errorBg from "creativetim/material-dashboard-pro/assets/img/clint-mckoy.jpg";
import Snackbars from "creativetim/material-dashboard-pro/components/Snackbar/Snackbar.js";
import styles from "creativetim/material-dashboard-pro/assets/jss/material-dashboard-pro-react/layouts/authStyle.js";

// App
import AuthNavbar from "../components/AuthNavbar.js";
import Footer from "../components/Footer.js";
import routes from "routes.js";
import {setError, setMessage} from "../actions";

const useStyles = makeStyles(styles);

 function Pages(props) {
  const {setMessage, setError, message, fetching, error, form, ...rest } = props;
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();


  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });

 React.useEffect(() => {
     let timerError = null;
     if (formError(error)) {
         timerError = setTimeout(cancelError, 3000)
     }
     let timerMessage = null
     if (formMessage(message, error)) {
         timerMessage = setTimeout(cancelMessage, 3000)
     }

     return function cleanup() {
         if (timerError) {
            clearTimeout(timerError)
         }
         if (timerMessage) {
            clearTimeout(timerMessage)
         }
     }
 })
     const [updateNow, setUpdateNow] = React.useState(true)


  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBgImage = () => {
    if (window.location.pathname.indexOf("/auth/register-page") !== -1) {
      return register;
    } else if (window.location.pathname.indexOf("/auth/login-page") !== -1) {
      return login;
    } else if (
      window.location.pathname.indexOf("/auth/lock-screen-page") !== -1
    ) {
      return lock;
    } else if (window.location.pathname.indexOf("/auth/error-page") !== -1) {
      return errorBg;
    }
  };
  const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
     const forms = [
         'LoginForm',
     ]
     var cancelError = function() {
         setError('')
         forms.forEach(
             formName => {
                 if (form[formName]) {
                     form[formName].error = ''
                 }
             }
         )
         setUpdateNow(!updateNow)
     };

     var cancelMessage = function () {
         setMessage('')
     }
     const formError = (error) => {

         let formError = null

         if (error) {
             formError = error
         }

         forms.forEach(
             formName => {
                 if (form[formName] && form[formName].error) {
                     formError = form[formName].error;
                 }
             }
         )

         if (formError) {
             return formError
         }

         return false
     }

     const formMessage = (message, error) => {
         if (message && !error) {
             return message
         }
         return false
     }

     return (
    <div>
        {!fetching &&
            <Snackbars
                place="tc"
                color="danger"
                icon={ErrorIcon}
                message={formError(error)}
                open={formError(error) ? true : false}
                closeNotification={() => false}
            />
        }
        {!fetching &&
            <Snackbars  xs={12} sm={6} md={4}
                place="tc"
                color="info"
                message={formMessage(message, error)}
                icon={CheckIcon}
                open={formMessage(message, error) ? true : false}
                closeNotification={() => false}
            />
        }

      <AuthNavbar brandText={getActiveRoute(routes)} {...rest} />
      <div className={classes.wrapper} ref={wrapper}>
        <div
          className={classes.fullPage}
          style={{ backgroundImage: "url(" + getBgImage() + ")" }}
        >
          <Switch>
            {getRoutes(routes)}
            <Redirect from="/auth" to="/auth/login-page" />
          </Switch>
          <Footer white />
        </div>
      </div>
    </div>
  );
}


function  mapStateToProps(store) {
    return {
        ...store.messageReducer,
        ...store.requestReducer,
        form: store.form
    }
}

const mapDispatchToProps = {
    setMessage,
    setError,
}

//export default Pages
export default connect(mapStateToProps, mapDispatchToProps)(
        (Pages)
)
