// React
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Redux
import connect from "react-redux/es/connect/connect";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Error as ErrorIcon, Check as CheckIcon} from "@material-ui/icons";

// Creative Tim
import styles from "creativetim/material-dashboard-pro/assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import Snackbars from "creativetim/material-dashboard-pro/components/Snackbar/Snackbar.js";

// App
import routes from "routes.js";
import {setError, setMessage} from "../actions";

const useStyles = makeStyles(styles);

function Iframe(props) {
    const {setMessage, setError, message, error, form, fetching} = props;

    const classes = useStyles();

    React.useEffect(() => {
        let timerError = null;
        if (formError(error)) {
            timerError = setTimeout(cancelError, 3000)
        }
        let timerMessage = null
        if (formMessage(message, error)) {
            timerMessage = setTimeout(cancelMessage, 3000)
        }

        return function cleanup() {
            if (timerError) {
                clearTimeout(timerError)
            }
            if (timerMessage) {
                clearTimeout(timerMessage)
            }
        }
    })
    const [updateNow, setUpdateNow] = React.useState(true)


    const getRoute = () => {
        return window.location.pathname !== "/iframe/full-screen-maps";
    };

    const getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (prop.layout === "/iframe") {
                return (
                    <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
                />
            );
            } else {
                return null;
            }
        });
    };

    const forms = [
        'configurationBijouterieForm',
        'configurationEscapeGameForm',
        'configurationCyberForm',
        'configurationImmeubleForm',
        'configurationGarageForm',
        'configurationDecennaleForm',
        'configurationInformatiqueForm',
        'clientForm',
        'devisEscapeGameForm',
        'devisBijouterieForm',
        'devisCyberForm',
        'devisImmeubleForm',
        'devisGarageForm',
        'devisDecennaleForm',
        'devisInformatiqueForm',
    ]

    var cancelError = function() {
        setError('')
        forms.forEach(
            formName => {
                if (form[formName]) {
                    form[formName].error = ''
                }
            }
        )
        setUpdateNow(!updateNow)
    };

    var cancelMessage = function () {
        setMessage('')
    }
    const formError = (error) => {

        let formError = null

        if (error) {
            formError = error
        }

        forms.forEach(
            formName => {
                if (form[formName] && form[formName].error) {
                    formError = form[formName].error;
                }
            }
        )

        if (formError) {
            return formError
        }

        return false
    }

    const formMessage = (message, error) => {
        if (message && !error) {
            return message
        }
        return false
    }

    const renderPages = () => {
      // On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller
        return  getRoute() ? (
            <div className={classes.content}>
                <div className={classes.container}>
                    <Switch>
                        {getRoutes(routes)}
                        <Redirect from="/iframe" to="error" />
                    </Switch>
                </div>
            </div>
        ) : (
            <div className={classes.map}>
                <Switch>
                    {getRoutes(routes)}
                    <Redirect from="/iframe" to="error" />
                </Switch>
            </div>
        )
    }

    return (
        <div>
            {!fetching &&
                <Snackbars
                    place="tc"
                    color="danger"
                    icon={ErrorIcon}
                    message={formError(error)}
                    open={formError(error) ? true : false}
                    closeNotification={() => false}
                />
            }
            {!fetching &&
                <Snackbars  xs={12} sm={6} md={4}
                    place="tc"
                    color="info"
                    message={formMessage(message, error)}
                    icon={CheckIcon}
                    open={formMessage(message, error) ? true : false}
                    closeNotification={() => false}
                />
            }
            {renderPages()}
        </div>
    );
}

function  mapStateToProps(store) {
    return {
        ...store.messageReducer,
        ...store.requestReducer,
        form: store.form
    }
}

const mapDispatchToProps = {
    setMessage,
    setError,
}

//export default Pages
export default connect(mapStateToProps, mapDispatchToProps)(
    (Iframe)
)

