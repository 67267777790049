// React
import React from "react";

// App
import {renderMultiCheckbox, renderCaptcha, renderDate, renderText, renderTextarea, renderInput, renderSelect, renderHidden, renderAutocomplete, renderCheckbox, renderRadio} from 'formUtils'

const columns = function (props) {
    return {
        'informationsPublic': [
            {name:"proposantNom", required:true, label:"Nom et prénom du proposant ou Raison Sociale", component:renderInput},
            {name:"proposantAdresse", required:true, label:"Adresse", component:renderInput},
            {name:"proposantVille", required:true, label:"Ville", component:renderInput},
            {name:"proposantCodepostal", required:true, label:"Code postal", component:renderInput},
            {name:"proposantTelephone", required:true, label:"Téléphone", component:renderInput},
            {name:"proposantEmail", required:true, label:"Email", component:renderInput},
            {
                name: "ispublic",
                component: renderHidden,
            },
        ],
        'informations': [
            {
                name: "utilisateur",
                component: renderHidden,
                required: true,
                reference: 'utilisateurList'
            },
            {
                name: "utilisateurList",
                label: "Utilisateur",
                component: renderAutocomplete,
                componentOptions: {
                    list: props.courtiers,
                    change: props.changeCourtier ? props.changeCourtier.bind(props.obj) : null,

                },
                fieldProps: {
                    selectedValue: props.courtiers != null ? props.getSelectedValueCourtier(props.obj) : null
                }
            },
            {
                name:"client",
                component:renderHidden,
                required:true,
                reference:'clientList'

            },
            {
                name:"clientList",
                label:"Client",
                component:renderAutocomplete,
                componentOptions:{
                    list:props.clients,
                    change:props.changeClient ? props.changeClient.bind(props.obj) : null
                },
                fieldProps: {
                    selectedValue:props.clients != null ? props.getSelectedValueClient(props.obj) : null
                }
            },
            {
                name: "utilisateur",
                component: renderHidden,
                componentOptions: {value:'/utilisateurs/0'}
            },
            {
                name:"client",
                component:renderHidden,
                componentOptions: {value:'/clients/0'}
            },
            {
                name:"config",
                component:renderHidden,
                componentOptions:{value:'/produit_cyber_configs/0'}
            },
            {
                name:"fraisIntermediation",
                label:"Frais d'intermédiation",
                component:renderSelect,
                required:true,
                componentOptions:{
                    options:props.getFraisIntermediation ? props.getFraisIntermediation(props.obj) : null
                }
            }
        ],
        'entreprise' : [
            {
                name:"entrepriseSiret",
                label:"SIRET",
                component:renderInput,
                required:true
            },
            {
                name:"entrepriseFormeJuridique",
                label:"Forme juridique",
                component:renderInput,
            },
            {
                name:"entrepriseProcedureJudiciaire",
                label:"Existence de l'une des procédures judiciaires suivantes ?",
                component:renderMultiCheckbox,
                componentOptions:{
                    options:[
                        {
                            name:"entrepriseProcedureJudiciaireMandat",
                            componentOptions: {
                                checkboxLabel:"Mandat « ad hoc »"
                            },
                            value:"1",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            name:"entrepriseProcedureJudiciaireConciliation",
                            componentOptions: {
                                checkboxLabel: "de conciliation",
                            },
                            value:"1",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            name:"entrepriseProcedureJudiciaireSauvegarde",
                            componentOptions: {
                                checkboxLabel: "de sauvegarde",
                            },
                            value:"1",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            name:"entrepriseProcedureJudiciaireRedressement",
                            componentOptions: {
                                checkboxLabel: "de redressement judiciaire",
                            },
                            value:"1",
                            parse: value => Boolean(Number(value)),
                        },
                    ]
                },
            },
            {
                name:"entrepriseExperience",
                label:"Avez-vous une expérience professionnelle dans le(s) métiers déclarés ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entrepriseDocuments",
                label:"",
                component:renderText,
                componentOptions:{text:"Merci de fournir diplôme, cv et attestation de travail dans la partie document"},
                condition:() => {return true === props.entrepriseExperienceValue}

            },
            {
                name:"entrepriseExperienceDetail",
                label:"Si oui",
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"moins de 3 ans",value:"0"},
                        {label:"entre 3 et 10 ans",value:"3"},
                        {label:"plus de 10 ans",value:"10"},
                    ]
                },
                condition:() => {return true === props.entrepriseExperienceValue}

            },
            {
                name:"entrepriseCompagnon",
                label:"Êtes-vous Compagnon ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"entrepriseCompagnonDetail",
                label:"Si oui, de quelle association",
                component:renderInput,
                condition:() => {return true === props.entrepriseCompagnonValue}
            },
            {
                name:"entrepriseEffectifTotal",
                label:"Effectif total y compris le chef d'entreprise",
                component:renderInput,
                required:true
            },
            {
                name:"entrepriseEffectifTravauxChantier",
                label:"Effectif exécutant les travaux sur chantier",
                component:renderInput,
                required:true
            },
            {
                name:"entrepriseEffectifHorsTravauxChantier",
                label:"Effectif n'exécutant pas les travaux sur chantier",
                component:renderInput,
                required:true
            },
            {
                name:"entrepriseExperienceMoyenne",
                label:"Ancienneté moyenne du personnel dans l'entreprise",
                component:renderRadio,
                required:true,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"0 à 3 ans",value:"0"},
                        {label:"entre 3 et 7 ans",value:"3"},
                        {label:"entre 7 et 10 ans",value:"7"},
                        {label:"plus de 10 ans",value:"10"},
                    ]
                },
            },
            {
                name:"entrepriseCaHt",
                label:"Chiffre d'affaire annuel HT (€)",
                component:renderInput,
                required:true

            },
            {
                name:"entrepriseCaHtSousTraite",
                label:"dont Chiffre d’affaires sous-traité (€)",
                component:renderInput,
                required:true

            },
            {
                name:"entrepriseCaTravauxPrive",
                label:"Travaux privés",
                child: [
                    {
                        name: "entrepriseCaTravauxPriveEuro",
                        fieldProps:{label: "CA en €"},
                        component: renderInput,
                        required:false,
                    },
                    {
                        name: "entrepriseCaTravauxPrivePourcentage",
                        fieldProps:{label: "% de votre CA"},
                        component: renderInput,
                        required:false,
                    }
                ],
            },
            {
                name:"entrepriseCaTravauxPublic",
                label:"Travaux publics",
                required:true,
                validate:(total) => {return parseFloat(total) === 100},
                error: "Le total de toutes les activités doit être égal à 100%",
                reference:[
                    "entrepriseCaTravauxPrivePourcentage",
                    "entrepriseCaTravauxPublicPourcentage",
                ],
                validateOptions: {
                    options: [
                        {name: "entrepriseCaTravauxPrivePourcentage"},
                        {name: "entrepriseCaTravauxPublicPourcentage"},
                    ]
                },
                child: [
                    {
                        name: "entrepriseCaTravauxPublicEuro",
                        fieldProps:{label: "CA en €"},
                        component: renderInput,
                        required:false,
                    },
                    {
                        name: "entrepriseCaTravauxPublicPourcentage",
                        fieldProps:{label: "% de votre CA"},
                        component: renderInput,
                        required:false,
                    }
                ],
            },
        ],
        'typeTravaux' : [
            {
                name:"typeTravauxText1",
                label:<strong>Type</strong>,
                component:renderText,
                required:true,
                validate:(total) => {return parseFloat(total) === 100},
                error: "Le total des types de travaux doit être égal à 100%",
                validateOptions: {
                options: [
                        {name: "typeTravauxParticuliers"},
                        {name: "typeTravauxCollectivitesPublic"},
                        {name: "typeTravauxEntreprises"},
                        {name: "typeTravauxConstructeurs"},
                        {name: "typeTravauxAutres"},
                    ]
                }
            },
            {
                name:"typeTravauxParticuliers",
                label:"Particuliers (%)",
                component:renderInput,
            },
            {
                name:"typeTravauxCollectivitesPublic",
                label:"Collectivités publiques / parapubliques (%)",
                component:renderInput,

            },
            {
                name:"typeTravauxEntreprises",
                label:"Entreprise(s) / Promoteur(s) (%)",
                component:renderInput,

            },
            {
                name:"typeTravauxConstructeurs",
                label:"Constructeur(s) de maison(s) individuelle(s) (%)",
                component:renderInput,

            },
            {
                name:"typeTravauxAutres",
                label:"Autres (%)",
                component:renderInput,

            },
            {
                name:"typeTravauxText2",
                label:<strong>Nature</strong>,
                component:renderText,
                required:true,
                validate:(total) => {return parseFloat(total) === 100},
                error: "Le total des natures de travaux doit être égal à 100%",
                validateOptions: {
                options: [
                    {name: "typeTravauxCaRenovation"},
                    {name: "typeTravauxCaNeuf"},
                ]
            }
            },
            {
                name:"typeTravauxCaRenovation",
                label:"Rénovation / réhabilitation / entretien maintenance (%)",
                component:renderInput,

            },
            {
                name:"typeTravauxCaNeuf",
                label:"Neuf (%)",
                component:renderInput,

            },
            {
                name:"lieuxtravail",
                label:"Où exercez-vous vos travaux ?",
                component:renderMultiCheckbox,
                required:true,
                componentOptions:{
                    options:[
                        {
                            name:"lieuTravauxFrance",
                            componentOptions: {
                                checkboxLabel:"France métropolitaine"
                            },
                            value:"1",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            name:"lieuTravauxDrom",
                            componentOptions: {
                                checkboxLabel: "DROM",
                            },
                            value:"1",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            name:"lieuTravauxUnionEuropeene",
                            componentOptions: {
                                checkboxLabel: "Union Européenne",
                            },
                            value:"1",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            name:"lieuTravauxUnionEuropeeneDetail",
                            component:renderInput,
                            placeholder:"Union Européenne : Précisez",
                            condition:() => {return true === props.lieuTravauxUnionEuropeeneValue},
                            fieldProps: {
                                multiCheckboxChild:true
                            }

                        },
                        {
                            name:"lieuTravauxCom",
                            componentOptions: {
                                checkboxLabel: "COM",
                            },
                            value:"1",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            name:"lieuTravauxAutres",
                            componentOptions: {
                                checkboxLabel: "Autres ",
                            },
                            value:"1",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            name:"lieuTravauxAutresDetail",
                            component:renderInput,
                            placeholder:"Autres : Précisez",
                            condition:() => {return true === props.lieuTravauxAutresValue},
                            fieldProps: {
                                multiCheckboxChild:true
                            }
                        },
                    ]
                },
            },
        ],
        'antecedents' : [
            {
                name:"antecedentAssureSansInterruption",
                label:"Êtes-vous ou avez-vous été assuré sans interruption pour le même risque ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },

            {
                name:"antecedentAssureSansInterruptionDetail",
                label:"Nom de l’assureur",
                component:renderInput,
                condition:() => {return true === props.antecedentAssureSansInterruptionValue},
            },
            {
                name:"antecedentAssureDateEcheance",
                label:"Date d’échéance principale du contrat",
                component:renderInput,
                condition:() => {return true === props.antecedentAssureSansInterruptionValue},
            },
        /*
            {
                name:"antecedentTypeContrat",
                label:"Par quel type de contrat ?",
                required:true,
                parse: value => Number(value),
                component:renderMultiCheckbox,
                componentOptions:{
                    options:[
                        {
                            name:"antecedentTypeContratDecennale",
                            componentOptions: {
                                checkboxLabel:"Responsabilité décennale"
                            },
                            value:"1",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            name:"antecedentTypeContratCivile",
                            componentOptions: {
                                checkboxLabel: "Responsabilité civile",
                            },
                            value:"1",
                            parse: value => Boolean(Number(value)),
                        },
                    ]
                },
            },

         */
            {
                name:"antecedentAssuranceResilie",
                label:"Avez-vous déjà été résilié par un assureur ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"antecedentAssuranceResilieDetail",
                label:"Si oui pour quel motif ?",
                component:renderTextarea,
                condition:() => {return true === props.antecedentAssuranceResilieValue},

            },
            {
                name:"antecedentMiseEnCause",
                label:"Avez-vous fait l'objet d'une ou de plusieurs mises en cause au cours des 5 dernières années ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"antecedentMiseEnCauseDetail",
                label:"Si oui, précisez pour chacune l'année, la nature, et le montant",
                component:renderTextarea,
                condition:() => {return true === props.antecedentMiseEnCauseValue},
            },
            {
                name:"interventionCmi",
                label:"Intervenez-vous sur les chantiers en qualité de « CMI »",
                tooltip:"constructeur de maisons individuelles, avec ou sans fourniture de plans, au sens de la Loi du 19/12/1990",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"interventionContractant",
                label:"Intervenez-vous sur les chantiers en qualité de « Contractant Général »",
                tooltip:"(personne physique ou morale qui s'engage au travers d'un contrat louage d'ouvrage unique, à la réalisation dans son intégralité, d'un ouvrage, assumant tout ou partie de la maîtrise d’œuvre et donnant tout ou partie des travaux en sous-traitance ) ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"interventionObligationAssurance",
                label:"Êtes-vous susceptible d'intervenir sur des ouvrages soumis à l'obligation d'assurance dont le coût prévisionnel déclaré par le maître d'ouvrage (honoraires et travaux) est supérieur à 15 millions d'€ HT ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"interventionMontantSup36",
                label:"Etes-vous susceptible de contracter des marchés dont le montant définitif est supérieur à 3,6 millions d'€ HT, selon la formule choisie, pour vos interventions sur des ouvrages non soumis à l'obligation d'assurance ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
        ],
        'activites1' : [
            {
                name:"activites1Validate",
                component:renderText,
                label:"",
                required:true,
                validate:(total) => {return total <= 100},
                error: "Le total de toutes les activités ne peut pas dépasser 100%",
                validateOptions: {
                    options: [
                        {name: "activiteDemolitionExplosifCa"},
                        {name: "activiteDemolitionSansExplosifCa"},
                        {name: "activiteTerrassementCa"},
                        {name: "activiteForageVerticalCa"},
                        {name: "activiteAmeliorationSolCa"},
                        {name: "activiteVrdCa"},
                        {name: "activiteAmenagementPaysagerCa"},
                        {name: "activiteMontageEchafaudageCa"},
                        {name: "activiteTraitementAmianteCa"},
                        {name: "activiteXylophagesCa"},
                        {name: "activiteAssechementMursCa"},
                    ]
                }

            },
            {
                label:"Démolition sans utilisation d'explosifs",
                tooltip:"démolition ou déconstruction totale ou partielle hors désamiantage / cette activité comprend la maçonnerie, zinguerie, couverture et étanchéité ainsi que V.R.D",
                child: [
                    {
                        name:"activiteDemolitionExplosifCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteDemolitionExplosifSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Démolition avec utilisation d'explosifs",
                tooltip:"démolition ou déconstruction totale ou partielle hors désamiantage / cette activité comprend la maçonnerie, zinguerie, couverture et étanchéité ainsi que V.R.D",
                child: [
                    {
                        name:"activiteDemolitionSansExplosifCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component:renderInput,
                    },
                    {
                        name:"activiteDemolitionSansExplosifSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Terrassement",
                tooltip:"défrichement, remise à niveau des terres, réalisation à ciel ouvert de creusement et de blindage de fouilles provisoire dans des sols. Cette activité comprend les travaux suivants : rabattement de nappes, remblaiements, enrochement non lié et gabions, comblement (sauf des carrières)",
                child: [
                    {
                        name:"activiteTerrassementCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component:renderInput,
                    },
                    {
                        name:"activiteTerrassementSousTraitance",
                        label:"",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Forage vertical",
                tooltip:"réalisation de forages verticaux destinés à une installation géothermique, au puisage d'eau ou à la reconnaissance des sols sans interprétation des résultats. Cette activité comprend les travaux suivants : réalisation de tranchées et mise en place des sondes et des pompes de puisage",
                child: [
                    {
                        name:"activiteForageVerticalCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component:renderInput,
                    },
                    {
                        name:"activiteForageVerticalSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Amélioration des sols",
                tooltip:"Traitement, drainage, renforcement et confortement des sols en place, en vue d'améliorer les caractéristiques physiques et mécaniques, par toutes techniques autres que pieux, micropieux, barrettes, parois moulées, palplanches, parois de soutènement structurellement autonomes. Cette activité comprend les travaux suivants : pose de géotextiles (hors géomembrane) et réalisation de sondages et forages.",
                child: [
                    {
                        name:"activiteAmeliorationSolCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component:renderInput,
                    },
                    {
                        name:"activiteAmeliorationSolSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Voiries Réseaux Divers (V.R.D.)",
                tooltip:"Réalisation de réseaux de canalisations, de tous types de réseaux enterrés ou aériens, de systèmes d'assainissement  autonome, de voiries, de poteaux et clôtures. Cette activité comprend les travaux suivants : réalisation de jardins, d'espaces verts, pose de bordures, de dallages, de pavages, maçonnerie décorative, éclairage et arrosage y compris les raccordements accessoires. Cette activité comprend également les travaux accessoires ou complémentaires de terrassementet de fouilles.",
                child: [
                    {
                        name:"activiteVrdCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component:renderInput,
                    },
                    {
                        name:"activiteVrdSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Aménagements paysagers",
                tooltip:"Réalisation de jardins, d'espaces verts et d'aménagements paysagers. Cette activité comprend les travaux suivants : drainage et collecte des eaux de ruissellement, pose de bordures, de dallages, de pavages, circulations piétonnières ou carrossables, stabilisés ou revêtues, maçonnerie décorative, installation d'équipements tels que que mobilier urbain et jeux, éclairage et arrosage, végétalisation des façades et toitures terrasses. ",
                child: [
                    {
                        name:"activiteAmenagementPaysagerCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component:renderInput,
                    },
                    {
                        name:"activiteAmenagementPaysagerSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Montage d'échafaudage – Étaiement",
                tooltip:"Montage pour le compte de tiers de tous étaiements, échafaudages fixes, suspendus ou élévateurs, employés à la construction ou à l'entretien des immeubles, monuments et édifices ainsi que le montage de structures.",
                child: [
                    {
                        name:"activiteMontageEchafaudageCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component:renderInput,
                    },
                    {
                        name:"activiteMontageEchafaudageSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Traitement d'amiante",
                tooltip:"Retrait et évacuation de l'amiante, de matériaux et produits en contenant ou leur maintien par encapsulage, dans tout ouvrage ou partie d'ouvrage.",
                child: [
                    {
                        name:"activiteTraitementAmianteCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component:renderInput,
                    },
                    {
                        name:"activiteTraitementAmianteSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Traitement contre les insectes xylophages et les champignons",
                tooltip:"Traitement des bois en œuvre et des constructions contres les insectes à larves xylophages, les termites et les champignons dans les charpentes et menuiseries en bois, mais aussi les sols, fondation, murs, cloisons et planchers.",
                child: [
                    {
                        name:"activiteXylophagesCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component:renderInput,
                    },
                    {
                        name:"activiteXylophagesSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Assèchement des murs",
                tooltip:"Traitements des murs contre les remontées d'humidité par capillarité. Cette activité comprend les travaux préparatoires et de traitement proprement dits ainsi que les travaux accessoires ou complémentaires de remplacement des parements.",
                child: [
                    {
                        name:"activiteAssechementMursCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component:renderInput,
                    },
                    {
                        name:"activiteAssechementMursSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },

        ],
        'activites2' : [
            {
                name:"activites2Validate",
                component:renderText,
                label:"",
                required:true,
                validate:(total) => {return total <= 100},
                error: "Le total de toutes les activités ne peut pas dépasser 100%",
                validateOptions: {
                    options: [
                        {name: "activiteDemolitionExplosifCa"},
                        {name: "activiteDemolitionSansExplosifCa"},
                        {name: "activiteTerrassementCa"},
                        {name: "activiteForageVerticalCa"},
                        {name: "activiteAmeliorationSolCa"},
                        {name: "activiteVrdCa"},
                        {name: "activiteAmenagementPaysagerCa"},
                        {name: "activiteMontageEchafaudageCa"},
                        {name: "activiteTraitementAmianteCa"},
                        {name: "activiteXylophagesCa"},
                        {name: "activiteAssechementMursCa"},
                        {name: "activiteFondationsSpecialesCa"},
                        {name: "activiteMaconnerieBetonArmeCa"},
                        {name: "activiteBetonPreContraintCa"},
                        {name: "activiteCharpenteStructureBoisCa"},
                        {name: "activiteOssatureBoisCa"},
                        {name: "activiteCharpenteStructureMetalliqueCa"},
                    ]
                }

            },
            {
                label: "Fondations et parois spéciales",
                tooltip: "Réalisation, y compris dans le cadre de travaux de reprises en sous-œuvre, de pieux, micropieux, barrettes, parois moulées, palplanches, parois de soutènement structurellement autonomes. Cette activité comprend les travaux de rabattement de nappes, de sondages, les forages, et les tirants d'ancrage.",
                child : [
                    {
                        name: "activiteFondationsSpecialesCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name: "activiteFondationsSpecialesSousTraitance",
                        label: "",
                        placeholder: "% de sous traitance",
                        component: renderInput,
                    },
                ],
            },
            {
                label:"Maçonnerie et béton armé",
                tooltip:<div>
            Réalisation de maçonnerie en béton armé préfabriqué ou non, en béton 	précontraint préfabriqué (hors précontrainte in situ), en blocs agglomérés de mortier 	ou de béton cellulaire, en pierre naturelles ou briques, ceci tant en infrastructure qu'en superstructure, hors parois de soutènement structurellement autonomes soutenant les terres sur une hauteur supérieur de 2,5 mètres, par toutes les 	techniques de maçonneries de coulage, hourdage (hors revêtement mural agrafé, attaché ou collé).<br/>
    <br/>
    Cette activité comprend les travaux de :
        <ul>
    <li>enduits à base de liants hydrauliques ou de synthèse</li>
    <li>ravalement en maçonnerie</li>
    <li>briquetage, pavage</li>
    <li>dallage, chape</li>
    <li>fondations superficielles (semelles filantes, isolées, radiers et puits courts).</li>
    </ul>
    <br/>
    Et la réalisation des travaux maçonnés suivants liés à la fumisterie (hors four et cheminée industriels) :
<ul>
    <li>cheminées, âtres et foyers ouverts</li>
    <li>conduits de fumées et de ventilation</li>
    <li>ravalement et réfection des souches</li>
    </ul>
    <br/>
    Et la réalisation des travaux maçonnés suivants liés à la fumisterie :
        <ul>
    <li>cheminées, âtres et foyers couverts</li>
    <li>conduits de fumées et de ventilation</li>
    <li>ravalement et réfection des souches</li>
    </ul>
    <br/>
    Ainsi que les travaux accessoires ou complémentaires de :
        <ul>
    <li>terrassement, drainage, et canalisations enterrés</li>
    <li>revêtement d'imperméabilisation des parois enterrés (hors cuvelage)</li>
    <li>pose de matériaux contribuant à l'isolation intérieure</li>
    <li>pose de renforts bois ou métal nécessités par l'ouverture de baies et les reprises en sous-œuvre</li>
    <li>démolition</li>
    <li>V.R.D.</li>
    <li>Pose d'huisseries</li>
    <li>pose d'éléments simple de charpente (pannes, chevrons..), à l'exclusion de toute charpente préfabriquée dans l'industrie</li>
    <li>plâtrerie</li>
    <li>carrelage, faïence et revêtement en matériaux durs à base minérale</li>
    <li>calfeutrements de joints.</li>
    </ul>
    </div>,
                child : [
                    {
                        name:"activiteMaconnerieBetonArmeCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component:renderInput,
                    },
                    {
                        name:"activiteMaconnerieBetonArmeSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ],
            },
            {
                label:"Béton précontraint in situ",
                tooltip:"Mise en œuvre de béton armé précontraint mis en tension sur chantier",
                child : [
                    {
                        name:"activiteBetonPreContraintCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component:renderInput,
                    },
                    {
                        name:"activiteBetonPreContraintSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ],
            },
            {
                label:"Charpente et structure en bois",
                    tooltip:<div>
            Réalisation de charpentes et structures à base de vois, à l'exclusion des façades-rideaux.<br/>
            <br/>
            Cette activité comprend les travaux accessoires ou complémentaires de :
                <ul>
            <li>couverture, bardage, châssis divers, lorsque ceux-ci sont fixés directement à l'ossature</li>
            <li>supports de couverture ou d'étanchéité</li>
            <li>plafonds, faux plafonds, cloisons en bois et autres matériaux</li>
            <li>planchers et parquets</li>
            <li>isolation thermique et acoustique liées à l'ossature ou à la charpente</li>
            <li>traitement préventif et curatif des bois</li>
            <li>mise en œuvre de matériaux ou tous éléments métalliques concourant à l'édification, au renforcement ou à la stabilité des charpentes et escaliers</li>
            </ul>
            </div>,
                child : [
                    {
                        name:"activiteCharpenteStructureBoisCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component:renderInput,
                    },
                    {
                        name:"activiteCharpenteStructureBoisSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ],
            },
            {
                label:"Constructions à ossature bois",
                tooltip:<div>
            Réalisation de l'ensemble des éléments à base de bois, à l'exclusion des fondations, des structures maçonnées, de la couverture et de l'étanchéité des toitures terrasses.<br/>
            <br/>
            Cette activité comprend la réalisation de parois, y compris les revêtements de 	façades limités aux bardages, vêtures et vêtages. Ainsi que les travaux accessoires ou complémentaires de :
                <ul>
            <li>plafonds, faux plafonds, cloisons en bois et autres matériaux</li>
            <li>menuiseries intérieure, et extérieure</li>
            <li>isolation thermique et acoustique</li>
            <li>traitement préventif et curatif des bois</li>
            <li>mise en œuvre de matériaux ou de tous éléments métalliques concourant à l'édification, au renforcement ou à la stabilité des charpentes et escaliers</li>
            </ul>
            </div>,
                child : [
                    {
                        name:"activiteOssatureBoisCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component:renderInput,
                    },
                    {
                        name:"activiteOssatureBoisSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ],
            },
            {
                label:"Charpente et structure métalliques",
                    tooltip:<div>
            Réalisation de charpentes, structures et ossatures métalliques, à l'exclusion des façades rideaux.<br/>
            <br/>
            Cette activité comprend les travaux accessoires ou complémentaires de :
                <ul>
            <li>couverture, bardage, châssis divers, lorsque ceux-ci sont métalliques et directement fixés à l'ossature</li>
            <li>supports de couverture ou d'étanchéité</li>
            <li>protection et traitement contre la corrosion</li>
            <li>travaux en sous-œuvre par structure métallique</li>
            <li>isolation thermique et acoustique liées à l'ossature ou à la charpente</li>
            </ul>
            </div>,
                child : [
                    {
                        name:"activiteCharpenteStructureMetalliqueCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component:renderInput,
                    },
                    {
                        name:"activiteCharpenteStructureMetalliqueSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ],
            },
        ],
        'activites3' : [
        {
            name:"activites3Validate",
            component:renderText,
            label:"",
            required:true,
            validate:(total) => {return total <= 100},
            error: "Le total de toutes les activités ne peut pas dépasser 100%",
            validateOptions: {
                options: [
                    {name: "activiteDemolitionExplosifCa"},
                    {name: "activiteDemolitionSansExplosifCa"},
                    {name: "activiteTerrassementCa"},
                    {name: "activiteForageVerticalCa"},
                    {name: "activiteAmeliorationSolCa"},
                    {name: "activiteVrdCa"},
                    {name: "activiteAmenagementPaysagerCa"},
                    {name: "activiteMontageEchafaudageCa"},
                    {name: "activiteTraitementAmianteCa"},
                    {name: "activiteXylophagesCa"},
                    {name: "activiteAssechementMursCa"},
                    {name: "activiteFondationsSpecialesCa"},
                    {name: "activiteMaconnerieBetonArmeCa"},
                    {name: "activiteBetonPreContraintCa"},
                    {name: "activiteCharpenteStructureBoisCa"},
                    {name: "activiteOssatureBoisCa"},
                    {name: "activiteCharpenteStructureMetalliqueCa"},
                    {name: "activiteCouvertureCa"},
                    {name: "activiteEtancheiteToitureCa"},
                    {name: "activiteEtancheiteCuvelageCa"},
                    {name: "activiteRevetementEnduitCa"},
                    {name: "activiteIteCa"},
                    {name: "activiteBardageCa"},
                    {name: "activiteFacadeRideauxCa"},
                    {name: "activiteStructureTextileCa"},
                    {name: "activiteMenuiserieExterieurCa"},
                    {name: "activiteVerandaCa"},
                ]
            }
        },
            {
                label:"Couverture",
                tooltip:<div>
Réalisation de couverture en tous matériaux, y compris par bardeau bitumé (hors couverture textiles et étanchéités de toitures terrasses).<br/>
<br/>
	Cette activité comprend les travaux de :
<ul>
<li>zinguerie et éléments accessoires en tous matériaux</li>
<li>bardages verticaux utilisant des techniques de couverture</li>
<li>pose de châssis de toit (y compris exutoires en toiture)</li>
<li>isolation et écran sous toiture</li>
<li>ravalement et réfection des souches hors combles</li>
<li>installation de paratonnerre</li>
<li>pose de capteurs solaires, hors réalisation de l'installation électrique ou thermique</li>
</ul>
<br/>
Ainsi que les travaux accessoires ou complémentaires de :
<ul>
<li>raccord d'étanchéité</li>
<li>vêtage et vêture</li>
<li>éléments simples de charpente (pannes, chevrons)</li>
</ul>
</div>,
                child: [
                    {
                        name:"activiteCouvertureCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteCouvertureSousTraitance",
                        label:"",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Étanchéité de toiture, terrasse et plancher intérieur",
                tooltip:<div>
            Réalisation d'étanchéité de toiture, terrasse et plancher intérieur par mise en œuvre 	de matériaux bitumineux ou de synthèse sur des supports horizontaux ou inclinés.
            <br/>
            Cette activité comprend les travaux de :
                <ul>
            <li>étanchéité de paroi enterrée,</li>
            <li>zinguerie et éléments accessoires en tous matériaux</li>
            <li>châssis de toit (y compris exutoires en toiture)</li>
            <li>pose de membranes d'étanchéité photovoltaïques, hors réalisation de l'installation électrique</li>
            </ul>
            <br/>
            Cette activité comprend les travaux accessoires ou complémentaires de mise en œuvre de matériaux d'isolation et tous travaux de protection du revêtement étanche, y compris par complexe de végétalisation extensive et semi intensive.
            </div>,
                child: [
                    {
                        name:"activiteEtancheiteToitureCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteEtancheiteToitureSousTraitance",
                        label:"",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Étanchéité et imperméabilisation de cuvelages, réservoirs et piscines",
                tooltip:"Réalisation de travaux d'étanchéité et d'imperméabilisation de cuvelages d'ouvrages en sous-pression hydrostatique, de réservoirs et piscines en béton armé et béton précontraint.",
                child: [
                    {
                        name:"activiteEtancheiteCuvelageCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteEtancheiteCuvelageSousTraitance",
                        label:"",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Revêtements de façades par enduits, avec ou sans fonction d'imperméabilité \tet/ou d'étanchéité, ravalements",
                tooltip:<div>
            Réalisation de revêtements de façades par enduits à base de liants hydrauliques ou organiques.
            <br/>
            Cette activité comprend les travaux de :
                <ul>
            <li>nettoyage, sablage, grenaillage</li>
            <li>peinture de façade, y compris revêtements de peinture épais ou semi-épais ou minéral épais (RPE, RSE, RME)</li>
            <li>protection et réfection des façades par revêtement d'imperméabilité à base de polymères de classe I1, I2,I3, et systèmes d'étanchéité à base de polymère de classe I4</li>
            <li>étanchéité des sols d'ouvrage lorsqu'ils dominent des parties non closes du bâtiment</li>
            <li>calfeutrement de joints de construction aux fins d'étanchéité à l'eau et à l'air</li>
            </ul>
            <br/>
            Cette activité comprend les travaux accessoires ou complémentaires de zinguerie et éléments accessoires en tous matériaux.
                N'est pas comprise la réalisation d'isolation thermique par l'extérieur
            </div>,
                child: [
                    {
                        name:"activiteRevetementEnduitCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteRevetementEnduitSousTraitance",
                        label:"",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Isolation thermique par l'extérieur",
                tooltip:"Réalisation de travaux d'isolation thermique par l'extérieur revêtue par un enduit à base de liants hydrauliques ou organiques directement appliqués sur un isolant ou un parement collé. Cette activité comprend les travaux accessoires ou complémentaires de zinguerie et les éléments de finition de l'isolation par l'extérieur.",
                child: [
                    {
                        name:"activiteIteCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteIteSousTraitance",
                        label:"",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Bardages de façade",
                tooltip:<div>
            Réalisation de bardages par mise en œuvre de clins ou de panneaux, avec ou sans	incorporation d’isolant, à l’exclusion des façades-rideaux, façades-semi-rideaux et façades-panneaux.
            <br/>
            Cette activité comprend les travaux de vêture et vêtage. Ainsi que les travaux accessoires ou complémentaires de :
                <ul>
            <li>mise en œuvre des matériaux ou produits contribuant à l'isolation thermique,</li>
            <li>zinguerie et éléments accessoires en tous matériaux.</li>
            </ul>
            </div>,
                child: [
                    {
                        name:"activiteBardageCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteBardageSousTraitance",
                        label:"",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Façades-Rideaux",
                tooltip:"Réalisation de façades-rideaux, façades-semi-rideaux, façades-panneaux, quel que soit le matériau et la technique utilisés, y compris la mise en place des éléments de remplissage. Cette activité comprend la réalisation de verrières.",
                child: [
                    {
                        name:"activiteFacadeRideauxCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteFacadeRideauxSousTraitance",
                        label:"",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Structures et couvertures textiles",
                tooltip:"Réalisation de superstructures et couvertures à base de membranes textiles tendues ou gonflées. Cette activité comprend la réalisation des structures complémentaires en support bois, métal ou autres matières ainsi que tous les éléments d’évacuation d’eaux nécessaires.",
                child: [
                    {
                        name:"activiteStructureTextileCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteStructureTextileSousTraitance",
                        label:"",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Menuiseries extérieures",
                tooltip:<div>
            Réalisation de menuiseries extérieures, y compris leur revêtement de protection, quel que soit le matériau utilisé, à l’exclusion des verrières, des vérandas, des façades-rideaux, des façades-semi-rideaux et des façades-panneaux.
            <br/>
            Cette activité comprend les travaux de :
                <ul>
            <li>mise en œuvre des éléments de remplissage, y compris les produits en résine, en plastique ou en polycarbonate,</li>
            <li>calfeutrement sur chantier des joints de menuiserie,</li>
            <li>mise en œuvre des fermetures et des protections solaires intégrées ou non,</li>
            <li>habillage et liaisons intérieures et extérieures,</li>
            <li>escaliers et garde-corps,</li>
            <li>terrasses et platelages extérieurs en bois naturel ou composite, à l’exclusion de la réalisation du support de maçonnerie, de système d’étanchéité de toiture-terrasse et d’éléments de charpente,</li>
            <li>installation de stands.</li>
            </ul>
            <br/>
            Ainsi que les travaux accessoires ou complémentaires de :
                <ul>
            <li>vitrerie et miroiterie,</li>
            <li>alimentations, commandes et branchements électriques,</li>
            <li>traitement préventif et curatif des bois,</li>
            <li>zinguerie et éléments accessoires en tous matériaux</li>
            </ul>
            </div>,
                child: [
                    {
                        name:"activiteMenuiserieExterieurCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteMenuiserieExterieurSousTraitance",
                        label:"",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Verrières-Vérandas",
                tooltip:"Réalisation de verrières et vérandas en tous matériaux, y compris la couverture utilisant des éléments de remplissage en résine, en plastique, en verre, en polycarbonate et en panneaux sandwichs, à l’exclusion des fondations, des structures maçonnées et des capteurs solaires.",
                child: [
                    {
                        name:"activiteVerandaCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteVerandaSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
        ],
        'activites4' : [
        {
            name:"activites3Validate",
            component:renderText,
            label:"",
            required:true,
            validate:(total) => {return total <= 100},
            error: "Le total de toutes les activités ne peut pas dépasser 100%",
            validateOptions: {
                options: [
                    {name: "activiteDemolitionExplosifCa"},
                    {name: "activiteDemolitionSansExplosifCa"},
                    {name: "activiteTerrassementCa"},
                    {name: "activiteForageVerticalCa"},
                    {name: "activiteAmeliorationSolCa"},
                    {name: "activiteVrdCa"},
                    {name: "activiteAmenagementPaysagerCa"},
                    {name: "activiteMontageEchafaudageCa"},
                    {name: "activiteTraitementAmianteCa"},
                    {name: "activiteXylophagesCa"},
                    {name: "activiteAssechementMursCa"},
                    {name: "activiteFondationsSpecialesCa"},
                    {name: "activiteMaconnerieBetonArmeCa"},
                    {name: "activiteBetonPreContraintCa"},
                    {name: "activiteCharpenteStructureBoisCa"},
                    {name: "activiteOssatureBoisCa"},
                    {name: "activiteCharpenteStructureMetalliqueCa"},
                    {name: "activiteCouvertureCa"},
                    {name: "activiteEtancheiteToitureCa"},
                    {name: "activiteEtancheiteCuvelageCa"},
                    {name: "activiteRevetementEnduitCa"},
                    {name: "activiteIteCa"},
                    {name: "activiteBardageCa"},
                    {name: "activiteFacadeRideauxCa"},
                    {name: "activiteStructureTextileCa"},
                    {name: "activiteMenuiserieExterieurCa"},
                    {name: "activiteVerandaCa"},
                    {name: "activiteMenuiserieInterieurCa"},
                    {name: "activiteSdbCa"},
                    {name: "activiteCuisineCa"},
                    {name: "activitePlatretieCa"},
                    {name: "activiteSerrurerieCa"},
                    {name: "activiteVitrerieCa"},
                    {name: "activitePeintureCa"},
                    {name: "activiteRevetementSoupleCa"},
                    {name: "activiteRevetementDurCa"},
                    {name: "activiteRevetementVerticalDurCa"},
                    {name: "activiteItiCa"},
                    {name: "activiteIsolationFrigorifiqueCa"},
                ]
            }
        },
            {
                label:"Menuiseries intérieures",
                tooltip:<div>
            Réalisation de menuiseries intérieures y compris leur revêtement de protection, quel que soit le matériau utilisé, à l’exclusion des éléments structurels ou porteurs.
    <br/>
    Cette activité comprend les travaux de :
        <ul>
    <li>pose de portes pare-flammes et coupe-feu, faux plafonds, cloisons, planchers y compris surélevés, parquets y compris pour les sols sportifs, escaliers et garde-corps,</li>
    <li>installation de stands, agencements et mobiliers,</li>
    <li>mise en œuvre des éléments de remplissage y compris les produits en résine, en plastique ou en polycarbonate,</li>
    <li>habillage et liaisons intérieures et extérieures.  </li>
    </ul>
    <br/>
    Ainsi que les travaux accessoires ou complémentaires de :
        <ul>
    <li>vitrerie et miroiterie,</li>
    <li>mise en œuvre des matériaux ou produits contribuant à l’isolation thermique, acoustique et à la sécurité incendie,</li>
    <li>traitement préventif et curatif des bois.</li>
    </ul>
    </div>,
                child: [
                    {
                        name:"activiteMenuiserieInterieurCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteMenuiserieInterieurSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Aménagement de salles de bains domestiques",
                tooltip:<div>
            Réalisation d’aménagement des salles de bains domestiques
            <br/>
            Cette activité comprend les travaux de :
                <ul>
            <li>plomberie,</li>
            <li>électricité,</li>
            <li>ventilation,</li>
            <li>plâtrerie,</li>
            <li>menuiserie intérieure,</li>
            <li>miroiterie,</li>
            <li>revêtement de sol et mural,</li>
            <li>peinture intérieure.</li>
            </ul>
            <br/>
            Ne sont pas compris les travaux sur les éléments structurels ou porteurs.

            </div>
                ,
                child: [
                    {
                        name:"activiteSdbCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteSdbSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Aménagement de cuisines domestiques",
                tooltip:<div>
            Réalisation d’aménagement de cuisines domestiques.
            <br/>
            Cette activité comprend les travaux de :
                <ul>
            <li>plomberie,</li>
            <li>électricité,</li>
            <li>ventilation,</li>
            <li>plâtrerie,</li>
            <li>menuiserie intérieure,</li>
            <li>revêtement de sol et mural,</li>
            <li>peinture intérieure.</li>
            </ul>
            <br/>
            Ne sont pas compris les travaux sur les éléments structurels ou porteurs.

            </div>,
                child: [
                    {
                        name:"activiteCuisineCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteCuisineSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Plâtrerie – Staff – Stuc – Gypserie",
                tooltip:<div>
            Réalisation d’aménagementRéalisation de plâtrerie, cloisonnement et faux plafonds à base de plâtre, en 	intérieur, y compris la mise en œuvre des matériaux ou produits contribuant à la sécurité incendie, de cuisines domestiques.
            <br/>
            Cette activité comprend les travaux accessoires ou complémentaires de :
                <ul>
            <li>menuiseries intégrées aux cloisons,</li>
            <li>doublage thermique ou acoustique intérieur.</li>
            </ul>
            </div>,
                child: [
                    {
                        name:"activitePlatretieCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activitePlatretieSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Serrurerie – Métallerie",
                tooltip:<div>
            Réalisation de serrurerie, ferronnerie et métallerie, à l’exclusion des charpentes métalliques et des vérandas.
            <br/>
            Cette activité comprend les travaux de planchers, escaliers, garde-corps, fermetures et protections, en métal.
                Ainsi que les travaux accessoires ou complémentaires de :
                <ul>
            <li>protection contre les risques de corrosion,</li>
            <li>installation et raccordement des alimentations électriques et automatismes nécessaires au fonctionnement des équipements,</li>
            <li>mise en œuvre des éléments de remplissage, y compris les produits en résine, en plastique ou en polycarbonate,</li>
            <li>mise en œuvre des matériaux ou produits contribuant à l’isolation thermique, acoustique et à la sécurité incendie</li>
            </ul>
            </div>,
                child: [
                    {
                        name:"activiteSerrurerieCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteSerrurerieSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Vitrerie – Miroiterie",
                tooltip:"Réalisation de tous travaux à partir de produits verriers, ainsi que les produits en résine, en plastique ou en polycarbonate, à l’exclusion des techniques de vitrage extérieur collé (VEC) ou de vitrage extérieur attaché (VEA). Cette activité comprend les travaux accessoires ou complémentaires d'encadrement des éléments verriers.",
                child: [
                    {
                        name:"activiteVitrerieCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteVitrerieSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Peinture",
                tooltip:<div>
            Réalisation de peinture, y compris les revêtements peinture épais, semi-épais ou minéral épais (RPE, RSE, RME), de ravalement en peinture, de pose de revêtements souples, textiles, plastiques ou assimilés sur surfaces horizontales et verticales, y compris les plafonds tendus.
            <br/>
            Cette activité comprend les travaux de :
                <ul>
            <li>nettoyage, sablage, grenaillage,</li>
            <li>enduits décoratifs intérieurs.</li>
            </ul>
            <br/>
            Ainsi que les travaux accessoires ou complémentaires de :
                <ul>
            <li>menuiseries intérieures,</li>
            <li>revêtements en faïence,</li>
            <li>isolation acoustique et thermique par l'intérieur.</li>
            </ul>
            <br/>
            Ne sont pas compris les travaux d’imperméabilisation, d’étanchéité et les sols coulés.
            </div>
                ,
                child: [
                    {
                        name:"activitePeintureCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activitePeintureSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Revêtement intérieur de surfaces en matériaux souples et parquets",
                tooltip:"Réalisation en intérieur de parquets collés ou flottants, de revêtements souples, y compris pour les sols sportifs, avec ou sans support textile, en tous matériaux plastiques, caoutchouc et produits similaires, ou en bois (feuilles de placage sur kraft ou sur textile, placages collés ou contreplaqués minces collés) ou tout autre matériau relevant des mêmes techniques de mise en œuvre.  Ne sont pas compris les travaux de sols coulés.",
                child: [
                    {
                        name:"activiteRevetementSoupleCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteRevetementSoupleSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Revêtement de surfaces en matériaux durs – Chapes et sols coulés",
                tooltip:<div>
            Réalisation de revêtement de surfaces en carrelage ou en tout autre produit en 	matériaux durs, naturels ou artificiels (hors agrafages, attaches), chapes, sols coulés à base de résine, sols coulés à base hydraulique (béton ciré).
            <br/>
            Cette activité comprend les travaux accessoires ou complémentaires de :
                <ul>
            <li>pose de résilient acoustique ou d’isolation sous chape ou formes flottantes,</li>
            <li>étanchéité, sous carrelage non immergé, à l’intérieur de locaux,</li>
            <li>étanchéité, sous carrelage, lorsqu’elle domine une partie non close du bâtiment, protection par imperméabilisation des supports de carrelage et faïence.</li>
            </ul>
            <br/>
            Ne sont pas compris les travaux d’étanchéité sous carrelage de toiture-terrasse, de 	piscine ou de cuvelage.
            </div>,
                child: [
                    {
                        name:"activiteRevetementDurCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteRevetementDurSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Revêtement vertical en matériaux durs agrafés ou attachés",
                tooltip:"Réalisation de revêtement vertical en matériaux durs, naturels ou artificiels utilisant des techniques d’agrafages ou d’attaches. Cette activité comprend les travaux accessoires ou complémentaires d’isolation thermique par l’extérieur.",
                child: [
                    {
                        name:"activiteRevetementVerticalDurCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteRevetementVerticalDurSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Isolation intérieure thermique – Acoustique",
                tooltip:<div>
            Réalisation de travaux d’isolation thermique ou acoustique intérieure.
            <br/>
            Cette activité comprend les travaux de :
                <ul>
            <li>isolation thermique de murs, parois, sols, plafonds et toitures de tous ouvrages,</li>
            <li>isolation et traitement acoustique,</li>
            <li>calorifugeage des circuits, tuyauteries et appareils.</li>
            </ul>
            <br/>
            Ainsi que les travaux accessoires ou complémentaires de revêtements et 	menuiseries intérieurs
            </div>,
                child: [
                    {
                        name:"activiteItiCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteItiSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Isolation frigorifique",
                tooltip:<div>
            Réalisation de travaux d’isolation frigorifique.
            <br/>
            Cette activité comprend les travaux de :
                <ul>
            <li>isolation frigorifique des locaux de toute capacité et fonctionnant à toutes températures,</li>
            <li>calorifugeage des circuits, tuyauteries et appareils.</li>
            </ul>
            <br/>
            Ainsi que les travaux accessoires ou complémentaires de revêtements et 	menuiseries.
            </div>,
                child: [
                    {
                        name:"activiteIsolationFrigorifiqueCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteIsolationFrigorifiqueSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
        ],
        'activites5' : [
        {
            name:"activites3Validate",
            component:renderText,
            label:"",
            required:true,
            validate:(total) => {return total === 100},
            error: "Le total de toutes les activités doit être égal à 100%",
            validateOptions: {
                options: [
                    {name: "activiteDemolitionExplosifCa"},
                    {name: "activiteDemolitionSansExplosifCa"},
                    {name: "activiteTerrassementCa"},
                    {name: "activiteForageVerticalCa"},
                    {name: "activiteAmeliorationSolCa"},
                    {name: "activiteVrdCa"},
                    {name: "activiteAmenagementPaysagerCa"},
                    {name: "activiteMontageEchafaudageCa"},
                    {name: "activiteTraitementAmianteCa"},
                    {name: "activiteXylophagesCa"},
                    {name: "activiteAssechementMursCa"},
                    {name: "activiteFondationsSpecialesCa"},
                    {name: "activiteMaconnerieBetonArmeCa"},
                    {name: "activiteBetonPreContraintCa"},
                    {name: "activiteCharpenteStructureBoisCa"},
                    {name: "activiteOssatureBoisCa"},
                    {name: "activiteCharpenteStructureMetalliqueCa"},
                    {name: "activiteCouvertureCa"},
                    {name: "activiteEtancheiteToitureCa"},
                    {name: "activiteEtancheiteCuvelageCa"},
                    {name: "activiteRevetementEnduitCa"},
                    {name: "activiteIteCa"},
                    {name: "activiteBardageCa"},
                    {name: "activiteFacadeRideauxCa"},
                    {name: "activiteStructureTextileCa"},
                    {name: "activiteMenuiserieExterieurCa"},
                    {name: "activiteVerandaCa"},
                    {name: "activiteMenuiserieInterieurCa"},
                    {name: "activiteSdbCa"},
                    {name: "activiteCuisineCa"},
                    {name: "activitePlatretieCa"},
                    {name: "activiteSerrurerieCa"},
                    {name: "activiteVitrerieCa"},
                    {name: "activitePeintureCa"},
                    {name: "activiteRevetementSoupleCa"},
                    {name: "activiteRevetementDurCa"},
                    {name: "activiteRevetementVerticalDurCa"},
                    {name: "activiteItiCa"},
                    {name: "activiteIsolationFrigorifiqueCa"},
                    {name: "activitePlomberieCa"},
                    {name: "activiteChauffageCa"},
                    {name: "activiteFumisterieCa"},
                    {name: "activiteAeroliqueCa"},
                    {name: "activiteElectriciteCa"},
                    {name: "activiteAscenceurCa"},
                    {name: "activitePiscineCa"},
                    {name: "activiteGeothermieCa"},
                    {name: "activitePhotovoltaiqueCa"},
                    {name: "activiteEolienCa"},
                    {name: "activiteFourIndustrielCa"},
                ]
            }
        },
            {
                label:"Plomberie",
                tooltip:<div>
            Réalisation d’installations ou de pose de :

        <ul>
    <li>production, distribution, évacuation d’eau chaude et froide sanitaires,</li>
    <li>appareils sanitaires,</li>
    <li>réseaux de distribution de fluide ou de gaz,</li>
    <li>réseaux de distribution de chauffage par eau, y compris les radiateurs,</li>
    <li>gouttières, descentes d’eaux pluviales et solins.</li>
    </ul>
    <br/>
    Cette activité comprend les travaux accessoires ou complémentaires de :
        <ul>
    <li>platelage, réalisation de socle et support d’appareils et équipements,</li>
    <li>tranchées, trous de passage, saignées et raccords,</li>
    <li>calorifugeage, isolation thermique et acoustique,</li>
    <li>raccordement électrique du matériel.</li>
    </ul>
    <br/>
    Ne sont pas comprises :
        <ul>
    <li>la réalisation d’installations d’appareils de production de chauffage,</li>
    <li>la réalisation d’installations de géothermie,</li>
    <li>la pose de capteurs solaires intégrés.</li>
    </ul>
    </div>
        ,
                child: [
                    {
                        name:"activitePlomberieCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activitePlomberieSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Chauffages et installations thermiques",
                tooltip:<div>
            Réalisation d’installations de :
                <ul>
            <li>production, distribution, évacuation de chauffage et/ou de rafraîchissement, y compris les pompes à chaleur et les poêles,</li>
            <li>production et distribution d’eau chaude sanitaire,</li>
            <li>ventilation mécanique contrôlée (V.M.C).</li>
            </ul>
            <br/>
            Cette activité comprend les travaux accessoires ou complémentaires de :
                <ul>
            <li>platelage, réalisation de socle et support d’appareils et équipements,</li>
            <li>tranchées, trous de passage, saignées et raccords,</li>
            <li>calorifugeage, isolation thermique et acoustique,</li>
            <li>raccordement électrique du matériel, installation de régulation, de téléalarme, de télésurveillance, de télégestion et de gestion technique centralisée des installations concernées.</li>
            </ul>
            <br/>
            Ne sont pas comprises :
                <ul>
            <li>la réalisation du système de captage géothermique,</li>
            <li>la pose de capteurs solaires intégrés,</li>
            <li>la réalisation d’inserts et cheminées</li>
            </ul>
            </div>,
                child: [
                    {
                        name:"activiteChauffageCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteChauffageSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Fumisterie",
                tooltip:<div>
                Réalisation d’installations de poêles, inserts, cheminées et de systèmes d'évacuation des produits de combustion. Cette activité comprend les travaux de ramonage. Ainsi que les travaux accessoires ou complémentaires de :
            <ul>
            <li>raccords d'enduits divers,</li>
            <li>calorifugeage des conduits,</li>
            <li>revêtements en carreaux et panneaux de faïence,</li>
            <li>réfection des souches,</li>
            <li>réalisation de socle et support d’appareils et équipements,</li>
            <li>pose sur le sol de carreaux réfractaires et céramiques.</li>
            </ul>
            <br/>
            N’est pas comprise la réalisation de fours et cheminées industriels
            </div>
                ,
                child: [
                    {
                        name:"activiteFumisterieCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteFumisterieSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Installations d'aéraulique, de climatisation et de conditionnement d'air",
                tooltip:<div>
            Réalisation d’installations d’aéraulique (production, distribution, évacuation) assurant les fonctions de renouvellement et traitement de l’air, de refroidissement, de climatisation et de chauffage.<br/>
            <br/>
            Cette activité comprend les travaux accessoires ou complémentaires de :
                <ul>
            <li>platelage, réalisation de socle et support d’appareils et équipements,</li>
            <li>tranchées, trous de passage, saignées et raccords,</li>
            <li>calorifugeage, isolation thermique et acoustique,</li>
            <li>raccordement électrique du matériel,</li>
            <li>installation de régulation, de téléalarme, de télésurveillance, de télégestion et de gestion technique centralisée des installations concernées</li>
            </ul>
            <br/>
            Ne sont pas comprises :
                <ul>
            <li>la réalisation du système de captage géothermique,</li>
            <li>la pose de capteurs solaires intégrés.</li>
            </ul>
            </div>,
                child: [
                    {
                        name:"activiteAeroliqueCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteAeroliqueSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Électricité – Télécommunications",
                tooltip:<div>
            Réalisation de réseaux de distribution de courant électrique, de chauffage électrique, ainsi que le raccord et l’installation d’appareils électriques, hors pose de capteurs solaires.
            <br/>
            Cette activité comprend :
                <ul>
            <li>l’installation de ventilation mécanique contrôlée (V.M.C.),</li>
            <li>l’installation de groupes électrogènes,</li>
            <li>la pose de dispositifs de protection contre les effets de la foudre,</li>
            <li>la réalisation de réseaux de télécommunication et de transmission de l’information, l’installation de système domotique et immotique, y compris la gestion technique centralisée (GTC) et la gestion technique du bâtiment (GTB).  Ainsi que les travaux accessoires ou complémentaires de tranchées, trous de passage, saignées et raccords</li>
            </ul>
            </div>
                ,
                child: [
                    {
                        name:"activiteElectriciteCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteElectriciteSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Ascenseurs",
                tooltip:"Réalisation d’ascenseur, de monte-charge, monte-personne, escalier mécanique et trottoir roulant, y compris les organes et équipements nécessaires à leur fonctionnement. Ne sont pas comprises toutes les modifications de la structure porteuse du bâtiment.",
                child: [
                    {
                        name:"activiteAscenceurCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteAscenceurSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Piscines",
                tooltip:<div>
            Réalisation de piscines y compris leurs organes, équipements et margelles.
            <br/>
            Ne sont pas comprises :
                <ul>
            <li>la réalisation du système de captage géothermique,</li>
            <li>la pose de capteurs solaires intégrés</li>
            </ul>
            </div>,
                child: [
                    {
                        name:"activitePiscineCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activitePiscineSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Géothermie",
                tooltip:<div>
            Réalisation d’installations de chauffage, de rafraîchissement et de production d’eau chaude sanitaire par les techniques utilisant l’énergie géothermique, y compris la réalisation du captage.
            <br/>
            Cette activité comprend les travaux accessoires ou complémentaires de :
                <ul>
            <li>forage et terrassement,</li>
            <li>platelage, réalisation de socle et support d’appareils et équipements</li>
            <li>tranchées, trous de passage, saignées et raccords,</li>
            <li>calorifugeage, isolation thermique et acoustique,</li>
            <li>raccordement électrique du matériel.</li>
            </ul>
            </div>,
                child: [
                    {
                        name:"activiteGeothermieCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteGeothermieSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Photovoltaïque",
                tooltip:<div>
            Réalisation d’installations photovoltaïques.
            <br/>
            Cette activité comprend les travaux de :
                <ul>
            <li>mise en œuvre de systèmes photovoltaïques,</li>
            <li>réalisation des installations et branchements électriques associés,</li>
            <li>raccordement au réseau public,</li>
            <li>pose de batterie de stockage, de régulateur de charges et de protections dédiées,</li>
            <li>mise en sécurité de l’installation.</li>
            </ul>
            <br/>
            Ainsi que les travaux accessoires et complémentaires de :
                <ul>
            <li>terrassement et V.R.D,</li>
            <li>installations de système de sécurité et de surveillance du fonctionnement,</li>
            <li>zinguerie et éléments accessoires en tous matériaux,</li>
            <li>réalisation d’écran sous-toiture,</li>
            <li>installation de parafoudre,</li>
            <li>raccord d’étanchéité,</li>
            <li>sécurisation de site.</li>
            </ul>
            <br/>
            Ne sont pas comprises :
                <ul>
            <li>la réalisation de fondations spéciales,</li>
            <li>les modifications de la structure porteuse de l’ouvrage</li>
            </ul>
            </div>,
                child: [
                    {
                        name:"activitePhotovoltaiqueCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activitePhotovoltaiqueSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Éolien",
                tooltip:<div>
            Réalisation d’installations éoliennes terrestres, y compris leurs organes et équipements.
            <br/>
            Cette activité comprend les travaux de :
                <ul>
            <li>installations électriques, y compris convertisseur, transformateur, armoire de commande,  raccordement au réseau public,</li>
            <li>pose de batterie de stockage.</li>
            </ul>
            <br/>
            Ainsi que les travaux accessoires et complémentaires de :
                <ul>
            <li>installations de parafoudre et paratonnerre,</li>
            <li>installations de système de sécurité et de surveillance du fonctionnement,</li>
            <li>sécurisation de site.</li>
            </ul>
            <br/>
            N’est pas comprise la réalisation de fondations.
            </div>
                ,
                child: [
                    {
                        name:"activiteEolienCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteEolienSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
            {
                label:"Four et cheminée industriels",
                tooltip:"Réalisation d’ouvrage de construction de fours industriels, d’incinération et de crémation y compris leurs cheminées.",
                child: [
                    {
                        name:"activiteFourIndustrielCa",
                        fieldProps:{label: "% du CA", type:"number"},
                        component: renderInput,
                    },
                    {
                        name:"activiteFourIndustrielSousTraitance",
                        fieldProps:{label: "% de sous traitance", type:"number"},
                        component:renderInput,
                    },
                ]
            },
        ],
        'state' : [
            {
                name:"config",
                required:true,
                component:renderHidden,
                componentOptions:{value:props.config}
            },
            {
                name:"statutPriseGarantie",
                required:true,
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Prise de garantie refusée",value:"0"},
                        {label:"Prise de garantie validée",value:"1"},
                        {label:"Prise de garantie en cours",value:"2"},
                        {label:"Prise de garantie en attente",value:"3"},
                    ]
                }

            },
            {
                name:"signatureContrat",
                required:true,
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Contrat signé",value:"1"},
                        {label:"Contrat non signé",value:"0"},
                    ]
                }

            },
            {
                name:"statutQuittance",
                required:true,
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Quittance à jour",value:"1"},
                        {label:"Quittance à régulariser",value:"0"},
                    ]
                }

            },
            {
                name:"statutContrat",
                required:true,
                component:renderRadio,
                parse: function (value) {
                    // Workaround pour les calendriers
                    if (value === "0" || value === "1" || value === "2" || value === "3" || value === "4") {
                        return Number(value);
                    }
                    return props.statutContratValue;
                },
                componentOptions:{
                    options:[
                        {label:"Contrat à échéance",value:"2"},
                        {
                            label:"Contrat résilié",
                            value:"1",
                            child: [
                                {
                                    name:"dateContratResilie",
                                    component: renderHidden,
                                    reference: 'contratResilieDatePicker'
                                },
                                {
                                    name:"contratResilieDatePicker",
                                    component:renderDate,
                                    fieldProps: {
                                        selectedValue: props.dateContratResilieValue,
                                        placeholder:'Date résiliation',
                                        dateTimeRef:props.obj ? props.obj.dateTimeResilieRef : null
                                    },
                                    componentOptions: {
                                        change: props.changeDateResilie ? props.changeDateResilie.bind(props.obj) : null,

                                    },
                                    condition:() => {return 1 === props.statutContratValue},

                                },
                            ]

                        },
                        {
                            label:"Contrat non résilié",
                            value:"0"
                        },
                        {
                            label:"Contrat sans effet",
                            value:"4",
                            child: [
                                {
                                    name:"dateContratSanseffet",
                                    component: renderHidden,
                                    reference: 'contratSanseffetDatePicker'
                                },
                                {
                                    name:"contratSanseffetDatePicker",
                                    component:renderDate,
                                    placeholder:'test',
                                    fieldProps: {
                                        selectedValue: props.dateContratSanseffetValue,
                                        placeholder:'Date sans effet',
                                        dateTimeRef:props.obj ? props.obj.dateTimeSansEffetRef : null
                                    },
                                    componentOptions: {
                                        change: props.changeDateSanseffet ? props.changeDateSanseffet.bind(props.obj) : null,

                                    },
                                    condition:() => {return 4 === props.statutContratValue},

                                },
                            ]
                        },
                    ]
                }

            },
            {
                name:"statutDevis",
                required:true,
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Devis en cours de cotation",value:"3"},
                        {label:"Devis valide",value:"1"},
                        {label:"Devis non valide",value:"0"},
                    ]
                }
            },
        ],
        'protectionDonneesPublic' : [
            {
                name:"rgpd",
                required:true,
                component:renderCheckbox,
                componentOptions: {
                    checkboxLabel:"En cochant cette case, vous acceptez que vos données soient utilisées dans le cadre d’une demande d’assurance"
                }
            },
            {name:"captcharesponse", required:true, component:renderCaptcha}

        ]
    }
}
export default columns;


