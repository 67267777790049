import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Redux
import {formValueSelector, reduxForm} from 'redux-form'
import connect from "react-redux/es/connect/connect";

// App
import columns from "./Columns.js";
import {validateColumns, renderColumns} from 'columnsUtils'
import styles from "assets/jss/styles.js"

const selector = formValueSelector('devisBijouterieForm')

class AssurancePersonnes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          touched : false
        };

        this.columns = [];
    }

    shouldComponentUpdate(nextProps, nextState) {
        this.columns = columns(
            {
            }
        )['assurancePersonnes'];

        return true;
    }

    isValidated() {
        let errors = validateColumns(this.props.myform.devisBijouterieForm.values, this.columns);
        this.setState({ touched: true});
        return Object.keys(errors).length === 0;
    }

    changeInfoDevisDateEffet(date) {
        this.props.change("infoDevisDateEffet", date)
    }

    sendState() {
        return this.state;
    }

    render() {

        const { classes, fetching } = this.props;

        // Cas spécifique pour l'autocomplete
        if (fetching) {
            return (<div></div>)
        }

        return (
            <>
            {
            renderColumns(
                {
                    columns: this.columns,
                    titre: 'Assurance des personnes',
                    classes: classes,
                    fetching: fetching,
                    touched: this.state.touched
                }
            )
            }
            {this.props.assurancePersonneValue &&
                <>
                    <h6 className={classes.infoText}>
                        AGRESSION, HOLD-UP, ATTENTAT, ACTE DE TERRORISME (ou mouvement de foules dû à une grève, émeute, mouvement populaire, actes de sédition ou rébellion)
                    </h6>
                    <table className={classes.marginAuto}>
                        <tbody>
                            <tr>
                                <td className={classes.width50}>
                                Personnes assurées :
                                </td>
                                <td className={classes.width50}>
                                    L’exploitant et les salariés de  l’établissement et les enfants et conjoint de l’Assuré.
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.width50}>
                                    Décès accidentel
                                </td>
                                <td className={classes.width50}>
                                    75 000 €
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.width50}>
                                    Invalidité Permanente Totale ou Partielle
                                </td>
                                <td className={classes.width50}>
                                    75 000 €
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.width50}>
                                    Frais médicaux
                                </td>
                                <td className={classes.width50}>
                                    3 000 €
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.width50}>
                                    Maximum par personne Assurée
                                </td>
                                <td className={classes.width50}>
                                    78 000 €
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.width50}>
                                    Maximum par Evènement
                                </td>
                                <td className={classes.width50}>
                                    300 000 €
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </>
            }
            </>
        );
    }
}

const mapStateToProps = store => ({
    myform:store.form,
    assurancePersonneValue: selector(store, 'assurancePersonne'),

});

const mapDispatchToProps = {
};

export default
connect(mapStateToProps, mapDispatchToProps) (
    // reduxForm nécéssaire pour le this.props.change
    reduxForm({form: 'devisBijouterieForm'}) (
        withStyles(styles)
       (
           AssurancePersonnes
       )
    )
);

