import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "creativetim/material-dashboard-pro/components/Grid/GridContainer.js";
import GridItem from "creativetim/material-dashboard-pro/components/Grid/GridItem.js";
import styles from "assets/jss/styles.js"

const useStyles = makeStyles(styles);

export default function ErrorPage() {
  const classes = useStyles();
  return (
    <div className={classes.contentCenter}>
      <GridContainer>
        <GridItem md={12} className={classes.textCenter} >
          <h1 className={classes.title}>404</h1>
          <h2 className={classes.subTitle}>Page non trouvé</h2>
          <h4 className={classes.description}>
          </h4>
        </GridItem>
      </GridContainer>
    </div>
  );
}
