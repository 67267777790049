// React
import React, { Component } from 'react'

// @material-ui/core components
import PermIdentity from "@material-ui/icons/PermIdentity";
import withStyles from "@material-ui/core/styles/withStyles";

// Redux
import {reduxForm,Field} from 'redux-form'
import connect from "react-redux/es/connect/connect";

// Creative Tim
import CustomLinearProgress from "creativetim/material-dashboard-pro/components/CustomLinearProgress/CustomLinearProgress.js";
import GridContainer from "creativetim/material-dashboard-pro/components/Grid/GridContainer.js";
import GridItem from "creativetim/material-dashboard-pro/components/Grid/GridItem.js";
import Button from "creativetim/material-dashboard-pro/components/CustomButtons/Button.js";
import CustomInput from "creativetim/material-dashboard-pro/components/CustomInput/CustomInput.js";
import Clearfix from "creativetim/material-dashboard-pro/components/Clearfix/Clearfix.js";
import Card from "creativetim/material-dashboard-pro/components/Card/Card.js";
import CardBody from "creativetim/material-dashboard-pro/components/Card/CardBody.js";
import CardHeader from "creativetim/material-dashboard-pro/components/Card/CardHeader.js";
import CardIcon from "creativetim/material-dashboard-pro/components/Card/CardIcon.js";

// App
import {getProfile, profileUpdate} from "../actions";
import {renderInput} from 'formUtils'
import styles from "assets/jss/styles.js"

class Utilisateur extends Component {

    constructor(props) {
        super(props);
        this.abortController = new AbortController();
    }

    componentWillUnmount()
    {
        this.abortController.abort();
    }
    componentDidMount() {
        this.props.getProfile(this.props.userId, this.abortController, this.props.history)
    }
    submit(post) {
       return this.props.profileUpdate(this.props.userId, post)
    }
    render() {

        const {handleSubmit, classes, fetching, email, pristine, submitting} = this.props;
        if (fetching) {
            return (
                <div className={classes.linearProgressWrapper}>
                    <CustomLinearProgress color="primary"/ >
                </div>
            )
        }
        let emailValue = email ? email : '';
        return (
            <div>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <form onSubmit={handleSubmit(this.submit.bind(this))} >
                      <Card>
                        <CardHeader color="rose" icon>
                          <CardIcon color="rose">
                            <PermIdentity />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>
                            Editer le compte - <small>Compléter vos informations</small>
                          </h4>
                        </CardHeader>
                        <CardBody>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                    labelText="Adresse E-mail"
                                    id="emailAddress"
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                        value:emailValue,
                                        disabled: true
                                    }}
                                    />

                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <Field
                                    name="newPassword"
                                    id="newPassword"
                                    type="password"
                                    component={renderInput}
                                    label="Nouveau mot de passe"
                                    placeholder="Taper votre nouveau mot de passe"
                                    classes={classes}
                                    fetching={fetching}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <Field
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    type="password"
                                    component={renderInput}
                                    label="Confirmer le mot de passe"
                                    placeholder="Retaper à nouveau votre nouveau mot de passe"
                                    classes={classes}
                                    fetching={fetching}
                                />
                            </GridItem>
                          </GridContainer>
                          <Button type="submit" disabled={pristine || submitting} color="rose" className={classes.updateProfileButton}>
                            Mettre à jour
                          </Button>
                          <Clearfix />
                        </CardBody>
                      </Card>
                    </form>
                </GridItem>

              </GridContainer>
            </div>
          );
    }

}

const mapStateToProps = store => ({
    ...store.authReducer,
    ...store.requestReducer,
    ...store.profileReducer

});

const mapDispatchToProps = {
    getProfile,
    profileUpdate
};


function validate (values) {
    const errors = {}

    if(values['newPassword'] && values['newPassword'].length < 8 ){
        errors['newPassword']= "Le mot de passe doit contenir au moins 8 caractères"
    }
    if(!values['newPassword'] ){
        errors['newPassword']= "Le mot de passe est requis"
    }
    if(!values['confirmPassword'] ){
        errors['confirmPassword']= "Le mot de passe est requis"
    }

    if(values['confirmPassword'] !== values['newPassword']){
        errors['confirmPassword']= "Les mots de passe ne sont pas identiques !"
    }
    return errors;
}

const formConfig ={
    form: 'UtilisateurForm',
    fields : ['newPassword', 'confirmPassword'],
    validate
}

export default
    connect(mapStateToProps, mapDispatchToProps) (
        reduxForm(formConfig) (
            withStyles(styles)
            (
                Utilisateur
            )
        )
    );



