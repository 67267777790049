// React
import React from "react";

// Creative Tim
import Button from "creativetim/material-dashboard-pro/components/CustomButtons/Button.js";
import GridContainer from "creativetim/material-dashboard-pro/components/Grid/GridContainer.js";
import GridItem from "creativetim/material-dashboard-pro/components/Grid/GridItem.js";

// Vendor
import TagsInput from "react-tagsinput";

// App
import {renderInput} from 'formUtils'

class GarageVehicule extends React.Component {
    constructor (props) {
        super(props);
        this.state = {items: JSON.parse(props.initItems)};
        this.marqueRef = React.createRef();
        this.modeleRef = React.createRef();
        this.anneeRef = React.createRef();
        this.usageRef = React.createRef();
    }

    onSubmit(event) {
        event.preventDefault();
        let marque = this.marqueRef.current.value;
        let modele = this.modeleRef.current.value;
        let annee = this.anneeRef.current.value;
        let usage = this.usageRef.current.value;

        if (marque && modele && annee && usage) {
            let items = [...this.state.items];
            items.unshift({
                marque: marque,
                modele: modele,
                annee: annee,
                usage: usage,
            });
            this.setState({items: items});
            this.props.onChange(items)

            this.marqueRef.current.value = ''
            this.modeleRef.current.value = ''
            this.anneeRef.current.value = ''
            this.usageRef.current.value = ''
        }

    }
    onDelete(tags, change, index) {
        let items = [...this.state.items];
        items.splice(index, 1);
        this.setState({items: items});
        this.props.onChange(items)
    }
    render() {
        const {fetching, classes} = this.props

        let items = this.state.items.map((item, index) => {
            return item.marque + " - " + item.modele + " - " + item.annee + " : " + item.usage
        });

        return (
            <>
                <TagsInput
                    value={items}
                    onChange={this.onDelete.bind(this)}
                    tagProps={{ className: "react-tagsinput-tag info" }}
                    renderInput={() => {}}
                />
                <GridContainer>
                    <GridItem xs={12} sm={4} xl={2}>
                        {renderInput({input:{inputRef:this.marqueRef}, placeholder:"Marque", classes:classes, fetching: fetching, meta : {}})}
                    </GridItem>
                    <GridItem xs={12} sm={4} xl={2}>
                        {renderInput({input:{inputRef:this.modeleRef}, placeholder:"Modèle", classes:classes, fetching: fetching, meta : {}})}
                    </GridItem>
                    <GridItem xs={12} sm={4} xl={2}>
                        {renderInput({input:{inputRef:this.anneeRef}, placeholder:"Année", classes:classes, fetching: fetching, meta : {}})}
                    </GridItem>
                    <GridItem xs={12} sm={4} xl={2}>
                        {renderInput({input:{inputRef:this.usageRef}, placeholder:"Usage", classes:classes, fetching: fetching, meta : {}})}
                    </GridItem>
                    <GridItem xs={12} sm={4} xl={2} className={classes.addActiviteSuperficie}>
                        <Button type="submit" disabled={fetching} color="rose" onClick={this.onSubmit.bind(this)}>
                            Ajouter
                        </Button>
                    </GridItem>
                </GridContainer>
            </>
        );
    }
}

export default GarageVehicule
