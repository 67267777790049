import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Redux
import {formValueSelector, reduxForm} from 'redux-form'
import connect from "react-redux/es/connect/connect";

// App
import columns from "./Columns.js";
import {validateColumns, renderColumns} from 'columnsUtils'
import styles from "assets/jss/styles.js"

const selector = formValueSelector('devisInformatiqueForm')

class Entreprise extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          touched : false
        };

        this.columns = [];
    }

    shouldComponentUpdate(nextProps, nextState) {
        this.columns = columns(
            {
                entrepriseExerciceLogicielsMedicauxValue:nextProps.entrepriseExerciceLogicielsMedicauxValue,
                entrepriseExerciceFabricationHorsUeValue:nextProps.entrepriseExerciceFabricationHorsUeValue,
                entrepriseExerciceLogicielsBancairesValue:nextProps.entrepriseExerciceLogicielsBancairesValue,
                entrepriseExerciceScadaValue:nextProps.entrepriseExerciceScadaValue,
                entrepriseExerciceLogicielsBiometrieValue:nextProps.entrepriseExerciceLogicielsBiometrieValue,
                entrepriseExerciceProductionAssisteeValue:nextProps.entrepriseExerciceProductionAssisteeValue,
                entrepriseExerciceProcesseurPaiementValue:nextProps.entrepriseExerciceProcesseurPaiementValue,
                entrepriseExerciceAssistanceNavigationValue:nextProps.entrepriseExerciceAssistanceNavigationValue,
                entrepriseExerciceReseauMobileVirtuelValue:nextProps.entrepriseExerciceReseauMobileVirtuelValue,
            }
        )['entreprise'];

        return true;
    }

    isValidated() {
        let errors = validateColumns(this.props.myform.devisInformatiqueForm.values, this.columns);
        this.setState({ touched: true});
        return Object.keys(errors).length === 0;
    }

    sendState() {
        return this.state;
    }


    render() {

        const { classes, fetching } = this.props;

        // Cas spécifique pour l'autocomplete
        if (fetching) {
            return (<div></div>)
        }

        return (
            renderColumns(
                {
                    columns: this.columns,
                    titre: 'Entreprise',
                    classes: classes,
                    fetching: fetching,
                    touched: this.state.touched
                }
            )
        );
    }
}

const mapStateToProps = store => ({
    myform:store.form,
    entrepriseExerciceLogicielsMedicauxValue: selector(store, 'entrepriseExerciceLogicielsMedicaux'),
    entrepriseExerciceFabricationHorsUeValue: selector(store, 'entrepriseExerciceFabricationHorsUe'),
    entrepriseExerciceLogicielsBancairesValue: selector(store, 'entrepriseExerciceLogicielsBancaires'),
    entrepriseExerciceScadaValue: selector(store, 'entrepriseExerciceScada'),
    entrepriseExerciceLogicielsBiometrieValue: selector(store, 'entrepriseExerciceLogicielsBiometrie'),
    entrepriseExerciceProductionAssisteeValue: selector(store, 'entrepriseExerciceProductionAssistee'),
    entrepriseExerciceProcesseurPaiementValue: selector(store, 'entrepriseExerciceProcesseurPaiement'),
    entrepriseExerciceAssistanceNavigationValue: selector(store, 'entrepriseExerciceAssistanceNavigation'),
    entrepriseExerciceReseauMobileVirtuelValue: selector(store, 'entrepriseExerciceReseauMobileVirtuel'),
});

const mapDispatchToProps = {
};

export default
connect(mapStateToProps, mapDispatchToProps) (
    // reduxForm nécéssaire pour le this.props.change
    reduxForm({form: 'devisInformatiqueForm'}) (
        withStyles(styles)
        (
            Entreprise
        )
    )
);

