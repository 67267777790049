import {PRODUITS_RECEIVED} from "../actions";

export default (state = {
    produits:[],
    page:0,
    totalCount:0

}, action) => {
  switch (action.type) {

      case PRODUITS_RECEIVED:

          var produits = action.data['hydra:member'].map(
              produit => ({
                  "id" : produit.id,
                  "uri": '/produits/' + produit.id,
                  "name": produit.nom
              })
          );

          return {
              ...state,
              produits: produits
          };
    default:
      return state;
  }
}
