import {USER_LOGIN_SUCCESS, USER_LOGOUT, REQUEST_FETCHING/*USER_PROFILE_RECEIVED, USER_SET_ID*/} from "../actions";

export default (state = {
  token: null,
  userId: null,
  userName: null,
  userRoles: [],
  isAuthenticated: false,
}, action) => {
  switch (action.type) {
      case USER_LOGIN_SUCCESS:
      return {
        ...state,
        token: action.data.token,
        userId: action.data.utilisateur_id,
        userName: action.data.utilisateur_nom,
        userRoles: action.data.role,
        isAuthenticated: true
      };
    /*case USER_SET_ID:
      return {
        ...state,
        userId: action.userId,
        isAuthenticated: true
      };
    case USER_PROFILE_RECEIVED:
      return {
        ...state,
        userData: (state.userId === action.userId && state.userData === null)
          ? action.userData : state.userData,
        isAuthenticated: (state.userId === action.userId && state.userData === null)
      };*/
    case USER_LOGOUT:
      return {
        ...state,
        token: null,
        userId: null,
        isAuthenticated: false,
        userData: null,
      };
      case REQUEST_FETCHING:
          return {
              ...state,
              message:''
          };
    default:
      return state;
  }
}
