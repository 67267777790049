// React
import React, {Component, forwardRef} from "react";
import {NavLink} from "react-router-dom";

// Redux
import connect from "react-redux/es/connect/connect";

// @material-ui/core components
import {
    Slide,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Box,
    CircularProgress
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import withStyles from "@material-ui/core/styles/withStyles";
import { Group as GroupIcon, Close as CloseIcon } from "@material-ui/icons";

// Creative Tim
import CustomLinearProgress from "creativetim/material-dashboard-pro/components/CustomLinearProgress/CustomLinearProgress.js";
import Button from "creativetim/material-dashboard-pro/components/CustomButtons/Button.js";
import GridContainer from "creativetim/material-dashboard-pro/components/Grid/GridContainer.js";
import GridItem from "creativetim/material-dashboard-pro/components/Grid/GridItem.js";
import Card from "creativetim/material-dashboard-pro/components/Card/Card.js";
import CardBody from "creativetim/material-dashboard-pro/components/Card/CardBody.js";
import CardHeader from "creativetim/material-dashboard-pro/components/Card/CardHeader.js";
import CardIcon from "creativetim/material-dashboard-pro/components/Card/CardIcon.js";
import CardFooter from "creativetim/material-dashboard-pro/components/Card/CardFooter.js";
import { primaryColor } from "creativetim/material-dashboard-pro/assets/jss/material-dashboard-pro-react.js";

// Vendor
import MaterialTable from "material-table";
import classNames from "classnames";

// App
import {requests} from "../requests";
import styles from "assets/jss/styles.js"
import {deleteClient, getClients, getCourtiers, requestFetching, setMessage, userLogout} from "../actions";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class Clients extends Component {

    async openModale(id){
        this.setState({tableRef:this.tableRef.current, modalDelete: true, clientToDelete : id, modaleFetching : true})
        await this.props.getClients(this.abortControllerClients, false);
        this.setState({modaleFetching : false})
    }

    constructor(props) {

        super(props);
        this.state = {
            modalDelete : false,
            idReassignement:null,
            clientToDelete:null,
            tableRef:null,
            modaleFetching:false,
        };
        this.tableRef = React.createRef();
        this.abortController = new AbortController();
        this.abortControllerClients = new AbortController();

    }

    async componentDidUpdate(prevProps, prevState)
    {
        if (this.state.modalDelete === false && prevState.modalDelete === true) {
        }
    }
    componentWillUnmount()
    {
        this.abortController.abort();
        this.abortControllerClients.abort();
    }

    handleChangeColumnHidden(column, hidden) {
        let columnsHidden = JSON.parse(window.localStorage.getItem("clients-columns"))
        if (columnsHidden === null) {
            columnsHidden = []
        }
        var find = false;
        if (columnsHidden.length) {
            columnsHidden.forEach(
                function(columnHidden) {
                    if (columnHidden.field === column.field) {
                        columnHidden.hidden = hidden;
                        find = true;
                    }
                }
            )
        }
        if (!find) {
            columnsHidden.push ({field:column.field, hidden:hidden});
        }
        window.localStorage.setItem("clients-columns", JSON.stringify(columnsHidden));
    }
    getColumns(savedQuery, columnsHidden){
        let columns = [
            { title: "Id", field: "id"},
            { title: "Apporteur", field: "utilisateur.representantLegalNom" },
            { title: "Nom", field: "nom" },
            { title: "Représentant légal", field: "representant" },
            { title: "Adresse", field: "adresse" },
            { title: "Code postal", field: "codepostal"},
            { title: "Ville", field: "ville" },
        ];

        columns.forEach(
            function(column) {
                if (savedQuery.orderBy && savedQuery.orderBy.field === column.field) {
                    column.defaultSort = savedQuery.orderDirection
                }
                if (savedQuery.filters) {
                    savedQuery.filters.forEach(
                        function (filter) {
                            if (filter.column.field === column.field) {
                                column.defaultFilter = filter.value
                            }
                        }
                    );
                }
                if (columnsHidden.length) {
                    columnsHidden.forEach(
                        function (columnHidden) {
                            if (columnHidden.field === column.field) {
                                column.hidden = columnHidden.hidden
                            }
                        }
                    );
                }


            }
        )

        return columns
    }
    handleChange(id) {
        this.setState({"idReassignement": id});
    }

    async  handleDelete()
    {
        if (!this.state.clientToDelete || !this.state.idReassignement) {
            return;
        }
        if (this.state.clientToDelete === this.state.idReassignement) {
            return;
        }

        await this.props.deleteClient(this.state.clientToDelete, this.state.idReassignement)
        this.setState({modalDelete:false});
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }

    render () {

        const { history, clients, classes, fetching} = this.props;
        const labelClasses = classNames({
            [" "  +  classes.labelRoot]: true,
            [" " + classes.labelRootSuccess]: true
        });
        const currentComponent = this;

        const underlineClasses = classNames({
            [classes.underline]: true,
        });

        if (fetching || clients == null) {
            return (
                <div className={classes.linearProgressWrapper}>
                    <CustomLinearProgress color="primary"/ >
                </div>
            )
        }

        let savedQuery = JSON.parse(window.localStorage.getItem("clients-query"))
        let columnsHidden = JSON.parse(window.localStorage.getItem("clients-columns"))

        if (savedQuery === null) {
            savedQuery = []
        }
        if (columnsHidden === null) {
            columnsHidden = []
        }
          const clientsRef = this;
          return (
            <>

            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <form>
                        <Card>
                            <CardHeader color="rose" icon>
                                <CardIcon color="rose">
                                    <GroupIcon />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                    Clients
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <Box  mb={2} display="flex"  flexDirection="row-reverse">
                                    <NavLink to="/client"  >
                                        <Button color="rose">Nouveau client</Button>
                                    </NavLink>
                                </Box>

                                <MaterialTable
                                    icons={{
                                        Filter: forwardRef((props, ref) => <div {...props} ref={ref} />),
                                    }}
                                    onChangeColumnHidden={this.handleChangeColumnHidden.bind(this)}
                                    columns={this.getColumns(savedQuery, columnsHidden)}
                                    tableRef={this.tableRef}
                                    data= {
                                        function(query) {
                                            clientsRef.abortController.abort();
                                            return new Promise(
                                                (resolve, reject) => {
                                                    clientsRef.abortController = new AbortController();
                                                    let url = '/clients';
                                                    url += '?page=' + (query.page + 1)
                                                    url += '&itemsPerPage=' + query.pageSize
                                                    if (query.orderBy && query.orderBy.field) {
                                                        url += '&order[' + query.orderBy.field + ']=' + query.orderDirection
                                                    } else {
                                                        url += '&order[id]=desc'
                                                    }
                                                    query.filters.forEach(
                                                        filter => url+= "&" + filter.column.field + "=" + filter.value
                                                    );
                                                    url += '&time=' + new Date().getTime();
                                                    window.localStorage.setItem("clients-query", JSON.stringify(query));


                                                    return requests.get(url, true, clientsRef.abortController)
                                                        .then(result => {
                                                            resolve({
                                                                data: result['hydra:member'],
                                                                page:  query.page,
                                                                totalCount: result['hydra:totalItems'],
                                                            })
                                                        }).catch(error => {
                                                            if (error.response && 401 === error.response.status) {
                                                                return clientsRef.props.userLogout('Merci de vous reconnecter');
                                                            }
                                                        })
                                                }
                                            )
                                        }
                                    }
                                    actions={[
                                        {
                                            icon: 'create',
                                            tooltip: 'Modification client',
                                            onClick: (event, rowData) => history.push('/client/' + rowData.id)
                                        },
                                        {
                                            icon: 'delete',
                                            tooltip: 'Suppression client',
                                            onClick: (event, rowData) => {clientsRef.openModale(rowData.id)}

                                        }
                                    ]}
                                    options={{
                                        filtering: true,
                                        actionsColumnIndex:99,
                                        columnsButton:true,
                                        pageSize:savedQuery.pageSize ? savedQuery.pageSize : 10,
                                        pageSizeOptions:[10,30,100],
                                        thirdSortClick:false,
                                        search:false,
                                        rowStyle: rowData => ({
                                            backgroundColor: (rowData.tableData.id % 2) ? '#FFF' : '#fafafa'
                                        })
                                    }}
                                    title=""
                                />
                            </CardBody>
                            <CardFooter className={classes.justifyContentCenter}>

                            </CardFooter>
                        </Card>
                    </form>
                </GridItem>
            </GridContainer>

            <Dialog
                classes={{
                    root: classes.center,
                        paper: classes.modal
                }}
                open={this.state.modalDelete}
                transition={Transition}
                keepMounted
                onClose={() => this.setState({modal: false})}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
            >
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => this.setState({modalDelete: false})}
                    >
                        <CloseIcon className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>Suppression d'un client</h4>
                </DialogTitle>
                <DialogContent id="modal-slide-description" className={classes.modalBody} >

        {this.state.modaleFetching &&
            <div className={classes.root + " " + classes.textCenter}>
                <CircularProgress  style={{ marginBottom:"18px", color: primaryColor[1]}}/>
            </div>
        }
        {!this.state.modaleFetching &&
            <>
            <h5>Merci de choisir un client auquel réassigner les procédures existantes</h5>
                    <Autocomplete
                        options={clients}
                        filterOptions={
                            (options, { inputValue }) => options.filter(client => client.id !== this.state.clientToDelete)
                        }
                        getOptionLabel={option => option.name}
                        onChange={function(i, client) {currentComponent.handleChange(client.id)}}
                        disableClearable={true}
                        renderInput={
                            function(params) {
                                return(
                                    <TextField {...params}
                                        margin="normal"
                                        InputLabelProps={{
                                            className: classes.labelRoot + " " + labelClasses,
                                        }}
                                        fullWidth
                                        disabled={fetching}
                                        className={classes.inputRootCustomClasses + "  " +  classes.formControl + " " + classes.input +" " +  classes.inputRootCustomClasses + " "   + classes.disabled + " " + underlineClasses}
                                        placeholder={"Sélectionner un client ..."}
                                        InputProps={{
                                            classes:{
                                                input: classes.input,
                                                    root: classes.inputRootCustomClasses,
                                                    disabled: classes.disabled,
                                                    underline: underlineClasses,
                                            },
                                        ...params.InputProps
                                        }}
                                    />
                                )
                            }
                        }
                    />
            </>
            }
                </DialogContent>
                <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter} >
                    <Button onClick={() => this.setState({modalDelete: false})}>Annuler</Button>
                    <Button disabled={!this.state.clientToDelete || !this.state.idReassignement || (this.state.clientToDelete === this.state.idReassignement)} onClick={() => this.handleDelete()} color="success">Supprimer</Button>
                </DialogActions>
            </Dialog>
        </>
        );

    }
}

const mapStateToProps = store => ({
    ...store.requestReducer,
    ...store.courtiersReducer,
    ...store.clientsReducer,
    message: store.messageReducer.message,
});

const mapDispatchToProps = {
    getCourtiers,
    requestFetching,
    setMessage,
    getClients,
    deleteClient,
    userLogout
};

export default
connect(mapStateToProps, mapDispatchToProps) (
    withStyles(styles)
    (
        Clients
    )
);

