// React
import React from "react";

// @material-ui
import withStyles from "@material-ui/core/styles/withStyles";

// Redux
import connect from "react-redux/es/connect/connect";
import {formValueSelector} from 'redux-form'

// App
import styles from "assets/jss/styles.js"
import columns from "./Columns.js";
import {validateColumns, renderColumns} from 'columnsUtils'

const selector = formValueSelector('devisEscapeGameForm')


class GarantiesSouhaitees extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            touched : false
        };

        this.columns = [];
    }

    shouldComponentUpdate(nextProps, nextState) {
        this.columns = columns(
            {
                descriptionValeurContenuValue :nextProps.descriptionValeurContenuValue,
                garantieDegatsEauxValue:nextProps.garantieDegatsEauxValue,
                garantieDegatsVolValue:nextProps.garantieDegatsVolValue,
                garantieDegatsBrisGlaceValue:nextProps.garantieDegatsBrisGlaceValue,
                garantieDegatsDommagesElectriquesValue:nextProps.garantieDegatsDommagesElectriquesValue,
                garantieDegatsBrisMachineValue:nextProps.garantieDegatsBrisMachineValue,
            }
        )['garantiesSouhaitees'];

        return true;
    }

    isValidated() {
        let errors = validateColumns(this.props.myform.devisEscapeGameForm.values, this.columns);
        this.setState({ touched: true});
        return Object.keys(errors).length === 0;
    }

    sendState() {
        return this.state;
    }

    render() {

        const { classes, fetching } = this.props;
        return (
            renderColumns(
                {
                    columns: this.columns,
                    titre: 'Garanties souhaitées',
                    classes: classes,
                    fetching: fetching,
                    touched: this.state.touched
                }
            )
        );
    }

}



const mapStateToProps = store => ({
    myform:store.form,
    descriptionValeurContenuValue: selector(store, 'descriptionValeurContenu'),
    garantieDegatsEauxValue: selector(store, 'garantieDegatsEaux'),
    garantieDegatsVolValue: selector(store, 'garantieDegatsVol'),
    garantieDegatsBrisGlaceValue: selector(store, 'garantieDegatsBrisGlace'),
    garantieDegatsDommagesElectriquesValue: selector(store, 'garantieDegatsDommagesElectriques'),
    garantieDegatsBrisMachineValue: selector(store, 'garantieDegatsBrisMachine'),
});

const mapDispatchToProps = {
};

export default
connect(mapStateToProps, mapDispatchToProps) (
    withStyles(styles)
    (
        GarantiesSouhaitees
    )
);

