// React
import React, {Component} from "react";

// Redux
import connect from "react-redux/es/connect/connect";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Creative Tim
import CustomLinearProgress from "creativetim/material-dashboard-pro/components/CustomLinearProgress/CustomLinearProgress.js";

// App
import {getDashboard} from "../actions";
import styles from "assets/jss/styles.js"

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.abortController = new AbortController();
    }

    componentWillUnmount()
    {
        this.abortController.abort();
    }

    componentDidMount() {
        if (window.location.pathname !== '/') {
            window.location = '/404'
            return;
        }
        this.props.getDashboard(this.abortController)
    }

    render() {
    const {fetching, classes} = this.props;
    if (fetching) {
        return (
            <div className={classes.linearProgressWrapper}>
                <CustomLinearProgress color="primary"/>
            </div>
        )
    }
      return <div></div>
    }
}

const mapStateToProps = store => ({
    ...store.requestReducer,

});

const mapDispatchToProps = {
    getDashboard,
};

export default
  connect(mapStateToProps, mapDispatchToProps) (
      withStyles(styles) (
          Dashboard
      )

  )
