import {requests} from "../requests";
import {SubmissionError} from "redux-form";


export const REQUEST_FETCHING = 'REQUEST_FETCHING';
export const REQUEST_DONE = 'REQUEST_DONE';
export const INIT_DONE = 'INIT_DONE';
export const SET_MESSAGE = 'SET_MESSAGE'

export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGOUT = 'USER_LOGOUT';

export const GET_PROFILE = 'GET_PROFILE';
export const PROFILE_RECEIVED = 'PROFILE_RECEIVED';

export const CONFIGURATION_BIJOUTERIE_RECEIVED = 'CONFIGURATION_BIJOUTERIE_RECEIVED';
export const CONFIGURATION_BIJOUTERIE_SAVE = 'CONFIGURATION_BIJOUTERIE_SAVE';

export const CONFIGURATION_ESCAPEGAME_RECEIVED = 'CONFIGURATION_ESCAPEGAME_RECEIVED';
export const CONFIGURATION_ESCAPEGAME_SAVE = 'CONFIGURATION_ESCAPEGAME_SAVE';

export const CONFIGURATION_CYBER_RECEIVED = 'CONFIGURATION_CYBER_RECEIVED';
export const CONFIGURATION_CYBER_SAVE = 'CONFIGURATION_CYBER_SAVE';

export const CONFIGURATION_IMMEUBLE_RECEIVED = 'CONFIGURATION_IMMEUBLE_RECEIVED';
export const CONFIGURATION_IMMEUBLE_SAVE = 'CONFIGURATION_IMMEUBLE_SAVE';

export const CONFIGURATION_GARAGE_RECEIVED = 'CONFIGURATION_GARAGE_RECEIVED';
export const CONFIGURATION_GARAGE_SAVE = 'CONFIGURATION_GARAGE_SAVE';

export const CONFIGURATION_DECENNALE_RECEIVED = 'CONFIGURATION_DECENNALE_RECEIVED';
export const CONFIGURATION_DECENNALE_SAVE = 'CONFIGURATION_DECENNALE_SAVE';

export const CONFIGURATION_INFORMATIQUE_RECEIVED = 'CONFIGURATION_INFORMATIQUE_RECEIVED';
export const CONFIGURATION_INFORMATIQUE_SAVE = 'CONFIGURATION_INFORMATIQUE_SAVE';

export const CLIENT_RECEIVED = 'CLIENT_RECEIVED'
export const CLIENT_SAVE = 'CLIENT_SAVE'
export const CLIENT_RESET = 'CLIENT_RESET'

export const COURTIERS_RECEIVED = 'COURTIERS_RECEIVED'
export const CLIENTS_RECEIVED = 'CLIENTS_RECEIVED'
export const PRODUITS_RECEIVED = 'PRODUITS_RECEIVED'

export const DEVIS_ESCAPEGAME_RECEIVED = 'DEVIS_ESCAPEGAME_RECEIVED'
export const DEVIS_ESCAPEGAME_SAVE = 'DEVIS_ESCAPEGAME_SAVE'
export const DEVIS_ESCAPEGAME_RESET = 'DEVIS_ESCAPEGAME_RESET'

export const DEVIS_BIJOUTERIE_RECEIVED = 'DEVIS_BIJOUTERIE_RECEIVED'
export const DEVIS_BIJOUTERIE_SAVE = 'DEVIS_BIJOUTERIE_SAVE'
export const DEVIS_BIJOUTERIE_RESET = 'DEVIS_BIJOUTERIE_RESET'

export const DEVIS_CYBER_RECEIVED = 'DEVIS_CYBER_RECEIVED'
export const DEVIS_CYBER_SAVE = 'DEVIS_CYBER_SAVE'
export const DEVIS_CYBER_RESET = 'DEVIS_CYBER_RESET'

export const DEVIS_IMMEUBLE_RECEIVED = 'DEVIS_IMMEUBLE_RECEIVED'
export const DEVIS_IMMEUBLE_SAVE = 'DEVIS_IMMEUBLE_SAVE'
export const DEVIS_IMMEUBLE_RESET = 'DEVIS_IMMEUBLE_RESET'

export const DEVIS_GARAGE_RECEIVED = 'DEVIS_GARAGE_RECEIVED'
export const DEVIS_GARAGE_SAVE = 'DEVIS_GARAGE_SAVE'
export const DEVIS_GARAGE_RESET = 'DEVIS_GARAGE_RESET'

export const DEVIS_DECENNALE_RECEIVED = 'DEVIS_DECENNALE_RECEIVED'
export const DEVIS_DECENNALE_SAVE = 'DEVIS_DECENNALE_SAVE'
export const DEVIS_DECENNALE_RESET = 'DEVIS_DECENNALE_RESET'

export const DEVIS_INFORMATIQUE_RECEIVED = 'DEVIS_INFORMATIQUE_RECEIVED'
export const DEVIS_INFORMATIQUE_SAVE = 'DEVIS_INFORMATIQUE_SAVE'
export const DEVIS_INFORMATIQUE_RESET = 'DEVIS_INFORMATIQUE_RESET'

// ************************************************
// ****************** INIT ************************
// ************************************************
export const initDone = (data) => {
    return {
        type: INIT_DONE,
        data
    }
};

// ************************************************
// ******************** UTILS *********************
// ************************************************
export const requestFetching = () => {
    return (dispatch) => {
        dispatch({
            type: REQUEST_FETCHING
        });
    }
};

export const requestDone = () => {
    return {
        type: REQUEST_DONE
    }
};

export const setMessage = (message) => {
    return {
        type: SET_MESSAGE,
        message: message,
        error:null
    }
};
export const setError = (error) => {
    return {
        type: SET_MESSAGE,
        message:null,
        error: error
    }
};

function checkErrorForm(error, dispatch) {
    dispatch(requestDone());
    if (error.response && 401 === error.response.status) {
        return dispatch(userLogout('Merci de vous reconnecter'));
    } else if (error.response) {
        return error.response.json().then(json => {
            throw new SubmissionError({
                _error: json['hydra:title'] + ' : ' + json['hydra:description']
            })
        });
    } else {
        if (error || !error.response) {
            throw new SubmissionError({
                _error: "Une erreur est intervenue. Merci de réessayer plus tard ou de contacter l'administrateur"
            })
        }
        return error.response.json().then(json => {
            throw new SubmissionError({
                _error: "Une erreur est intervenue. Merci de réessayer plus tard ou de contacter l'administrateur"
            })
        })
    }
}

function checkErrorSimple(error, dispatch, history) {
    dispatch(requestDone());
    if (error.response && 401 === error.response.status) {
        return dispatch(userLogout('Merci de vous reconnecter'));
    } else if (error.response && 404 === error.response.status) {
        if (history) {
            history.push('/404')
        } else {
            window.location = '/404';
        }
    } else if (error.response && 500 === error.response.status) {
        dispatch(setError(error.message));
    }
}

// ************************************************
// ****************** LOGIN ***********************
// ************************************************
export const userLogin = (username, password) => {

    return (dispatch) => {
        dispatch(requestFetching());

        return requests.post('/login_check', JSON.stringify({"username":username, "password":password}), false).then(
            function (json) {
                dispatch(requestDone());
                dispatch(userLoginSuccess(json));
            }).catch((er) => {
                dispatch(requestDone());
                throw new SubmissionError({
                    _error: 'E-mail ou mot de passe invalide'
                })
        });
    }
};

export const userLoginSuccess = (data) => {
    return {
        type: USER_LOGIN_SUCCESS,
        data
    }
};

export const userLogout = (message) => {
    return (dispatch) => {
        dispatch(setMessage(message));
        dispatch({
            type: USER_LOGOUT
        })
    }
};

export function userSave(data) {
    return function (dispatch) {

        dispatch(userLoginSuccess(data))
        dispatch(initDone());

    }
}

// ************************************************
// ******************** INDEX  ********************
// ************************************************
export const getDashboard = (abortController) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.get('/', true, abortController).then(
            function (json) {
                dispatch(requestDone());
            }).catch((error) => {
                checkErrorSimple(error, dispatch)
            });
    }
}

// ************************************************
// ****************** PROFILE  ********************
// ************************************************
export const profileReceived = (data) => {
    return {
        type: PROFILE_RECEIVED,
        data
    }
};

export const getProfile = (id, abortController, history) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.get(`/utilisateurs/${id}`, true, abortController).then(
            function (json) {
                dispatch(requestDone());
                dispatch(profileReceived(json));
            }).catch((error) => {
                checkErrorSimple(error, dispatch, history)
        });
    }
};

export const profileUpdate = (id, json) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.put(`/utilisateurs/${id}`, JSON.stringify(json), true).then(
            function (json) {
                dispatch(requestDone());
                if (new Date(json['dateChangementPassword']) > new Date(window.localStorage.getItem('lastLogin'))) {
                    return dispatch(userLogout('Le mot de passe a été modifié. Merci de vous reconnecter.'));
                } else {
                    dispatch(profileReceived(json));
                }
            }).catch((error) => {
                return checkErrorForm(error, dispatch);
        });
    }
};


// ************************************************
// *********** CONFIG BIJOUTERIE ******************
// ************************************************
export const configurationBijouterieReceived = (data) => {
    return {
        type: CONFIGURATION_BIJOUTERIE_RECEIVED,
        data
    }
};

export const getLastConfigBijouterie = (abortController, secured = true) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.get(`/produit_bijouterie_configs?itemsPerPage=1&order[id]=desc`, secured, abortController).then(
            function (json) {
                dispatch(configurationBijouterieReceived(json['hydra:member'][0]));
                dispatch(requestDone());
            }).catch((error) => {
                checkErrorSimple(error, dispatch)
        });
    }
};

export const getConfigBijouterie = (uri, abortController) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.get(`${uri}`, true, abortController).then(
            function (json) {
                dispatch(configurationBijouterieReceived(json));
                dispatch(requestDone());
            }).catch((error) => {
            checkErrorSimple(error, dispatch)
        });
    }
};

export const configBijouterieSave = (json) => {
    return (dispatch) => {
        dispatch({
            type: CONFIGURATION_BIJOUTERIE_SAVE,
            data:json
        });
        dispatch(requestFetching());
        json.id=null
        return requests.post(`/produit_bijouterie_configs`, JSON.stringify(json), true).then(
            function (json) {
                dispatch(setMessage('Configuration enregistrée'));
                dispatch(configurationBijouterieReceived(json));
                dispatch(requestDone());
            }).catch((error) => {
                return checkErrorForm(error, dispatch);

            });
    }
};

// ************************************************
// *********** CONFIG ESCAPE GAME *****************
// ************************************************
export const configurationEscapeGameReceived = (data) => {
    return {
        type: CONFIGURATION_ESCAPEGAME_RECEIVED,
        data
    }
};

export const getLastConfigEscapeGame = (abortController, secured = true) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.get(`/produit_escape_game_configs?itemsPerPage=1&order[id]=desc`, secured, abortController).then(
            function (json) {
                dispatch(configurationEscapeGameReceived(json['hydra:member'][0]));
                dispatch(requestDone());
            }).catch((error) => {
                checkErrorSimple(error, dispatch)
            });
    }
};

export const getConfigEscapeGame = (uri, abortController) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.get(`${uri}`, true, abortController).then(
            function (json) {
                dispatch(configurationEscapeGameReceived(json));
                dispatch(requestDone());
            }).catch((error) => {
            checkErrorSimple(error, dispatch)
        });
    }
};

export const configEscapeGameSave = (json) => {
    return (dispatch) => {
        dispatch(requestFetching());
        dispatch({
            type: CONFIGURATION_ESCAPEGAME_SAVE,
            data:json
        });
        json.id=null
        return requests.post(`/produit_escape_game_configs`, JSON.stringify(json), true).then(
            function (json) {
                dispatch(setMessage('Configuration enregistrée'));
                dispatch(configurationEscapeGameReceived(json));
                dispatch(requestDone());
            }).catch((error) => {
                return checkErrorForm(error, dispatch);
            });
    }
};

// ************************************************
// *********** CONFIG CYBER ***********************
// ************************************************
export const configurationCyberReceived = (data) => {
    return {
        type: CONFIGURATION_CYBER_RECEIVED,
        data
    }
};

export const getLastConfigCyber = (abortController, secured = true) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.get(`/produit_cyber_configs?itemsPerPage=1&order[id]=desc`, secured, abortController).then(
            function (json) {
                dispatch(configurationCyberReceived(json['hydra:member'][0]));
                dispatch(requestDone());
            }).catch((error) => {
            checkErrorSimple(error, dispatch)
        });
    }
};

export const getConfigCyber = (uri, abortController) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.get(`${uri}`, true, abortController).then(
            function (json) {
                dispatch(configurationCyberReceived(json));
                dispatch(requestDone());
            }).catch((error) => {
            checkErrorSimple(error, dispatch)
        });
    }
};

export const configCyberSave = (json) => {
    return (dispatch) => {
        dispatch(requestFetching());
        dispatch({
            type: CONFIGURATION_CYBER_SAVE,
            data:json
        });
        json.id=null
        return requests.post(`/produit_cyber_configs`, JSON.stringify(json), true).then(
            function (json) {
                dispatch(setMessage('Configuration enregistrée'));
                dispatch(configurationCyberReceived(json));
                dispatch(requestDone());
            }).catch((error) => {
            return checkErrorForm(error, dispatch);
        });
    }
};


// ************************************************
// *********** CONFIG IMMEUBLE ***********************
// ************************************************
export const configurationImmeubleReceived = (data) => {
    return {
        type: CONFIGURATION_IMMEUBLE_RECEIVED,
        data
    }
};

export const getLastConfigImmeuble = (abortController, secured = true) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.get(`/produit_immeuble_configs?itemsPerPage=1&order[id]=desc`, secured, abortController).then(
            function (json) {
                dispatch(configurationImmeubleReceived(json['hydra:member'][0]));
                dispatch(requestDone());
            }).catch((error) => {
            checkErrorSimple(error, dispatch)
        });
    }
};

export const getConfigImmeuble = (uri, abortController) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.get(`${uri}`, true, abortController).then(
            function (json) {
                dispatch(configurationImmeubleReceived(json));
                dispatch(requestDone());
            }).catch((error) => {
            checkErrorSimple(error, dispatch)
        });
    }
};

export const configImmeubleSave = (json) => {
    return (dispatch) => {
        dispatch(requestFetching());
        dispatch({
            type: CONFIGURATION_IMMEUBLE_SAVE,
            data:json
        });
        json.id=null
        return requests.post(`/produit_immeuble_configs`, JSON.stringify(json), true).then(
            function (json) {
                dispatch(setMessage('Configuration enregistrée'));
                dispatch(configurationImmeubleReceived(json));
                dispatch(requestDone());
            }).catch((error) => {
            return checkErrorForm(error, dispatch);
        });
    }
};


// ************************************************
// *********** CONFIG GARAGE ***********************
// ************************************************
export const configurationGarageReceived = (data) => {
    return {
        type: CONFIGURATION_GARAGE_RECEIVED,
        data
    }
};

export const getLastConfigGarage = (abortController, secured = true) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.get(`/produit_garage_configs?itemsPerPage=1&order[id]=desc`, secured, abortController).then(
            function (json) {
                dispatch(configurationGarageReceived(json['hydra:member'][0]));
                dispatch(requestDone());
            }).catch((error) => {
            checkErrorSimple(error, dispatch)
        });
    }
};

export const getConfigGarage = (uri, abortController) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.get(`${uri}`, true, abortController).then(
            function (json) {
                dispatch(configurationGarageReceived(json));
                dispatch(requestDone());
            }).catch((error) => {
            checkErrorSimple(error, dispatch)
        });
    }
};

export const configGarageSave = (json) => {
    return (dispatch) => {
        dispatch(requestFetching());
        dispatch({
            type: CONFIGURATION_GARAGE_SAVE,
            data:json
        });
        json.id=null
        return requests.post(`/produit_garage_configs`, JSON.stringify(json), true).then(
            function (json) {
                dispatch(setMessage('Configuration enregistrée'));
                dispatch(configurationGarageReceived(json));
                dispatch(requestDone());
            }).catch((error) => {
            return checkErrorForm(error, dispatch);
        });
    }
};


// ************************************************
// *********** CONFIG DECENNALE ***********************
// ************************************************
export const configurationDecennaleReceived = (data) => {
    return {
        type: CONFIGURATION_DECENNALE_RECEIVED,
        data
    }
};

export const getLastConfigDecennale = (abortController, secured = true) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.get(`/produit_decennale_configs?itemsPerPage=1&order[id]=desc`, secured, abortController).then(
            function (json) {
                dispatch(configurationDecennaleReceived(json['hydra:member'][0]));
                dispatch(requestDone());
            }).catch((error) => {
            checkErrorSimple(error, dispatch)
        });
    }
};

export const getConfigDecennale = (uri, abortController) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.get(`${uri}`, true, abortController).then(
            function (json) {
                dispatch(configurationDecennaleReceived(json));
                dispatch(requestDone());
            }).catch((error) => {
            checkErrorSimple(error, dispatch)
        });
    }
};

export const configDecennaleSave = (json) => {
    return (dispatch) => {
        dispatch(requestFetching());
        dispatch({
            type: CONFIGURATION_DECENNALE_SAVE,
            data:json
        });
        json.id=null
        return requests.post(`/produit_decennale_configs`, JSON.stringify(json), true).then(
            function (json) {
                dispatch(setMessage('Configuration enregistrée'));
                dispatch(configurationDecennaleReceived(json));
                dispatch(requestDone());
            }).catch((error) => {
            return checkErrorForm(error, dispatch);
        });
    }
};

// ************************************************
// *********** CONFIG INFORMATIQUE ****************
// ************************************************
export const configurationInformatiqueeReceived = (data) => {
    return {
        type: CONFIGURATION_INFORMATIQUE_RECEIVED,
        data
    }
};

export const getLastConfigInformatique = (abortController, secured = true) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.get(`/produit_informatique_configs?itemsPerPage=1&order[id]=desc`, secured, abortController).then(
            function (json) {
                dispatch(configurationInformatiqueeReceived(json['hydra:member'][0]));
                dispatch(requestDone());
            }).catch((error) => {
            checkErrorSimple(error, dispatch)
        });
    }
};

export const getConfigInformatique = (uri, abortController) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.get(`${uri}`, true, abortController).then(
            function (json) {
                dispatch(configurationInformatiqueeReceived(json));
                dispatch(requestDone());
            }).catch((error) => {
            checkErrorSimple(error, dispatch)
        });
    }
};

export const configInformatiqueSave = (json) => {
    return (dispatch) => {
        dispatch(requestFetching());
        dispatch({
            type: CONFIGURATION_INFORMATIQUE_SAVE,
            data:json
        });
        json.id=null
        return requests.post(`/produit_informatique_configs`, JSON.stringify(json), true).then(
            function (json) {
                dispatch(setMessage('Configuration enregistrée'));
                dispatch(configurationInformatiqueeReceived(json));
                dispatch(requestDone());
            }).catch((error) => {
            return checkErrorForm(error, dispatch);
        });
    }
};


// ************************************************
// ************* LISTE COURTIERS ******************
// ************************************************
export const getCourtiers = (abortController) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.get(`/utilisateurs?role[lte]=1`, true, abortController).then(
            function (json) {
                dispatch(courtiersReceived(json));
                dispatch(requestDone());
            }).catch((error) => {
                checkErrorSimple(error, dispatch)
            });
    }
};

export const courtiersReceived = (data) => {
    return {
        type: COURTIERS_RECEIVED,
        data
    }
};



// ************************************************
// ****************** CLIENT **********************
// ************************************************
export const clientReceived = (data) => {
    return {
        type: CLIENT_RECEIVED,
        data
    }
};
export const clientReset = (data) => {
    return {
        type: CLIENT_RESET,
    }
};

export const clientSave = (json, history) => {
    return (dispatch) => {
        dispatch(requestFetching());
        dispatch({
            type: CLIENT_SAVE,
            data:json
        });
        if (json.id === null) {
            return requests.post(`/clients`, JSON.stringify(json), true).then(
                function (json) {
                    dispatch(requestDone());
                    history.push('/client/' + json.id)
                    dispatch(setMessage('Client enregistré'));
                }).catch((error) => {
                    return checkErrorForm(error, dispatch);
                });
        } else {
            return requests.put(`/clients/${json.id}`, JSON.stringify(json), true).then(
                function (json) {
                    dispatch(requestDone());
                    history.push('/client/' + json.id)
                    dispatch(setMessage('Client enregistré'));
                }).catch((error) => {
                return checkErrorForm(error, dispatch);
            });
        }
    }
}

export const getClient = (id, abortController, history) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.get(`/clients/${id}`, true, abortController).then(
            function (json) {
                dispatch(clientReceived(json));
                dispatch(requestDone());
            }).catch((error) => {
                checkErrorSimple(error, dispatch, history)
        });
    }
};
export const clientsReceived = (data) => {
    return {
        type: CLIENTS_RECEIVED,
        data
    }
};
export const getClients = (abortController, fetching = true) => {
    return (dispatch) => {

        if (fetching) {
            dispatch(requestFetching());
        }

        return requests.get(`/clients?pagination=false&order[nom]=asc`, true, abortController).then(
            function (json) {
                dispatch(clientsReceived(json));
                dispatch(requestDone())

            }).catch((error) => {
            checkErrorSimple(error, dispatch)
        });
    }
}

export const deleteClient = (id, idReassignement) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.delete(`/clients/${id}?idReassignement=${idReassignement}`, true).then(
            function (json) {
                dispatch(requestDone())
                dispatch(setMessage('Client supprimé'));

            }).catch((error) => {
            checkErrorSimple(error, dispatch)
        });
    }
}

// ************************************************
// ************ PRODUITS *****************
// ************************************************
export const produitsReceived = (data) => {
    return {
        type: PRODUITS_RECEIVED,
        data
    }
};
export const getProduits = (abortController) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.get(`/produits?pagination=false`, true, abortController).then(
            function (json) {
                dispatch(produitsReceived(json));
                dispatch(requestDone())

            }).catch((error) => {
            checkErrorSimple(error, dispatch)
        });
    }
}

export const deleteDevis = (id) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.delete(`/produits/${id}`, true).then(
            function (json) {
                dispatch(requestDone())
                dispatch(setMessage('Procédure supprimée'));

            }).catch((error) => {
            checkErrorSimple(error, dispatch)
        });
    }
}

// ************************************************
// ************ DEVIS ESCAPE GAME *****************
// ************************************************

export const getDevisEscapeGame = (id, abortController, history) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.get(`/produit_escape_games/${id}`, true, abortController).then(
            function (json) {
                dispatch(devisEscapeGameReceived(json));
                dispatch(requestDone());
            }).catch((error) => {
            checkErrorSimple(error, dispatch, history)
        });
    }
};
export const devisEscapeGameReceived = (data) => {
    return {
        type: DEVIS_ESCAPEGAME_RECEIVED,
        data
    }
};

export const devisEscapeGameReset = (data) => {
    return {
        type: DEVIS_ESCAPEGAME_RESET,
    }
};

export const devisEscapeGameSave = (json, history, formData) => {
    return (dispatch) => {
        dispatch(requestFetching());
        dispatch({
            type: DEVIS_ESCAPEGAME_SAVE,
            data: json
        });
        if (json.id === null) {
            return requests.post(`/produit_escape_games`, JSON.stringify(json), !json.ispublic).then(
                function (json) {
                    dispatch(devisEscapeGameReceived(json));
                    if (formData) {
                        dispatch(documentsSave(formData, json, !json.ispublic))
                    }
                    dispatch(requestDone());
                    if (!json.ispublic) {
                        history.push('/devis-escape-game/' + json.id)
                        dispatch(setMessage('Procédure enregistrée'));
                    }
                }).catch((error) => {
                return checkErrorForm(error, dispatch);
            });
        } else {
            if (formData) {
                dispatch(documentsSave(formData, json))
            }
            return requests.put(`/produit_escape_games/${json.id}`, JSON.stringify(json), true).then(
                function (json) {
                    dispatch(devisEscapeGameReceived(json));
                    dispatch(setMessage('Procédure enregistrée'));
                    dispatch(requestDone());
                }).catch((error) => {
                return checkErrorForm(error, dispatch);
            });
        }
    }
}



// ************************************************
// ************ DEVIS BIJOUTERIE *****************
// ************************************************

export const getDevisBijouterie = (id, abortController, history) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.get(`/produit_bijouteries/${id}`, true, abortController).then(
            function (json) {
                dispatch(devisBijouterieReceived(json));
                dispatch(requestDone());
            }).catch((error) => {
            checkErrorSimple(error, dispatch, history)
        });
    }
};
export const devisBijouterieReceived = (data) => {
    return {
        type: DEVIS_BIJOUTERIE_RECEIVED,
        data
    }
};

export const devisBijouterieReset = (data) => {
    return {
        type: DEVIS_BIJOUTERIE_RESET,
        data
    }
};

export const devisBijouterieSave = (json, history, formData) => {
    return (dispatch) => {
        dispatch(requestFetching());
        dispatch({
            type: DEVIS_BIJOUTERIE_SAVE,
            data:json
        });
        if (json.id === null) {
            return requests.post(`/produit_bijouteries`, JSON.stringify(json), !json.ispublic).then(
                function (json) {
                    dispatch(devisBijouterieReceived(json));
                    if (formData) {
                        dispatch(documentsSave(formData, json,!json.ispublic))
                    }
                    dispatch(requestDone());
                    if (!json.ispublic) {
                        history.push('/devis-bijouterie/' + json.id)
                        dispatch(setMessage('Procédure enregistrée'));
                    }
                }).catch((error) => {
                return checkErrorForm(error, dispatch);
            });
        } else {
            if (formData) {
                dispatch(documentsSave(formData, json))
            }
            return requests.put(`/produit_bijouteries/${json.id}`, JSON.stringify(json), true).then(
                function (json) {
                    dispatch(devisBijouterieReceived(json));
                    dispatch(setMessage('Procédure enregistrée'));
                    dispatch(requestDone());
                }).catch((error) => {
                return checkErrorForm(error, dispatch);
            });
        }
    }
}


// ************************************************
// ************ DEVIS CYBER ***********************
// ************************************************

export const getDevisCyber = (id, abortController, history) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.get(`/produit_cybers/${id}`, true, abortController).then(
            function (json) {
                dispatch(devisCyberReceived(json));
                dispatch(requestDone());
            }).catch((error) => {
            checkErrorSimple(error, dispatch, history)
        });
    }
};
export const devisCyberReceived = (data) => {
    return {
        type: DEVIS_CYBER_RECEIVED,
        data
    }
};

export const devisCyberReset = (data) => {
    return {
        type: DEVIS_CYBER_RESET,
        data
    }
};

export const devisCyberSave = (json, history, formData) => {
    return (dispatch) => {
        dispatch(requestFetching());
        dispatch({
            type: DEVIS_CYBER_SAVE,
            data: json
        });
        if (json.id === null) {
            return requests.post(`/produit_cybers`, JSON.stringify(json), !json.ispublic).then(
                function (json) {
                    dispatch(devisCyberReceived(json));
                    if (formData) {
                        dispatch(documentsSave(formData, json, !json.ispublic))
                    }
                    dispatch(requestDone());
                    if (!json.ispublic) {
                        history.push('/devis-cyber/' + json.id)
                        dispatch(setMessage('Procédure enregistrée'));
                    }
                }).catch((error) => {
                return checkErrorForm(error, dispatch);
            });
        } else {
            if (formData) {
                dispatch(documentsSave(formData, json))
            }
            return requests.put(`/produit_cybers/${json.id}`, JSON.stringify(json), true).then(
                function (json) {
                    dispatch(devisCyberReceived(json));
                    dispatch(setMessage('Procédure enregistrée'));
                    dispatch(requestDone());
                }).catch((error) => {
                return checkErrorForm(error, dispatch);
            });
        }
    }
}


// ************************************************
// ************ DEVIS IMMEUBLE ***********************
// ************************************************

export const getDevisImmeuble = (id, abortController, history) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.get(`/produit_immeubles/${id}`, true, abortController).then(
            function (json) {
                dispatch(devisImmeubleReceived(json));
                dispatch(requestDone());
            }).catch((error) => {
            checkErrorSimple(error, dispatch, history)
        });
    }
};
export const devisImmeubleReceived = (data) => {
    return {
        type: DEVIS_IMMEUBLE_RECEIVED,
        data
    }
};

export const devisImmeubleReset = (data) => {
    return {
        type: DEVIS_IMMEUBLE_RESET,
        data
    }
};

export const devisImmeubleSave = (json, history, formData) => {
    return (dispatch) => {
        dispatch(requestFetching());
        dispatch({
            type: DEVIS_IMMEUBLE_SAVE,
            data: json
        });
        if (json.id === null) {
            return requests.post(`/produit_immeubles`, JSON.stringify(json), !json.ispublic).then(
                function (json) {
                    dispatch(devisImmeubleReceived(json));
                    if (formData) {
                        dispatch(documentsSave(formData, json, !json.ispublic))
                    }
                    dispatch(requestDone());
                    if (!json.ispublic) {
                        history.push('/devis-immeuble/' + json.id)
                        dispatch(setMessage('Procédure enregistrée'));
                    }
                }).catch((error) => {
                return checkErrorForm(error, dispatch);
            });
        } else {
            if (formData) {
                dispatch(documentsSave(formData, json))
            }
            return requests.put(`/produit_immeubles/${json.id}`, JSON.stringify(json), true).then(
                function (json) {
                    dispatch(devisImmeubleReceived(json));
                    dispatch(setMessage('Procédure enregistrée'));
                    dispatch(requestDone());
                }).catch((error) => {
                return checkErrorForm(error, dispatch);
            });
        }
    }
}


// ************************************************
// ************ DEVIS GARAGE ***********************
// ************************************************

export const getDevisGarage = (id, abortController, history) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.get(`/produit_garages/${id}`, true, abortController).then(
            function (json) {
                dispatch(devisGarageReceived(json));
                dispatch(requestDone());
            }).catch((error) => {
            checkErrorSimple(error, dispatch, history)
        });
    }
};
export const devisGarageReceived = (data) => {
    return {
        type: DEVIS_GARAGE_RECEIVED,
        data
    }
};

export const devisGarageReset = (data) => {
    return {
        type: DEVIS_GARAGE_RESET,
        data
    }
};

export const devisGarageSave = (json, history, formData) => {
    return (dispatch) => {
        dispatch(requestFetching());
        dispatch({
            type: DEVIS_GARAGE_SAVE,
            data: json
        });
        if (json.id === null) {
            return requests.post(`/produit_garages`, JSON.stringify(json), !json.ispublic).then(
                function (json) {
                    dispatch(devisGarageReceived(json));
                    if (formData) {
                        dispatch(documentsSave(formData, json, !json.ispublic))
                    }
                    dispatch(requestDone());
                    if (!json.ispublic) {
                        history.push('/devis-garage/' + json.id)
                        dispatch(setMessage('Procédure enregistrée'));
                    }
                }).catch((error) => {
                return checkErrorForm(error, dispatch);
            });
        } else {
            if (formData) {
                dispatch(documentsSave(formData, json))
            }

            return requests.put(`/produit_garages/${json.id}`, JSON.stringify(json), true).then(
                function (json) {
                    dispatch(devisGarageReceived(json));
                    dispatch(setMessage('Procédure enregistrée'));
                    dispatch(requestDone());
                }).catch((error) => {
                return checkErrorForm(error, dispatch);
            });
        }
    }

}


// ************************************************
// ************ DEVIS DECENNALE ***********************
// ************************************************

export const getDevisDecennale = (id, abortController, history) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.get(`/produit_decennales/${id}`, true, abortController).then(
            function (json) {
                dispatch(devisDecennaleReceived(json));
                dispatch(requestDone());
            }).catch((error) => {
            checkErrorSimple(error, dispatch, history)
        });
    }
};
export const devisDecennaleReceived = (data) => {
    return {
        type: DEVIS_DECENNALE_RECEIVED,
        data
    }
};
export const devisDecennaleReset = (data) => {
    return {
        type: DEVIS_DECENNALE_RESET,
        data
    }
};

export const devisDecennaleSave = (json, history, formData) => {
    return (dispatch) => {
        dispatch(requestFetching());
        dispatch({
            type: DEVIS_DECENNALE_SAVE,
            data: json
        });
        if (json.id === null) {
            return requests.post(`/produit_decennales`, JSON.stringify(json), !json.ispublic).then(
                function (json) {
                    dispatch(devisDecennaleReceived(json));
                    if (formData) {
                        dispatch(documentsSave(formData, json, !json.ispublic))
                    }
                    dispatch(requestDone());
                    if (!json.ispublic) {
                        history.push('/devis-decennale/' + json.id)
                        dispatch(setMessage('Procédure enregistrée'));
                    }
                }).catch((error) => {
                return checkErrorForm(error, dispatch);
            });
        } else {
            if (formData) {
                dispatch(documentsSave(formData, json))
            }
            return requests.put(`/produit_decennales/${json.id}`, JSON.stringify(json), true).then(
                function (json) {
                    dispatch(devisDecennaleReceived(json));
                    dispatch(setMessage('Procédure enregistrée'));
                    dispatch(requestDone());
                }).catch((error) => {
                return checkErrorForm(error, dispatch);
            });
        }
    }
}


// ************************************************
// ************ DEVIS INFORMATIQUE ****************
// ************************************************

export const getDevisInformatique = (id, abortController, history) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.get(`/produit_informatiques/${id}`, true, abortController).then(
            function (json) {
                dispatch(devisInformatiqueReceived(json));
                dispatch(requestDone());
            }).catch((error) => {
            checkErrorSimple(error, dispatch, history)
        });
    }
};
export const devisInformatiqueReceived = (data) => {
    return {
        type: DEVIS_INFORMATIQUE_RECEIVED,
        data
    }
};
export const devisInformatiqueReset = (data) => {
    return {
        type: DEVIS_INFORMATIQUE_RESET,
        data
    }
};

export const devisInformatiqueSave = (json, history, formData) => {
    return (dispatch) => {
        dispatch(requestFetching());
        dispatch({
            type: DEVIS_INFORMATIQUE_SAVE,
            data: json
        });
        if (json.id === null) {
            return requests.post(`/produit_informatiques`, JSON.stringify(json), !json.ispublic).then(
                function (json) {
                    dispatch(devisInformatiqueReceived(json));
                    if (formData) {
                        dispatch(documentsSave(formData, json, !json.ispublic))
                    }
                    dispatch(requestDone());
                    if (!json.ispublic) {
                        history.push('/devis-informatique/' + json.id)
                        dispatch(setMessage('Procédure enregistrée'));
                    }
                }).catch((error) => {
                return checkErrorForm(error, dispatch);
            });
        } else {
            if (formData) {
                dispatch(documentsSave(formData, json))
            }
            return requests.put(`/produit_informatiques/${json.id}`, JSON.stringify(json), true).then(
                function (json) {
                    dispatch(devisInformatiqueReceived(json));
                    dispatch(setMessage('Procédure enregistrée'));
                    dispatch(requestDone());
                }).catch((error) => {
                return checkErrorForm(error, dispatch);
            });
        }
    }
}
// ************************************************
// ************ DOCUMENT *****************
// ************************************************
export const download = (url, nom) => {
    return (dispatch) => {

            requests.download(url, true, nom).then(
                function (json) {

                }).catch((error) => {
                return checkErrorSimple(error, dispatch);
            });
    }
}

export const deleteDocument = (uri, callback, callbackParam) => {
    return (dispatch) => {
        dispatch(requestFetching());
        return requests.delete(`${uri}`, true).then(
            function (json) {
                dispatch(requestDone())
            }).catch((error) => {
            checkErrorSimple(error, dispatch)
        });
    }
}

export const documentsSave = (formData, json, secured) => {
    return async function(dispatch)  {
        for (let i = 0; i < formData.get('fileLength'); ++i) {
            let file = new FormData();
            file.append('file', formData.get('file' + i))
            file.append('produit_id', json.id);
            file.append('utilisateur_id', json.utilisateur.split('/')[2]);
            file.append('type', 3);
            await requests.upload(`/documents`, file, secured).then(
                function (json) {

                }).catch((error) => {
                    return checkErrorSimple(error, dispatch);
                }
            );
        }
    }
}