// React
import React from "react";

// @material-ui
import withStyles from "@material-ui/core/styles/withStyles";

// Redux
import connect from "react-redux/es/connect/connect";

// App
import styles from "assets/jss/styles.js"
import columns from "./Columns.js";
import {validateColumns, renderColumns} from 'columnsUtils'

class GarantiesOptionnellesSouhaitees extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            touched : false
        };

        this.columns = [];
    }

    shouldComponentUpdate(nextProps, nextState) {
        this.columns = columns(
            {
            }
        )['garantiesOptionnellesSouhaitees'];

        return true;
    }

    isValidated() {
        let errors = validateColumns(this.props.myform.devisEscapeGameForm.values, this.columns);
        this.setState({ touched: true});
        return Object.keys(errors).length === 0;
    }

    sendState() {
        return this.state;
    }

    render() {

        const { classes, fetching } = this.props;
        return (
            renderColumns(
                {
                    columns: this.columns,
                    titre: 'Garanties optionnelles souhaitées',
                    classes: classes,
                    fetching: fetching,
                    touched: this.state.touched
                }
            )
        );
    }
}



const mapStateToProps = store => ({
    myform:store.form,
});

const mapDispatchToProps = {
};

export default
connect(mapStateToProps, mapDispatchToProps) (
    withStyles(styles)
    (
        GarantiesOptionnellesSouhaitees
    )
);

