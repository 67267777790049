import {COURTIERS_RECEIVED} from "../actions";

export default (state = {
    courtiers: null,

}, action) => {
  switch (action.type) {
      case COURTIERS_RECEIVED:

          var courtiers = action.data['hydra:member'].map(
              courtier => ({
                  "id" : courtier.id,
                  "uri": '/utilisateurs/' + courtier.id,
                  "name": courtier.representantLegalPrenom + " " + courtier.representantLegalNom
              })
          );

          return {
              ...state,
              courtiers : courtiers
          };
    default:
      return state;
  }
}
