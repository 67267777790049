// React
import React from 'react'
import Datetime from "react-datetime";

// Material UI
import {TextField, FormControlLabel, Radio, RadioGroup, FormLabel, FormControl, InputLabel, Checkbox, Select, MenuItem, Switch, FormHelperText, FormGroup, Box, Tooltip }from '@material-ui/core'
import {Check as CheckIcon, FiberManualRecord as FiberManualRecordIcon, Attachment as AttachmentIcon, Help as HelpIcon} from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";

// Creative Tim
import GridContainer from "creativetim/material-dashboard-pro/components/Grid/GridContainer.js";
import GridItem from "creativetim/material-dashboard-pro/components/Grid/GridItem.js";
import Button from "creativetim/material-dashboard-pro/components/CustomButtons/Button.js";

// Redux
import {Field} from 'redux-form'

// Vendor
import classNames from "classnames";
import ReCAPTCHA from "react-google-recaptcha";
import {DropzoneArea} from 'material-ui-dropzone'

// App
import ImmeubleActivite from "./components/DevisImmeuble/ImmeubleActivite";
import GarageActivite from "./components/DevisGarage/GarageActivite";
import GarageVehicule from "./components/DevisGarage/GarageVehicule";

var moment = require('moment');
require('moment/locale/fr');


export const RenderSwitch = function ({ input, label, classes }) {

    return (
        <FormControlLabel
            control=
            <Switch
                checked={input.value ? true : false}
                onChange={(e,  checked ) => input.onChange(checked)}
                classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    thumb: classes.switchIcon,
                    track: classes.switchBar
                }}
            />
            classes={{
                label: classes.label
            }}
            label={label}
        />
    )
}


export const renderMultiCheckbox = function(
    {
        input,
        checkboxlabel,
        classes,
        fetching,
        options,
        values,
        forceTouched,
        meta: { touched, invalid, error, success }
    }) {

    let isTouched = touched || forceTouched

    return (
        <>
            <FormGroup className={classes.label + " " + classes.labelRoot + " " + classes.checkGroup} >
            {
                options.map(
                    (column, i) => {
                        if (column.condition === undefined || column.condition()) {
                            return (
                                <Field key={i}
                                    parse={value => column.parse ? column.parse(value) : value}
                                    forceTouched={touched}
                                    name={column.name}
                                    id={column.name}
                                    component={column.component ? column.component : renderSimpleCheckbox}
                                    text={column.componentOptions && column.componentOptions.text  ? column.componentOptions.text : null}
                                    options={column.componentOptions && column.componentOptions.options  ? column.componentOptions.options : []}
                                    list={column.componentOptions && column.componentOptions.list  ? column.componentOptions.list : []}
                                    change={column.componentOptions && column.componentOptions.change ? column.componentOptions.change: null}
                                    checkboxlabel={column.componentOptions && column.componentOptions.checkboxLabel ? column.componentOptions.checkboxLabel: null}
                                    classes={classes}
                                    fetching={fetching}
                                    placeholder={column.placeholder}
                                    {...column.fieldProps}

                                />
                            )
                        }
                        return null
                    }
                )
            }
            </FormGroup>
            <FormHelperText className={classes.labelError}>{isTouched && error}</FormHelperText>
        </>
    )
}

export const renderSimpleCheckbox = function(
    {
        input, checkboxlabel, classes, fetching,options,
        values,
        forceTouched,
        meta: { touched, invalid, error, success }
    }) {

    return (
        <>

        <FormControlLabel
    control=
<Checkbox
    disabled={fetching}
    checked={input.value ? true : false}
    onChange={(e,  checked ) => input.onChange(checked)}
    checkedIcon={<CheckIcon className={classes.checkedIcon} />}
    icon={<CheckIcon className={classes.uncheckedIcon} />}
    classes={{
        checked: classes.checked,
            root: classes.checkRoot
    }}
    />
    classes={{
        label: classes.label,
            root: classes.labelRoot
    }}
    label={checkboxlabel}
    />
    </>

)
}
export const renderCheckbox = function(params) {
    const {
        classes,
        forceTouched,
        meta: { touched, error }
    } = params;

    let isTouched = touched || forceTouched

    return (
        <>
            {renderSimpleCheckbox(params)}
            <FormHelperText className={classes.labelError}>{isTouched && error}</FormHelperText>
        </>
    )
}


const renderChild = function(child, classes, fetching, touched)
{
    return (
        <GridContainer>
            {
                child.map( (column, i) =>
                    {
                        if (column.condition === undefined || column.condition()) {
                            return (
                                <GridItem xs={12} sm={12/child.length}  xl={12/child.length} key={i} className={classes.noPadding + " " +  (column.component === renderRadio ? classes.alignItemsCenter : '') }>
                                    {createRowState(column, classes, fetching, i, touched, {'xl':12, 'xs':12, 'sm':12})}
                                </GridItem>

                            )
                        }
                        return null
                    }
                )
            }
        </GridContainer>
    )
}

const renderChildState = function(child, classes, fetching, touched)
{
    return child.map( (column, i) =>
        {
            if (column.condition === undefined || column.condition()) {
                return (createRowState(column, classes, fetching, i, touched, {'xl':12, 'xs':12, 'sm':12}))
            }
            return null
        }
    )
}
export const renderRadio = function(
    {
        options,
        input,
        values,
        label,
        classes,
        fetching,
        forceTouched,
        meta: { touched, invalid, error, success },
        ...rest
    }) {

    let isTouched = touched || forceTouched

    return (
        <RadioGroup {...input} {...rest} className={classes.radioGroup} >
            {
                options.map((option, idx) =>
                    {
                        return (
                            <div key={idx}>
                            <FormControlLabel
                                control=
                                    <Radio
                                        checked={ typeof input.value === 'boolean' ? Boolean(input.value) === Boolean(parseInt(option.value)) : input.value.toString() === option.value.toString()}
                                        value={option.value}
                                        disabled={fetching}
                                        icon={
                                            <FiberManualRecordIcon className={classes.radioUnchecked} />
                                        }
                                        checkedIcon={
                                            <FiberManualRecordIcon className={classes.radioChecked} />
                                        }
                                        classes={{
                                            checked: classes.radio,
                                                root: classes.radioRoot
                                        }}
                                    />

                                    classes={{
                                        label: classes.label,
                                            root: classes.labelRoot
                                    }}
                                    label={option.label}
                            />
                            {option.child && renderChildState(option.child, classes, fetching, touched)}
                        </div>
                        )
                    }
                )
            }
        <FormHelperText className={classes.labelError}>{isTouched && error}</FormHelperText>
        </RadioGroup>
    )
}

export const renderPeriod= function(
    {
        change1,
        change2,
        input,
        type,
        text,
        classes,
        meta: { touched, invalid, error, success },
        custom,
        fetching,
        inputProps,
        placeholder1,
        placeholder2,
        forceTouched,
        selectedValue1,
        selectedValue2,
        label1,
        label2,
    }) {
    let isTouched = touched || forceTouched

    delete (input.value)
    return (
        <>
            <FormControl fullWidth className={classes.datePickerGroup}>
                <>
                    <Box display="flex" justifyContent="flex-start">
                        <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            value={new moment(selectedValue1)}
                            onChange={ value => change1(value ? value.format() : null) }
                            inputProps={{ readOnly:true, placeholder:placeholder1, disabled: fetching, ...input}}

                        />

                        <Box className={classes.datePickerGroup} pl={2} pr={2} >
                            /
                        </Box>
                        <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            value={new moment(selectedValue2)}
                            onChange={ value => change2(value ? value.format() : null) }
                            inputProps={{ readOnly:true, placeholder:placeholder2, disabled: fetching, ...input}}
                        />
                        </Box>
                </>
            </FormControl>
            <FormHelperText className={classes.labelError}>{isTouched && error}</FormHelperText>
        </>
)
}

export const renderDate = function(
    {
        change,
        input,
        type,
        text,
        classes,
        meta: { touched, invalid, error, success },
        custom,
        fetching,
        inputProps,
        placeholder,
        forceTouched,
        selectedValue,
        dateTimeRef
    }) {

    let isTouched = touched || forceTouched

    delete (input.value)
        return (
            <>
                <FormControl fullWidth className={classes.datePickerGroup} >
                    <Datetime ref={dateTimeRef}
                        timeFormat={false}
                        value={new moment(selectedValue)}
                        onChange={ value => change(value ? value.format() : null) }
                        inputProps={{ readOnly:true, placeholder:placeholder, disabled: fetching, ...input}}
                    />
                </FormControl>
                <FormHelperText className={classes.labelError}>{isTouched && error}</FormHelperText>
            </>
    )
}



export const renderMultiInput = function(
    {
        input,
        checkboxlabel,
        classes,
        fetching,
        options,
        values,
        forceTouched,
        meta: { touched, invalid, error, success }
    }) {

    let isTouched = touched || forceTouched

    return (
        <>
            <FormGroup className={classes.label + " " + classes.labelRoot + " " + classes.inputGroup} >
            {
                options.map(
                    (column, idx) => {
                        return (
                            <GridContainer key={idx}>
                                <GridItem xs={12} sm={4}  xl={2}>
                                    <Field
                                        parse={value => column.parse ? column.parse(value) : value}
                                        forceTouched={touched}
                                        name={column.name}
                                        id={column.name}
                                        component={renderInput}
                                        text={column.componentOptions && column.componentOptions.text  ? column.componentOptions.text : null}
                                        options={column.componentOptions && column.componentOptions.options  ? column.componentOptions.options : []}
                                        list={column.componentOptions && column.componentOptions.list  ? column.componentOptions.list : []}
                                        change={column.componentOptions && column.componentOptions.change ? column.componentOptions.change: null}
                                        checkboxlabel={column.componentOptions && column.componentOptions.checkboxLabel ? column.componentOptions.checkboxLabel: null}
                                        classes={classes}
                                        fetching={fetching}
                                        {...column.fieldProps}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={4}  xl={2}>
                                    <FormLabel className={classes.labelHorizontal + " " + classes.labelLeft}>
                                        {column.label}
                                    </FormLabel>
                                </GridItem>
                            </GridContainer>
                        )
                    }
                )
            }
            </FormGroup>
            <FormHelperText className={classes.labelError}>{isTouched && error}</FormHelperText>
        </>
    )
}

export const renderTextarea = function (params)
{
    const params2 = {...params, multiline: true}
    return renderInput(params2)
}

export const renderImmeubleActivite = function({list, classes, fetching, change})
{
    return (
        <ImmeubleActivite onChange={change} classes={classes} fetching={fetching} initItems={list}/>
    )
}
export const renderGarageActivite = function({list, classes, fetching, change, forceTouched, meta: { touched, error}})
{

    let isTouched = touched || forceTouched
    return (
        <GarageActivite isTouched={isTouched} error={error} onChange={change} classes={classes} fetching={fetching} initItems={list}/>
    )
}

export const renderGarageVehicule = function({list, classes, fetching, change})
{
    return (
        <GarageVehicule onChange={change} classes={classes} fetching={fetching} initItems={list}/>
    )
}

export const renderInput = function(
    {
        ref,
        maxLength,
        label,
        input,
        type,
        text,
        classes,
        meta: { touched, invalid, error, success },
        custom,
        fetching,
        inputProps,
        placeholder,
        forceTouched,
        multiline,
        multiCheckboxChild
    }) {
    let isTouched = touched || forceTouched
    const labelClasses = classNames({
        [" "  +  classes.labelRoot]: true,
        [" " + classes.labelRootError]: isTouched && invalid,
        [" " + classes.labelRootSuccess]: success && !error
    });

    const underlineClasses = classNames({
        [classes.underlineError]: isTouched && error,
        [classes.underlineSuccess]: success && !error,
        [classes.underline]: true,
        [classes.multiCheckboxChild] : multiCheckboxChild
    });

    return (
        <TextField
            InputLabelProps={{
                className: classes.labelRoot + " " + labelClasses,
            }}
            multiline={multiline}
            label={label}
            type={type}
            disabled={fetching}
            helperText={isTouched && error}
            fullWidth={true}
            className={classes.inputRootCustomClasses + "  " +  classes.formControl + " " + (multiCheckboxChild  ? classes.multiCheckboxChild : '') }
            placeholder={placeholder}
            error={isTouched && invalid}
            InputProps={{
                autoComplete:'off',
                classes:{
                    input: classes.input ,
                    root: classes.inputRootCustomClasses ,
                    disabled: classes.disabled,
                    underline: underlineClasses,
                },
            ...inputProps
            }}
            inputProps={{
                maxLength: maxLength,
            }}
            {...input}
            {...custom}
        />
    )
}

export const renderSelect = function (
    props) {

        const {
            forceTouched,
            placeholder,
            input,
            fetching,
            label,
            meta: { touched, invalid, error },
            options,
            children,
            classes,
            selectedValue,
            ...custom
        } = props
    let isTouched = touched || forceTouched


    function childrens() {
        var nodes = []
        options.forEach((option, idx)=> nodes.push(
            <MenuItem
                key={idx}
                classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                }}
                value={option.value}
            >
                {option.label}
            </MenuItem>))
        return nodes;
    }

    return (
        <>
            <FormControl fullWidth className={classes.selectFormControl} error={isTouched && invalid}>
                <InputLabel className={classes.selectLabel}>{placeholder}</InputLabel>
                <Select
                    MenuProps={{
                        className: classes.selectMenu
                    }}
                    classes={{
                        select: classes.select
                    }}
                    inputProps={{
                    }}
                    error={isTouched && invalid}
                    {...input}
                    {...custom}
                    children={childrens()}
                    defaultValue={""}
                />
                <FormHelperText className={classes.labelError}>{isTouched && error}</FormHelperText>
            </FormControl>
        </>
    )
}
export const renderHidden = function({ hiddenValue, input, custom, classes, forceTouched, meta: { touched, invalid, error, success }}) {
    let isTouched = touched || forceTouched
    return (
        <>
            <input  type='hidden' {...input} {...custom} value={hiddenValue} />
            <FormHelperText className={classes.labelError}>{isTouched && error}</FormHelperText>
        </>
    )

}

export const renderCaptcha= function(
    {
        classes,
        input,
        custom,
        forceTouched,
        meta: { touched, invalid, error, success },
    }) {

    let isTouched = touched || forceTouched
    return (
        <>
        <br/>
            <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onChange={input.onChange}
            />
            <FormHelperText className={classes.labelError}>{isTouched && error}</FormHelperText>
        </>
    )

}

export const renderText = function({text, classes, forceTouched, meta: { touched, invalid, error, success }}) {
    let isTouched = touched || forceTouched

    return  (
        <>
            <div className={classes.labelHorizontal + " " + classes.floatLeft}>{text}</div>
            <FormHelperText className={classes.errorHorizontal + " " + classes.labelError}>{isTouched && error}</FormHelperText>
        </>
    )
}

export const renderDropzoneField = function({change,
                                    disabled,
                                    classes}) {
    return (
        <DropzoneArea acceptedFiles={[]} showFileNames={true} maxFileSize={parseInt(process.env.REACT_APP_MAX_FILE_SIZE)} dropzoneClass={classes.dropzonePadding} dropzoneText="Déplacer vos fichiers ici ou bien cliquer"
            onChange={ files => {change(files)}}
        />
    );
}

export const renderFiles= function({text, classes, change}) {
    return (
        <input
    onChange={change}
    color = "primary"
    type = "file"
    id = "icon-button-file"
    style = {
    {
        display: '',
    }
}
    />
)
}
export const renderUpload= function({text, classes}) {
    return (
        <>
            <input
                color="primary"
                type="file"
                id="icon-button-file"
                style={{ display: 'none', }}
            />
            <label htmlFor="icon-button-file">
                <Button
                    variant="contained"
                    component="span"
                    className={classes.button}
                    color="primary"
                >
                    <AttachmentIcon className={classes.extendedIcon} />
                </Button>
            </label>
        </>
    )
}

export const renderAutocomplete = function({
                                        label,
                                        input,
                                        type,
                                        text,
                                        classes,
                                        meta: { touched, invalid, error, success },
                                        custom,
                                        fetching,
                                        inputProps,
                                        placeholder,
                                        list,
                                        change,
                                        disableClearable,
                                       selectedValue,
                                               forceTouched,
                                    }) {

    let isTouched = touched || forceTouched
    const labelClasses = classNames({
        [" "  +  classes.labelRoot]: true,
        [" " + classes.labelRootError]: isTouched && error,
        [" " + classes.labelRootSuccess]: success && !error
    });

    const underlineClasses = classNames({
        [classes.underlineError]: isTouched && error,
        [classes.underlineSuccess]: success && !error,
        [classes.underline]: true,
    });

    if (list == null) {
        return (<div></div>)
    }

    return (
        <>
            <Autocomplete
                options={list}
                defaultValue={selectedValue}
                getOptionLabel={option => option.name}
                onChange={function(i, courtier) {change(courtier.uri)}}
                disableClearable={disableClearable}
                renderInput={
                    function(params) {
                        return(
                            <TextField {...params}
                                margin="normal"
                                InputLabelProps={{
                                    className: classes.labelRoot + " " + labelClasses,
                                }}
                                helperText={isTouched && error}
                                fullWidth
                                disabled={fetching}
                                className={classes.inputRootCustomClasses + "  " +  classes.formControl + " " + classes.input +" " +  classes.inputRootCustomClasses + " "   + classes.disabled + " " + underlineClasses}
                                placeholder={placeholder}
                                error={isTouched && invalid}
                                InputProps={{
                                    classes:{
                                        input: classes.input,
                                            root: classes.inputRootCustomClasses,
                                            disabled: classes.disabled,
                                            underline: underlineClasses,
                                    },
                                ...params.InputProps
                                }}
                                {...input}
                                {...custom}
                            />
                        )
                    }
                }
             />
        </>
    )
}

export const createRow = function(column, classes, fetching, idx, touched)
{

    if (column.component === renderHidden) {
        return (
            <div key={idx}>
                <Field
                    parse={value => column.parse ? column.parse(value) : value}
                    forceTouched={touched}
                    name={column.name}
                    id={column.name}
                    component={column.component}
                    text={column.componentOptions && column.componentOptions.text  ? column.componentOptions.text : null}
                    hiddenValue={column.componentOptions && column.componentOptions.value ? column.componentOptions.value : ''}
                    options={column.componentOptions && column.componentOptions.options  ? column.componentOptions.options : []}
                    list={column.componentOptions && column.componentOptions.list  ? column.componentOptions.list : []}
                    change={column.componentOptions && column.componentOptions.change ? column.componentOptions.change: null}
                    checkboxlabel={column.componentOptions && column.componentOptions.checkboxLabel ? column.componentOptions.checkboxLabel: null}
                    classes={classes}
                    fetching={fetching}
                    {...column.fieldProps}
                />
            </div>
        )
    }



    return (
        <GridContainer key={idx} className={column.component === renderRadio ? classes.alignItemsCenter : '' }>
            <GridItem xs={12} sm={4}  xl={4}>
                <FormLabel className={classes.labelHorizontal + " " + classes.textRight}>
                    {column.label}
                    {column.tooltip &&
                        <Tooltip title={column.tooltip}>
                            <div aria-label="delete">
                            &nbsp;<HelpIcon className={classes.tooltip} />
                            </div>
                        </Tooltip>
                    }
                </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={8} xl={8}>
                {column.component &&

                        <Field
                            parse={value => column.parse ? column.parse(value) : value}
                            forceTouched={touched}
                            name={column.name}
                            id={column.name}
                            component={column.component}
                            text={column.componentOptions && column.componentOptions.text  ? column.componentOptions.text : null}
                            options={column.componentOptions && column.componentOptions.options  ? column.componentOptions.options : []}
                            list={column.componentOptions && column.componentOptions.list  ? column.componentOptions.list : []}
                            change={column.componentOptions && column.componentOptions.change ? column.componentOptions.change: null}
                            checkboxlabel={column.componentOptions && column.componentOptions.checkboxLabel ? column.componentOptions.checkboxLabel: null}
                            classes={classes}
                            fetching={fetching}
                            placeholder={column.placeholder}
                            {...column.fieldProps}
                        />

                }
                {column.child && renderChild(column.child, classes, fetching, touched)}
            </GridItem>
        </GridContainer>
    )
}

export const createRowState = function(column, classes, fetching, idx, touched, responsive)
{

    if (!responsive) {
        responsive = {
            'xs' : 12,
            'sm' : 6,
            'lg' : 3,
            'xl' : 3
        }
    }
    if (column.component === renderHidden) {
        return (
            <div key={idx}>
                <Field
                    parse={value => column.parse ? column.parse(value) : value}
                    forceTouched={touched}
                    name={column.name}
                    id={column.name}
                    component={column.component}
                    text={column.componentOptions && column.componentOptions.text  ? column.componentOptions.text : null}
                    hiddenValue={column.componentOptions && column.componentOptions.value ? column.componentOptions.value : ''}
                    options={column.componentOptions && column.componentOptions.options  ? column.componentOptions.options : []}
                    list={column.componentOptions && column.componentOptions.list  ? column.componentOptions.list : []}
                    change={column.componentOptions && column.componentOptions.change ? column.componentOptions.change: null}
                    checkboxlabel={column.componentOptions && column.componentOptions.checkboxLabel ? column.componentOptions.checkboxLabel: null}
                    classes={classes}
                    fetching={fetching}
                    {...column.fieldProps}
                />
            </div>
        )
    }

    return (
        <GridItem xs={responsive['xs']} sm={responsive['sm']} lg={responsive['lg']} xl={responsive['xl']} key={idx}>
            <Field
                parse={value => column.parse ? column.parse(value) : value}
                forceTouched={touched}
                name={column.name}
                id={column.name}
                component={column.component}
                text={column.componentOptions && column.componentOptions.text  ? column.componentOptions.text : null}
                options={column.componentOptions && column.componentOptions.options  ? column.componentOptions.options : []}
                list={column.componentOptions && column.componentOptions.list  ? column.componentOptions.list : []}
                change={column.componentOptions && column.componentOptions.change ? column.componentOptions.change: null}
                checkboxlabel={column.componentOptions && column.componentOptions.checkboxLabel ? column.componentOptions.checkboxLabel: null}
                classes={classes}
                fetching={fetching}
                placeholder={column.placeholder}
                {...column.fieldProps}
            />
        </GridItem>
    )
}