// React
import React from "react";

// Creative Tim
import GridContainer from "creativetim/material-dashboard-pro/components/Grid/GridContainer.js";
import GridItem from "creativetim/material-dashboard-pro/components/Grid/GridItem.js";

// App
import { createRow, renderMultiCheckbox} from 'formUtils'

export const validateColumns = function (values, columns) {
    values = values ? values : {}
    const errors = {}

    columns.forEach(
        column => {
            if(column.required && (!column.validateActivites && (!column.validate || column.doubleValidate)) && (values[column.name] === undefined || values[column.name] === null || values[column.name] === '')){
                if (column.component === renderMultiCheckbox) {
                    let checkedValue = 0;
                    column.componentOptions.options.forEach(
                        child => {if (values[child.name]) { checkedValue++}}
                    )
                    if (checkedValue === 0) {
                        errors[column.name]= "Ce champs est obligatoire !"
                    }
                    return;
                }
                if (column.reference) {
                    if (Array.isArray(column.reference)) {
                        column.reference.forEach(
                            reference => {
                                errors[reference] = "Ce champs est obligatoire !"
                            }
                        )
                    } else {
                        errors[column.reference] = "Ce champs est obligatoire !"
                    }
                } else {
                    errors[column.name]= "Ce champs est obligatoire !"
                }
            }
            if(column.required && column.validate) {
                let total = 0;

                if (column.validateActivites) {
                    if (values['entrepriseActivites']) {
                        let activites = JSON.parse(values['entrepriseActivites'])
                        activites.forEach(
                            activite => {
                                total += parseFloat(activite.part)
                            }
                        )
                    }
                } else {
                    let validateOptions = column.validateOptions ? column.validateOptions : column.componentOptions;
                    validateOptions.options.forEach(
                        child => {
                            total += values[child.name] ? parseFloat(values[child.name]) : 0
                        }
                    )
                }

                if (!column.validate(total)) {
                    if (column.reference) {
                        if (Array.isArray(column.reference)) {
                            column.reference.forEach(
                                reference => {
                                    errors[reference] = column.error
                                }
                            )
                        } else {
                            errors[column.reference] = column.error
                        }

                    } else {
                        errors[column.name]= column.error
                    }
                }

            }
        }
    )
    return errors;
}

export const renderColumns = function ({columns, titre, soustitre, classes, fetching, touched}) {
    return (
        <GridContainer justify="center">
            <GridItem xs={12} sm={12}>
                <h4 className={classes.infoText}>
                    {titre}
                </h4>
                {soustitre &&
                <h6 className={classes.infoText2}>
                    {soustitre}
                </h6>
                }
            </GridItem>
            <GridItem xs={12} sm={12}>
                {
                    columns.map( (column, i) =>
                        {
                            if (column.condition === undefined || column.condition()) {
                                return (createRow(column, classes, fetching, i, touched))
                            }
                            return null
                        }
                    )
                }
            </GridItem>
        </GridContainer>
    )
}




