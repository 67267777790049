import {DEVIS_GARAGE_RESET, DEVIS_GARAGE_RECEIVED, DEVIS_GARAGE_SAVE} from "../actions";

const defaultState = {
    id: null,
    config: null,

    entrepriseSiret: null,
    entrepriseCaHt: null,
    entrepriseEffectif: null,
    entrepriseActivites: '[]',
    entrepriseAdresse: null,
    entrepriseVille: null,
    entrepriseCodepostal: null,
    entrepriseSurface: null,
    entrepriseQualiteJuridique: null,

    risqueAutoPersonnesEntreprise: null,
    risqueAutoPersonnesEntrepriseNombreConducteurs: null,
    risqueAutoPersonnesPrivees: null,
    risqueAutoPersonnesPriveesNombreConduteurTousUsages: null,
    risqueAutoPersonnesPriveesNombreConduteurUsageAffaire: null,
    risqueAutoPersonnesPriveesNombreConduteurNovice: null,
    risqueAutoValeurParc: null,
    risqueAutoValeurParcInterieur: null,
    risqueAutoValeurParcExterieur: null,

    moyensPreventionIncendiePresenceExtincteurs: null,
    moyensPreventionIncendieVerificationAnnuelle: null,
    moyensPreventionIncendieAutresMoyens: null,
    moyensPreventionIncendieAutresMoyensDetail: null,

    moyensPreventionVolEnvironnementHabitationProposant: false,
    moyensPreventionVolEnvironnementZoneHabitee: false,
    moyensPreventionVolEnvironnementZoneIsolee: false,

    moyensPreventionVolStationnementInterieur: false,
    moyensPreventionVolStationnementEnceinte: false,
    moyensPreventionVolStationnementExterieur: false,

    moyensPreventionVolProtectionEnceinteCloturesLourdes: false,
    moyensPreventionVolProtectionEnceinteEclairage: false,
    moyensPreventionVolProtectionEnceinteGrillage: false,
    moyensPreventionVolProtectionEnceinteAucune: false,

    moyensPreventionVolProtectionParcCloturesLourdes: false,
    moyensPreventionVolProtectionParcGrillage: false,
    moyensPreventionVolProtectionParcAntivol: false,
    moyensPreventionVolProtectionParcAucune: false,

    moyensPreventionVolProtectionElectroniqueAlarme: false,
    moyensPreventionVolProtectionElectroniqueLiaison: false,
    moyensPreventionVolProtectionElectroniqueSecourue: false,
    moyensPreventionVolProtectionElectroniqueAucune: false,

    garantieLocaux: null,
    garantieLocauxIncendieValeurMarchandises: null,
    garantieLocauxIncendieValeurMateriel: null,
    garantieLocauxDegatsEaux: null,
    garantieLocauxDegatsEauxSouhaitee: null,
    garantieLocauxVol: null,
    garantieLocauxVolPremierRisque: null,
    garantieLocauxVolCoffreFort: null,
    garantieLocauxVolInnocupationSup2: false,
    garantieLocauxVolInnocupationSup45: false,
    garantieBrisGlaces: null,
    garantieBrisGlacesValeurAssuree: null,
    garantieBrisGlacesValeurAssureeDetail: null,
    garantieBrisGlacesEnseigne: null,
    garantieBrisGlacesAutres: null,
    garantieDommagesElectriques: null,
    garantieDommagesElectriquesCapitalAssure: null,
    garantieDommagesElectriquesCapitalAssureDetail: null,
    garantieBrisMachines: null,
    garantieBrisMachinesCapitalAssure: null,
    garantieBrisMachinesCapitalAssureDetail: null,
    garantiePerteExploitation: null,
    garantiePerteExploitationMargeBrute: null,
    garantiePerteExploitationApresIncendie: null,
    garantiePerteExploitationDegatsEaux: null,
    garantiePerteExploitationBrisMachine: null,
    garantiePerteExploitationPeriodeIndemnisation: null,
    garantiePerteValeurVenale: null,
    garantiePerteValeurVenaleDetail: null,
    garantieVehiculeConfiesSup76000: null,
    garantieVehiculeConfiesSup76000Detail: null,
    garantieVehiculePropres: null,
    garantieVehiculePropresDetail: '[]',

    antecedentAssuranceDejaAssuree: null,
    antecedentAssuranceDejaAssureeDetail: null,
    antecedentAssuranceDateEcheance: null,
    antecedentAssuranceResilie: null,
    antecedentAssuranceResilieDetail: null,
    antecedentAssurancePrimeAssuranceActuelle: null,
    antecedentAssuranceSinistres3Ans: null,
    antecedentAssuranceSinistres3AnsDetail: null,

    proposantAdresse: null,
    proposantVille: null,
    proposantCodepostal: null,
    proposantTelephone: null,
    proposantEmail: null,
    rgpd: null,
    captcha: null,
    fraisIntermediation: null,
    signatureContrat: false,
    statutPriseGarantie: 3,
    statutContrat: 0,
    statutQuittance: 0,
    statutDevis: 3,
    dateContratResilie: null,
    dateContratSanseffet: null,
    client: null,
    utilisateur: null,
    referenceNumero: null,
    ispublic: null,
    notificationUtilisateur: null,

}

export default (state = defaultState, action) => {
    switch (action.type) {
        case DEVIS_GARAGE_RESET:
            return defaultState;

        case DEVIS_GARAGE_SAVE:
            return {
                ...state,
                ...action.data
            };
        case DEVIS_GARAGE_RECEIVED:
            delete action.data['referenceMasque'];
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }
}
