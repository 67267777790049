// React
import React from "react";

// Creative Tim
import Button from "creativetim/material-dashboard-pro/components/CustomButtons/Button.js";
import GridContainer from "creativetim/material-dashboard-pro/components/Grid/GridContainer.js";
import GridItem from "creativetim/material-dashboard-pro/components/Grid/GridItem.js";

// Vendor
import TagsInput from "react-tagsinput";

// App
import {renderInput} from 'formUtils'

class ImmeubleActivite extends React.Component {
    constructor (props) {
        super(props);
        this.state = {items: JSON.parse(props.initItems)};
        this.activiteRef = React.createRef();
        this.superficieRef = React.createRef();
    }

    onSubmit(event) {
        event.preventDefault();
        let activite = this.activiteRef.current.value;
        let superficie = this.superficieRef.current.value;

        if (activite && superficie) {
            let items = [...this.state.items];
            items.unshift({
                activite: activite,
                superficie: superficie
            });
            this.setState({items: items});
            this.props.onChange(items)

            this.activiteRef.current.value = ''
            this.superficieRef.current.value = ''
        }

    }
    onDelete(tags, change, index) {
        let items = [...this.state.items];
        items.splice(index, 1);
        this.setState({items: items});
        this.props.onChange(items)
    }
    render() {
        const {fetching, classes} = this.props

        let items = this.state.items.map((item, index) => {
            return item.activite + " : " + item.superficie + "m²"
        });

        return (
            <>
                <TagsInput
                    value={items}
                    onChange={this.onDelete.bind(this)}
                    tagProps={{ className: "react-tagsinput-tag info" }}
                    renderInput={() => {}}
                />
                <GridContainer>
                    <GridItem xs={12} sm={4} xl={2}>
                        {renderInput({input:{inputRef:this.activiteRef}, placeholder:"Activité", classes:classes, fetching: fetching, meta : {}})}
                    </GridItem>
                    <GridItem xs={12} sm={4} xl={2}>
                        {renderInput({input:{inputRef:this.superficieRef}, placeholder:"Superficie", classes:classes, fetching: fetching, meta : {}})}
                    </GridItem>
                    <GridItem xs={12} sm={4} xl={2} className={classes.addActiviteSuperficie}>
                        <Button type="submit" disabled={fetching} color="rose" onClick={this.onSubmit.bind(this)}>
                            Ajouter
                        </Button>
                    </GridItem>
                </GridContainer>
            </>
        );
    }
}

export default ImmeubleActivite
