// React
import React, { Component } from 'react'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Settings from "@material-ui/icons/Settings";

// Redux
import {reduxForm,Field} from 'redux-form'
import connect from "react-redux/es/connect/connect";

// Creative Tim
import GridContainer from "creativetim/material-dashboard-pro/components/Grid/GridContainer.js";
import GridItem from "creativetim/material-dashboard-pro/components/Grid/GridItem.js";
import Button from "creativetim/material-dashboard-pro/components/CustomButtons/Button.js";
import Card from "creativetim/material-dashboard-pro/components/Card/Card.js";
import CardBody from "creativetim/material-dashboard-pro/components/Card/CardBody.js";
import CardHeader from "creativetim/material-dashboard-pro/components/Card/CardHeader.js";
import CardIcon from "creativetim/material-dashboard-pro/components/Card/CardIcon.js";
import CardFooter from "creativetim/material-dashboard-pro/components/Card/CardFooter.js";
import CustomLinearProgress from "creativetim/material-dashboard-pro/components/CustomLinearProgress/CustomLinearProgress.js";
import styles from "assets/jss/styles.js"

//App
import {clientReset, getClient, clientSave, getCourtiers,requestFetching, setMessage, setError} from "../actions";
import {renderInput, renderHidden, renderAutocomplete} from 'formUtils'

class Client extends Component {

    constructor(props) {
        super(props);
        this.state = {
            init : false,
        };
        this.abortController = new AbortController();
        this.mounted = false;
    }
    componentWillUnmount()
    {
        this.abortController.abort();
        this.mounted = false;
    }
    async componentDidMount() {
        this.mounted = true

        this.props.clientReset()

        await this.props.getCourtiers(this.abortController)

        if (this.props.match.params.id) {
            await this.props.getClient(this.props.match.params.id, this.abortController, this.props.history)
        }

        if (this.mounted) {
            this.setState({ init: true});
        }
    }

    submit(post) {
        return this.props.clientSave(post, this.props.history)
    }

    handleChange(uri) {
        this.props.change("utilisateur", uri)
    }

    getSelectedValue()
    {
        if (this.props.courtiers == null || this.props.initialValues.utilisateur == null) {
            return null;
        }

        let c =  this.props.courtiers.find(
            (courtier) =>
                courtier.uri === this.props.initialValues.utilisateur
        )

        return this.props.courtiers[this.props.courtiers.indexOf(c)]
    }

    render() {
        const { courtiers, handleSubmit, classes, fetching, pristine, submitting} = this.props;
        if (!this.state.init || fetching || this.props.courtiers == null) {
            return (
                <div className={classes.linearProgressWrapper}>
                    <CustomLinearProgress color="primary"/ >
                </div>
            )
        }

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <form onSubmit={handleSubmit(this.submit.bind(this))} >
                            <Card>
                                <CardHeader color="rose" icon>
                                    <CardIcon color="rose">
                                        <Settings />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>
                                        Client - <small>{ this.props.initialValues.id ? "Edition" : "Nouveau" }</small>
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={8} xl={4}>
                                            <Field
                                                name="nom"
                                                id="nom"
                                                component={renderInput}
                                                label="Nom"
                                                maxLength="128"
                                                classes={classes}
                                                fetching={fetching}
                                            />
                                        </GridItem>

                                        <GridItem xs={12} sm={4} xl={4}>
                                            <Field
                                                name="representant"
                                                label="Représentant légal"
                                                id="representant"
                                                component={renderInput}
                                                classes={classes}
                                                fetching={fetching}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={8} xl={4}>
                                            <Field
                                                type="hidden"
                                                name="utilisateur"
                                                id="utilisateur"
                                                component={renderHidden}
                                                classes={classes}
                                                fetching={fetching}
                                            />
                                            <Field
                                                name="utilisateurList"
                                                id="utilisateurList"
                                                selectedValue={courtiers != null ? this.getSelectedValue() : null}
                                                disableClearable={true}
                                                component={renderAutocomplete}
                                                change={this.handleChange.bind(this)}
                                                list={courtiers}
                                                classes={classes}
                                                fetching={fetching}
                                                custom={{label:"Apporteur"}}
                                            />
                                        </GridItem>
                                    </GridContainer>

                                    <GridContainer>
                                        <GridItem xs={12} sm={4} xl={4}>
                                            <Field
                                                name="adresse"
                                                id="adresse"
                                                label="Adresse"
                                                component={renderInput}
                                                classes={classes}
                                                fetching={fetching}
                                            />
                                        </GridItem>

                                        <GridItem xs={12} sm={4} xl={4}>
                                            <Field
                                                name="codepostal"
                                                id="codepostal"
                                                label="Code postal"
                                                component={renderInput}
                                                classes={classes}
                                                fetching={fetching}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={4} xl={4}>
                                            <Field
                                                name="ville"
                                                id="ville"
                                                label="Ville"
                                                component={renderInput}
                                                classes={classes}
                                                fetching={fetching}
                                            />
                                        </GridItem>
                                    </GridContainer>

                                    <GridContainer>
                                        <GridItem xs={12} sm={4} xl={4}>
                                            <Field
                                                name="telephone"
                                                id="telephone"
                                                label="Téléphone"
                                                component={renderInput}
                                                classes={classes}
                                                fetching={fetching}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={4} xl={4}>
                                            <Field
                                                name="email"
                                                id="email"
                                                label="E-mail"
                                                placeholder="example@site.com"
                                                component={renderInput}
                                                classes={classes}
                                                fetching={fetching}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={4} xl={4}>
                                            <Field
                                                name="siteInternet"
                                                id="siteInternet"
                                                label="Site internet"
                                                placeholder="http://www.example.com"
                                                component={renderInput}
                                                classes={classes}
                                                fetching={fetching}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                                <CardFooter className={classes.justifyContentCenter}>
                                    <Button type="submit" disabled={pristine || submitting} color="rose" >
                                        Sauvegarder
                                    </Button>
                                </CardFooter>
                            </Card>
                        </form>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    ...store.authReducer,
    ...store.requestReducer,
    ...store.courtiersReducer,
    message: store.messageReducer.message,
    initialValues:{...store.clientReducer},
});

const mapDispatchToProps = {
    clientSave,
    getCourtiers,
    requestFetching,
    setMessage,
    setError,
    getClient,
    clientReset
};

function handleChangeForm(values, dispatch, props)
{
    if (props.dirty && props.message !== '') {
        props.setMessage('');
    }
}
function validate (values) {
    const errors = {}

    const columns = [
        "nom",
        "adresse",
        "codepostal",
        "ville",
        "telephone",
        "email",
    ]
    columns.forEach(
        column => {
            if (!values[column]) {
                errors[column] = "Ce champs est obligatoire !"
            }
        }
    )

    if(!values['utilisateur'] ){
        errors['utilisateurList']= "Ce champs est obligatoire !"
    }

    return errors;
}

const formConfig ={
    form: 'clientForm',
    fields : ['nom', 'representant', 'utilisateur', 'adresse', 'codepostal', 'ville', 'telephone', 'email', 'siteInternet', 'utilisateurList'],
    validate,
    enableReinitialize : true ,// you need to add this property
    onChange:handleChangeForm
}

export default
connect(mapStateToProps, mapDispatchToProps) (
    reduxForm(formConfig) (
        withStyles(styles)
        (
            Client
        )
    )
);



