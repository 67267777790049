// App
import {renderCaptcha, renderDate, renderText, renderInput, renderSelect, renderHidden, renderAutocomplete, renderCheckbox, renderRadio} from 'formUtils'

const columns = function (props) {
    return {
        'informationsPublic': [
            {name:"proposantNom", required:true, label:"Nom et prénom du proposant ou Raison Sociale", component:renderInput},
            {name:"proposantAdresse", required:true, label:"Adresse", component:renderInput},
            {name:"proposantVille", required:true, label:"Ville", component:renderInput},
            {name:"proposantCodepostal", required:true, label:"Code postal", component:renderInput},
            {name:"proposantTelephone", required:true, label:"Téléphone", component:renderInput},
            {name:"proposantEmail", required:true, label:"Email", component:renderInput},
            {
                name: "ispublic",
                component: renderHidden,
            },
        ],
        'informations': [
            {
                name: "utilisateur",
                component: renderHidden,
                required: true,
                reference: 'utilisateurList'
            },
            {
                name: "utilisateurList",
                label: "Utilisateur",
                component: renderAutocomplete,
                componentOptions: {
                    list: props.courtiers,
                    change: props.changeCourtier ? props.changeCourtier.bind(props.obj) : null,

                },
                fieldProps: {
                    selectedValue: props.courtiers != null ? props.getSelectedValueCourtier(props.obj) : null
                }
            },
            {
                name:"client",
                component:renderHidden,
                required:true,
                reference:'clientList'

            },
            {
                name:"clientList",
                label:"Client",
                component:renderAutocomplete,
                componentOptions:{
                    list:props.clients,
                    change:props.changeClient ? props.changeClient.bind(props.obj) : null
                },
                fieldProps: {
                    selectedValue:props.clients != null ? props.getSelectedValueClient(props.obj) : null
                }
            },
            {
                name: "utilisateur",
                component: renderHidden,
                componentOptions: {value:'/utilisateurs/0'}
            },
            {
                name:"client",
                component:renderHidden,
                componentOptions: {value:'/clients/0'}
            },
            {
                name:"config",
                component:renderHidden,
                componentOptions:{value:'/produit_escape_game_configs/0'}
            },
            {
                name:"fraisIntermediation",
                label:"Frais d'intermédiation",
                component:renderSelect,
                required:true,
                componentOptions:{
                    options:props.getFraisIntermediation ? props.getFraisIntermediation(props.obj) : null
                }
            }
        ],
        'coordonneesSouscripteur' : [
            {name:"coordonneesSiret", label:"SIRET", component:renderInput},
            {name:"coordonneesCaHt", label:"Chiffre d’affaires annuel HT", component:renderInput},
            {name:"coordonneesEffectif", required:true,label:"Effectif", component:renderInput},
            {name:"coordonneesMj", label:"Dont Meneur(se)s de jeu", component:renderInput},
        ],
        'description' : [
            {name:"descriptionAdresseLocal", label:"Adresse du local", component:renderInput},
            {name:"descriptionCodepostalLocal", label:"Ville du local", component:renderInput},
            {name:"descriptionVilleLocal", label:"Code postal du local", component:renderInput},
            {name:"descriptionSuperficie", label:"Superficie développée (m2)", component:renderInput},
            {name:"descriptionQualiteJuridique", required:true, label:"Qualité juridique", component:renderRadio, parse: value => Number(value), componentOptions:{options:[{label:"Propriétaire",value:"1"}, {label:"Locataire",value:"0"}]}},
            {name:"descriptionNbSallesJeux", label:"Nbre de salles de jeux", component:renderInput},
            {
                name:"descriptionValeurContenu",
                label:"Valeur du contenu (mobiliers, matériels, marchandises...)",
                fieldProps:{type:"number"},
                component:renderInput
            },
            {name:"descriptionNbParticipantMax", label:"Nbre de participants maximum", component:renderInput},
        ],
        'moyensIncendie' : [
            {name:"protectionIncendiePresenceExtincteur", required:true, label:"Présence d’extincteurs vérifiés annuellement par un professionnel habilité", component:renderRadio, parse: value => Boolean(Number(value)), componentOptions:{options:[{label:"Oui",value:"1"}, {label:"Non",value:"0"}]}},
            {name:"protectionIncendieInstallationElectriqueVerifiee", required:true, label:"Installations électriques vérifiées annuellement par un organisme habilité", component:renderRadio, parse: value => Boolean(Number(value)), componentOptions:{options:[{label:"Oui",value:"1"}, {label:"Non",value:"0"}]}},
        ],
        'moyensVol' : [
            {
                name:"protectionVolDevantureVitree",
                label:"Devanture vitrée",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{options:[{label:"Oui",value:"1"}, {label:"Non",value:"0"}]}
            },
            {
                name:"protectionVolDevantureVitreeDetail",
                label:"Si oui, devanture protégée par un rideau ou une grille métallique ?",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                componentOptions:{options:[{label:"Oui",value:"1"}, {label:"Non",value:"0"}]},
                condition:() => {return true === props.protectionVolDevantureVitreeValue},
            },
            {
                name:"protectionVolOuverturesProtegees",
                label:"Toutes les ouvertures sont-elles protégées par des barreaux ou volets ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{options:[{label:"Oui",value:"1"}, {label:"Non",value:"0"}]}
            },
            {
                name:"protectionVolNbPointsSecurites",
                label:"Combien de points de sécurité sur les portes d’accès ?",
                component:renderInput
            },
            {
                name:"protectionVolAlarmeAntiIntrusion",
                label:"Alarme anti-intrusion",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{options:[{label:"Oui",value:"1"}, {label:"Non",value:"0"}]}
            },
            {
                name:"protectionVolAlarmeAntiIntrusionCentraleTelesurveillance",
                label:"Si oui, est-elle reliée à une centrale de télésurveillance ?",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                componentOptions:{options:[{label:"Oui",value:"1"}, {label:"Non",value:"0"}]},
                condition:() => {return 1 === props.protectionVolAlarmeAntiIntrusionValue},
            },
        ],
        'antecedentsAssurance' : [
            {
                name:"antecedentAssuranceDejaAssuree",
                label:"Déjà assuré ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{options:[{label:"Oui",value:"1"}, {label:"Non",value:"0"}]}

            },
            {
                name:"antecedentAssuranceDejaAssureeDetail",
                label:"Si oui, quelle Compagnie",
                component:renderInput,
                condition: () => {return true === props.antecedentAssuranceDejaAssureeValue},

            },
            {
                name:"antecedentAssuranceSinistres3Ans",
                label:"Sinistres dans les 3 ans ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{options:[{label:"Oui",value:"1"}, {label:"Non",value:"0"}]}
            },
            {
                name:"antecedentAssuranceSinistres3AnsDetail",
                label:"Si oui, description des sinistres + montant ?",
                component:renderInput,
                condition:() => {return true === props.antecedentAssuranceSinistres3AnsValue},

            },
            {
                name:"antecedentAssurancePasResilie",
                label:"Ne pas avoir été résilié par un assureur précédent",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{options:[{label:"Oui",value:"1"}, {label:"Non",value:"0"}]}
            },
            {
                name:"antecedentAssurancePasResilieDetail",
                label:"Si non, pour quel motif ?",
                component:renderInput,
                condition:() => {return false === props.antecedentAssurancePasResilieValue},

            },
        ],
        'garantiesSouhaitees' : [
            {
                name:"incendie_et_risques_annexes",
                label:"Incendie et risques annexes",
                component:renderText,
                componentOptions:{text:"100% du contenu"}
            },
            {
                name:"garantieDegatsEaux",
                label:"Dégâts des eaux",
                component:renderRadio,
                required:true,
                parse: value => Number(value),
                componentOptions:{options:[
                        {
                            label: props.descriptionValeurContenuValue ? `100% du contenu (${Math.round((props.descriptionValeurContenuValue / 1 + Number.EPSILON) * 100) / 100}€)` : '100% du contenu',
                            value:"100"
                        },
                        {
                            label: props.descriptionValeurContenuValue ? `50% du contenu (${Math.round((props.descriptionValeurContenuValue / 2 + Number.EPSILON) * 100) / 100}€)` : '50% du contenu',
                            value:"50"
                        },
                        {
                            label:"Autres",
                            value:"0"
                        }
                    ]}

            },
            {
                name:"garantieDegatsEauxAutre",
                label:"Si autres, précisez",
                component:renderInput,
                required : (0 === props.garantieDegatsEauxValue),
                condition: () => {return 0 === props.garantieDegatsEauxValue},

            },
            {
                name:"garantieDegatsVol",
                label:"Vol/Vandalisme",
                component:renderRadio,
                required:true,
                parse: value => Number(value),
                componentOptions:{options:[
                        {
                            label: props.descriptionValeurContenuValue ? `50% du contenu (${Math.round((props.descriptionValeurContenuValue / 2 + Number.EPSILON) * 100) / 100}€)` : '50% du contenu',
                            value:"50"
                        },
                        {
                            label: props.descriptionValeurContenuValue ? `25% du contenu (${Math.round((props.descriptionValeurContenuValue / 4 + Number.EPSILON) * 100) / 100}€)` : '25% du contenu',
                            value:"25"
                        },
                        {
                            label:"Autres",
                            value:"0"
                        }
                    ]}

            },
            {
                name:"garantieDegatsVolAutre",
                label:"Si autres, précisez",
                component:renderInput,
                required : 0 === props.garantieDegatsVolValue,
                condition: () => {return 0 === props.garantieDegatsVolValue},

            },
            {
                name:"garantieDegatsBrisGlace",
                label:"Bris de glaces et Enseignes",
                component:renderRadio,
                required:true,
                parse: value => Number(value),
                componentOptions:{options:[{label:"5000 €",value:"5000"}, {label:"10 000 €",value:"10000"}, {label:"Autres",value:"0"}]}
            },
            {
                name:"garantieDegatsBrisGlaceAutre",
                label:"Si autres, précisez",
                component:renderInput,
                required : 0 === props.garantieDegatsBrisGlaceValue,
                condition: () => {return 0 === props.garantieDegatsBrisGlaceValue},

            },
            {
                name:"evenements_climatiques",
                label:"Evénements climatiques",
                component:renderText,
                componentOptions:{text:"100% du contenu"}
            },
            {
                name:"garantieDegatsDommagesElectriques",
                label:"Dommages électriques",
                component:renderRadio,
                required:true,
                parse: value => Number(value),
                componentOptions:{options:[{label:"15 000 €",value:"15000"}, {label:"Autres",value:"0"}]}
            },
            {
                name:"garantieDegatsDommagesElectriquesAutre",
                label:"Si autres, précisez",
                component:renderInput,
                required : 0 === props.garantieDegatsDommagesElectriquesValue,
                condition: () => {return 0 === props.garantieDegatsDommagesElectriquesValue},

            },
            {
                name:"attentats_et_actes_de_terrorisme",
                label:"Attentats et actes de terrorisme",
                component:renderText,
                componentOptions:{text:"100% du contenu"}
            },
            {
                name:"garantieDegatsBrisMachine",
                label:"Bris de machines",
                component:renderRadio,
                required:true,
                parse: value => Number(value),
                componentOptions:{options:[{label:"15 000 €",value:"15000"}, {label:"Autres",value:"0"}]}
            },
            {
                name:"garantieDegatsBrisMachineAutre",
                label:"Si autres, précisez",
                component:renderInput,
                required : 0 === props.garantieDegatsBrisMachineValue,
                condition: () => {return 0 === props.garantieDegatsBrisMachineValue},

            },
            {
                name:"responsabilite_civile",
                label:"Responsabilité civile",
                component:renderText,
                componentOptions:{text:"Oui"}
            },
            {
                name:"pertes_d_exploitation",
                label:"Pertes d’exploitation",
                component:renderText,
                componentOptions:{text:"100 % de la marge brute"}
            },
            {
                name:"perte_de_la_valeur_venale_du_fonds_de_commerce",
                label:"Perte de la valeur vénale du fonds de commerce",
                component:renderText,
                componentOptions:{text:"100 % du chiffre d'affaires"}
            },
        ],
        'garantiesOptionnellesSouhaitees' : [
            {
                name:"garantieOptionnelleTousRisquesDecors",
                required:true,
                label:"Tous risques Dommages aux décors (3.000 €)",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                componentOptions:{options:[{label:"Oui",value:"1"}, {label:"Non",value:"0"}]}
            },
            {
                name:"garantieOptionnelleVolCasiers",
                required:true,
                label:"Vol des casiers (5.000 €)",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                componentOptions:{options:[{label:"Oui",value:"1"}, {label:"Non",value:"0"}]}
            },
            // Demande de Florent Cautela : Mail du 01/04/2020
            /*
            {
                name:"garantieOptionnelleIndividuelleAccident",
                required:true,
                label:"Individuelle accident pour les participants (10.000 € en cas de décès + 30.000 € en cas d’incapacité permanente)",
                component:renderRadio, parse: value => Boolean(Number(value)),
                componentOptions:{options:[{label:"Oui (+ 600 € TTC)",value:"1"}, {label:"Non",value:"0"}]}
            },
            */
        ],
        'state' : [
            {
                name:"config",
                required:true,
                component:renderHidden,
                componentOptions:{value:props.config}
            },
            {
                name:"statutPriseGarantie",
                required:true,
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Prise de garantie refusée",value:"0"},
                        {label:"Prise de garantie validée",value:"1"},
                        {label:"Prise de garantie en cours",value:"2"},
                        {label:"Prise de garantie en attente",value:"3"},
                    ]
                }

            },
            {
                name:"signatureContrat",
                required:true,
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Contrat signé",value:"1"},
                        {label:"Contrat non signé",value:"0"},
                    ]
                }

            },
            {
                name:"statutQuittance",
                required:true,
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Quittance à jour",value:"1"},
                        {label:"Quittance à régulariser",value:"0"},
                    ]
                }

            },
            {
                name:"statutContrat",
                required:true,
                component:renderRadio,
                parse: function (value) {
                    // Workaround pour les calendriers
                    if (value === "0" || value === "1" || value === "2" || value === "3" || value === "4") {
                        return Number(value);
                    }
                    return props.statutContratValue;
                },
                componentOptions:{
                    options:[
                        {label:"Contrat à échéance",value:"2"},
                        {
                            label:"Contrat résilié",
                            value:"1",
                            child: [
                                {
                                    name:"dateContratResilie",
                                    component: renderHidden,
                                    reference: 'contratResilieDatePicker'
                                },
                                {
                                    name:"contratResilieDatePicker",
                                    component:renderDate,
                                    fieldProps: {
                                        selectedValue: props.dateContratResilieValue,
                                        placeholder:'Date résiliation',
                                        dateTimeRef:props.obj ? props.obj.dateTimeResilieRef : null
                                    },
                                    componentOptions: {
                                        change: props.changeDateResilie ? props.changeDateResilie.bind(props.obj) : null,

                                    },
                                    condition:() => {return 1 === props.statutContratValue},

                                },
                            ]

                        },
                        {
                            label:"Contrat non résilié",
                            value:"0"
                        },
                        {
                            label:"Contrat sans effet",
                            value:"4",
                            child: [
                                {
                                    name:"dateContratSanseffet",
                                    component: renderHidden,
                                    reference: 'contratSanseffetDatePicker'
                                },
                                {
                                    name:"contratSanseffetDatePicker",
                                    component:renderDate,
                                    placeholder:'test',
                                    fieldProps: {
                                        selectedValue: props.dateContratSanseffetValue,
                                        placeholder:'Date sans effet',
                                        dateTimeRef:props.obj ? props.obj.dateTimeSansEffetRef : null
                                    },
                                    componentOptions: {
                                        change: props.changeDateSanseffet ? props.changeDateSanseffet.bind(props.obj) : null,

                                    },
                                    condition:() => {return 4 === props.statutContratValue},

                                },
                            ]
                        },
                    ]
                }

            },
            {
                name:"statutDevis",
                required:true,
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Devis en cours de cotation",value:"3"},
                        {label:"Devis valide",value:"1"},
                        {label:"Devis non valide",value:"0"},
                    ]
                }
            },
        ],
        'protectionDonneesPublic' : [
            {
                name:"rgpd",
                required:true,
                component:renderCheckbox,
                componentOptions: {
                    checkboxLabel:"En cochant cette case, vous acceptez que vos données soient utilisées dans le cadre d’une demande d’assurance"
                }
            },
            {name:"captcharesponse", required:true, component:renderCaptcha}

        ]
    }
}
export default columns;
