import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Redux
import {formValueSelector, reduxForm} from 'redux-form'
import connect from "react-redux/es/connect/connect";

// App
import columns from "./Columns.js";
import {validateColumns, renderColumns} from 'columnsUtils'
import styles from "assets/jss/styles.js"

const selector = formValueSelector('devisBijouterieForm')

class AssuranceLocal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          touched : false
        };

        this.columns1 = [];
        this.columns2 = [];
        this.columns3 = [];
        this.columns4 = [];
        this.columns5 = [];
        this.columns6 = [];
        this.columns7 = [];
        this.columns8 = [];
        this.columns9 = [];
        this.columns10 = [];
        this.columns11 = [];
    }

    shouldComponentUpdate(nextProps, nextState) {

        this.columns1 = columns(
            {
            }
        )['assuranceLocal'];

        this.columns2 = columns(
            {
                obj:this,
                assuranceLocalValue:nextProps.assuranceLocalValue,
                assuranceLocalRisqueAggravantValue:nextProps.assuranceLocalRisqueAggravantValue,

            }
        )['assuranceLocalImmeuble'];

        this.columns3 = columns(
            {
                assuranceLocalValue:nextProps.assuranceLocalValue,
                precisionProposantQualiteValue:nextProps.precisionProposantQualiteValue,

            }
        )['assuranceLocalProposant'];

        this.columns4 = columns(
            {
                assuranceLocalValue:nextProps.assuranceLocalValue,
            }
        )['assuranceLocalRenonciation'];

        this.columns5 = columns(
            {
                assuranceLocalValue:nextProps.assuranceLocalValue,
            }
        )['assuranceLocalBatimentProprietaire'];

        this.columns6 = columns(
            {
                assuranceLocalValue:nextProps.assuranceLocalValue,
            }
        )['assuranceLocalIncendie'];

        this.columns7 = columns(
            {
                assuranceLocalValue:nextProps.assuranceLocalValue,
            }
        )['assuranceLocalDegatsEaux'];

        this.columns8 = columns(
            {
                assuranceLocalValue:nextProps.assuranceLocalValue,
            }
        )['assuranceLocalBrisAccidentel'];

        this.columns9 = columns(
            {
                assuranceLocalValue:nextProps.assuranceLocalValue,
            }
        )['assuranceLocalDommagesMateriels'];

        this.columns10 = columns(
            {
                assuranceLocalValue:nextProps.assuranceLocalValue,
                rcPrestationsTiersValue:nextProps.rcPrestationsTiersValue,
                rcActiviteFabricationValue:nextProps.rcActiviteFabricationValue,
            }
        )['assuranceLocalRC'];

        this.columns11 = columns(
            {
                assuranceLocalValue:nextProps.assuranceLocalValue,
            }
        )['assuranceLocalPerteExploitation'];

        return true;
    }


    isValidated() {
        let errors = validateColumns(this.props.myform.devisBijouterieForm.values, this.columns1);
        errors = {...validateColumns(this.props.myform.devisBijouterieForm.values, this.columns2), ...errors};
        errors = {...validateColumns(this.props.myform.devisBijouterieForm.values, this.columns3), ...errors};
        errors = {...validateColumns(this.props.myform.devisBijouterieForm.values, this.columns4), ...errors};
        errors = {...validateColumns(this.props.myform.devisBijouterieForm.values, this.columns5), ...errors};
        errors = {...validateColumns(this.props.myform.devisBijouterieForm.values, this.columns6), ...errors};
        errors = {...validateColumns(this.props.myform.devisBijouterieForm.values, this.columns7), ...errors};
        errors = {...validateColumns(this.props.myform.devisBijouterieForm.values, this.columns8), ...errors};
        errors = {...validateColumns(this.props.myform.devisBijouterieForm.values, this.columns9), ...errors};
        errors = {...validateColumns(this.props.myform.devisBijouterieForm.values, this.columns10), ...errors};
        errors = {...validateColumns(this.props.myform.devisBijouterieForm.values, this.columns11), ...errors};
        this.setState({ touched: true});
        return Object.keys(errors).length === 0;
    }

    sendState() {
        return this.state;
    }

    render() {

        const { classes, fetching } = this.props;

        // Cas spécifique pour l'autocomplete
        if (fetching) {
            return (<div></div>)
        }

        return (
            <>
                <>
                    {
                        renderColumns(
                            {
                                columns: this.columns1,
                                titre : "Assurance du local",
                                classes: classes,
                                fetching: fetching,
                                touched: this.state.touched
                            }
                        )
                    }
                </>
                {this.props.assuranceLocalValue &&
                    <>
                        <>
                            {
                                renderColumns(
                                    {
                                        columns: this.columns2,
                                        titre : <div>&nbsp;</div>,
                                        soustitre: "Précisions sur l’immeuble",
                                        classes: classes,
                                        fetching: fetching,
                                        touched: this.state.touched
                                    }
                                )
                            }
                        </>
                        <>
                            {
                                renderColumns(
                                    {
                                        columns: this.columns3,
                                        titre : <div>&nbsp;</div>,
                                        soustitre: "Précision sur le Proposant",
                                        classes: classes,
                                        fetching: fetching,
                                        touched: this.state.touched
                                    }
                                )
                            }
                        </>
                        <>
                            {
                                renderColumns(
                                    {
                                        columns: this.columns4,
                                        titre : <div>&nbsp;</div>,
                                        soustitre: "Renonciation à recours",
                                        classes: classes,
                                        fetching: fetching,
                                        touched: this.state.touched
                                    }
                                )
                            }
                        </>
                        <>
                            {
                                renderColumns(
                                    {
                                        columns: this.columns5,
                                        titre : <div>&nbsp;</div>,
                                        soustitre: "Assurance des bâtiments pour le compte du propriétaire",
                                        classes: classes,
                                        fetching: fetching,
                                        touched: this.state.touched
                                    }
                                )
                            }
                        </>
                        <>
                            {
                                renderColumns(
                                    {
                                        columns: this.columns6,
                                        titre : <div>&nbsp;</div>,
                                        soustitre: "INCENDIE & RISQUES ANNEXES",
                                        classes: classes,
                                        fetching: fetching,
                                        touched: this.state.touched
                                    }
                                )
                            }
                        </>
                        <>
                            {
                                renderColumns(
                                    {
                                        columns: this.columns7,
                                        titre : <div>&nbsp;</div>,
                                        soustitre: "DEGATS DES EAUX",
                                        classes: classes,
                                        fetching: fetching,
                                        touched: this.state.touched
                                    }
                                )
                            }
                        </>
                        <>
                            {
                                renderColumns(
                                    {
                                        columns: this.columns8,
                                        titre : <div>&nbsp;</div>,
                                        soustitre: "Bris accidentel des enseignes lumineuses et journaux lumineux",
                                        classes: classes,
                                        fetching: fetching,
                                        touched: this.state.touched
                                    }
                                )
                            }
                        </>
                        <>
                            {
                                renderColumns(
                                    {
                                        columns: this.columns9,
                                        titre : <div>&nbsp;</div>,
                                        soustitre: "Dommages matériels",
                                        classes: classes,
                                        fetching: fetching,
                                        touched: this.state.touched
                                    }
                                )
                            }
                        </>
                        <>
                            {
                                renderColumns(
                                    {
                                        columns: this.columns10,
                                        titre : <div>&nbsp;</div>,
                                        soustitre: "RESPONSABILITE CIVILE (Maximum 20 employés)",
                                        classes: classes,
                                        fetching: fetching,
                                        touched: this.state.touched
                                    }
                                )
                            }
                        </>
                        <>
                            {
                                renderColumns(
                                    {
                                        columns: this.columns11,
                                        titre : <div>&nbsp;</div>,
                                        soustitre: "PERTE D’EXPLOITATION ET VALEUR VENALE DU FONDS DE COMMERCE",
                                        classes: classes,
                                        fetching: fetching,
                                        touched: this.state.touched
                                    }
                                )
                            }
                        </>
                    </>
                }
            </>

        );
    }
}

const mapStateToProps = store => ({
    myform:store.form,
    assuranceLocalValue: selector(store, 'assuranceLocal'),
    precisionProposantQualiteValue: selector(store, 'precisionProposantQualite'),
    assuranceLocalRisqueAggravantValue: selector(store, 'assuranceLocalRisqueAggravant'),
    rcPrestationsTiersValue: selector(store, 'rcPrestationsTiers'),
    rcActiviteFabricationValue: selector(store, 'rcActiviteFabrication'),
});

const mapDispatchToProps = {
};

export default
connect(mapStateToProps, mapDispatchToProps) (
    // reduxForm nécéssaire pour le this.props.change
    reduxForm({form: 'devisBijouterieForm'}) (
        withStyles(styles)
       (
           AssuranceLocal
       )
    )
);

