// React
import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route } from 'react-router-dom'
import { createBrowserHistory } from "history";
import { tokenMiddleware } from "./middleware/tokenMiddleware";

// Material UI

// Creative Tim
import "creativetim/material-dashboard-pro/assets/scss/material-dashboard-pro-react.scss?v=1.8.0";

// Vendor
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'

// App
import reducers from './reducers'
import App from './components/App';
import './index.css';

// Const
const createStoreWithMiddleware = applyMiddleware(thunkMiddleware, tokenMiddleware)(createStore)
const history = createBrowserHistory();

ReactDOM.render(
  <Provider store={createStoreWithMiddleware(reducers, process.env.REACT_APP_DEVTOOLS_EXTENSION ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() : [])}>
    <Router history={history}>
        <Route path="/" component={App}/>
    </Router>
  </Provider>,
  document.getElementById('root'))
