import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Redux
import {reduxForm} from 'redux-form'
import connect from "react-redux/es/connect/connect";

// App
import columns from "./Columns.js";
import {validateColumns, renderColumns} from 'columnsUtils'
import styles from "assets/jss/styles.js"


class InformationsPublic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          touched : false
        };

        this.columns = [];
    }

    componentDidMount() {

    }

    shouldComponentUpdate(nextProps, nextState) {
        this.columns = columns(
            {
            }
        )['informationsPublic'];

        return true;
    }

    isValidated() {
        let errors = validateColumns(this.props.myform.devisInformatiqueForm.values, this.columns);
        this.setState({ touched: true});
        return Object.keys(errors).length === 0;
    }

    sendState() {
        return this.state;
    }

    render() {

        const { classes, fetching } = this.props;

        return (
            renderColumns(
                {
                    columns: this.columns,
                    titre: 'Informations',
                    classes: classes,
                    fetching: fetching,
                    touched: this.state.touched
                }
            )
        );
    }
}

const mapStateToProps = store => ({
    ...store.requestReducer,
    ...store.courtiersReducer,
    ...store.clientsReducer,
    ...store.configurationInformatiqueReducer,
    initialValues:{...store.devisInformatiqueReducer},
    myform:store.form
});

const mapDispatchToProps = {
};

export default
connect(mapStateToProps, mapDispatchToProps) (
    // reduxForm nécéssaire pour le this.props.change
    reduxForm({form: 'devisInformatiqueForm'}) (
        withStyles(styles)
       (
          InformationsPublic
       )
    )
);

