import extendedFormsStyle from "creativetim/material-dashboard-pro/assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import stylesUserProfile from "creativetim/material-dashboard-pro/assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import stylesCustomInput from "creativetim/material-dashboard-pro/assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import stylesRegularFormsStyle from "creativetim/material-dashboard-pro/assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import wizardStyle from "creativetim/material-dashboard-pro/assets/jss/material-dashboard-pro-react/components/wizardStyle.js";
import stylesModal from "creativetim/material-dashboard-pro/assets/jss/material-dashboard-pro-react/modalStyle.js";

import {
    primaryColor,
    dangerColor,
    blackColor,
    hexToRgb,
    tooltip
} from "creativetim/material-dashboard-pro/assets/jss/material-dashboard-pro-react.js";


const styles = theme => ({
    ...stylesModal(theme),
    ...stylesUserProfile,
    ...stylesRegularFormsStyle,
    ...stylesCustomInput,
    ...extendedFormsStyle,
    ...wizardStyle,
    ...tooltip,
    linearProgressWrapper:{
        position:'absolute',
        width:'calc(100% - 60px)',
        top:'49%'
    },
    labelSwitch:{
        paddingTop:'10px'
    },
    justifyContentCenter: {
        justifyContent: "center"
    },
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center",
    },
    infoText2: {
        fontWeight: "bold",
        margin: "10px 0 30px",
        textAlign: "center",
        fontSize:'0.9em'
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    inputAdornment: {
        position: "relative"
    },
    labelError:{
        fontWeight: "300",
        color: dangerColor[0]

    },
    checkRoot: {
        padding: "6px 16px",
    },
    radioRoot: {
        padding: "6px 16px",
        "&:hover": {
            backgroundColor: "unset"
        }
    },
    datePickerGroup: {
        marginTop:'22px'

    },
    radioGroup: {
        marginTop:'22px'
    },
    checkGroup: {
        marginTop:'22px',
    },
    inputGroup: {
        width:'100%'
    },
    floatLeft: {
        float:'left'
    },
    disabled:{
        opacity:0.5,
        cursor:'initial'
    },
    dropzonePadding: {
        paddingTop:'50px'
    },
    cursorPointer:{
        cursor:'pointer'
    },
    textCenter:{
        textAlign: "center"
    },
    radioChecked: {
        ...stylesRegularFormsStyle.radioChecked,
        width: "16px!important",
        height: "16px!important",
    },
    radioUnchecked: {
        ...stylesRegularFormsStyle.radioUnchecked,
        width: "0px!important",
        height: "0px!important",
    },

    underline: {
        ...stylesCustomInput.underline,

    },
    labelHorizontal :{
        ...extendedFormsStyle.labelHorizontal,
        color: "rgba(" + hexToRgb(blackColor) + ", 0.54)",
        cursor:"default",
        textAlign:"right",
    },
    errorHorizontal :{
        paddingTop: "39px",
    },
    labelLeft: {
        float:'left'
    },
    label: {
        ...extendedFormsStyle.label,
        color: "rgba(" + hexToRgb(blackColor) + ", 0.54)",
    },
    labelRoot: {
        ...stylesCustomInput.labelRoot,// annule la classes dans customCheckboxRadioSwitch
        color: "rgba(" + hexToRgb(blackColor) + ", 0.54)!important",

    },
    input: {
        ...stylesCustomInput.input,
        "&::placeholder": {
            color: "rgba(" + hexToRgb(blackColor) + ", 0.54)",
        }
    },
    width50 : {
        width:'50%'
    },
    marginAuto: {
        margin:'auto'
    },
    tooltip:{
        fontSize:'1.3rem',
        marginTop:'-1px'
    },
    whiteSpaceNoWrap: {
        textAlign:'center',

    },
    classeCol1:{
        width:'120px',

        verticalAlign:'top'
    },
    classeCol2:{
        width:'240px',
        verticalAlign:'top'
    },
    alignItemsCenter: {
        alignItems:'center',
        " & label" : {
            paddingTop:'0!important'
        }
    },
    textLeft:{
        textAlign:'left'
    },
    addActiviteSuperficie: {
        marginTop:"13px"
    },
    selectLabel: {
        top: "4px"
    },
    multiCheckboxChild: {
        paddingTop:'5px!important'
    },
    textRight:{
        textAlign:'right',
    },
    selectFormControl: {
        ...extendedFormsStyle.selectFormControl,
        "& > div": {
            ...extendedFormsStyle.selectFormControl["& > div"],
            "&:after": {
                borderBottomColor: primaryColor[0]
            }
        }
    },
    noPadding:{
        padding:"0!important"
    }
})

export default styles;
