// React
import React from 'react'

// Redux
import { connect } from 'react-redux'
import {reduxForm,Field} from 'redux-form'

// @material-ui/core components
import { InputAdornment, CircularProgress, Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Email as EmailIcon } from "@material-ui/icons";

// Creative Tim
import GridContainer from "creativetim/material-dashboard-pro/components/Grid/GridContainer.js";
import GridItem from "creativetim/material-dashboard-pro/components/Grid/GridItem.js";
import Button from "creativetim/material-dashboard-pro/components/CustomButtons/Button.js";
import Card from "creativetim/material-dashboard-pro/components/Card/Card.js";
import CardBody from "creativetim/material-dashboard-pro/components/Card/CardBody.js";
import CardHeader from "creativetim/material-dashboard-pro/components/Card/CardHeader.js";
import CardFooter from "creativetim/material-dashboard-pro/components/Card/CardFooter.js";
import stylesLoginPage from "creativetim/material-dashboard-pro/assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import stylesCustomInput from "creativetim/material-dashboard-pro/assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import { primaryColor } from "creativetim/material-dashboard-pro/assets/jss/material-dashboard-pro-react.js";

// App
import {renderInput} from 'formUtils'
import {userLogin} from "../actions";

const styles = theme => ({
    ...stylesLoginPage(theme),
    ...stylesCustomInput
})

const verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
        return true;
    }
    return false;
};

function validate (values) {
    const errors = {}
    if(!values.email ){
        errors.email= "L'e-mail est requis"
    }
    if (!verifyEmail(values.email)) {
        errors.email= "L'e-mail est invalide"
    }
    if(!values.password ){
        errors.password= "Le mot de passe est requis"
    }
    return errors;
}

const formConfig ={
    form: 'LoginForm',
    fields : ['email', 'password'],
    validate
}

const useStyles = makeStyles(styles);




function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const { handleSubmit, fetching, isAuthenticated,} = props
    const classes = useStyles();
    if (isAuthenticated) {
        return (<></>);
    }

    setTimeout(function() {
        setCardAnimation("");
    }, 700);

    function login(post) {
        return props.userLogin(post.email, post.password)
    }
  return (

    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={handleSubmit(login)} >
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>Connectez-vous<br/>à votre espace courtier</h4>

              </CardHeader>
              <CardBody>
                <Field
                    name="email"
                    id="email"
                    component={renderInput}
                    label="E-mail"
                    placeholder="Taper votre adresse e-mail"
                    classes={classes}
                    fetching={fetching}
                    inputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <EmailIcon className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                    )
                    }}
                />
                <Field
                    name="password"
                    id="password"
                    type="password"
                    component={renderInput}
                    label="Password"
                    placeholder="Taper votre mot de passe"
                    classes={classes}
                    fetching={fetching}
                    inputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                        lock_outline
                        </Icon>
                        </InputAdornment>
                    ),
                }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>

        {fetching &&
            <div className={classes.root}>
                <CircularProgress  style={{ marginBottom:"18px", color: primaryColor[1]}}/>
            </div>
        }
        {!fetching &&
            <Button disabled={fetching} type="submit" color="rose" simple size="lg" block>
              Connexion
            </Button>
        }


    </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}


function  mapStateToProps(store) {


    return {
        fetching: store.requestReducer.fetching,
        isAuthenticated: store.authReducer.isAuthenticated
    }
}

const mapDispatchToProps = {
    userLogin
}

export default
connect(mapStateToProps, mapDispatchToProps)(
    reduxForm(formConfig) (
        (LoginPage)
    )
)

//