// React
import React from "react";

// App
import {renderCaptcha, renderDate, renderPeriod, renderText, renderTextarea, renderInput, renderMultiInput, renderSelect, renderHidden, renderAutocomplete, renderMultiCheckbox, renderCheckbox, renderRadio} from 'formUtils'

const columns = function (props) {
    return {
        'informationsPublic': [
            {name:"proposantNom", required:true, label:"Nom et prénom du proposant ou Raison Sociale", component:renderInput},
            {name:"proposantAdresse", required:true, label:"Adresse", component:renderInput},
            {name:"proposantVille", required:true, label:"Ville", component:renderInput},
            {name:"proposantCodepostal", required:true, label:"Code postal", component:renderInput},
            {
                name: "ispublic",
                component: renderHidden,
            },
        ],
        'informations': [
            {
                name: "utilisateur",
                component: renderHidden,
                required: true,
                reference: 'utilisateurList'
            },
            {
                name: "utilisateurList",
                label: "Utilisateur",
                component: renderAutocomplete,
                componentOptions: {
                    list: props.courtiers,
                    change: props.changeCourtier ? props.changeCourtier.bind(props.obj) : null,

                },
                fieldProps: {
                    selectedValue: props.courtiers != null ? props.getSelectedValueCourtier(props.obj) : null
                }
            },
            {
                name:"client",
                component:renderHidden,
                required:true,
                reference:'clientList'

            },
            {
                name:"clientList",
                label:"Client",
                component:renderAutocomplete,
                componentOptions:{
                    list:props.clients,
                    change:props.changeClient ? props.changeClient.bind(props.obj) : null
                },
                fieldProps: {
                    selectedValue:props.clients != null ? props.getSelectedValueClient(props.obj) : null
                }
            },
            {
                name: "utilisateur",
                component: renderHidden,
                componentOptions: {value:'/utilisateurs/0'}
            },
            {
                name:"client",
                component:renderHidden,
                componentOptions: {value:'/clients/0'}
            },
            {
                name:"config",
                component:renderHidden,
                componentOptions:{value:'/produit_bijouterie_configs/0'}
            },
            {
                name:"fraisIntermediation",
                label:"Frais d'intermédiation",
                component:renderSelect,
                required:true,
                componentOptions:{
                    options:props.getFraisIntermediation ? props.getFraisIntermediation(props.obj) : null
                }
            }
        ],
        'informationsDevis' : [
            {
                name:"infoDevisAffaireNouvelle",
                label:"Affaire nouvelle",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"infoDevisRemplacementContratNum",
                label:"Remplacement de contrat N°",
                component:renderInput,
                condition:() => {return false === props.infoDevisAffaireNouvelleValue},
            },
            {
                name:"infoDevisDateEffet",
                component: renderHidden,
                reference: 'contratResilieDatePicker'
            },
            {
                name:"infoDevisDateEffetDatePicker",
                label:"Date d’effet au",
                component:renderDate,
                fieldProps: {
                    selectedValue: props.infoDevisDateEffetValue,
                    placeholder:'JJ/MM/AAAA',
                    dateTimeRef:props.obj ? props.obj.dateTimeDateEffetRef : null
                },
                componentOptions: {
                    change: props.changeInfoDevisDateEffet ? props.changeInfoDevisDateEffet.bind(props.obj) : null,

                },
            },
            {
                name:"infoDevisDateEcheance",
                label:"Date d’échéance principale au",
                component:renderInput,
            },

        ],
        'informationsRisqueProposant' : [
            {
                name:"proposantSiret",
                label:"SIRET",
                component:renderInput
            },
            {
                name:"proposantGroupement",
                label:"Faisant partie d'un Groupement ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"proposantGroupementDetail",
                label:"Si OUI, lequel",
                component:renderInput,
                condition:() => {return true === props.proposantGroupementValue},
            },
            {
                name:"proposantExerceDepuis",
                label:"Exerce ce commerce depuis combien de temps ?",
                component:renderInput
            },
        ],
        'informationsRisqueActivite' : [
            {
                name:"activitesNature",
                label:"Nature des activités exercées",
                component:renderMultiCheckbox,
                required:true,
                componentOptions:{
                    options:[
                        {
                            componentOptions: {
                                checkboxLabel:"Bijouterie"
                            },
                            value:"1",
                            name:"activitesNatureBijouterie",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel: "Horlogerie",
                            },
                            value:"1",
                            name:"activitesNatureHorlogerie",
                            parse: value => Boolean(Number(value)),
                        },
                    ]
                },
            },
            {
                name:"activitesRepartition",
                label:"Répartition des activités exercées",
                component:renderMultiInput,
                required:true,
                validate:(total) => {return total === 100},
                error: "Le total doit être égal à 100%",
                componentOptions:{
                    options:[
                        {
                            label:"% Détail",
                            component:renderInput,
                            name:"activitesRepartitionDetail",
                            fieldProps:{type:"number"},
                        },
                        {
                            label:"% Fabrication",
                            component:renderInput,
                            name:"activitesRepartitionFabrication",
                            fieldProps:{type:"number"},
                        },
                        {
                            label:"% Gros",
                            component:renderInput,
                            name:"activitesRepartitionGros",
                            fieldProps:{type:"number"},
                        },
                        {
                            label:"% Diamantaire",
                            component:renderInput,
                            name:"activitesRepartitionDiamantaire",
                            fieldProps:{type:"number"},
                        },
                        {
                            label:"% Autre",
                            component:renderInput,
                            name:"activitesRepartitionAutre",
                            fieldProps:{type:"number"},
                        },
                    ]
                },
            },
            {
                name:"activitesCaHt",
                label:"Chiffre d’affaires annuel Hors Taxes",
                component:renderInput
            },
            {
                name:"activitesLiquidationOuRedressement",
                label:"En état de liquidation de biens ou redressement judiciaire depuis moins de 3 ans",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"activitesProprietaireCommerce",
                label:"Propriétaire du fonds de commerce",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"activitesNombrePersonne",
                label:"Nombre de personnes travaillant (y compris exploitant, proposant, membres de la famille)",
                component:renderInput
            },
            {
                name:"activitesNombreMiniSalaries",
                label:"Nombre minimum de salariés présents pendant les heures d'exploitation",
                component:renderInput
            },
            {
                name:"activitesNombreMiniSalariesDejeuner",
                label:"dont les heures de déjeuner",
                component:renderInput
            },
            {
                name:"activitesDureeInoccupationAnnuelle",
                label:"Durée d'inoccupation annuelle (en jours)",
                component:renderInput
            },
        ],
        'informationsRisqueLocalCommercial' : [
            {
                name:"localDenominationEnseigne",
                label:"Dénomination de l’enseigne",
                component:renderInput
            },
            {
                name:"localAdresse",
                label:"Adresse des locaux",
                component:renderInput
            },
            {
                name:"localCodePostal",
                label:"Code Postal",
                component:renderInput
            },
            {
                name:"localVille",
                label:"Commune",
                component:renderInput
            },
            {
                name:"localLocalisation",
                label:"Localisation des locaux",
                component:renderMultiCheckbox,
                required:true,
                componentOptions:{
                    options:[
                        {
                            componentOptions: {
                                checkboxLabel:"Centre commercial"
                            },
                            value:"1",
                            name:"localLocalisationCentreCommercial",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel: "Centre ville",
                            },
                            value:"1",
                            name:"localLocalisationCentreVille",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel:"Rue piétonne"
                            },
                            value:"1",
                            name:"localLocalisationRuePietonne",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel: "ZAC",
                            },
                            value:"1",
                            name:"localLocalisationZac",
                            parse: value => Boolean(Number(value)),
                        },
                    ]
                },
            },
            {
                name:"localNature",
                label:"Nature des locaux",
                component:renderMultiCheckbox,
                required:true,
                componentOptions:{
                    options:[
                        {
                            componentOptions: {
                                checkboxLabel:"Magasin"
                            },
                            value:"1",
                            name:"localNatureMagasin",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel: "Atelier",
                            },
                            value:"1",
                            name:"localNatureAtelier",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel:"Appartement"
                            },
                            value:"1",
                            name:"localNatureAppartement",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel: "Stand",
                            },
                            value:"1",
                            name:"localNatureStand",
                            parse: value => Boolean(Number(value)),
                        },
                        {
                            componentOptions: {
                                checkboxLabel: "Kiosque",
                            },
                            value:"1",
                            name:"localLocalNatureKiosque",
                            parse: value => Boolean(Number(value)),
                        },
                    ]
                },
            },
            {
                name:"localNiveauxOccupes",
                label:"Niveau(x) occupé(s) par les locaux",
                component:renderInput,
                fieldProps:{type:"number"},
                parse: value => Number(value),
            },
            {
                name:"localEtages",
                label:"Etages(s) des locaux",
                component:renderInput
            },
            {
                name:"localSurfaceOccupee",
                label:"Surface occupée par les locaux (en m2)",
                component:renderInput
            },
        ],
        'assuranceStockValeur' : [
            {
                name:"garantiesStockValeurTotale",
                label:"Calculée sur la base du Prix d’Achat Hors Taxes. Max 1 M€",
                fieldProps:{type:"number"},
                required:true,
                component:renderInput
            },
        ],
        'assuranceStockGaranties' : [
            {
                name:"garantiesStockValeur",
                label:"Stock assuré y compris confiés clients, fournisseurs",
                component:renderMultiInput,
                required:true,
                validate:(total) => {return total === 100},
                error: "Le total doit être égal à 100%",
                componentOptions:{
                    options:[
                        {
                            label:"% Précieux",
                            component:renderInput,
                            fieldProps:{type:"number"},
                            name:"garantiesStockValeurPrecieux",
                        },
                        {
                            label:"% Non Précieux",
                            component:renderInput,
                            fieldProps:{type:"number"},
                            name:"garantiesStockValeurNonPrecieux",
                        },
                    ]
                },
            },
            {
                name:"garantiesAugmentation25Debut1",
                component: renderHidden,
                reference: 'garantiesAugmentation25Debut1DatePicker'
            },
            {
                name:"garantiesAugmentation25Fin1",
                component: renderHidden,
                reference: 'garantiesAugmentation25Fin1DatePicker'
            },
            {
                name:"garantiesAugmentation",
                label:"Augmentation du stock de 25% (120 jours max)",
                component:renderPeriod,
                fieldProps:{
                    name1:"garantiesAugmentation25Debut1DatePicker",
                    name2:"garantiesAugmentation25Fin1DatePicker",
                    selectedValue1: props.garantiesAugmentation25Debut1Value,
                    selectedValue2: props.garantiesAugmentation25Fin1Value,
                    placeholder1:'DU',
                    placeholder2:'AU',
                    change1: props.changeGarantiesAugmentation25Debut1 ? props.changeGarantiesAugmentation25Debut1.bind(props.obj) : null,
                    change2: props.changeGarantiesAugmentation25Fin1 ? props.changeGarantiesAugmentation25Fin1.bind(props.obj) : null,


                },
            },
            {
                name:"garantiesAugmentation25Debut2",
                component: renderHidden,
                reference: 'garantiesAugmentation25Debut2DatePicker'
            },
            {
                name:"garantiesAugmentation25Fin2",
                component: renderHidden,
                reference: 'garantiesAugmentation25Fin2DatePicker'
            },
            {
                name:"garantiesAugmentation2",
                label:"",
                component:renderPeriod,
                fieldProps:{
                    name1:"garantiesAugmentation25Debut2DatePicker",
                    name2:"garantiesAugmentation25Fin2DatePicker",
                    selectedValue1: props.garantiesAugmentation25Debut2Value,
                    selectedValue2: props.garantiesAugmentation25Fin2Value,
                    placeholder1:'DU',
                    placeholder2:'AU',
                    change1: props.changeGarantiesAugmentation25Debut2 ? props.changeGarantiesAugmentation25Debut2.bind(props.obj) : null,
                    change2: props.changeGarantiesAugmentation25Fin2 ? props.changeGarantiesAugmentation25Fin2.bind(props.obj) : null,


                },
            },
            {
                name:"garantiesDeteriorationMobiliere",
                label:"Détériorations mobilières",
                component:renderRadio,
                required:true,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"25 000 €",value:"25000"},
                        {label:"50 000 €",value:"50000"}
                    ]
                },
            },
            {
                name:"garantiesDeteriorationImmobiliere",
                label:"Détériorations immobilières",
                component:renderRadio,
                required:true,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"15 000 €",value:"15000"},
                        {label:"30 000 €",value:"30000"}
                    ]
                },
            },



        ],
        'assuranceStockGaranties2' : [
            {
                name:"garantiesTerritorialite",
                label:"Territorialité",
                component:renderRadio,
                required:true,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"France",value:"0"},
                        {label:"Europe",value:"1"},
                        {label:"Monde entier",value:"2"}
                    ]
                },
            },
            {
                name:"garantiesExpositions",
                label:"Expositions (4 salons par année d’assurance, dates et lieux à déclarer aux Assureurs 48h avant)",
                component:renderRadio,
                required:true,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"15 000 €",value:"15000"},
                        {label:"30 000 €",value:"30000"},
                        {label:"50 000 €",value:"50000"}
                    ]
                },
            },
            {
                name:"garantiesBrisGlace",
                label:"Bris de glace SANS FRANCHISE",
                component:renderRadio,
                required:true,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"15 000 €",value:"15000"},
                        {label:"5 000 €",value:"5000"},
                    ]
                },
            },

        ],
        'assuranceStockValeur2' : [
            {
                name:"garantiesFranchiseInf150",
                label:"Valeur totale du stock inférieur à 150.000 €",
                component:renderRadio,
                parse: value => Number(value),
                condition:() => {return parseFloat(props.garantiesStockValeurTotaleValue) < 150000 || props.garantiesStockValeurTotaleValue === null || props.garantiesStockValeurTotaleValue === ''},
                required: props.garantiesStockValeurTotaleValue < 150000 || props.garantiesStockValeurTotaleValue === null || props.garantiesStockValeurTotaleValue === '',
                componentOptions:{
                    options:[
                        {label:"500 €",value:"500"},
                        {label:"700 €",value:"700"},
                        {label:"1 500 €",value:"1500"}
                    ]
                },
            },
            {
                name:"garantiesFranchise150300",
                label:"Valeur totale du stock inférieur à 300.000 €",
                component:renderRadio,
                parse: value => Number(value),
                condition:() => {return props.garantiesStockValeurTotaleValue >= 150000 && props.garantiesStockValeurTotaleValue < 300000},
                required: props.garantiesStockValeurTotaleValue >= 150000 && props.garantiesStockValeurTotaleValue < 300000,
                componentOptions:{
                    options:[
                        {label:"1 000 €",value:"1000"},
                        {label:"1 500 €",value:"1500"},
                        {label:"2 500 €",value:"2500"}
                    ]
                },
            },
            {
                name:"garantiesFranchise300500",
                label:"Valeur totale du stock inférieur à 500.000 €",
                component:renderRadio,
                parse: value => Number(value),
                condition:() => {return props.garantiesStockValeurTotaleValue >= 300000 && props.garantiesStockValeurTotaleValue < 500000},
                required: props.garantiesStockValeurTotaleValue >= 300000 && props.garantiesStockValeurTotaleValue < 500000,

                componentOptions:{
                    options:[
                        {label:"2 000 €",value:"2000"},
                        {label:"2 500 €",value:"2500"},
                        {label:"4 000 €",value:"4000"}
                    ]
                },
            },
            {
                name:"garantiesFranchise500750",
                label:"Valeur totale du stock inférieur à 750.000 €",
                component:renderRadio,
                parse: value => Number(value),
                condition:() => {return props.garantiesStockValeurTotaleValue >= 500000 && props.garantiesStockValeurTotaleValue < 750000},
                required: props.garantiesStockValeurTotaleValue >= 500000 && props.garantiesStockValeurTotaleValue < 750000,
                componentOptions:{
                    options:[
                        {label:"3 000 €",value:"3000"},
                        {label:"4 000 €",value:"4000"},
                        {label:"6 000 €",value:"6000"}
                    ]
                },
            },
            {
                name:"garantiesFranchise750Sup",
                label:"Valeur totale du stock inférieur à 1.000.000 €",
                component:renderRadio,
                parse: value => Number(value),
                condition:() => {return props.garantiesStockValeurTotaleValue >= 750000},
                required: props.garantiesStockValeurTotaleValue >= 750000,
                componentOptions:{
                    options:[
                        {label:"5 000 €",value:"5000"},
                        {label:"6 000 €",value:"6000"},
                        {label:"8 000 €",value:"8000"}
                    ]
                },
            },
        ],
        'protectionRisqueProtections' : [
            {
                name:"conditionsRespectees",
                label:"Toutes les conditions sont respectées",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"conditionsRespecteesDetail",
                label:"Si non, veuillez préciser",
                component:renderTextarea,
                condition:() => {return false === props.conditionsRespecteesValue},

            },
            {
                name:"conditionsAutresProtections",
                label:"Autres protections déclarées par l’Assuré",
                component:renderTextarea,

            },

        ],
        'protectionRisqueAlarme' : [
            {
                name:"alarmeMarque",
                label:"Marque",
                component:renderInput,

            },
            {
                name:"alarmeInstallateur",
                label:"Installateur",
                component:renderInput,

            },
            {
                name:"alarmeDateMiseService",
                label:"Date de mise en service",
                component:renderInput,

            },
            {
                name:"alarmeSocieteEntretien",
                label:"Société d’entretien",
                component:renderInput,

            },
        ],
        'protectionRisqueTelesurveillance' : [
            {
                name:"centraleTelesurveillance",
                label:"Centrale de Télésurveillance",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"centraleTelesurveillanceNomSociete",
                label:"Nom de la société",
                component:renderInput,
                condition:() => {return true === props.centraleTelesurveillanceValue},

            },
        ],
        'protectionRisqueCoffre' : [
            {
                name:"coffreFortMarque",
                label:"Marque",
                component:renderInput,
            },
            {
                name:"coffreFortClasse",
                label:"Classe",
                tooltip:
                    <table className={props.classes ? props.classes.whiteSpaceNoWrap : ''}>
                        <thead>
                            <tr>
                                <th className={props.classes ?  props.classes.classeCol1 : ''}>
                                    classe (EN 1143-1)
                                </th>
                                <th className={props.classes ? props.classes.classeCol2 : ''}>
                                    Indication de la valeur du contenu objets de valeur
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    0
                                </td>
                                <td>
                                   14 000 €
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    I
                                </td>
                                <td>
                                   20 000 €
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    II
                                </td>
                                <td>
                                   50 000 €
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    III
                                </td>
                                <td>
                                   90 000 €
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    IV
                                </td>
                                <td>
                                   150 000 €
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    V
                                </td>
                                <td>
                                   250 000 €
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    VI
                                </td>
                                <td>
                                   500 000 €
                                </td>
                            </tr>
                        </tbody>
                    </table>,
                component:renderInput,
            },
            {
                name:"coffreFortPoids",
                label:"Poids",
                component:renderInput,
            },
            {
                name:"coffreFortSousProtection",
                label:"Sous protection du système d’alarme",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"coffreFortContacteurs",
                label:"Equipé de contacteur(s)",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"coffreFortSerrureHoraire",
                label:"Muni d'une serrure horaire",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"coffreFortScelle",
                label:"Scellé",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
        ],
        'protectionRisqueDevanture' :
        [
            {
                name:"devantures",
                label:"Devantures",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"devanturesNbVitres",
                label:"Nombre de vitrines de devanture",
                component:renderInput,
                condition:() => {return true === props.devanturesValue},
            },
            {
                name:"devanturesNatureProduitVerrier",
                label:"Nature du produit verrier",
                component:renderInput,
                condition:() => {return true === props.devanturesValue},
            },
            {
                name:"devanturesEpaisseurProduitVerrier",
                label:"Epaisseur du produit verrier (en mm)",
                component:renderInput,
                condition:() => {return true === props.devanturesValue},
            },
            {
                name:"devanturesVitrinesRelieesAlarme",
                label:"Vitrines reliées à l'alarme",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                condition:() => {return true === props.devanturesValue},
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
        ],
        'protectionRisqueRideaux' :
        [
            {
                name:"rideaux",
                label:"Rideaux",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"rideauxDescriptif",
                label:"Descriptif des rideaux ou grilles (ex. : Rideau métallique à lames pleines, à mailles agrafées, mixte)",
                component:renderInput,
                condition:() => {return true === props.rideauxValue},
            },
            {
                name:"rideauxPlacement",
                label:"Placement par rapport à la devanture",
                component:renderRadio,
                parse: value => Number(value),
                condition:() => {return true === props.rideauxValue},
                componentOptions:{
                    options:[
                        {label:"Intérieur",value:"1"},
                        {label:"Exterieur",value:"0"}
                    ]
                },
            },
            {
                name:"rideauxRelieAlarme",
                label:"Le rideau est-il relié à l'alarme",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                condition:() => {return true === props.rideauxValue},
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"rideauxProtection",
                label:"Protection",
                component:renderRadio,
                parse: value => Number(value),
                condition:() => {return true === props.rideauxValue},
                componentOptions:{
                    options:[
                        {label:"Totale (devanture + porte)",value:"0"},
                        {label:"Partielle",value:"1"},

                    ]
                },
            },
            {
                name:"rideauxProtectionDetail",
                label:"Si Partielle, précisions",
                component:renderInput,
                condition:() => {return 1 === props.rideauxProtectionValue},
            },
        ],
        'protectionRisqueAutreAcces' :
        [
            {
                name:"autresAcces",
                label:"Autres accès (portes/fenêtres/baies vitrées)",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"alarmeDateMiseService",
                label:<strong>Mode de fermeture et de protection</strong>,
                component:renderText,
                text:"",
                condition:() => {return true === props.autresAccesValue},
            },
            {
                name:"autresAccesModeFermeturePorte",
                label:"pour les portes : blindages, nombre de serrures et de points de fermeture",
                component:renderTextarea,
                condition:() => {return true === props.autresAccesValue},
            },
            {
                name:"autresAccesModeFermetureFenetre",
                label:"pour les fenêtres et baies vitrées : présence de volets, barreaux, rideaux métalliques",
                component:renderTextarea,
                condition:() => {return true === props.autresAccesValue},
            },
            {
                name:"autresAccesReliesAlarme",
                label:"Reliés à l'alarme",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                condition:() => {return true === props.autresAccesValue},
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
        ],
        'assuranceLocal' : [
            {
                name:"assuranceLocal",
                label:"ASSURANCE DU LOCAL Multirisque Commerce :",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                tooltip:"Sont exclus les locaux situés dans les bâtiments classés, répertoriés ou inscrits à l’inventaire des bâtiments historiques.",
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },

        ],
        'assuranceLocalImmeuble' : [
            {
                name:"assuranceLocalDateConstruction",
                label:"Date de construction",
                component:renderInput,
                condition:() => {return true === props.assuranceLocalValue},
            },
            {
                name:"assuranceLocalNatureConstruction",
                label:"Nature de la construction",
                component:renderInput,
                condition:() => {return true === props.assuranceLocalValue},
            },
            {
                name:"assuranceLocalNatureCouverture",
                label:"Nature de la couverture",
                component:renderInput,
                condition:() => {return true === props.assuranceLocalValue},
            },
            {
                name:"assuranceLocalEtatEntretien",
                label:"Etat d'entretien (construction, couverture et ciels vitrés, conduites d'eau)",
                component:renderInput,
                condition:() => {return true === props.assuranceLocalValue},
            },
            {
                name:"assuranceLocalNatureProfessionExercees",
                label:"Nature des professions exercées dans l'immeuble",
                component:renderInput,
                condition:() => {return true === props.assuranceLocalValue},
            },
            {
                name:"assuranceLocalRisqueAggravant",
                label:"Risque aggravant, contigu ou à moins de 10 mètres",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                condition:() => {return true === props.assuranceLocalValue},
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"assuranceLocalRisqueAggravantDetail",
                label:"Si OUI, lesquels",
                component:renderInput,
                condition:() => {return true === props.assuranceLocalRisqueAggravantValue},
            },
        ],
        'assuranceLocalProposant' :
        [
            {
                name:"precisionProposantQualite",
                label:"Qualité du Proposant",
                component:renderRadio,
                parse: value => Number(value),
                condition:() => {return true === props.assuranceLocalValue},
                componentOptions:{
                    options:[
                        {label:"Propriétaire des murs",value:"0"},
                        {label:"Locataire des murs",value:"1"},
                    ]
                },
            },
            {
                name:"assuranceLocalText",
                componentOptions:{text:"Pour un locataire, joindre une copie du bail."},
                component:renderText,
                condition:() => {return 1 === props.precisionProposantQualiteValue},
            },
            {
                name:"precisionProposantQualiteType",
                label:"",
                component:renderRadio,
                parse: value => Number(value),
                condition:() => {return true === props.assuranceLocalValue},
                componentOptions:{
                    options:[
                        {label:"Total",value:"0"},
                        {label:"Partiel",value:"1"},
                    ]
                },
            },
        ],
        'assuranceLocalRenonciation' :
        [
            {
                name:"renonciationRecoursProprietaireAssureur",
                label:"Contre le propriétaire et ses assureurs",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                condition:() => {return true === props.assuranceLocalValue},
                componentOptions:{
                    options:[
                        {label:"Propriétaire des murs",value:"0"},
                        {label:"Locataire des murs",value:"1"},
                    ]
                },
            },
            {
                name:"renonciationRecoursReciproque",
                label:"Réciproque",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                condition:() => {return true === props.assuranceLocalValue},
                componentOptions:{
                    options:[
                        {label:"Total",value:"0"},
                        {label:"Partiel",value:"1"},
                    ]
                },
            },
            {
                name:"renonciationRecoursInteretCommun",
                label:"Si intérêt commun entre le propriétaire et le locataire, précisez",
                component:renderInput,
                condition:() => {return true === props.assuranceLocalValue},
            },
        ],
        'assuranceLocalBatimentProprietaire' :
        [
            {
                name:"assuranceBatimentProprietaire",
                label:"Assurance des bâtiments pour le compte du propriétaire",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                condition:() => {return true === props.assuranceLocalValue},
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
        ],
        'assuranceLocalIncendie' :
        [
            {
                name:"incendieRisqueBatiment",
                label:"Sur bâtiment ou risques locatifs (max 1.000 m2)",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                condition:() => {return true === props.assuranceLocalValue},
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"incendieRisqueMobilier",
                label:"Sur Mobilier, matériels professionnels, Agencements et Embellissements (marchandises exclues)",
                component:renderInput,
                condition:() => {return true === props.assuranceLocalValue},
            },
        ],
        'assuranceLocalDegatsEaux' :
        [
            {
                name:"degatsEauxBatiment",
                label:"Sur bâtiment ou risques locatifs (max 1.000 m2)",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                condition:() => {return true === props.assuranceLocalValue},
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"degatsEauxMobilier",
                label:"Sur Mobilier, matériels professionnels, Agencements et Embellissements (marchandises exclues)",
                component:renderInput,
                condition:() => {return true === props.assuranceLocalValue},
            },
        ],
        'assuranceLocalBrisAccidentel' :
        [
            {
                name:"brisAccidentel",
                label:"Bris accidentel des enseignes lumineuses et journaux lumineux",
                component:renderRadio,
                parse: value => Number(value),
                condition:() => {return true === props.assuranceLocalValue},
                componentOptions:{
                    options:[
                        {label:"10 000 €",value:"10000"},
                        {label:"Non",value:"0"},
                    ]
                },
            },
        ],
        'assuranceLocalDommagesMateriels' :
        [
            {
                name:"dommageMateriels",
                label:"Dommages électriques et bris de machine",
                component:renderRadio,
                parse: value => Number(value),
                condition:() => {return true === props.assuranceLocalValue},
                componentOptions:{
                    options:[
                        {label:"10 000 €",value:"10000"},
                        {label:"25 000 €",value:"25000"},
                        {label:"50 000 €",value:"50000"},
                    ]
                },
            },
        ],
        'assuranceLocalRC' :
        [
            {
                name:"rc",
                label:"RESPONSABILITE CIVILE (Maximum 20 employés)",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                condition:() => {return true === props.assuranceLocalValue},
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"rcPrestationsTiers",
                label:"Prestations réalisées chez des tiers",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                condition:() => {return true === props.assuranceLocalValue},
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"rcPrestationsTiersDetail",
                label:"Si OUI, nature des prestations",
                component:renderInput,
                condition:() => {return true === props.assuranceLocalValue && true === props.rcPrestationsTiersValue},
            },
            {
                name:"rcActiviteFabrication",
                label:"Activité de fabrication des produits vendus",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                condition:() => {return true === props.assuranceLocalValue},
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"rcActiviteFabricationDetail",
                label:"Si OUI, nature de ces travaux",
                component:renderInput,
                condition:() => {return true === props.assuranceLocalValue && true === props.rcActiviteFabricationValue},
            },
            {
                name:"rcRecoursSousTraitance",
                label:"Recours à de la sous-traitance",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                condition:() => {return true === props.assuranceLocalValue},
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"rcBiensConfies",
                label:"RESPONSABILITE CIVILE Biens confiés",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                condition:() => {return true === props.assuranceLocalValue},
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
        ],
        'assuranceLocalPerteExploitation' :
        [
            {
                name:"perteExploitation",
                label:"Réservé aux Sociétés en activité depuis plus d’un an",
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                condition:() => {return true === props.assuranceLocalValue},
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
        ],
        'assurancePersonnes' :
        [
            {
                name:"assurancePersonne",
                label:"ASSURANCE DES PERSONNES Individuelle Agression",
                required:true,
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
        ],
        'antecedentsSinistres' :
        [
            {
                name:"sinistresDeclares",
                label:"Sinistres déclarés sur les 5 dernières années",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"sinistresDeclaresDetail",
                label:"Si OUI, indiquez les dates, montants et natures des sinistres",
                component:renderTextarea,
                condition:() => {return true === props.sinistresDeclaresValue},
            },
            {
                name:"sinistresDeclaresMesures",
                label:"Si OUI, mesures préventives prises depuis pour éviter une éventuelle récidive",
                component:renderTextarea,
                condition:() => {return true === props.sinistresDeclaresValue},
            },
        ],
        'antecedentsAssurancesCumulatives' :
        [
            {
                name:"assuranceCumulative",
                label:"Risques assurés partiellement ou en totalité par des polices en cours",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"assuranceCumulativeDetail",
                label:"Si OUI, précisez le nom de l’Assureur, le numéro de police et les capitaux",
                component:renderInput,
                condition:() => {return true === props.assuranceCumulativeValue},
            },
        ],
        'antecedentsAssurancesAnterieures' :
        [
            {
                name:"assuranceAnterieurRefus",
                label:"Des Assureurs ont-ils déjà refusé de vous assurer, de continuer une assurance avec vous ou ont-ils déjà résilié une assurance avec vous au cours des cinq dernières années",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"assuranceAnterieurAssure",
                label:"Avez-vous été assuré précédemment ?",
                component:renderRadio,
                required:true,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Oui",value:"1"},
                        {label:"Non",value:"0"}
                    ]
                },
            },
            {
                name:"assuranceAnterieurAssureDetail",
                label:"Si OUI, précisez le nom de l’Assureur, le numéro de police et les capitaux, ainsi que le motif de la résiliation",
                component:renderInput,
                condition:() => {return true === props.assuranceAnterieurAssureValue},
            },
        ],
        'antecedentsAutres' :
        [
            {
                name:"autre",
                label:"En dehors des renseignements fournis ci-dessus, précisez les autres faits relatifs à la présente proposition, qui devraient être communiqués aux Assureurs pour leur appréciation du risque",
                component:renderTextarea,
            },
        ],
        'state' : [
            {
                name:"config",
                required:true,
                component:renderHidden,
                componentOptions:{value:props.config}
            },
            {
                name:"statutPriseGarantie",
                required:true,
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Prise de garantie refusée",value:"0"},
                        {label:"Prise de garantie validée",value:"1"},
                        {label:"Prise de garantie en cours",value:"2"},
                        {label:"Prise de garantie en attente",value:"3"},
                    ]
                }

            },
            {
                name:"signatureContrat",
                required:true,
                component:renderRadio,
                parse: value => Boolean(Number(value)),
                componentOptions:{
                    options:[
                        {label:"Contrat signé",value:"1"},
                        {label:"Contrat non signé",value:"0"},
                    ]
                }

            },
            {
                name:"statutQuittance",
                required:true,
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Quittance à jour",value:"1"},
                        {label:"Quittance à régulariser",value:"0"},
                    ]
                }

            },
            {
                name:"statutContrat",
                required:true,
                component:renderRadio,
                parse: function (value) {
                    // Workaround pour les calendriers
                    if (value === "0" || value === "1" || value === "2" || value === "3" || value === "4") {
                        return Number(value);
                    }
                    return props.statutContratValue;
                },
                componentOptions:{
                    options:[
                        {label:"Contrat à échéance",value:"2"},
                        {
                            label:"Contrat résilié",
                            value:"1",
                            child: [
                                {
                                    name:"dateContratResilie",
                                    component: renderHidden,
                                    reference: 'contratResilieDatePicker'
                                },
                                {
                                    name:"contratResilieDatePicker",
                                    component:renderDate,
                                    fieldProps: {
                                        selectedValue: props.dateContratResilieValue,
                                        placeholder:'Date résiliation',
                                        dateTimeRef:props.obj ? props.obj.dateTimeResilieRef : null
                                    },
                                    componentOptions: {
                                        change: props.changeDateResilie ? props.changeDateResilie.bind(props.obj) : null,

                                    },
                                    condition:() => {return 1 === props.statutContratValue},

                                },
                            ]

                        },
                        {
                            label:"Contrat non résilié",
                            value:"0"
                        },
                        {
                            label:"Contrat sans effet",
                            value:"4",
                            child: [
                                {
                                    name:"dateContratSanseffet",
                                    component: renderHidden,
                                    reference: 'contratSanseffetDatePicker'
                                },
                                {
                                    name:"contratSanseffetDatePicker",
                                    component:renderDate,
                                    placeholder:'test',
                                    fieldProps: {
                                        selectedValue: props.dateContratSanseffetValue,
                                        placeholder:'Date sans effet',
                                        dateTimeRef:props.obj ? props.obj.dateTimeSansEffetRef : null
                                    },
                                    componentOptions: {
                                        change: props.changeDateSanseffet ? props.changeDateSanseffet.bind(props.obj) : null,

                                    },
                                    condition:() => {return 4 === props.statutContratValue},

                                },
                            ]
                        },
                    ]
                }

            },
            {
                name:"statutDevis",
                required:true,
                component:renderRadio,
                parse: value => Number(value),
                componentOptions:{
                    options:[
                        {label:"Devis en cours de cotation",value:"3"},
                        {label:"Devis valide",value:"1"},
                        {label:"Devis non valide",value:"0"},
                    ]
                }
            },
        ],
        'protectionDonneesPublic' : [
            {
                name:"rgpd",
                required:true,
                component:renderCheckbox,
                componentOptions: {
                    checkboxLabel:"En cochant cette case, vous acceptez que vos données soient utilisées dans le cadre d’une demande d’assurance"
                }
            },
            {name:"captcharesponse", required:true, component:renderCaptcha}

        ]
    }
}
export default columns;


