// React
import React from "react";

// @material-ui
import withStyles from "@material-ui/core/styles/withStyles";

// Redux
import connect from "react-redux/es/connect/connect";

// Creative Tim
import GridContainer from "creativetim/material-dashboard-pro/components/Grid/GridContainer.js";
import GridItem from "creativetim/material-dashboard-pro/components/Grid/GridItem.js";

// App
import styles from "assets/jss/styles.js"
import columns from "./Columns.js";
import {validateColumns, renderColumns} from 'columnsUtils'

class ProtectionDonneesPublic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          touched : false
        };
        this.columns = [];

    }
    shouldComponentUpdate(nextProps, nextState) {
        this.columns = columns(
            {
            }
        )['protectionDonneesPublic'];

        return true;
    }

    sendState() {
    }
    isValidated() {
        let errors = validateColumns(this.props.myform.devisBijouterieForm.values, this.columns);
        this.setState({ touched: true});
        return Object.keys(errors).length === 0;
    }

    render() {
        const { classes, fetching } = this.props;
        return (
            <>
                <GridContainer justify="center">
                <GridItem xs={12} sm={12}>
                    <h4 className={classes.infoText}>
                        AVIS RELATIF A LA PROTECTION DE VOS DONNEES
                    </h4>
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <p>
                        Les informations recueillies par BA ASSURANCES font l’objet d’un traitement informatique qui peut être destiné à la préparation, la conclusion, la gestion et l’exécution de contrats d’assurance, l’application de la réglementation en matière de lutte contre le blanchiment des capitaux et le financement du terrorisme, la lutte contre les fraudes et la mise en place de virements ou prélèvements bancaires.
                    </p>
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <p>
                        Elles sont conservées pendant toute la durée nécessaire au respect de nos obligations légales et réglementaires, c’est-à-dire au minimum pendant 5 ans à compter de la fin du contrat d’assurance souscrit.
                    </p>
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <p>
                        Les destinataires des données sont des assureurs, des intermédiaires d’assurance et, éventuellement, leurs sous-traitants qui interviennent dans le cadre de l’exécution ou de la gestion du contrat souscrit et, le cas échéant, les autorités administratives et judiciaires pour satisfaire aux obligations légales et réglementaires en vigueur. Les données peuvent également être transmises s’il y a lieu à toutes les personnes intervenant au contrat tels qu’avocats, experts, auxiliaires de justice et officiers ministériels, curateurs, tuteurs, enquêteurs, professionnels de santé, médecins-conseils et organismes sociaux lorsqu’ils interviennent dans le règlement des sinistres et des prestations. Des données peuvent également être transmises à toute personne intéressée au contrat (souscripteur, assuré, adhérent et bénéficiaire du contrat).
                    </p>
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <p>
                            Conformément à la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, vous disposez d’un droit d’accès, de portabilité, de rectification, de suppression et d’opposition aux données personnelles vous concernant, que vous pouvez exercer en adressant une demande écrite et signée à BA ASSURANCES – 43 boulevard de Strasbourg – 94130 NOGENT-SUR-MARNE (la demande doit être accompagnée d’une copie de votre pièce d’identité et préciser l’adresse à laquelle la réponse doit être envoyée).
                    </p>
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <p>
                        Vous avez la faculté d’introduire une réclamation :
                    </p>
                    <ul>
                        <li>En renseignant un formulaire de plainte en ligne sur le site de la CNIL,</li>
                        <li>En adressant un courrier postal à la CNIL – 3 place de Fontenoy – TSA 80715 – 75334 PARIS CEDEX 07 ».</li>
                    </ul>
                </GridItem>
            </GridContainer>
            {
                renderColumns(
                    {
                        columns: this.columns,
                        titre: '',
                        classes: classes,
                        fetching: fetching,
                        touched: this.state.touched
                    }
                )
            }
            </>
        );
    }
}


const mapStateToProps = store => ({
    myform:store.form,
});

const mapDispatchToProps = {
};

export default
connect(mapStateToProps, mapDispatchToProps) (
    withStyles(styles)
    (
        ProtectionDonneesPublic
    )
);

