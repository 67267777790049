import {DEVIS_CYBER_RECEIVED, DEVIS_CYBER_SAVE, DEVIS_CYBER_RESET} from "../actions";

const defaultState = {
    id: null,
    config: null,

    entrepriseSiret: null,
    entrepriseNombreSalarie: null,
    entrepriseCaAnnuel: null,
    entreprisePourcentageTransaction: null,
    entreprisePersonnesDonneesSensibles: null,
    entreprisePersonnesDonneesSensiblesDetail: null,
    entrepriseFilialeHorsUe: null,
    entrepriseFilialeHorsUeDetail: null,

    securiteFirewall: null,
    securiteMaj: null,
    securiteTestVulnerabilite: null,
    securiteNonRecoursCompteAdmin: null,
    securiteRestrictionAccess: null,
    securiteVerificationDeuxDetapes: null,
    securiteCharteBonMdp: null,
    securiteDonnnesCrypteesReseau: false,
    securiteDonnnesCrypteesPeripherique: false,
    securiteDonnnesCrypteesServeur: false,
    securitePaimentCb: null,
    securitePaimentCbDetail: null,
    securiteVerificationPrestataires: null,
    securiteAuditPrestataire: null,
    securitePolitiqueDonnees: null,
    securitePolitiqueDonneesDetail: null,
    securiteCorrespondantInformatique: null,
    securiteSauvegardeHebdo: null,
    securiteSauvegardeHebdoDetail: null,
    securitePlanReprise: null,
    securiteDelaiReprise12h: null,
    securiteDelaiReprise12hDetail: null,
    securiteProgrammeSensibilisation: null,

    antecedentAssuranceSinistres3Ans: null,
    antecedentAssuranceSinistres3AnsDetail: null,
    antecedentAssuranceDejaAssuree: null,
    antecedentAssuranceDejaAssureeDetail: null,
    antecedentAssuranceResilie: null,
    antecedentAssuranceResilieDetail: null,
    garantieSouhaitee: null,
    garantieSouhaiteeDetail: null,
    garantiePerteExploitation: null,
    garantieRc: null,
    garantieTentativeCyberRanconnage: null,
    garantieCyberDetournement: null,
    garantieCyberEspionnage: null,
    garantiePj: null,

    proposantAdresse: null,
    proposantVille: null,
    proposantCodepostal: null,
    proposantTelephone: null,
    proposantEmail: null,
    rgpd: null,
    captcha: null,
    fraisIntermediation: null,
    signatureContrat: false,
    statutPriseGarantie: 3,
    statutContrat: 0,
    statutQuittance: 0,
    statutDevis: 3,
    dateContratResilie: null,
    dateContratSanseffet: null,
    client: null,
    utilisateur: null,
    referenceNumero: null,
    ispublic: null,
    notificationUtilisateur: null,

}

export default (state = defaultState, action) => {
    switch (action.type) {
        case DEVIS_CYBER_RESET:
            return defaultState;

        case DEVIS_CYBER_SAVE:
            return {
                ...state,
                ...action.data
            };
        case DEVIS_CYBER_RECEIVED:
            delete action.data['referenceMasque'];
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }
}
