import 'whatwg-fetch';

const API_ROOT = process.env.REACT_APP_API_URL;
const debug = process.env.REACT_APP_PARAM_DEBUG;

let token = "" ;

const getHeaders = secured => {
    if (token && secured) {
        return {
            'Accept': 'application/ld+json',
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/ld+json'
        }
    } else {
        return {
            'Accept': 'application/ld+json',
            'Content-Type': 'application/json'
        }
    }
};

const checkStatus = (response)=> {
    var error = null;
    if (response.status >= 200 && response.status < 300) {
        return response.status === 204 ? {} : response.json()
    } else if (response.status >= 401) {
        if (response.statusText) {
            error = new Error(response.statusText)
        } else if (response.message) {
            error = new Error(response.message)
        } else {
            error = new Error("Erreur indéfinie")
        }
        error.response = response
        throw error
    } else {
        error = new Error(response.statusText)
        response = response ? response : [];
        error.response = response
        throw error
    }
}

function getUrl(url) {
    let u = API_ROOT + url;
    u += u.indexOf('?') >= 0 ? '&' : '?';
    u += debug;
    return u;
}
export const requests = {
  get: (url, secured = false, abortController=null) => {
      return fetch(
          getUrl(url),
          {
            headers: getHeaders(secured),
              signal: abortController ? abortController.signal : null
          }
      )
      .then(checkStatus)
      ;
  },
  post: (url, body = null, secured = true) => {
      return fetch(
          getUrl(url),
          {
              method: 'POST',
              body: body,
              headers: getHeaders(secured)
          }
      )
      .then(checkStatus)
      ;
  },
    upload: (url, body = null, secured = true) => {
        return fetch(
            getUrl(url),
            {
                method: 'POST',
                body: body,
            }
        )
            .then(checkStatus)
            ;
    },
    download: (url, secured = true, nom) => {
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);

        return fetch(
            getUrl(url),
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept':'*/*',
                    'Accept-Encoding': 'gzip, deflate',
                }
            }
        )
            .then(response => response.blob())
            .then(blobby => {
                let objectUrl = window.URL.createObjectURL(blobby);
                anchor.href = objectUrl;
                anchor.download = nom;
                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
            })
    },
    put: (url, body = null, secured = true) => {
        return fetch(
            getUrl(url),
            {
                method: 'PUT',
                body: body,
                headers: getHeaders(secured)
            }
        )
        .then(checkStatus)
        ;
    },
    delete: (url, secured = true) => {
        return fetch(
            getUrl(url),
            {
                method: 'DELETE',
                headers: getHeaders(secured)
            }
        )
        .then(checkStatus)
        ;

    },

  //delete: (url, secured = true) => {
   // return superagent.del(`${API_ROOT}${url}`).use(tokenPlugin(secured)).then(responseBody)
  //},
  setToken: (newJwtToken) => token = newJwtToken,
    getToken: () =>  {return token}
};
