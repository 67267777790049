import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Redux
import {reduxForm} from 'redux-form'
import connect from "react-redux/es/connect/connect";

// App
import columns from "./Columns.js";
import {validateColumns, renderColumns} from 'columnsUtils'
import styles from "assets/jss/styles.js"


class Informations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          touched : false
        };

        this.columns = [];
    }

    shouldComponentUpdate(nextProps, nextState) {
        this.columns = columns(
            {
                courtiers:nextProps.courtiers,
                clients:nextProps.clients,
                obj:this,
                changeCourtier:this.changeCourtier,
                getSelectedValueCourtier:this.getSelectedValueCourtier,
                changeClient:this.changeClient,
                getSelectedValueClient:this.getSelectedValueClient,
                getFraisIntermediation:this.getFraisIntermediation,
            }
        )['informations'];

        return true;
    }

    isValidated() {
        let errors = validateColumns(this.props.myform.devisImmeubleForm.values, this.columns);
        this.setState({ touched: true});
        return Object.keys(errors).length === 0;
    }

    changeCourtier(uri) {
        this.props.change("utilisateur", uri)
    }

    changeClient(uri) {
        this.props.change("client", uri)
    }

    sendState() {
        return this.state;
    }

    getSelectedValueCourtier(obj)
    {
        let there = obj;
        if (!obj) {
            there = this
        }
        if (there.props.courtiers == null || there.props.initialValues.utilisateur == null) {
            return null;
        }

        let c =  there.props.courtiers.find(
            (courtier) =>
                courtier.uri === there.props.initialValues.utilisateur
        )
        return there.props.courtiers[there.props.courtiers.indexOf(c)]
    }

    getSelectedValueClient(obj)
    {
        let there = obj;
        if (!obj) {
            there = this
        }
        if (there.props.clients == null || there.props.initialValues.client == null) {
            return null;
        }

        let c =  there.props.clients.find(
            (client) =>
                client.uri === there.props.initialValues.client
        )

        return there.props.clients[there.props.clients.indexOf(c)]
    }

    getFraisIntermediation(obj)
    {
        let there = obj;
        if (!obj) {
          there = this
        }
        let arr = []
        for (var frais = there.props.fraisIntermediationAdminMin; frais <= there.props.fraisIntermediationAdminMax; ++frais) {
          arr.push({value:frais, label:frais})
        }
        return arr;
    }

    render() {

        const { classes, fetching } = this.props;

        // Cas spécifique pour l'autocomplete
        if (fetching || this.props.clients  == null|| this.props.courtiers == null) {
            return (<div></div>)
        }

        return (
            renderColumns(
                {
                    columns: this.columns,
                    titre: 'Informations',
                    classes: classes,
                    fetching: fetching,
                    touched: this.state.touched
                }
            )
        );
    }
}

const mapStateToProps = store => ({
    ...store.requestReducer,
    ...store.courtiersReducer,
    ...store.clientsReducer,
    ...store.configurationImmeubleReducer,
    initialValues:{...store.devisImmeubleReducer},
    myform:store.form
});

const mapDispatchToProps = {
};

export default
connect(mapStateToProps, mapDispatchToProps) (
    // reduxForm nécéssaire pour le this.props.change
    reduxForm({form: 'devisImmeubleForm'}) (
        withStyles(styles)
       (
          Informations
       )
    )
);

