import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Redux
import {formValueSelector, reduxForm} from 'redux-form'
import connect from "react-redux/es/connect/connect";

// App
import columns from "./Columns.js";
import {validateColumns, renderColumns} from 'columnsUtils'
import styles from "assets/jss/styles.js"

const selector = formValueSelector('devisGarageForm')

class Garanties extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          touched : false
        };

        this.columns1 = [];
        this.columns2 = [];
        this.columns3 = [];
        this.columns4 = [];
    }

    changeGarantieVehiculePropresDetail(obj, items) {
        obj.props.change("garantieVehiculePropresDetail", JSON.stringify(items))
    }

    shouldComponentUpdate(nextProps, nextState) {
        this.columns1 = columns(
            {
            }
        )['garantiesRc'];
        this.columns2 = columns(
            {
                garantieLocauxValue:nextProps.garantieLocauxValue,
                garantieLocauxDegatsEauxValue:nextProps.garantieLocauxDegatsEauxValue,
                garantieLocauxVolValue:nextProps.garantieLocauxVolValue,
                garantieBrisGlacesValue:nextProps.garantieBrisGlacesValue,
                garantieBrisGlacesValeurAssureeValue:nextProps.garantieBrisGlacesValeurAssureeValue,
                garantieDommagesElectriquesValue:nextProps.garantieDommagesElectriquesValue,
                garantieDommagesElectriquesCapitalAssureValue:nextProps.garantieDommagesElectriquesCapitalAssureValue,
                garantieBrisMachinesValue:nextProps.garantieBrisMachinesValue,
                garantieBrisMachinesCapitalAssureValue:nextProps.garantieBrisMachinesCapitalAssureValue,
                garantiePerteExploitationValue:nextProps.garantiePerteExploitationValue,
                garantiePerteValeurVenaleValue:nextProps.garantiePerteValeurVenaleValue,
            }
        )['garantiesLocaux'];
        this.columns3 = columns(
            {
                garantieVehiculeConfiesSup76000Value:nextProps.garantieVehiculeConfiesSup76000Value,
            }
        )['garantiesVehiculesConfies'];
        this.columns4 = columns(
            {
                garantieVehiculePropresDetailValue: nextProps.garantieVehiculePropresDetailValue,
                garantieVehiculePropresValue: nextProps.garantieVehiculePropresValue,
                changeGarantieVehiculePropresDetail: this.changeGarantieVehiculePropresDetail,
                obj:this,
            }
        )['garantiesVehiculesPropres'];

        return true;
    }

    isValidated() {
        let errors = validateColumns(this.props.myform.devisGarageForm.values, this.columns1);
        errors = {...validateColumns(this.props.myform.devisGarageForm.values, this.columns2), ...errors};
        errors = {...validateColumns(this.props.myform.devisGarageForm.values, this.columns3), ...errors};
        errors = {...validateColumns(this.props.myform.devisGarageForm.values, this.columns4), ...errors};

        this.setState({ touched: true});
        return Object.keys(errors).length === 0;
    }

    sendState() {
        return this.state;
    }


    render() {

        const { classes, fetching } = this.props;

        // Cas spécifique pour l'autocomplete
        if (fetching) {
            return (<div></div>)
        }

        return (
            <>
                {
                    renderColumns(
                        {
                            columns: this.columns1,
                            titre: 'Garanties',
                            soustitre: 'Responsabilité civile professionnelle',
                            classes: classes,
                            fetching: fetching,
                            touched: this.state.touched
                        }
                    )
                }
                {
                    renderColumns(
                        {
                            columns: this.columns2,
                            titre: <div>&nbsp;</div>,
                            soustitre: 'Les locaux',
                            classes: classes,
                            fetching: fetching,
                            touched: this.state.touched
                        }
                    )
                }
                {
                    renderColumns(
                        {
                            columns: this.columns3,
                            titre: <div>&nbsp;</div>,
                            soustitre: 'Véhicules confiés',
                            classes: classes,
                            fetching: fetching,
                            touched: this.state.touched
                        }
                    )
                }
                {
                    renderColumns(
                        {
                            columns: this.columns4,
                            titre: <div>&nbsp;</div>,
                            soustitre: 'Véhicules propres',
                            classes: classes,
                            fetching: fetching,
                            touched: this.state.touched
                        }
                    )
                }
            </>
        );
    }
}

const mapStateToProps = store => ({
    myform:store.form,
    garantieLocauxValue: selector(store, 'garantieLocaux'),
    garantieLocauxDegatsEauxValue: selector(store, 'garantieLocauxDegatsEaux'),
    garantieLocauxVolValue: selector(store, 'garantieLocauxVol'),
    garantieBrisGlacesValue: selector(store, 'garantieBrisGlaces'),
    garantieBrisGlacesValeurAssureeValue: selector(store, 'garantieBrisGlacesValeurAssuree'),
    garantieDommagesElectriquesValue: selector(store, 'garantieDommagesElectriques'),
    garantieDommagesElectriquesCapitalAssureValue: selector(store, 'garantieDommagesElectriquesCapitalAssure'),
    garantieBrisMachinesValue: selector(store, 'garantieBrisMachines'),
    garantieBrisMachinesCapitalAssureValue: selector(store, 'garantieBrisMachinesCapitalAssure'),
    garantiePerteExploitationValue: selector(store, 'garantiePerteExploitation'),
    garantiePerteValeurVenaleValue: selector(store, 'garantiePerteValeurVenale'),
    garantieVehiculeConfiesSup76000Value: selector(store, 'garantieVehiculeConfiesSup76000'),
    garantieVehiculePropresValue: selector(store, 'garantieVehiculePropres'),
    garantieVehiculePropresDetailValue: selector(store, 'garantieVehiculePropresDetail'),
});

const mapDispatchToProps = {
};

export default
connect(mapStateToProps, mapDispatchToProps) (
    // reduxForm nécéssaire pour le this.props.change
    reduxForm({form: 'devisGarageForm'}) (
        withStyles(styles)
        (
            Garanties
        )
    )
);