import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Redux
import {formValueSelector} from 'redux-form'
import connect from "react-redux/es/connect/connect";

// App
import columns from "./Columns.js";
import {validateColumns, renderColumns} from 'columnsUtils'
import styles from "assets/jss/styles.js"

const selector = formValueSelector('devisImmeubleForm')

class Entretien extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          touched : false
        };

        this.columns1 = [];
        this.columns2 = [];
    }

    shouldComponentUpdate(nextProps, nextState) {
        this.columns1 = columns(
            {
            }
        )['entretien1'];

        this.columns2 = columns(
            {
            }
        )['entretien2'];

        return true;
    }

    isValidated() {
        let errors = validateColumns(this.props.myform.devisImmeubleForm.values, this.columns1);
        errors = {...validateColumns(this.props.myform.devisImmeubleForm.values, this.columns2), ...errors};
        this.setState({ touched: true});
        return Object.keys(errors).length === 0;
    }

    sendState() {
        return this.state;
    }


    render() {

        const { classes, fetching } = this.props;

        // Cas spécifique pour l'autocomplete
        if (fetching) {
            return (<div></div>)
        }

        return (
            <>
                <>
                    {
                        renderColumns(
                            {
                                columns: this.columns1,
                                titre: 'Entretien',
                                soustitre: 'Entretien général et moyens de protection',
                                classes: classes,
                                fetching: fetching,
                                touched: this.state.touched
                            }
                        )
                    }
                    {
                        renderColumns(
                            {
                                columns: this.columns2,
                                titre : <div>&nbsp;</div>,
                                soustitre: "Travaux d'entretien programmés",
                                classes: classes,
                                fetching: fetching,
                                touched: this.state.touched
                            }
                        )
                    }
                </>
            </>
        );
    }
}

const mapStateToProps = store => ({
    myform:store.form,
    securitePaimentCbValue: selector(store, 'securitePaimentCb'),
    securitePolitiqueDonneesValue: selector(store, 'securitePolitiqueDonnees'),
    securiteSauvegardeHebdoValue: selector(store, 'securiteSauvegardeHebdo'),
    securiteDelaiReprise12hValue: selector(store, 'securiteDelaiReprise12h'),

});

const mapDispatchToProps = {
};

export default
connect(mapStateToProps, mapDispatchToProps) (
    withStyles(styles)
   (
       Entretien
   )
);

