import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Redux
import {formValueSelector, reduxForm} from 'redux-form'
import connect from "react-redux/es/connect/connect";

// App
import columns from "./Columns.js";
import {validateColumns, renderColumns} from 'columnsUtils'
import styles from "assets/jss/styles.js"

const selector = formValueSelector('devisImmeubleForm')

class InformationsRisque extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          touched : false
        };

        this.columns = [];
    }

    changeInformationsSurfaceProDetail(obj, items) {
        obj.props.change("informationsSurfaceProDetail", JSON.stringify(items))
    }
    shouldComponentUpdate(nextProps, nextState) {
        this.columns = columns(
            {
                obj:this,
                informationsSurfaceProSup25Value: nextProps.informationsSurfaceProSup25Value,
                informationsSurfaceProDetailValue: nextProps.informationsSurfaceProDetailValue,
                informationsLocauxVidesValue: nextProps.informationsLocauxVidesValue,
                informationsBatimentClasseValue: nextProps.informationsBatimentClasseValue,
                changeInformationsSurfaceProDetail:this.changeInformationsSurfaceProDetail,
                informationsTypeOccupationProfessionnelleValue:nextProps.informationsTypeOccupationProfessionnelleValue

            }
        )['informationsRisque'];

        return true;
    }

    isValidated() {
        let errors = validateColumns(this.props.myform.devisImmeubleForm.values, this.columns);
        this.setState({ touched: true});
        return Object.keys(errors).length === 0;
    }

    sendState() {
        return this.state;
    }


    render() {

        const { classes, fetching } = this.props;

        // Cas spécifique pour l'autocomplete
        if (fetching) {
            return (<div></div>)
        }

        return (
            renderColumns(
                {
                    columns: this.columns,
                    titre: 'Informations sur le risque',
                    classes: classes,
                    fetching: fetching,
                    touched: this.state.touched
                }
            )
        );
    }
}

const mapStateToProps = store => ({
    myform:store.form,
    initialValues:{...store.devisImmeubleReducer},
    informationsSurfaceProSup25Value: selector(store, 'informationsSurfaceProSup25'),
    informationsSurfaceProDetailValue: selector(store, 'informationsSurfaceProDetail'),
    informationsLocauxVidesValue: selector(store, 'informationsLocauxVides'),
    informationsBatimentClasseValue: selector(store, 'informationsBatimentClasse'),
    informationsTypeOccupationProfessionnelleValue: selector(store, 'informationsTypeOccupationProfessionnelle'),
});

const mapDispatchToProps = {
};

export default
connect(mapStateToProps, mapDispatchToProps) (
    // reduxForm nécéssaire pour le this.props.change
    reduxForm({form: 'devisImmeubleForm'}) (
        withStyles(styles)
        (
            InformationsRisque
        )
    )
);
