import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Redux
import {formValueSelector} from 'redux-form'
import connect from "react-redux/es/connect/connect";

// App
import columns from "./Columns.js";
import {validateColumns, renderColumns} from 'columnsUtils'
import styles from "assets/jss/styles.js"

const selector = formValueSelector('devisCyberForm')

class Securite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          touched : false
        };

        this.columns = [];
    }

    shouldComponentUpdate(nextProps, nextState) {
        this.columns = columns(
            {
                securitePaimentCbValue: nextProps.securitePaimentCbValue,
                securitePolitiqueDonneesValue: nextProps.securitePolitiqueDonneesValue,
                securiteSauvegardeHebdoValue: nextProps.securiteSauvegardeHebdoValue,
                securiteDelaiReprise12hValue: nextProps.securiteDelaiReprise12hValue,
                classes:nextProps.classes,
            }
        )['securite'];

        return true;
    }

    isValidated() {
        let errors = validateColumns(this.props.myform.devisCyberForm.values, this.columns);
        this.setState({ touched: true});
        return Object.keys(errors).length === 0;
    }

    sendState() {
        return this.state;
    }


    render() {

        const { classes, fetching } = this.props;

        // Cas spécifique pour l'autocomplete
        if (fetching) {
            return (<div></div>)
        }

        return (
            renderColumns(
                {
                    columns: this.columns,
                    titre: 'Sécurité',
                    classes: classes,
                    fetching: fetching,
                    touched: this.state.touched
                }
            )
        );
    }
}

const mapStateToProps = store => ({
    myform:store.form,
    securitePaimentCbValue: selector(store, 'securitePaimentCb'),
    securitePolitiqueDonneesValue: selector(store, 'securitePolitiqueDonnees'),
    securiteSauvegardeHebdoValue: selector(store, 'securiteSauvegardeHebdo'),
    securiteDelaiReprise12hValue: selector(store, 'securiteDelaiReprise12h'),

});

const mapDispatchToProps = {
};

export default
connect(mapStateToProps, mapDispatchToProps) (
    withStyles(styles)
   (
       Securite
   )
);

